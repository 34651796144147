import { useEffect, useState, useCallback, useReducer } from 'react';
import * as api from '../../../api/user';
import { Paper } from '../../../components/Paper';
import { List } from '../../../components/List';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, TextField } from '@material-ui/core';
import { UserDB, UserRole } from 'foreclosure-types';
import { MainTemplate } from '../../../components/Templates/Main';
import { Select } from '../../../components/Select';

const header: Partial<Record<keyof UserDB, string>> = {
	_id: 'User ID',
	email: 'Email',
	role: 'Role',
};

type Filters = {
	email: string;
	role: UserRole;
	limit: number;
	offset: number;
};

type FilterAction = {
	type: 'setEmail' | 'setRole' | 'setLimit' | 'setOffset';
	payload: any;
};

const initialFilters: Filters = {
	email: '',
	role: UserRole.USER,
	limit: 20,
	offset: 0,
};

const filtersReducer = (state: Filters, action: FilterAction) => {
	switch (action.type) {
		case 'setRole':
			return { ...state, role: action.payload };
		case 'setEmail':
			return { ...state, email: action.payload };
		case 'setLimit':
			return { ...state, limit: action.payload };
		case 'setOffset':
			return { ...state, offset: action.payload };
		default:
			return state;
	}
};

export const UserList = () => {
	const navigate = useNavigate();
	const [list, setList] = useState<Array<UserDB>>([]);
	const [filters, dispatch] = useReducer(filtersReducer, initialFilters);

	useEffect(() => {
		(async (): Promise<void> => {
			setList(await api.get(filters));
		})();
	}, [filters]);

	const renderActions = useCallback(
		() => (
			<Button
				color="primary"
				variant="outlined"
				onClick={() => navigate('/user/new')}
			>
				Create
			</Button>
		),
		[navigate]
	);
	const renderFilters = useCallback(
		() => (
			<Box display="flex" gridGap="20px">
				<Select
					options={UserRole}
					label="Role"
					defaultValue={filters.role}
					onChange={(val) =>
						dispatch({
							type: 'setRole',
							payload: val,
						})
					}
				/>
				<TextField
					label="Email"
					name="email"
					type="email"
					value={filters.email}
					onChange={(event) => {
						dispatch({
							type: 'setEmail',
							payload: event.target.value,
						});
					}}
				/>
			</Box>
		),
		[filters]
	);

	const redirectToDetails = (el: UserDB) => {
		navigate('/edit/user/' + el._id);
	};

	return (
		<MainTemplate>
			<Container maxWidth="lg">
				<Paper>
					<List
						onRowClick={redirectToDetails}
						title="Users"
						renderActions={renderActions}
						renderFilters={renderFilters}
						header={header}
						rows={list}
					/>
				</Paper>
			</Container>
		</MainTemplate>
	);
};
