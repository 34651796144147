import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { EstateDB } from 'foreclosure-types';
import { contact } from '../../../api/estate';
import {
	emailPattern,
	numberPattern,
	useErrorMessage,
} from '../../../helpers/form';
import { useNotifications } from '../../../context/toast';
import { ProgressButton } from '../../../components/Form/ProgressButton';

type Props = {
	estateId: string;
	onSuccessContact: (estate: EstateDB) => void;
	switch?: React.ReactNode;
};

export const ContactForm = (props: Props) => {
	const { estateId, onSuccessContact } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();
	const validEmail = t('form.valid_email');
	const validNumber = t('form.valid_number');

	const { register, handleSubmit, errors, formState } = useForm({
		mode: 'onChange',
	});
	const { isValid, isSubmitting } = formState;
	const getErrorMessage = useErrorMessage();

	const onSubmit = async (values: any) => {
		try {
			const estate = await contact(estateId, values);

			setToastMessage({
				severity: 'success',
				message: t('form.success'),
			});

			onSuccessContact(estate);
		} catch (err) {
			console.log(err);

			setToastMessage({
				severity: 'error',
				message: t('form.error'),
			});
		}

		navigate(`/estate/${estateId}/contact-sent`, { replace: true });
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				{props.switch && (
					<Grid item xs={12}>
						{props.switch}
					</Grid>
				)}

				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({ required: true, minLength: 3 })}
						defaultValue=""
						error={!!errors.name}
						helperText={getErrorMessage(errors.name)}
						label={t('estateView.contact.name')}
						name="name"
						required
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({
							required: true,
							validate: (val) =>
								emailPattern.test(val) ? undefined : validEmail,
						})}
						defaultValue=""
						error={!!errors.email}
						helperText={getErrorMessage(errors.email)}
						label={t('estateView.contact.email')}
						name="email"
						required
						InputProps={{
							inputProps: {
								inputMode: 'email',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({
							required: true,
							minLength: {
								value: 10,
								message: t('form.min_length'),
							},
							validate: (val) =>
								numberPattern.test(val)
									? undefined
									: validNumber,
						})}
						defaultValue=""
						error={!!errors.phone}
						helperText={getErrorMessage(errors.phone)}
						label={t('estateView.contact.phone')}
						name="phone"
						required
						InputProps={{
							inputProps: {
								inputMode: 'tel',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						multiline
						fullWidth
						rows={4}
						variant="outlined"
						inputRef={register({ required: true, minLength: 10 })}
						defaultValue={t('estateView.contact.defaultText')}
						error={!!errors.text}
						helperText={getErrorMessage(errors.text)}
						label={t('estateView.contact.text')}
						name="text"
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								name="terms"
								inputRef={register({
									validate: (val) => !!val,
								})}
							/>
						}
						label={
							<div>
								{t('estateView.contact.I_understand')}{' '}
								<Link to="/terms-and-condition">
									{t('legal_menu.terms')}
								</Link>{' '}
								şi{' '}
								<Link to="/privacy">
									{t('legal_menu.privacy')}
								</Link>
							</div>
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<ProgressButton
						data-transaction-name="lead"
						type="submit"
						color="secondary"
						disabled={!isValid}
						loading={isSubmitting}
					>
						{t('estateView.auction.contact_button')}
					</ProgressButton>
				</Grid>
			</Grid>
		</form>
	);
};
