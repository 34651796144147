import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { EstateComment } from 'foreclosure-types';
import { createEstateComment, getEstateComments } from '../../../api/comment';
import { AddEstateComment } from './Form';
import { CommentsList } from './Comment';
import { useNotifications } from '../../../context/toast';
import { Alert } from '@material-ui/lab';
import { COMMENTS_SECTION } from '../Tour';

export const EstateComments = (props: { estateId: string }) => {
	const { estateId } = props;
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();

	const [comments, setComments] = useState([] as EstateComment[]);

	const fetchComments = useCallback(async () => {
		if (!estateId) return;

		const result = await getEstateComments(estateId);

		setComments(result);
	}, [estateId]);

	useEffect(() => {
		fetchComments();
	}, [fetchComments]);

	const postComment = useCallback(
		async (text: string) => {
			try {
				await createEstateComment(text, estateId);

				await fetchComments();
			} catch (err) {
				console.error(err);

				setToastMessage({
					severity: 'error',
					message: t('form.error'),
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[estateId]
	);

	return (
		<Box marginTop={2} className={COMMENTS_SECTION} id="comments">
			<Typography variant="h5">
				{t('estateView.comment.title')}
			</Typography>

			{comments.length > 0 && (
				<Box marginTop={1} marginBottom={4}>
					<CommentsList list={comments} />
				</Box>
			)}

			<Box marginTop={1}>
				<Alert severity="info">
					<Typography variant="body1">
						{t('estateView.auction.haveQuestions')}
					</Typography>
				</Alert>
			</Box>

			<Box marginTop={2}>
				<AddEstateComment onSubmit={postComment} />
			</Box>
		</Box>
	);
};
