import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Box, Typography } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import { UploadGallery } from '../../../components/UploadGallery';
import { SelectController } from './SelectController';
import { SpecificFieldsForSubtype } from './SpecificFieldsForSubtype';
import { SubTypeField } from './SubTypeField';
import { PriceField } from '../../../components/Form/Price';
import { useStyles } from './style';
import { Currency, PropertyType } from 'foreclosure-types';
import { useDefaultTextProps, useFormError } from './helper';
import { useAuthRole } from '../../../context/Auth';
import { useMobileSize } from '../../../hooks/responsive';

const currencyOptions: Record<Currency, string> = {
	EUR: 'Euro',
	RON: 'Ron',
};
const typeOptions: Record<PropertyType, string> = {
	ESTATE: 'Imobil',
	MOVABLE: 'Mobil',
};

type Props = {
	map: ReactNode;
};

export const PropertyForm = (props: Props) => {
	const { map } = props;
	const classes = useStyles({});

	const { isMobile } = useMobileSize();
	const { isAdmin } = useAuthRole();
	const { getFormError } = useFormError();
	const { defaultTextProps } = useDefaultTextProps();

	return (
		<>
			<Box marginBottom={3} marginTop={4}>
				<Typography variant="h5">Detalii proprietate</Typography>
			</Box>

			{map}

			<Box className={classes.fieldsContainer}>
				<Box
					className={classes.fieldsRow}
					style={{ display: isAdmin ? '' : 'none' }}
				>
					<TextField
						{...defaultTextProps}
						error={!!getFormError('location.coordinates[0]')}
						helperText={getFormError('location.coordinates[0]')}
						label="Latitudine"
						name="location.coordinates[0]"
						required
						defaultValue={0}
						disabled
					/>
					<TextField
						{...defaultTextProps}
						error={!!getFormError('location.coordinates[1]')}
						helperText={getFormError('location.coordinates[1]')}
						label="Longitudine"
						name="location.coordinates[1]"
						required
						defaultValue={0}
						disabled
					/>
					<TextField
						{...defaultTextProps}
						error={!!getFormError('address')}
						helperText={getFormError('address')}
						label="Adresa imobil"
						name="address"
						style={{ flexGrow: 1 }}
					/>
				</Box>
				<Box className={classes.fieldsRow}>
					<TextField
						{...defaultTextProps}
						error={!!getFormError('title')}
						helperText={getFormError('title')}
						label="Titlu"
						name="title"
						required
					/>

					<PriceField required name="price" label="Preț pornire" />

					<PriceField name="marketPrice" label="Preț evaluare" />
					<SelectController
						name="currency"
						label="Moneda"
						required
						options={currencyOptions}
						defaultValue="RON"
					/>
				</Box>
				<Box className={classes.fieldsRow}>
					<Controller
						name="description"
						defaultValue=""
						render={({ onChange, value }) => (
							<MDEditor
								style={{
									width: '100%',
									background: 'transparent',
								}}
								value={value}
								onChange={onChange}
								height={300}
								enableScroll
								hideToolbar={isMobile}
								placeholder="Descriere"
								preview={isMobile ? 'edit' : 'live'}
							/>
						)}
					/>
					{getFormError('description') && (
						<Typography
							color="error"
							variant="body2"
							style={{ marginTop: -12 }}
						>
							{getFormError('description')}
						</Typography>
					)}
				</Box>
				<Box className={classes.fieldsRow}>
					<SelectController
						name="type"
						label="Imobil/Mobil"
						required
						options={typeOptions}
						defaultValue="ESTATE"
					/>
					<SubTypeField />
				</Box>
				<Box className={classes.fieldsRow}>
					<SpecificFieldsForSubtype />
				</Box>
				<Box width="100%" padding={2}>
					<UploadGallery name="pictures" />
				</Box>
			</Box>
		</>
	);
};
