type EventListener = (message: string) => any;

export class EventEmitter {
	private _stack: Record<string, any[]> = {};
	private _events: Record<string, EventListener[]> = {};

	on(name: string, listener: EventListener) {
		if (!this._events[name]) {
			this._events[name] = [];
		}

		this._events[name].push(listener);

		if (this._stack[name]) {
			this._stack[name].forEach((data) => listener(data));
			delete this._stack[name];
		}
	}

	removeListener(name: string, listenerToRemove: EventListener) {
		if (!this._events[name]) {
			throw new Error(
				`Can't remove a listener. Event "${name}" doesn't exits.`
			);
		}

		const filterListeners = (listener: EventListener) =>
			listener !== listenerToRemove;

		this._events[name] = this._events[name].filter(filterListeners);
	}

	emit(name: string, data?: any, stack?: boolean) {
		if (!this._events[name]) {
			if (stack) {
				if (this._stack[name]) {
					this._stack[name].push(data);
				} else {
					this._stack[name] = [data];
				}

				return;
			}

			console.error(
				`Can't emit an event. Event "${name}" doesn't exits.`
			);

			return;
		}

		this._events[name].forEach((callback) => {
			callback(data);
		});
	}
}
