import { useMemo } from 'react';
import { EstateFilters, EstateType } from 'foreclosure-types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useCacheBasedOnDisplayEstateList } from '../pages/EstateList/hooks';

export const useFiltersFromUrl = (): EstateFilters => {
	const { search } = useCacheBasedOnDisplayEstateList(useLocation());

	return useMemo(() => {
		return qs.parse(search, { ignoreQueryPrefix: true });
	}, [search]);
};

const defaultStatusFilter = 'default';

type El = {
	key: EstateType | typeof defaultStatusFilter;
	label: string;
};

export const useSubTypeOptions = () => {
	const { t } = useTranslation();

	const subTypeKeys: El['key'][] = [
		'APARTMENT',
		'HOUSE',
		'LAND',
		'COMERCIAL',
		'FACTORY',
	];

	const subTypeOptions = subTypeKeys.reduce((acc, key) => {
		return [
			...acc,
			{
				key,
				label: t(`estate_list.sub_type.${key.toLowerCase()}`),
			},
		];
	}, [] as El[]);

	return subTypeOptions;
};
