import {
	CSSProperties,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { debounce } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import {
	TextField,
	Grid,
	Typography,
	makeStyles,
	TextFieldProps,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { apm } from '../../helpers/elastic';
import { loadMapsJS } from '../../helpers/map';

const autocompleteService: {
	current: google.maps.places.AutocompleteService | null;
} = { current: null };

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
}));

type PlaceType = google.maps.places.AutocompletePrediction;

type Props = {
	loadMapsAPI?: boolean;
	className?: string;
	onLocationChange?: (location: string) => void;
	textFieldProps?: TextFieldProps;
	value?: string;
	style?: CSSProperties;
};

const autocompleteCache: Record<string, PlaceType[]> = {};

const getAutocompletePredictions = (address: string) => {
	if (autocompleteCache[address]) {
		return autocompleteCache[address];
	}

	const spanApm = apm.startSpan('autocompleteService', 'geocode');
	spanApm?.addLabels({ address });
	spanApm?.end();

	return new Promise<PlaceType[]>((resolve) =>
		autocompleteService.current?.getPlacePredictions(
			{
				input: address,
				componentRestrictions: { country: 'ro' },
			},
			(predictions) => {
				autocompleteCache[address] = predictions;

				resolve(predictions);
			}
		)
	);
};

export const AddressAutocomplete = (props: Props) => {
	const classes = useStyles();
	const {
		loadMapsAPI = true,
		onLocationChange,
		value: valueProps,
		style = {},
	} = props;
	const [value, setValue] = useState<PlaceType | string | null>(null);
	const [options, setOptions] = useState<PlaceType[]>([]);
	const loaded = useRef(false);

	const search = useCallback(
		(inputValue: string) => {
			if (typeof value === 'string') return;

			if (!autocompleteService.current && (window as any).google) {
				autocompleteService.current = new (
					window as any
				).google.maps.places.AutocompleteService();
			}
			if (!autocompleteService.current) {
				return;
			}

			if (inputValue === '') {
				setOptions(value ? [value] : []);
				return;
			}

			if (inputValue.length < 4) return;

			(async () => {
				const results = await getAutocompletePredictions(inputValue);

				let newOptions = [] as PlaceType[];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			})();
		},
		[value]
	);

	const handleChange = useMemo(
		() =>
			debounce((_event: any, val: PlaceType | null | string) => {
				if (!val || typeof val === 'string') return;

				setOptions((prev) => (val ? [val, ...prev] : prev));
				setValue(val);

				if (onLocationChange) {
					onLocationChange(val?.description);
				}
			}, 700),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const handleInputChange = useMemo(
		() =>
			debounce((_event, val: string) => {
				if (options.find((el) => el.description === val)) return;

				search(val);
			}, 700),
		[search, options]
	);

	useEffect(() => {
		if (loadMapsAPI && !loaded.current) {
			(async () => {
				loaded.current = await loadMapsJS();
			})();
		}
	}, [loadMapsAPI]);

	useEffect(() => {
		if (valueProps) {
			if (typeof valueProps === 'string') {
				setValue({
					description: valueProps,
					place_id: '',
					matched_substrings: [],
					terms: [],
					types: [],
					reference: '',
					structured_formatting: {
						main_text: '',
						main_text_matched_substrings: [],
						secondary_text: '',
					},
				});
			} else {
				setValue(valueProps);
			}
		}
	}, [valueProps]);

	useEffect(() => handleChange.cancel, [handleChange]);
	useEffect(() => handleInputChange.cancel, [handleInputChange]);

	return (
		<Autocomplete
			style={style}
			className={props.className}
			getOptionLabel={(option) =>
				typeof option === 'string' ? option : option.description
			}
			clearOnEscape
			freeSolo
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			onChange={handleChange}
			onInputChange={handleInputChange}
			renderInput={(params) => (
				<TextField {...params} {...props.textFieldProps} />
			)}
			renderOption={(option) => {
				const matches =
					option.structured_formatting.main_text_matched_substrings;

				if (!matches?.length) return null;

				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match: any) => [
						match.offset,
						match.offset + match.length,
					])
				);

				return (
					<Grid container alignItems="center">
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{
										fontWeight: part.highlight ? 700 : 400,
									}}
								>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
};
