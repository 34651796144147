import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableRow, Checkbox } from '@material-ui/core';
import { preventDefaultNStop } from '../../helpers/events';
import { Row, EventHandler } from './types';
import { CellContent } from './CellContent';
import { SelectableListContext } from './SelectableList';

const useStyles = makeStyles(() => ({
	row: {
		cursor: 'pointer',
	},
	highlighted: {
		background: '#eee',
	},
}));

type Props<T extends Row> = {
	headerKeys: Array<string>;
	rows: Array<T>;
	highlight?: T;
	selectable?: boolean;
	onRowClick?: EventHandler<T>;
	onCheckClick?: EventHandler<T>;
};

export const ListBody = <T extends Row>(
	props: Props<T>
): React.ReactElement => {
	const classes = useStyles({});
	const selectableListContext = useContext(SelectableListContext);
	const {
		headerKeys,
		rows,
		selectable,
		onRowClick,
		onCheckClick,
		highlight,
	} = props;
	const handleClick =
		(row: T, handler?: EventHandler<T>) =>
		(event: React.SyntheticEvent): void => {
			preventDefaultNStop(event);

			handler && handler(row);
		};

	return (
		<TableBody>
			{rows.map((row) => (
				<TableRow
					key={Object.values(row).join()}
					hover
					className={
						classes.row +
						' ' +
						(highlight === row ? classes.highlighted : '')
					}
					onClick={handleClick(row, onRowClick)}
				>
					{selectable && (
						<TableCell>
							<Checkbox
								checked={selectableListContext.isChecked(row)}
								onClick={handleClick(
									row,
									selectableListContext.onClick
								)}
							/>
						</TableCell>
					)}
					{headerKeys.map((key, index) => (
						<TableCell
							key={row[key].toString()}
							align={
								index === headerKeys.length - 1
									? 'right'
									: 'left'
							}
						>
							<CellContent
								onClick={handleClick(row, onCheckClick)}
							>
								{row[key]}
							</CellContent>
						</TableCell>
					))}
				</TableRow>
			))}
		</TableBody>
	);
};
