import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { Container, LinearProgress, makeStyles } from '@material-ui/core';

import { Lead } from 'foreclosure-types';
import { MainTemplate } from '../../../components/Templates/Main';
import { getOne } from '../../../api/leads';
import { LeadHeader } from './components/LeadHeader';
import { LeadProfile } from './components/LeadProfile';
import { LeadNotes } from './components/LeadNotes';

const LeadEstates = lazy(() => import('./components/LeadEstates'));
const LeadSocial = lazy(() => import('./components/LeadSocial'));

const useStyles = makeStyles((theme) => ({
	header: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	bottomPadding: {
		width: '100%',
		height: theme.spacing(8),
	},
}));

const useLeadDetails = (id?: string) => {
	const [lead, setLead] = useState({} as Lead);
	const fetch = useCallback(async () => {
		if (!id) return;

		const lead = await getOne(id);

		setLead(lead);
	}, [id]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return { lead, setLead, fetch };
};

const Tabs = (props: {
	lead: Lead;
	refresh: () => void;
	updateLead: (lead: Lead) => any;
}) => {
	const { lead, refresh, updateLead } = props;

	return (
		<Routes>
			<Route
				path="notes"
				element={<LeadNotes lead={lead} refresh={refresh} />}
			/>
			<Route path="estates" element={<LeadEstates lead={lead} />} />
			<Route path="social" element={<LeadSocial lead={lead} />} />

			<Route
				path="*"
				element={<LeadProfile lead={lead} updateLead={updateLead} />}
			/>
		</Routes>
	);
};

export const LeadDetails = () => {
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();
	const { lead, setLead, fetch } = useLeadDetails(id);

	return (
		<MainTemplate>
			<Container>
				<div className={classes.header}>
					<LeadHeader lead={lead} />
				</div>

				<Suspense fallback={<LinearProgress />}>
					<Tabs lead={lead} updateLead={setLead} refresh={fetch} />
				</Suspense>

				<div className={classes.bottomPadding}></div>
			</Container>
		</MainTemplate>
	);
};
