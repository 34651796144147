import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import * as api from '../../api/estate';
import { makeStyles, Grid, LinearProgress, Button } from '@material-ui/core';
import {
	EstateFilters as EstateFiltersType,
	EstateSafeDB,
	ESTATE_LIST_LIMIT,
	SimpleCoordinates,
	SubscriptionTypes,
} from 'foreclosure-types';
import { MainTemplate } from '../../components/Templates/Main';
import { extractCoordinates } from '../../helpers/map';
import { defaultMapCenter } from '../../config';
import { useTranslation } from 'react-i18next';
import { FiltersContext, FiltersProvider } from '../../context/Filters';
import { useFiltersFromUrl } from '../../hooks/filters';
import { emptyEstateList } from '../../helpers/defaults';
import { PageMeta } from '../../components/PageMeta';
import { debounce } from 'lodash';
import { useNotifications } from '../../context/toast';
import { getErrorFromResponse } from '../../helpers/form';
import { useAuth } from '../../context/Auth';
import { EstatesListMap } from './EstateListMap';
import { EstateGrid } from './EstateGrid';
import {
	useCacheBasedOnDisplayEstateList,
	useEstateListUrlParams,
	usePropertyTypeFromUrl,
} from './hooks';
import { EstateFilters } from './filters';
import { useApiWrapper } from '../../helpers/api';
import { DEFAULT_RANGE } from '../../components/EstateMap';
import { promotedAuctionsFirst } from '../../helpers/estate';
import moment from 'moment';
import { useMobileSize } from '../../hooks/responsive';
import { MapOutlined } from '@material-ui/icons';
import {
	getDisplayEstateList,
	setDisplayEstateList,
} from '../../context/EstateList';

const useStyles = makeStyles((theme) => ({
	appWrapper: {
		display: 'flex',
	},
	container: {
		flexGrow: 1,
	},
	list: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(4),
		[theme.breakpoints.up('lg')]: {
			maxHeight: '100%',
			overflowY: 'auto',
		},
	},
	mapContainer: {
		[theme.breakpoints.down('lg')]: {
			height: '50vh',
		},
	},
	progressBar: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		zIndex: 999,
	},
	displayMapButton: {
		// center fixed button
		position: 'fixed',
		bottom: theme.spacing(3),
		left: '50%',
		marginLeft: -75,
		zIndex: 99999,
	},
}));

const useSearchSaving = (filters: EstateFiltersType, centerAddress: string) => {
	const { t } = useTranslation();
	const { logged } = useAuth();
	const { setToastMessage } = useNotifications();
	const apiWrapper = useApiWrapper();

	const saveSearch = useCallback(async () => {
		if (!logged) {
			setToastMessage({
				severity: 'info',
				message: t('estate_list.filters.not_logged_in'),
			});

			return;
		}

		await apiWrapper(() => api.saveSearch(filters, {}, centerAddress), {
			successMsg: t('estate_list.filters.success'),
			errorMsg: (err) =>
				getErrorFromResponse(err) || t('estate_list.filters.error'),
		});
	}, [filters, setToastMessage, apiWrapper, t, logged, centerAddress]);

	return { saveSearch };
};

const useApiFilters = (mapCenter?: SimpleCoordinates) => {
	const { filters } = useContext(FiltersContext);
	const params = usePropertyTypeFromUrl();
	const urlFilters = useFiltersFromUrl();
	const { rangeKM } = useEstateListUrlParams();

	const apiFilters = useMemo(() => {
		return {
			...filters,
			subType: urlFilters.subType || params.types,
			location: mapCenter || defaultMapCenter,
			rangeKM: rangeKM ? parseInt(rangeKM) : DEFAULT_RANGE,
		};
	}, [params, filters, rangeKM, mapCenter, urlFilters]);

	return {
		apiFilters: useCacheBasedOnDisplayEstateList(apiFilters),
	};
};

const useListLimitNotification = (
	list: Array<EstateSafeDB>,
	enabled = true
) => {
	const { setToastMessage } = useNotifications();
	const { t } = useTranslation();
	const { searchId } = useEstateListUrlParams();
	const listLength = list.length;
	const isPlaceholderList =
		listLength && list[0]._id === emptyEstateList[0]._id;

	useEffect(() => {
		if (!enabled) return;
		if (isPlaceholderList) return;

		if (listLength >= ESTATE_LIST_LIMIT) {
			setToastMessage({
				message: t('estate_list.limitReched'),
				severity: 'warning',
			});

			return;
		}

		setToastMessage({
			timeout: 2000,
			message: t(
				searchId
					? 'estate_list.savedSearchCount'
					: 'estate_list.searchCount',
				{
					count: listLength,
				}
			),
			severity: 'success',
		});
	}, [listLength, isPlaceholderList, setToastMessage, t, searchId, enabled]);
};

let cachedList: Array<EstateSafeDB> = [];

const useEstateList = (mapCenter?: SimpleCoordinates) => {
	const { apiFilters } = useApiFilters(mapCenter);
	const { searchId, since } = useEstateListUrlParams();

	const [loading, setLoading] = useState(true);
	const [list, setList] = useState<Array<EstateSafeDB>>(
		cachedList.length ? cachedList : emptyEstateList
	);

	const loadList = useMemo(
		() =>
			debounce(
				async (
					search: typeof searchId,
					filters: typeof apiFilters,
					sinceDate: typeof since
				) => {
					setLoading(true);

					const list = search
						? await api.getSavedSearch(search, { since: sinceDate })
						: await api.get(filters);

					cachedList = promotedAuctionsFirst(list);

					setList(cachedList);
					setLoading(false);
				},
				500
			),
		[]
	);

	useEffect(() => {
		if (!mapCenter) {
			// wait for map to load default location before fetching the API
			return;
		}

		loadList(searchId, apiFilters, since);
	}, [apiFilters, mapCenter, searchId, since, loadList]);

	return {
		loading,
		list,
	};
};

const EstatesContent = () => {
	const { t } = useTranslation();
	const classes = useStyles({});
	const displayEstateList = getDisplayEstateList();
	const { searchId, location } = useEstateListUrlParams();
	const { user, hasSubscription } = useAuth();
	const { isTablet } = useMobileSize();
	const [displayMap, setDisplayMap] = useState(false);
	const [highlighted, setHighlighted] = useState<EstateSafeDB>();

	const [centerAddress, setCenterAddress] = useState<string>(location || '');
	const [mapCenter, _setMapCenter] = useState<SimpleCoordinates>();
	const setMapCenter = useMemo(
		() => debounce(_setMapCenter, 500),
		[_setMapCenter]
	);
	const { apiFilters } = useApiFilters(mapCenter);
	const { saveSearch } = useSearchSaving(apiFilters, centerAddress);
	const { list, loading } = useEstateList(mapCenter);

	useListLimitNotification(list, displayEstateList);
	// useScrollToTop();

	useEffect(() => {
		setDisplayMap(!isTablet);
	}, [isTablet]);

	useEffect(() => {
		if (searchId) return;
		if (loading) return;
		if (!hasSubscription(SubscriptionTypes.PRO)) return;
		if (user.searches?.length) return;
		if (!displayEstateList) return;

		const CACHE_KEY = 'search-notification';
		const lastView = localStorage.getItem(CACHE_KEY);

		if (lastView && moment().diff(moment(lastView), 'week') < 1) return;

		localStorage.setItem(CACHE_KEY, moment().toISOString());

		setTimeout(() => {
			if (window.confirm(t('estate_list.confirmSearchNotifications'))) {
				saveSearch();
			}
		}, 2000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchId, loading, t, saveSearch, user, displayEstateList]);

	useEffect(() => {
		if (list && searchId && list.length > 0) {
			const newMapCenter = extractCoordinates(list[0].location);

			// Prevent infinite re-rendering
			if (
				newMapCenter.lat !== mapCenter?.lat ||
				newMapCenter.lng !== mapCenter?.lng
			) {
				setMapCenter(newMapCenter);
			}
		}
	}, [list, searchId, setMapCenter, mapCenter]);

	if (!displayEstateList && !location) {
		return null;
	}

	const map = (
		<EstatesListMap
			hideMap={!displayMap}
			list={list}
			disableSearch={!!searchId}
			highlighted={highlighted}
			onHighlighted={setHighlighted}
			mapCenter={mapCenter}
			onMapCenterChange={(center) => {
				if (!searchId) setMapCenter(center);
			}}
			onLocationChange={(loc) => setCenterAddress(loc.address)}
		/>
	);

	return (
		<MainTemplate
			style={{ display: displayEstateList ? '' : 'none' }}
			displayFooter={false}
			useContainerForHeader={false}
			contentClassName={classes.appWrapper}
		>
			{loading && <LinearProgress className={classes.progressBar} />}

			<PageMeta title={t('top_menu.all_estates')} />

			<Grid container className={classes.container}>
				<Grid
					item
					lg={6}
					xs={12}
					className={classes.list}
					style={{ display: displayMap && isTablet ? 'none' : '' }}
				>
					<EstateGrid
						list={list}
						highlighted={highlighted}
						onHighlighted={setHighlighted}
						filters={
							!searchId && (
								<EstateFilters saveSearch={saveSearch} />
							)
						}
					/>
				</Grid>
				<Grid item lg={6} xs={12}>
					{map}
				</Grid>
			</Grid>

			{isTablet && (
				<Button
					variant="contained"
					color="secondary"
					className={classes.displayMapButton}
					onClick={() => setDisplayMap((prev) => !prev)}
					startIcon={<MapOutlined />}
				>
					{t(
						displayMap
							? 'estate_list.hide_map'
							: 'estate_list.display_map'
					)}
				</Button>
			)}
		</MainTemplate>
	);
};

export const EstateList = (props: { display?: boolean }) => {
	setDisplayEstateList(props.display ?? true);

	return (
		<FiltersProvider>
			<EstatesContent />
		</FiltersProvider>
	);
};
