/**
Default breakpoints https://mui.com/material-ui/customization/breakpoints/
Each breakpoint (a key) matches with a fixed screen width (a value):
- xs, extra-small: 0px
- sm, small: 600px
- md, medium: 900px
- lg, large: 1200px
- xl, extra-large: 1536px
 */

export const useResponsiveSize = () => {
	const sm = typeof window !== 'undefined' && window.innerWidth < 600;
	const md = typeof window !== 'undefined' && window.innerWidth < 960;

	return {
		sm,
		md,
	};
};

export const useMobileSize = () => {
	const { sm: isMobile, md: isTablet } = useResponsiveSize();

	return { isMobile, isTablet };
};
