import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { capitalize } from 'lodash';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Estate, SubscriptionTypes, LegalReference } from 'foreclosure-types';
import { useAuth } from '../../context/Auth';
import { usePaymentModal } from '../../context/Payment';
import { Modal } from '../../components/Modal';
import { REQUEST_LEGAL_HISTORY_BUTTON } from './Tour';

const formatDate = (date: string) =>
	moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : date;

const Row = (props: {
	left: ReactNode;
	right: ReactNode;
	truncate?: boolean;
}) => {
	if (!props.right) return null;

	return (
		<Box display="flex" marginBottom={1}>
			<Box fontWeight="bold" width="30%">
				{props.left}
			</Box>
			<Box
				flex="1"
				{...(props.truncate === false
					? null
					: {
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
					  })}
			>
				{props.right}
			</Box>
		</Box>
	);
};

const TextDivider = () => (
	<Box marginBottom={1}>
		<Divider />
	</Box>
);

const LegalReferenceText = (props: { reference: LegalReference }) => {
	const { reference: ref } = props;

	return (
		<Box marginBottom={3}>
			<Box marginBottom={2}>
				<Typography variant="h6">{capitalize(ref.obiect)}</Typography>
			</Box>
			<Row left="Dată" right={formatDate(ref.data)} />
			<Row
				left="Dată Modificare"
				right={formatDate(ref.dataModificare)}
			/>
			<Row left="Număr" right={ref.numar} />
			<Row left="Număr Vechi" right={ref.numarVechi} />
			<Row left="Instituție" right={ref.institutie} />
			<Row left="Departament" right={ref.departament} />
			<Row left="Categorie Caz" right={ref.categorieCaz} />
			<Row left="Stadiu Procesual" right={ref.stadiuProcesual} />
			<TextDivider />
			{ref.parti?.DosarParte?.map((parte) => (
				<Row left={parte.calitateParte} right={parte.nume} />
			))}
			<TextDivider />
			{/* {ref.caiAtac?.DosarCaleAtac.map((parte) => (
				<Row
					left={`Cale Atac - ${parte.tipCaleAtac}`}
					right={parte.parteDeclaratoare}
				/>
			))} */}
			<Box marginBottom={2} textAlign="center">
				<Typography variant="body1">
					<strong>Sedințe</strong>
				</Typography>
			</Box>
			{ref.sedinte?.DosarSedinta?.map((sedinta, index) => (
				<>
					<Row left="Dată" right={formatDate(sedinta.data)} />
					<Row left="Document" right={sedinta.documentSedinta} />
					<Row left="Număr Document" right={sedinta.numarDocument} />
					<Row left="Soluție" right={sedinta.solutie} />
					<Row
						left="Rezumat"
						right={sedinta.solutieSumar}
						truncate={false}
					/>

					{index < ref.sedinte.DosarSedinta.length - 1 && (
						<TextDivider />
					)}
				</>
			))}
		</Box>
	);
};

const LegalReferencesText = (props: { references: LegalReference[] }) => {
	const { t } = useTranslation();
	const { references } = props;

	return (
		<Box
			overflow="auto"
			maxHeight="70vh"
			maxWidth="90vw"
			width="700px"
			padding={2}
		>
			<Box marginBottom={2}>
				<Typography variant="h4">
					{t('estateView.auction.legalReferences')}
				</Typography>
			</Box>
			{references
				.sort((a, b) => moment(b.data).diff(a.data))
				.map((ref) => (
					<LegalReferenceText reference={ref} />
				))}
			<Box marginBottom={2}>
				<Alert severity="info">
					{t('estateView.auction.legalReferencesDisclaimer')}
				</Alert>
			</Box>
		</Box>
	);
};

export const LegalReferences = (props: {
	references: Estate['legalReferrences'] | null;
}) => {
	const { t } = useTranslation();
	const { logged, hasSubscription } = useAuth();
	const { openPaymentModal } = usePaymentModal();
	const { references } = props;
	const [open, setOpen] = useState(false);

	const showReferences = useCallback(() => setOpen(true), []);
	const closeReferences = useCallback(() => setOpen(false), []);

	if (!logged) {
		return (
			<>
				<Alert severity="warning">
					{t('estateView.auction.requestLegalReferencesNotLogged')}
				</Alert>
				<Box marginTop={2}>
					<Link to="/login">
						<Button color="primary" variant="contained">
							{t('login.title')}
						</Button>
					</Link>
				</Box>
			</>
		);
	}

	if (!hasSubscription(SubscriptionTypes.PRO) ) {
		return (
			<>
				<Alert severity="warning">
					{t(
						'estateView.auction.requestLegalReferencesNotSubscribed'
					)}
				</Alert>
				<Box marginTop={2}>
					<Button
						color="primary"
						variant="contained"
						onClick={openPaymentModal}
					>
						{t('payment.subscribe')}
					</Button>
				</Box>
			</>
		);
	}

	if (!references) {
		return (
			<Alert severity="success">
				{t('estateView.auction.noLegalReference')}
			</Alert>
		);
	}

	return (
		<>
			<Modal open={open} onClose={closeReferences}>
				<LegalReferencesText references={references as any} />
			</Modal>
			<Button
				color="primary"
				variant="contained"
				onClick={showReferences}
				className={REQUEST_LEGAL_HISTORY_BUTTON}
			>
				{t('estateView.auction.requestLegalReferences')}
			</Button>
		</>
	);
};
