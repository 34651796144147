import axios from './cache';
import qs from 'qs';
import { baseUrl } from './config';
import {
	EstateDB,
	EstateSafeDB,
	EstateResponse,
	EstateFilters,
	EstateSort,
	Contact,
	BuyOffer,
} from 'foreclosure-types';
import { promotedAuctionsFirst } from '../helpers/estate';

const apiUrl = baseUrl + '/estate';
const documentUrl = baseUrl + '/document';
const pictureUrl = baseUrl + '/picture';
const contactUrl = baseUrl + '/contact';

const addBaseURLToPictures = (pictures?: string[]) => {
	return pictures?.length
		? pictures.map((url) =>
				url.includes('www') ? url : `${pictureUrl}/${url}`
		  )
		: [];
};

const hydrateEstatePictures = <T extends EstateSafeDB>(estates: T[]) => {
	estates.forEach(
		(estate) => (estate.pictures = addBaseURLToPictures(estate.pictures))
	);

	return estates;
};

const removeBaseURLFromPictures = (pictures?: string[]) => {
	return pictures?.length
		? pictures.map((url) => url.replace(pictureUrl + '/', ''))
		: [];
};

export const get = async (
	filter: EstateFilters = {},
	sort: EstateSort = {}
) => {
	const query = qs.stringify({ ...filter, ...sort }, { encode: false });
	const response = await axios.get(`${apiUrl}?${query}`);

	return hydrateEstatePictures(response.data as EstateResponse['getList']);
};

export const getSavedSearch = async (
	searchId: string,
	params: { since?: string } = {}
) => {
	const query = qs.stringify(params, { encode: false });
	const response = await axios.get(`${apiUrl}/search/${searchId}?${query}`);

	return promotedAuctionsFirst(
		hydrateEstatePictures(response.data as EstateResponse['getList'])
	);
};

export const getAllSavedSearches = async (
	userId?: string,
	params: { since?: string } = {}
) => {
	const query = qs.stringify(params, { encode: false });
	const response = await axios.get(
		userId
			? `${apiUrl}/search/user/${userId}?${query}`
			: `${apiUrl}/search?${query}`
	);
	const data = response.data as EstateResponse['allSavedSearches'];

	data.forEach((row) => {
		row.list = promotedAuctionsFirst(hydrateEstatePictures(row.list));
	});

	return data;
};

export const saveSearch = async (
	filter: EstateFilters = {},
	sort: EstateSort = {},
	address: string
) => {
	const query = qs.stringify(
		{ ...filter, ...sort, address },
		{ encode: false }
	);

	await axios.post(`${apiUrl}/search?${query}`);
};

export const deleteSearch = async (searchId: string) => {
	await axios.delete(`${apiUrl}/search/${searchId}`);
};

export const markAsRead = async (searchId: string) => {
	await axios.put(`${apiUrl}/search/${searchId}`, { lastViewedAt: 'now' });
};

export const getSavedSearchesCount = async () => {
	const response = await axios.get(`${apiUrl}/search/count`);

	return response.data as EstateResponse['savedSearchCount'];
};

export const getSelfEstatesCount = async () => {
	const response = await axios.get(`${apiUrl}/self/count`);

	return response.data as EstateResponse['count'];
};

export const getSelfEstates = async () => {
	const response = await axios.get(`${apiUrl}/self`);

	return response.data as EstateResponse['adminGetAll'];
};

export const getCount = async () => {
	const response = await axios.get(`${apiUrl}/count`);

	return response.data as EstateResponse['count'];
};

export const getById = async (
	id: string
): Promise<EstateResponse['getOne']> => {
	const response = await axios.get(`${apiUrl}/${id}`);
	const estate: EstateResponse['getOne'] = response.data;

	estate.pictures = addBaseURLToPictures(estate.pictures);

	return estate;
};

export const getAllAdmin = async (): Promise<EstateResponse['adminGetAll']> => {
	const response = await axios.get(`${apiUrl}/admin`);

	return response.data;
};

export const getForProvider = async (
	provider: string
): Promise<EstateResponse['getList']> => {
	const response = await axios.get(`${apiUrl}/provider/${provider}`);

	return response.data;
};

export const getSubscribedEstates = async (
	filter: EstateFilters = {},
	sort: EstateSort = {}
): Promise<EstateResponse['getList']> => {
	const query = qs.stringify({ ...filter, ...sort }, { encode: false });
	const response = await axios.get(`${apiUrl}/subscribed?${query}`);

	return hydrateEstatePictures(response.data as EstateResponse['getList']);
};

export const unsubscribeFromEstate = async (estateId: string) => {
	await axios.delete(`${apiUrl}/subscribed/${estateId}`);
};

export const watchEstate = async (estateId: string) => {
	await axios.post(`${apiUrl}/watch/${estateId}`);
};

export const getByIdAdmin = async (
	id: string
): Promise<EstateResponse['adminGetOne']> => {
	const response = await axios.get(`${apiUrl}/admin/${id}`);
	const estate: EstateResponse['adminGetOne'] = response.data;

	estate.pictures = addBaseURLToPictures(estate.pictures);

	return estate;
};

export const requestEstateHistory = async (id: string) => {
	await axios.post(`${contactUrl}/estate/${id}/history`);
};

export const contact = async (
	id: string,
	body: Pick<Contact, 'name' | 'email' | 'phone' | 'text'>
): Promise<EstateResponse['contact']> => {
	const response = await axios.post(`${contactUrl}/estate/${id}`, body);
	const estate: EstateDB = response.data;

	estate.pictures = addBaseURLToPictures(estate.pictures);

	return estate;
};

export const sendBuyOffer = async (id: string, body: BuyOffer) => {
	const response = await axios.post(`${contactUrl}/estate/buy/${id}`, body);
	const estate: EstateDB = response.data;

	estate.pictures = addBaseURLToPictures(estate.pictures);

	return estate as EstateResponse['contact'];
};

export const update = async (
	estate: EstateDB
): Promise<EstateResponse['put']> => {
	const { _id, ...body } = estate;
	body.pictures = removeBaseURLFromPictures(body.pictures);

	await axios.put(`${apiUrl}/${_id}`, body);
};

export const create = async (
	estate: EstateDB
): Promise<EstateResponse['post']> => {
	const { _id, ...body } = estate;
	body.pictures = removeBaseURLFromPictures(body.pictures);

	const response = await axios.post(apiUrl, body);

	return response.data;
};

export const uploadPictures = async (estateId: string, pictures: FileList) => {
	const form = new FormData();

	Object.values(pictures).forEach((picture) => {
		form.append('pictures', picture, picture.name);
	});

	await axios.post(`${pictureUrl}/${estateId}`, form);
};

export const deletePicture = async (pictureName: string) => {
	await axios.delete(`${pictureUrl}/${pictureName}`);
};

export const uploadAuctionDocument = async (
	estateId: string,
	doc: File
): Promise<void> => {
	const form = new FormData();

	form.append('document', doc, doc.name);

	await axios.post(`${documentUrl}/${estateId}`, form);
};

export const getAuctionDocument = async (
	documentName: string
): Promise<Buffer> => {
	const { data } = await axios.get(`${documentUrl}/${documentName}`);

	return data;
};

export const remove = async (id: string): Promise<EstateResponse['delete']> => {
	await axios.delete(`${apiUrl}/${id}`);
};

export const getEstatePublications = async (
	id: string
): Promise<EstateResponse['publications']> => {
	const { data } = await axios.get(`${apiUrl}/${id}/publications`);

	return data;
};
