import { ReactElement, Ref, FormEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { SchemaErrors } from '../../types/SchemaErrors';
import { AuthTemplate } from '../../components/Templates/Auth';
import { ReactHookField } from '../../components/ReactHookField';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

type Props = {
	title: string;
	handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
	errors: SchemaErrors;
	emailRef: Ref<ReactElement>;
	passwordRef: Ref<ReactElement>;
	children?: ReactNode;
};

export const LoginTemplate = (props: Props): ReactElement => {
	const { t } = useTranslation();
	const { handleSubmit, title } = props;
	const classes = useStyles({});

	const form = (
		<form className={classes.form} onSubmit={handleSubmit}>
			<ReactHookField
				autoFocus
				name="email"
				label="Email"
				type="email"
				autoComplete="email"
				required
			/>
			<ReactHookField
				name="password"
				label="Parola"
				type="password"
				autoComplete="current-password"
				required
			/>
			<Box marginTop={2}>
				<Typography variant="body2">
					{t('login.forgotPassword')}
				</Typography>
			</Box>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			>
				{t('login.submit')}
			</Button>
			{props.children}
		</form>
	);

	return <AuthTemplate title={title} form={form} />;
};
