import axios from 'axios';
import { SubscriberResponse } from 'foreclosure-types';
import { baseUrl } from './config';

const apiUrl = baseUrl + '/mail';

type SubscribeUser = {
	email: string;
	phone?: string;
	states: string[];
	acceptTerms: boolean;
	acceptNotifications: boolean;
};

export const subscribe = async (values: SubscribeUser) => {
	await axios.post(`${apiUrl}/subscribe`, values);
};

export const unsubscribe = async (id: string) => {
	await axios.post(`${apiUrl}/unsubscribe/${id}`);
};

export const getSubscriberStatus = async () => {
	const { data } = await axios.get(apiUrl);

	return data as SubscriberResponse['getOne'];
};
