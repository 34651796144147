import { createRoot } from 'react-dom/client';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { isProduction } from './config';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (isProduction) {
	const updateSite = async () => {
		try {
			const ready = await navigator.serviceWorker.ready;
			ready.update();
		} catch (err) {
			console.error('Service worker update error', err);
		}

		window.location.reload();
	};
	serviceWorkerRegistration.register({
		onUpdate(registration) {
			const waitingServiceWorker = registration.waiting;

			if (waitingServiceWorker) {
				waitingServiceWorker.addEventListener('statechange', () => {
					if (waitingServiceWorker.state === 'activated') {
						updateSite();
					}
				});
				waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
			}
		},
	});
} else {
	serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (!isProduction) {
// 	reportWebVitals((...args) => console.log('performance', ...args));
// }
