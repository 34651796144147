import { ReactNode, useEffect, useRef, useState } from 'react';
import { Paper, makeStyles, Drawer } from '@material-ui/core';
import { useMobileSize } from '../../hooks/responsive';

const useStyles = makeStyles((theme) => ({
	drawer: {},
	relativeOverlay: {
		position: 'absolute',
		left: 0,
		top: theme.spacing(6),
		zIndex: 9,
		borderRadius: 12,
	},
	rightPosition: {
		left: 'auto',
		right: 0,
	},
	filterContainer: {
		border: '0.5px solid rgba(118, 118, 118, 0.28)',
	},
}));

type Props = {
	open: boolean;
	overlayId: string;
	children: ReactNode;
};

export const Overlay = (props: Props) => {
	const classes = useStyles();
	const { isMobile: isPhone, isTablet } = useMobileSize();
	const isMobile = isPhone || isTablet;
	const containerRef = useRef<HTMLDivElement>(null);

	const [isOnLeft, setIsOnLeft] = useState(true);

	useEffect(() => {
		if (!props.open) return;

		const self = containerRef.current;

		if (!self) return;

		const parent = self.closest('.filters-container') as HTMLDivElement;

		if (!parent) return;

		const selfOffset = self.getBoundingClientRect();
		const parentOffset = parent.getBoundingClientRect();

		if (
			parentOffset.width > selfOffset.width &&
			selfOffset.right > parentOffset.right
		) {
			setIsOnLeft(false);
		}
	}, [props.open]);

	if (isMobile) {
		return (
			<Drawer
				open={props.open}
				anchor="bottom"
				PaperProps={{
					style: { backgroundColor: 'transparent' },
				}}
			>
				<div
					className={[
						classes.drawer,
						classes.filterContainer,
						props.overlayId,
					].join(' ')}
				>
					{props.children}
				</div>
			</Drawer>
		);
	}

	return (
		<Paper
			ref={containerRef}
			className={[
				classes.filterContainer,
				classes.relativeOverlay,
				props.overlayId,
				isOnLeft ? '' : classes.rightPosition,
			].join(' ')}
			style={{ display: props.open ? '' : 'none' }}
		>
			{props.children}
		</Paper>
	);
};
