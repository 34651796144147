import { useFormContext, useWatch } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { EstateLandType, SubType, PropertyType } from 'foreclosure-types';
import { SelectController } from './SelectController';
import { useDefaultTextProps, useFormError } from './helper';

const landTypeOptions: Record<EstateLandType, string> = {
	URBAN: 'Intravilan',
	RURAL: 'Extravilan',
};

export const SpecificFieldsForSubtype = () => {
	const { control } = useFormContext();
	const { getFormError } = useFormError();
	const { defaultTextProps: defaultProps } = useDefaultTextProps();

	const defaultTextProps = {
		...defaultProps,
		InputLabelProps: {
			shrink: true,
		},
	};

	const auctionSubType = useWatch<SubType>({
		control,
		name: 'subType',
		defaultValue: 'HOUSE',
	});
	const auctionType = useWatch<PropertyType>({
		control,
		name: 'type',
		defaultValue: 'ESTATE',
	});

	const yearField = (
		<TextField
			{...defaultTextProps}
			error={!!getFormError('year')}
			helperText={getFormError('year')}
			label="An"
			name="year"
			type="number"
			defaultValue={2000}
		/>
	);

	if (auctionType !== 'ESTATE') {
		return yearField;
	}

	return (
		<>
			{auctionSubType !== 'LAND' && (
				<>
					{yearField}
					<TextField
						{...defaultTextProps}
						error={!!getFormError('propertySize')}
						helperText={getFormError('propertySize')}
						label="Suprafata proprietate (mp)"
						name="propertySize"
						type="number"
						defaultValue={0}
						inputProps={{ step: '.1' }}
					/>
				</>
			)}
			{(['APARTMENT', 'HOUSE'] as SubType[]).includes(auctionSubType) && (
				<TextField
					{...defaultTextProps}
					error={!!getFormError('rooms')}
					helperText={getFormError('rooms')}
					label="Nr camere"
					name="rooms"
					type="number"
					defaultValue={0}
				/>
			)}
			{auctionSubType !== 'APARTMENT' && (
				<TextField
					{...defaultTextProps}
					error={!!getFormError('landSize')}
					helperText={getFormError('landSize')}
					label="Suprafata teren (mp)"
					name="landSize"
					type="number"
					defaultValue={0}
					inputProps={{ step: '.1' }}
				/>
			)}
			{auctionSubType === 'LAND' && (
				<SelectController
					name="landType"
					label="Tip teren"
					defaultValue="URBAN"
					options={landTypeOptions}
				/>
			)}
		</>
	);
};
