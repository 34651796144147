import moment from 'moment';
import {
	AuctionDateType,
	Currency,
	EstateSafe,
	getAuctionDateType,
} from 'foreclosure-types';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import axios from '../api/cache';

const ronFormatter = new Intl.NumberFormat('ro-RO', {
	style: 'currency',
	currency: 'RON',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0,
	//maximumFractionDigits: 0,
});
const euroFormatter = new Intl.NumberFormat('ro-RO', {
	style: 'currency',
	currency: 'EUR',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0,
	//maximumFractionDigits: 0,
});

export const displayPrice = (
	price: number,
	currency: Currency = Currency.RON
) => {
	if (!price) {
		return i18next.t('general.undefinedPrice');
	}

	if (currency === 'RON') {
		return ronFormatter.format(price);
	}

	if (currency === 'EUR') {
		return euroFormatter.format(price);
	}

	return price + ' ' + currency;
};

export const getRON = (price: number, currency: Currency) => {
	if (currency === 'RON') {
		return price;
	}

	if (currency === 'EUR') {
		return price * 5;
	}

	return price;
};

export const valueIsMissing = (val?: string) => {
	return !val || val.trim().toLowerCase() === 'none';
};

const formatDate = (date: moment.Moment, includesHour = false) => {
	if (includesHour) {
		return {
			date,
			formatted: date.format('YYYY-MM-DD HH:mm'),
		};
	}

	return {
		date,
		formatted: date.format('YYYY-MM-DD'),
	};
};

const parseDate = (date: string) => {
	if (date.includes('.') && !date.includes('.00')) {
		if (date.includes(' ')) {
			return formatDate(moment.utc(date, 'DD.MM.YYYY HH:mm'), true);
		}

		return formatDate(moment.utc(date, 'DD.MM.YYYY'), false);
	}

	return formatDate(moment.utc(date), true);
};

const displayOneTimeDate = (date: string) => {
	const undefinedDate = i18next.t('advertCard.undefinedDate');

	const parsed = parseDate(date);

	if (!parsed.date.isValid()) {
		return undefinedDate;
	}

	const now = moment().utc();

	if (parsed.date.isBefore(now)) {
		return i18next.t('advertCard.passedDate', { date: parsed.formatted });
	}

	return parsed.formatted || undefinedDate;
};

const displayRecurrentDate = (
	auctionDateRecurrence: EstateSafe['auctionDateRecurrence']
) => {
	const undefinedDate = i18next.t('advertCard.undefinedDate');

	if (!auctionDateRecurrence) return undefinedDate;

	const { day, interval, hour } = auctionDateRecurrence;

	const days = day.map((d) =>
		d.length > 2 ? i18next.t(`estateEdit.date.${d.toLowerCase()}`) : d
	);
	const intervalText = i18next.t(`estateEdit.date.${interval.toLowerCase()}`);
	const and = i18next.t('general.and');

	return [intervalText, days.join(` ${and} `), hour].join(' ');
};

export const displayEstateDate = (
	estate: Partial<Pick<EstateSafe, 'auctionDate' | 'auctionDateRecurrence'>>
) => {
	const { auctionDate = '', auctionDateRecurrence } = estate;

	if (!estate.auctionDate && !estate.auctionDateRecurrence) return '';

	const dateType = getAuctionDateType({ auctionDate, auctionDateRecurrence });

	if (dateType === AuctionDateType.RECURRENT) {
		return displayRecurrentDate(auctionDateRecurrence);
	}

	return displayOneTimeDate(auctionDate);
};

export const getValueForDatePicker = (date?: moment.MomentInput) => {
	return moment.utc(date).format('YYYY-MM-DDTHH:mm').replace('Z', '');
};

export const formatOwnerPhone = (phone: string) => {
	return phone
		.toLowerCase()
		.replace(/ /g, '')
		.replace(/sau/g, ';')
		.split(';')
		.filter((el) => !valueIsMissing(el));
};

export const useMarkDownText = (path: string) => {
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		(async () => {
			const response = await axios.get(path);

			setMarkdown(response.data);
		})();
	}, [path]);

	return {
		markdown,
	};
};
