import { Currency, EstateSafeDB, SubType } from 'foreclosure-types';
import { apartment, house, land, car, otherAuction } from '../assets';
import { defaultMapCenter } from '../config';

export const defaultPictures: Record<SubType, any> = {
	APARTMENT: apartment,
	HOUSE: house,
	LAND: land,
	COMERCIAL: land,
	FACTORY: land,
	CAR: car,
	OTHER: otherAuction,
};

export const emptyEstate: EstateSafeDB = {
	_id: '',
	address: '',
	features: [],
	pictures: [],
	landType: 'URBAN',
	currency: Currency.RON,
	state: 'AVAILABLE',
	type: 'ESTATE',
	subType: 'HOUSE',
	auctionDate: 'none',
	title: '...',
	description: '...',
	price: 1000,
	updatedPrice: 0,
	county: '',
	location: {
		type: 'Point',
		coordinates: [defaultMapCenter.lng, defaultMapCenter.lat],
	},
	propertySize: 0,
	landSize: 0,
	marketPrice: 0,
	rooms: 2,
	year: 0,
	user: '6186dc379dc93800fd6fcf2e',
	viewCount: 0,
	contactCount: 38,
	commentsCount: 0,
	auctionType: 'BAILIFF',
};

export const getEmptyEstate = (_id: string) => {
	return {
		...emptyEstate,
		_id,
	};
};

export const emptyEstateList = [
	getEmptyEstate('1'),
	getEmptyEstate('2'),
	getEmptyEstate('3'),
	getEmptyEstate('4'),
];
