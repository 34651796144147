import { Button, makeStyles } from '@material-ui/core';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiltersContext } from '../../context/Filters';
import { useResponsiveSize } from '../../hooks/responsive';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		borderTop: '0.5px solid rgba(118, 118, 118, 0.28)',
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

export const Footer = (props: {
	onSave?: () => void;
	onReset?: () => void;
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { sm: isMobile } = useResponsiveSize();
	const { setFilters } = useContext(FiltersContext);
	const { onReset } = props;

	const handleClear = useCallback(() => {
		setFilters({});

		if (onReset) onReset();
	}, [setFilters, onReset]);

	return (
		<div className={classes.root}>
			<Button onClick={handleClear}>
				{t('estate_list.filters.reset')}
			</Button>
			<Button color="primary" variant="contained" onClick={props.onSave}>
				{isMobile
					? t('estate_list.filters.show_results')
					: t('estate_list.filters.save')}
			</Button>
		</div>
	);
};
