import { Tab, Tabs } from '@material-ui/core';
import {
	useGmailTabsStyles,
	useGmailTabItemStyles,
} from '@mui-treasury/styles/tabs';
import Inbox from '@material-ui/icons/Inbox';
import LocalOffer from '@material-ui/icons/LocalOffer';
import People from '@material-ui/icons/People';
import Info from '@material-ui/icons/Info';

import { Lead } from 'foreclosure-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
	wrapper: {
		overflowX: 'auto',
		'& button': {
			maxWidth: '100% !important',
		},
		'& .MuiTabs-scroller': {
			minWidth: 700,
		},
	},
}));

const indicatorColors = ['#d93025', '#1a73e8', '#188038', '#e37400'];

enum TabValue {
	Profile = 'profile',
	Social = 'social',
	Estates = 'estates',
	Notes = 'notes',
}

export const LeadHeader = (props: { lead: Lead }) => {
	const { lead } = props;
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const tab = pathname.split('/').pop() as TabValue;

	const classes = useStyles();
	const tabsStyles = useGmailTabsStyles({ indicatorColors });
	const tabItem1Styles = useGmailTabItemStyles({ color: indicatorColors[0] });
	const tabItem2Styles = useGmailTabItemStyles({ color: indicatorColors[1] });
	const tabItem3Styles = useGmailTabItemStyles({ color: indicatorColors[2] });
	const tabItem4Styles = useGmailTabItemStyles({ color: indicatorColors[3] });

	const currenTabIndex = Object.values(TabValue).indexOf(tab);
	const currentTab = currenTabIndex > -1 ? tab : TabValue.Profile;
	const tabIndex = currenTabIndex > -1 ? currenTabIndex : 0;

	return (
		<div className={classes.wrapper}>
			<Tabs
				classes={tabsStyles}
				value={currentTab}
				onChange={(_e, value) => {
					navigate('/admin/leads/' + lead._id + '/' + value, {
						replace: true,
					});
				}}
				TabIndicatorProps={{
					children: <div className={`MuiIndicator-${tabIndex}`} />,
				}}
			>
				<Tab
					value={TabValue.Profile}
					classes={tabItem1Styles}
					disableTouchRipple
					label={`Profil - ${lead.name}`}
					icon={<Inbox />}
				/>
				<Tab
					value={TabValue.Social}
					classes={tabItem2Styles}
					disableTouchRipple
					label={
						<div className={'MuiTabItem-labelGroup'}>
							<div className={'MuiTabItem-label'}>
								WhatsApp{' '}
								<span className={'MuiTabItem-tag'}>2 new</span>
							</div>
						</div>
					}
					icon={<People />}
				/>
				<Tab
					value={TabValue.Estates}
					classes={tabItem3Styles}
					disableTouchRipple
					label="Licitații"
					icon={<LocalOffer />}
				/>
				<Tab
					value={TabValue.Notes}
					classes={tabItem4Styles}
					disableTouchRipple
					label="Notițe"
					icon={<Info />}
				/>
			</Tabs>
		</div>
	);
};
