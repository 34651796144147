import { Button, makeStyles, Typography } from '@material-ui/core';
import { EstateSafeDB, SubType } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { displayEstateDate, displayPrice } from '../helpers/display';
import { getEstateLink, getEstateState } from '../helpers/form';
import { EstateImage } from './EstateImage';
import { useMobileSize } from '../hooks/responsive';
import { PromotedLabel } from './PromotedLabel';
import { translateOwner } from '../helpers/estate';
import { FiltersContext } from '../context/Filters';
import { useContext } from 'react';

// https://developers.google.com/gmail/design/reference/supported_css
// Gmail doesn't support any flex property!!
const useStyles = makeStyles((theme) => ({
	container: {
		border: '1px solid transparent',
		borderTopColor: theme.palette.grey[200],
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		display: (props: Props) => (props.forceMobile ? 'block' : 'flex'),
	},
	content: {
		display: (props: Props) => (props.forceMobile ? 'block' : 'flex'),
		flex: 1,
	},
	image: {
		minHeight: 200,
		height: '100%',
		width: (props: Props) => (props.forceMobile ? '100%' : 'auto'),
	},
	details: {
		flex: 1,
		height: '100%',
		padding: theme.spacing(2),
	},
	description: {
		flexGrow: 1,
		paddingTop: theme.spacing(2),
		overflowY: 'auto',
		height: (props: Props) => (props.forceMobile ? 'auto' : 120),
		maxHeight: (props: Props) => (props.forceMobile ? 150 : 'auto'),
	},
	priceAndSize: {
		height: '100%',
		padding: theme.spacing(2),
		textAlign: (props: Props) => (props.forceMobile ? 'left' : 'right'),
		width: (props: Props) => (props.forceMobile ? '100%' : 200),
		'&& > *': {
			width: (props: Props) => (props.forceMobile ? 'auto' : '100%'),
			display: (props: Props) =>
				props.forceMobile ? 'inline-block' : 'block',
			verticalAlign: 'top',
		},
	},
	detailsLink: {
		float: (props: Props) => (props.forceMobile ? 'right' : 'none'),
	},
	priceContainer: {
		height: (props: Props) => (props.forceMobile ? 'auto' : 150),
		textAlign: (props: Props) => (props.forceMobile ? 'left' : 'right'),
	},
	price: {
		color: theme.palette.text.hint,
	},
	link: {
		display: 'block',
		position: 'relative',
	},
}));

const getPropertySize = (content: EstateSafeDB): string => {
	const propertyAndLand: SubType[] = ['HOUSE', 'FACTORY', 'COMERCIAL'];

	if (propertyAndLand.includes(content.subType)) {
		return [content.propertySize, content.landSize]
			.filter(Boolean)
			.map((el) => `${el}mp`)
			.join(' + ');
	}

	if (content.subType === 'LAND' && content.landSize) {
		return content.landSize + 'mp';
	}

	if (content.subType === 'CAR' && content.year) {
		return `${content.year}`;
	}

	if (content.propertySize) {
		return content.propertySize + 'mp';
	}

	return '';
};

type Props = {
	id?: string;
	content: EstateSafeDB;
	highlighted?: boolean;
	onMouseEnter?: () => void;
	forceMobile?: boolean;
};

export const Advert = (props: Props) => {
	const { isMobile } = useMobileSize();
	const forceMobile = isMobile || props.forceMobile;
	const classes = useStyles({
		...props,
		forceMobile,
	});
	const { t } = useTranslation();
	const { content, highlighted, onMouseEnter, id } = props;
	const { filters } = useContext(FiltersContext);

	const displayState = filters.state === 'ALL';
	const detailsLink = getEstateLink(content);

	return (
		<div className={classes.container} onMouseEnter={onMouseEnter} id={id}>
			<Link to={detailsLink} className={classes.link}>
				<EstateImage
					className={classes.image}
					imageURL={
						content.pictures.length ? content.pictures[0] : null
					}
					type={content.subType}
				/>
				{content.promoted && <PromotedLabel />}
			</Link>
			<div className={classes.content}>
				<div className={classes.details}>
					<Typography variant="h5" component="span">
						{displayState
							? `${getEstateState(content.state)} - `
							: ''}
						{content.title}
					</Typography>
					<Typography variant="subtitle2">
						{content.address || ''}
					</Typography>
					<Typography variant="subtitle2">
						{t('advertCard.date') +
							' ' +
							displayEstateDate(content)}
					</Typography>
					<Typography variant="body1" className={classes.description}>
						<strong>{translateOwner(content.auctionType)}</strong>
						{': ' +
							content.description
								.slice(0, forceMobile ? 200 : 120)
								.trim() +
							'...'}
					</Typography>
				</div>
				<div className={classes.priceAndSize}>
					<div className={classes.priceContainer}>
						<Typography
							variant="h5"
							component="span"
							className={classes.price}
						>
							{displayPrice(content.price, content.currency)}
						</Typography>
						{getPropertySize(content) && (
							<>
								<br />
								<Typography variant="h6" component="span">
									{getPropertySize(content)}
								</Typography>
							</>
						)}
						<br />
						<Typography variant="subtitle2" component="span">
							{content.commentsCount === 1
								? t('advertCard.comment')
								: t('advertCard.comments', {
										count: content.commentsCount,
								  })}
						</Typography>
					</div>
					<Link className={classes.detailsLink} to={detailsLink}>
						<Button
							color={highlighted ? 'primary' : 'secondary'}
							variant={highlighted ? 'contained' : 'outlined'}
						>
							{t('advertCard.button_label')}
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};
