import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import { PaymentRow1 } from '../Payment/Subscription';
import { useUserCount } from '../../hooks/auth';

export const HomepageSubscription = () => {
	const count = useUserCount();
	const total = count.basic + count.pro + count.agency;

	return (
		<Box marginTop={10}>
			<Box textAlign="center" marginTop={5}>
				<Typography variant="h3">Ești investitor imobiliar?</Typography>
			</Box>

			<Box textAlign="center" marginTop={2}>
				<Typography variant="h6">
					{total} investitori deja profită de oferta noastră!
				</Typography>
			</Box>

			<Box textAlign="center" marginTop={4}>
				<Link to="/subscription">
					<Button variant="contained" color="primary" size="large">
						Vezi oferta pentru investitori
					</Button>
				</Link>
			</Box>

			<PaymentRow1 />
		</Box>
	);
};
