import { ReactNode, createContext, useContext } from 'react';

let displayEstateList = true;

export const setDisplayEstateList = (val: boolean) => {
	displayEstateList = val;
};

export const getDisplayEstateList = () => {
	return displayEstateList;
};

export const EstateListContext = createContext<{
	displayEstateList: boolean;
}>({
	displayEstateList: true,
});

type Props = {
	children: ReactNode;
	displayEstateList?: boolean;
};

export const EstateListProvider = (props: Props) => {
	const { children, displayEstateList = true } = props;

	return (
		<EstateListContext.Provider value={{ displayEstateList }}>
			{children}
		</EstateListContext.Provider>
	);
};

export const useEstateListContext = () => {
	return useContext(EstateListContext);
};
