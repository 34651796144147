import { ReactNode, useMemo, useRef } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { SubscriptionPicker } from '../../components/Payments/SubscriptionPicker';
import { MainTemplate } from '../../components/Templates/Main';
// @ts-ignore
import legalHistoryImg from '../../assets/images/legal-history.png';
// @ts-ignore
import foreclosureHistoryImg from '../../assets/images/foreclosure-history.png';
import { useMobileSize } from '../../hooks/responsive';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	title: {
		textAlign: 'center',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		color: theme.palette.primary.main,
	},
	video: {
		display: 'block',
		maxWidth: '100%',
		margin: '0 auto',
	},
	image: {
		maxWidth: '100%',
	},
	text: {
		fontSize: 24,
		lineHeight: '1.2858342857',
		fontWeight: 400,
		letterSpacing: '.007em',
	},
}));

const Row = (props: {
	media: ReactNode;
	children: ReactNode;
	title: ReactNode;
	cta?: ReactNode;
	alignMedia: 'left' | 'right';
	forceMobile?: boolean;
}) => {
	const { isMobile } = useMobileSize();
	const { media, children, title, alignMedia, forceMobile, cta } = props;
	const mediaOnLeft = alignMedia === 'left';

	const header = (
		<Box marginBottom={4}>
			<Typography variant="h3">{title}</Typography>
		</Box>
	);

	if (isMobile || forceMobile) {
		return (
			<Box marginBottom={8} marginTop={8}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						{header}
					</Grid>
					<Grid item xs={12}>
						{media}
					</Grid>
					<Grid item xs={12}>
						{children}
					</Grid>
					{cta && (
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							alignItems="center"
						>
							{cta}
						</Grid>
					)}
				</Grid>
			</Box>
		);
	}

	const content = (
		<>
			{header}
			{children}
		</>
	);

	return (
		<Box marginBottom={15} marginTop={15}>
			<Grid container spacing={6}>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent="center"
					alignItems="center"
					style={{ textAlign: 'right' }}
				>
					{mediaOnLeft ? media : content}
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent="center"
					alignItems="center"
					style={{ textAlign: 'left' }}
				>
					{mediaOnLeft ? content : media}
				</Grid>
				{cta && (
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						alignItems="center"
					>
						{cta}
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

const Text = (props: { children: ReactNode }) => {
	const classes = useStyles();

	return (
		<Typography variant="body1" className={classes.text}>
			{props.children}
		</Typography>
	);
};

type RowProps = {
	forceMobile?: boolean;
};

const ResponsiveVideo = () => {
	const { isMobile, isTablet } = useMobileSize();
	const containerRef = useRef(null);
	const height = useMemo(() => {
		if (!containerRef.current) return 500;

		const ASPECT_RATIO = 15 / 20; // width: 15, height: 20
		const container = containerRef.current as HTMLDivElement;
		const width = container.clientWidth;
		const height = width / ASPECT_RATIO;

		return height;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile, isTablet, containerRef.current]);

	return (
		<iframe
			ref={containerRef}
			height={height}
			width="500"
			src="https://www.youtube-nocookie.com/embed/Cl09Xy3ZyJc?controls=0&autoplay=1&mute=1&loop=1&playlist=Cl09Xy3ZyJc"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			className={useStyles().video}
		></iframe>
	);

	// return (
	// 	<div
	// 		ref={containerRef}
	// 		style={{
	// 			padding: '75% 0 0 0',
	// 			position: 'relative',
	// 			width: '100%',
	// 			height,
	// 			maxWidth: 500,
	// 			margin: '0 auto',
	// 		}}
	// 	>
	// 		<iframe
	// 			src="https://player.vimeo.com/video/770395694?h=fa3e33fe7c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
	// 			frameBorder="0"
	// 			allow="autoplay; fullscreen; picture-in-picture"
	// 			allowFullScreen
	// 			style={{
	// 				position: 'absolute',
	// 				top: '0',
	// 				left: '0',
	// 				width: '100%',
	// 				height: '100%',
	// 			}}
	// 			title="demo-cf.mov"
	// 		></iframe>
	// 		{/* <script src="https://player.vimeo.com/api/player.js"></script> */}
	// 	</div>
	// );
};

export const PaymentRow1 = (props: RowProps & { children?: ReactNode }) => (
	<Row
		forceMobile={props.forceMobile}
		alignMedia="left"
		title="Localizează CF-ul pe hartă printr-un click"
		media={<ResponsiveVideo />}
		cta={
			<Link to="/cf">
				<Button variant="contained" color="primary" size="large">
					Încearcă Geoportalul Gratuit
				</Button>
			</Link>
		}
	>
		<div>
			<Text>
				Toate licitațiile au numărul CF pentru teren! Dar cum îl găsesc?
			</Text>
			<br />
			<Text>
				Nimic mai simplu! Mergi pe Geoportalul nostru si alege Județul,
				Comuna și numărul CF. <strong>Atât.</strong>
			</Text>
			<br />
			<Text>Bonus: vezi și aria în metri pătrați!</Text>
			<br />
			<Text>
				P.S. Din Google Maps poți vedea detalii incredibile fără să
				mergi la fața locului.
			</Text>
			<br />
			<Text>
				P.P.S. Vrei să te convingi? Ai nevoie doar de un cont pentru a
				vedea un singur CF <strong>(fără niciun cost)</strong> pe
				Geoportalul nostru!
			</Text>
			{props.children}
		</div>
	</Row>
);

export const PaymentRow2 = (props: RowProps) => (
	<Row
		forceMobile={props.forceMobile}
		alignMedia="right"
		title="Verifică instant istoricul juridic al unei licitații!"
		media={
			<img src={legalHistoryImg} alt="" className={useStyles().image} />
		}
	>
		<div>
			<Text>
				O licitație cu un istoric cât mai mic sau inexistent este un
				semn bun!
			</Text>
			<Text>
				Riscul să te trezești cu contestații după licitație este mic!
			</Text>
		</div>
	</Row>
);

export const PaymentRow3 = (props: RowProps) => (
	<Row
		forceMobile={props.forceMobile}
		alignMedia="left"
		title="Vezi termenele trecute ale licitațiilor!"
		media={
			<img
				src={foreclosureHistoryImg}
				alt=""
				className={useStyles().image}
			/>
		}
	>
		<div>
			<Text>
				Primești acces la istoricul licitațiilor unde poți să vezi
				reducerile de preț.
			</Text>
			<Text>
				Dacă o licitație este la al 20-lea termen, de ce este așa?
			</Text>
		</div>
	</Row>
);

export const SubscriptionContent = (props: { forceMobile?: boolean }) => {
	const { forceMobile } = props;

	return (
		<Container maxWidth="md">
			<Box textAlign="center" marginBottom={7} marginTop={5}>
				<Typography variant="h4" color="primary">
					Alege abonamentul construit pentru investitorii imobiliari!
				</Typography>
			</Box>

			<PaymentRow1 forceMobile={forceMobile} />

			<Box
				textAlign="center"
				color="primary.main"
				marginTop={8}
				marginBottom={8}
			>
				<Typography variant="h4">
					Cu cât ai mai multe informații despre o licitație, cu atât
					este riscul mai mic!
				</Typography>
			</Box>

			<PaymentRow2 forceMobile={forceMobile} />

			<PaymentRow3 forceMobile={forceMobile} />
		</Container>
	);
};

export const SubscriptionPage = () => {
	const classes = useStyles();

	return (
		<MainTemplate displayHeaderShadow={false}>
			<SubscriptionContent />

			<Grid container alignContent="center" direction="column">
				<Grid item xs={12}>
					<Typography
						variant="h4"
						id="options"
						classes={{ root: classes.title }}
					>
						Alege acum un abonament!
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<SubscriptionPicker scroll={false} />
				</Grid>
			</Grid>
		</MainTemplate>
	);
};
