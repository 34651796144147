import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, makeStyles, Tooltip } from '@material-ui/core';
import { Share } from '@material-ui/icons';
import { useMobileSize } from '../hooks/responsive';
import { useCallback, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	button: {
		maxHeight: '100%',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		'&&': {
			border: 'none',
			minWidth: 'auto',
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
	},
}));

export const ShareButton = (props: ButtonProps) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isMobile } = useMobileSize();
	const { onClick } = props;
	const [open, setOpen] = useState(false);

	const onClickWithConfirmation = useCallback(
		(...args: any) => {
			if (!onClick) return;

			const result = onClick.apply(null, args);

			if (Boolean(result)) {
				setOpen(true);

				setTimeout(() => {
					setOpen(false);
				}, 2000);
			}
		},
		[onClick]
	);

	return (
		<Tooltip title={t('locateCF.shared')} open={open}>
			{isMobile ? (
				<Button
					className={classes.button}
					{...props}
					onClick={onClickWithConfirmation}
				>
					<Share />
				</Button>
			) : (
				<Button
					endIcon={<Share />}
					className={classes.button}
					{...props}
					onClick={onClickWithConfirmation}
				>
					{t('locateCF.share')}
				</Button>
			)}
		</Tooltip>
	);
};
