import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { SubscriptionPrice, SubscriptionTypes } from 'foreclosure-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/Auth';
import { useSubscription } from '../../../helpers/payment';
import moment from 'moment';

const getSubscriptionTranslation = (subscription?: SubscriptionTypes) => {
	switch (subscription) {
		case SubscriptionTypes.AGENCY:
			return 'account.agency_subscription';
		case SubscriptionTypes.PRO:
			return 'account.pro_subscription';
		case SubscriptionTypes.BASIC:
			return 'account.basic_subscription';
		default:
			return 'account.free_subscription';
	}
};

export const SubscriptionStatus = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { cancel } = useSubscription();

	const subscribed =
		user.subscription && user.subscription !== SubscriptionTypes.FREE;
	const price = (() => {
		if (user.skipPaymentUntil) {
			return 0;
		}

		if (user.subscription === SubscriptionTypes.AGENCY) {
			return SubscriptionPrice.AGENCY;
		}

		if (user.subscription === SubscriptionTypes.PRO) {
			return SubscriptionPrice.PRO;
		}

		return SubscriptionPrice.BASIC;
	})();

	const cancelSubscription = useCallback(async () => {
		if (!window.confirm(t('account.cancel_subscription_confirmation'))) {
			return;
		}

		await cancel();
	}, [t, cancel]);

	return (
		<div>
			<Typography variant="h6">
				{t('account.your_subscription')}
			</Typography>
			<br />

			{subscribed && (
				<>
					<Typography>
						<strong>
							{t('subscription.montly_price', { price })}
						</strong>
					</Typography>
					<br />
				</>
			)}

			<Typography>
				{user.skipPaymentUntil
					? t('account.canceled_subscription', {
							date: moment(user.skipPaymentUntil).format(
								'YYYY-MM-DD'
							),
							subscription: user.subscription?.toUpperCase(),
					  })
					: t(getSubscriptionTranslation(user.subscription))}
			</Typography>
			<br />

			{(() => {
				if (subscribed && !user.skipPaymentUntil) {
					return (
						<>
							<Alert severity="info">
								{t('account.subscription_note')
									.split('\n')
									.map((text) => (
										<div>{text}</div>
									))}
							</Alert>
							<br />
							<br />
							<Alert severity="info">
								{t('account.failed_card')}
							</Alert>
							<br />
							<br />
							<Link to="/subscription#options">
								<Button color="primary">
									{t('account.update_subscription')}
								</Button>
							</Link>
							<br />
							<br />
							<Button onClick={cancelSubscription}>
								{t('account.cancel_subscription')}
							</Button>
						</>
					);
				}

				return (
					<Link to="/subscription">
						<Button>{t('account.choose_subscription')}</Button>
					</Link>
				);
			})()}
		</div>
	);
};
