import { useCallback, ChangeEvent, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Link,
	makeStyles,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	EstateFilters as EstateFiltersType,
	EstateType,
	SubscriptionTypes,
} from 'foreclosure-types';
import { FiltersContext } from '../../../context/Filters';
import { InnerFiltersProps } from './types';
import {
	ApartmentFilters,
	HouseFilters,
	LandFilters,
	PriceFilter,
} from './byType';
import { useFieldsStyles } from './styles';
import { SubTypeFilter } from './SubType';
import { Alert } from '@material-ui/lab';
import { useAuth } from '../../../context/Auth';
import { usePaymentModal } from '../../../context/Payment';
import { StateFilter } from './byType/State';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(4),
		'& > *': {
			marginBottom: theme.spacing(1),
		},
	},
	contentWrapper: {
		[theme.breakpoints.down('sm')]: {
			overflowX: 'scroll',
			paddingTop: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			marginLeft: -theme.spacing(2),
			marginRight: -theme.spacing(3),
		},
	},
	content: {
		display: 'flex',
		flexWrap: 'nowrap',
		'& > *': {
			marginBottom: theme.spacing(2),
			marginRight: theme.spacing(1),
		},
	},
	subType: {
		width: '100%',
	},
}));

const defaultStatusFilter = 'default';

const isEvent = (value: any): value is ChangeEvent<HTMLInputElement> =>
	typeof value === 'object' && 'target' in value;
const getPartialFilter = (
	field: keyof EstateFiltersType,
	event: string | string[] | ChangeEvent
) => {
	const value = isEvent(event) ? event.target.value : (event as string);

	if (value === '' || value === defaultStatusFilter) return null;

	return { [field]: value };
};

const filtersByPropertyType: Record<
	EstateType | 'default',
	(props: InnerFiltersProps) => JSX.Element
> = {
	default: () => <></>,
	LAND: LandFilters,
	COMERCIAL: LandFilters,
	FACTORY: LandFilters,
	APARTMENT: ApartmentFilters,
	HOUSE: HouseFilters,
};

export const EstateFilters = (props: { saveSearch?: () => any }) => {
	const { t } = useTranslation();
	const { hasSubscription } = useAuth();
	const { openPaymentModal } = usePaymentModal();
	const { filters, setFilters } = useContext(FiltersContext);

	const classes = useStyles();
	const fieldsClasses = useFieldsStyles();

	const [propertyType, setPropertyType] = useState<EstateType | 'default'>(
		// @ts-ignore
		Array.isArray(filters.subType) ? filters.subType[0] : 'default'
	);
	const filter = useRef<EstateFiltersType>({});

	const handleChange = useCallback(
		(field: keyof EstateFiltersType) =>
			(event: string | string[] | ChangeEvent) => {
				if (field === 'subType') {
					// reset filters
					filter.current = {};
					setPropertyType(event as EstateType);
				}
				const fieldFilter = getPartialFilter(field, event);

				if (!fieldFilter) {
					delete filter.current[field];
				} else {
					filter.current = {
						...filter.current,
						...fieldFilter,
					};
				}

				// always create new object
				setFilters({ ...filter.current });
			},
		[setFilters]
	);

	const SpecificFilters = filtersByPropertyType[propertyType];

	return (
		<div
			className={[
				classes.root,
				fieldsClasses.fields,
				'filters-container',
			].join(' ')}
		>
			<div className={classes.contentWrapper}>
				<div className={classes.content}>
					<StateFilter handleChange={handleChange} />
					<PriceFilter handleChange={handleChange} />

					{propertyType === 'default' && (
						<Tooltip title="Numărul de dosar suprascrie toate celelalte filtre">
							<TextField
								size="small"
								name="executionNumber"
								label="Număr Dosar"
								value={filters.executionNumber}
								onChange={handleChange('executionNumber')}
							/>
						</Tooltip>
					)}

					{SpecificFilters && (
						<SpecificFilters handleChange={handleChange} />
					)}
				</div>
			</div>

			<div className={classes.subType}>
				<SubTypeFilter handleChange={handleChange} />
			</div>

			<Box marginTop={2}>
				<Box textAlign="center" marginBottom={2}>
					<Button color="primary" onClick={props.saveSearch}>
						{t('estate_list.filters.save_search')}
					</Button>
				</Box>

				{!hasSubscription(SubscriptionTypes.PRO) && (
					<Alert severity="warning">
						{t('estate_list.filters.subscription_required')}
						{'  '}
						<Link href="#" onClick={openPaymentModal}>
							{t('account.choose_subscription')}!
						</Link>
					</Alert>
				)}
			</Box>
		</div>
	);
};
