export enum WatiEventType {
    BROADCAST = 'broadcastMessage',
    TICKET = 'ticket',
    MESSAGE = 'message',
}

export enum WatiStatus {
    SENT = 'SENT',
    READ = 'READ',
}

export type WatiMessage = {
    actor?: string | null;
    assignee?: string | null;
    conversationId: string;
    created: string;
    eventDescription: string;
    eventType: WatiEventType;
    failedDetail: string;
    text: string;
    id: string;
    owner: boolean;
    localMessageId: string | null;
    mediaHeaderLink: string | null;
    statusString: WatiStatus;
    template: string | null;
    ticketId: string | null;
};

export enum WatiTemplateStatus {
    APPROVED = 'APPROVED',
    DELETED = 'DELETED',
}

export enum WatiTemplateCategory {
    MARKETING = 'MARKETING',
    UTILITY = 'UTILITY',
    AUTO_REPLY = 'AUTO_REPLY',
    ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
}

export type WatiTemplate = {
    id: string;
    elementName: string; // template name
    category: WatiTemplateCategory;
    customParams: Array<{ paramName: string; paramValue: string }>;
    status: WatiTemplateStatus;
    language: { key: 'Romanian'; value: 'ro'; text: 'Romanian' };
    lastModified: string; // ISO
    type: 'template';
    header: null | {
        type: 1;
        typeString: string;
        text: string;
        link: null | string;
        mediaHeaderId: null;
        mediaFromPC: null;
        headerOriginal: string;
        headerParamMapping: [{ index: number; paramName: string }];
    };
    body: string;
    footer: null | string;
    buttons:
        | null
        | [
              {
                  type: 'quick_reply';
                  parameter: {
                      text: string;
                      phoneNumber: null | string;
                      url: null | string;
                      urlOriginal: null | string;
                      urlType: 'none';
                      buttonParamMapping: null;
                  };
              }
          ];
    buttonsType: 'none' | 'quick_reply';
    expiresIn: 0;
    includeExpiryTime: false;
    addSecurityRecommendation: false;
};
