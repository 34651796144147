import { useEffect, useState } from 'react';
import moment from 'moment';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	AddOutlined,
	CheckOutlined,
	DeleteOutlineOutlined,
	EditOutlined,
} from '@material-ui/icons';
import { useAuth } from '../context/Auth';
import { Select } from './Select';

const useStyles = makeStyles((theme) => ({
	adminAvatar: {
		backgroundColor: theme.palette.primary.main,
	},
	newNote: {
		backgroundColor: theme.palette.success.main,
	},
}));

const NoteCard = (props: {
	isNew: boolean;
	editable: boolean;
	text: string;
	title: string;
	subtitle: string;
	selectProps?: {
		options?: Record<string, string>;
		value: string;
	};
	onChange: (params: { select?: string; text?: string }) => any;
	onSubmit: () => any;
	onDelete: () => any;
}) => {
	const classes = useStyles();
	const {
		text,
		title,
		subtitle,
		selectProps,
		isNew,
		editable,
		onChange,
		onDelete,
		onSubmit,
	} = props;
	const [edit, setEdit] = useState(isNew);

	useEffect(() => {
		setEdit(isNew);
	}, [isNew]);

	return (
		<Card elevation={0}>
			<CardHeader
				avatar={
					<Avatar
						className={
							isNew ? classes.newNote : classes.adminAvatar
						}
					>
						{isNew ? <AddOutlined /> : title[0]?.toUpperCase()}
					</Avatar>
				}
				action={
					<>
						{(isNew || editable) && (
							<IconButton
								color="primary"
								onClick={() => {
									if (edit) onSubmit();

									setEdit(!edit);
								}}
							>
								{edit ? <CheckOutlined /> : <EditOutlined />}
							</IconButton>
						)}
						{!isNew && editable && (
							<IconButton color="secondary" onClick={onDelete}>
								<DeleteOutlineOutlined />
							</IconButton>
						)}
					</>
				}
				title={title}
				subheader={subtitle}
			/>
			<CardContent>
				{edit ? (
					<>
						{selectProps?.options &&
							Boolean(
								Object.keys(selectProps?.options || {}).length
							) && (
								<Box marginBottom={2} maxWidth={400}>
									<Select
										fullWidth
										options={selectProps.options}
										defaultValue={selectProps.value}
										onChange={(val) =>
											onChange({ select: val })
										}
									/>
								</Box>
							)}

						<TextField
							placeholder="Text..."
							fullWidth
							multiline
							minRows={5}
							contentEditable={edit}
							value={text}
							onChange={(event) =>
								onChange({ text: event.target.value })
							}
						/>
					</>
				) : (
					<>
						{selectProps?.options && selectProps.value && (
							<Box marginBottom={2} maxWidth={400}>
								<Select
									fullWidth
									disabled
									options={selectProps.options}
									defaultValue={selectProps.value}
								/>
							</Box>
						)}

						<Typography
							component="pre"
							variant="body2"
							color="textSecondary"
						>
							{text}
						</Typography>
					</>
				)}
			</CardContent>
		</Card>
	);
};

type Props = {
	values: Array<{
		text: string;
		createdAt: string;
		updatedAt: string;
		author?: string;
		authorName?: string;
		selectProps?: {
			options?: Record<string, string>;
			value: string;
		};
	}>;
	selectProps?: {
		options: Record<string, string>;
	};
	onChange: (
		params: { select?: string; text?: string },
		index: number
	) => void;
	onSubmit?: () => unknown;
};

export const Notes = (props: Props) => {
	const { values, onChange, onSubmit, selectProps } = props;
	const { user } = useAuth();

	return (
		<Grid container direction="column" spacing={2}>
			{values.map((el, i) => {
				const date = el.updatedAt || el.createdAt;

				return (
					<Grid item xs={12} key={el.createdAt}>
						<NoteCard
							selectProps={{
								value: el.selectProps?.value || '',
								options:
									el.selectProps?.options ||
									selectProps?.options ||
									{},
							}}
							editable={user._id === el.author}
							isNew={!date}
							title={
								date
									? el.authorName || el.author || ''
									: 'Notă nouă'
							}
							subtitle={
								date
									? moment(date).format('YYYY-MM-DD HH:mm')
									: ''
							}
							text={el.text}
							onSubmit={() => {
								if (onSubmit) onSubmit();
							}}
							onChange={(val) => onChange(val, i)}
							onDelete={() => {
								if (
									!window.confirm(
										'Sigur vrei să ștergi nota?'
									)
								)
									return;

								onChange({ text: '' }, i);

								if (onSubmit) onSubmit();
							}}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};
