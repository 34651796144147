import React from 'react';
import { withStyles } from '@material-ui/core';

const Footer = (props: {
	children: JSX.Element;
	classes: { root: string };
}): React.ReactElement => (
	<div className={props.classes.root}>{props.children}</div>
);

export const PaperFooter = withStyles((theme) => ({
	root: {
		marginTop: theme.spacing(3),
		display: 'flex',
		'& > *': {
			marginRight: theme.spacing(2),
		},
	},
}))(Footer);
