import { useCallback, useState, useEffect, useContext } from 'react';
import {
	FieldValues,
	FormProvider,
	useForm,
	UseFormMethods,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { EstateEditForm } from './Form';
import * as api from '../../api/estate';
import { Container, makeStyles } from '@material-ui/core';
import {
	AuctionDateType,
	EstateDB,
	EstateDBAdmin,
	getAuctionDateType,
	RecurrenceDay,
	RecurrenceInterval,
} from 'foreclosure-types';
import { valueIsMissing } from '../../helpers/display';
import { ToastContext } from '../../context/toast';
import { MainTemplate } from '../../components/Templates/Main';
import { useTranslation } from 'react-i18next';
import { initialEstate, initialValues } from './initialValues';
import { EstateEditMap } from './Form/EstateEditMap';
import { EstateTasks } from './Tasks';
import { getEstateLink } from '../../helpers/form';
import { useAuthRole } from '../../context/Auth';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(4),
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	documentModal: {
		minHeight: 200,
		minWidth: 200,
		outline: 'none',
		padding: theme.spacing(2),
	},
}));

type RouteParams = {
	id: string;
};

const useDelete = () => {
	const { id } = useParams<RouteParams>();
	const navigate = useNavigate();
	const { setToastMessage } = useContext(ToastContext);
	const { t } = useTranslation();

	return useCallback(async () => {
		if (!id) return;

		try {
			// eslint-disable-next-line no-restricted-globals
			if (!confirm('Sigur vrei sa stergi?')) return;

			await api.remove(id);

			setToastMessage({
				message: t('estateEdit.deleted'),
				severity: 'success',
			});

			navigate('/', { replace: true });
		} catch (err) {
			setToastMessage({
				message: t('estateEdit.error'),
				severity: 'error',
			});
		}
	}, [id, navigate, setToastMessage, t]);
};

type FormValues = EstateDB & {
	auctionDocumentFile: FileList;
	uploadPictures: FileList;
	auctionDateRecurrence_interval: RecurrenceInterval;
	auctionDateRecurrence_day: RecurrenceDay[];
	auctionDateRecurrence_hour: string;
	auctionDateType: AuctionDateType;
	auctionEmail: string;
	auctionPhone: string;
	auctionAddress: string;
	auctionLocation: { type: 'Point'; coordinates: [number, number] };
};

const mapFormForApi = (form: FormValues, estate: EstateDBAdmin) => {
	const {
		auctionDocumentFile,
		uploadPictures,
		auctionDateRecurrence_interval,
		auctionDateRecurrence_day,
		auctionDateRecurrence_hour,
		auctionDateType,
		...rest
	} = form;
	const keys = Object.keys(initialEstate) as Array<keyof EstateDB>;

	const newEstate = keys.reduce((acc, key) => {
		return {
			...acc,
			// @ts-ignore
			[key]: rest[key],
		};
	}, {} as EstateDB);

	if (!newEstate.address) newEstate.address = estate.address;

	if (!newEstate.provider) newEstate.provider = null;

	if (
		valueIsMissing(newEstate.auctionDate) ||
		auctionDateType === AuctionDateType.DISABLED
	) {
		newEstate.auctionDate = 'none';
	}

	if (auctionDateType === AuctionDateType.RECURRENT) {
		newEstate.auctionDate = 'none';
		newEstate.auctionDateRecurrence = {
			interval: auctionDateRecurrence_interval,
			day: auctionDateRecurrence_day,
			hour: auctionDateRecurrence_hour,
		};
	} else {
		// @ts-ignore
		newEstate.auctionDateRecurrence = null;
	}

	const default0Fields: Array<keyof EstateDB> = [
		'price',
		'year',
		'propertySize',
		'rooms',
		'landSize',
	];

	default0Fields.forEach((key) => {
		// @ts-ignore
		if (!newEstate[key]) newEstate[key] = 0;
	});

	if (!form.landCF) {
		// removing landCF removes the CF entirely
		newEstate.cfCoordinatesId = null;
	}

	return newEstate;
};

const useSubmit = (
	estate: EstateDBAdmin,
	formMethods: UseFormMethods<FieldValues>,
	onComplete?: (form: FormValues) => void
) => {
	const { id } = useParams<RouteParams>();
	const navigate = useNavigate();

	const { setToastMessage } = useContext(ToastContext);
	const { t } = useTranslation();
	const { setError, getValues } = formMethods;

	const onSubmit = async () => {
		const form = getValues() as FormValues;
		const { auctionDocumentFile = [], uploadPictures } = form;
		const newEstate = mapFormForApi(form, estate);

		try {
			if (id === 'new' || !id) {
				const response = await api.create(newEstate);
				if (auctionDocumentFile.length) {
					await api.uploadAuctionDocument(
						response._id,
						auctionDocumentFile[0]
					);
				}
				if (uploadPictures.length) {
					await api.uploadPictures(response._id, uploadPictures);
				}

				navigate('/estate/' + response._id);

				setToastMessage({
					message: t('estateEdit.created'),
					severity: 'success',
				});
			} else {
				await api.update({ ...newEstate, _id: id });
				if (auctionDocumentFile.length) {
					await api.uploadAuctionDocument(id, auctionDocumentFile[0]);
				}
				if (uploadPictures.length) {
					await api.uploadPictures(id, uploadPictures);
				}

				setToastMessage({
					message: t('estateEdit.updated'),
					severity: 'success',
				});
			}

			onComplete && onComplete(form);
		} catch (err: any) {
			const response = err?.response?.data;
			const responseError = response?.error;

			console.log(responseError, err);

			if (responseError && typeof responseError === 'object') {
				Object.values(responseError).forEach((val: any) => {
					if (val?.message && val?.label) {
						setError(val.label, {
							message: val.message,
						});
					}
				});
			} else {
				setToastMessage({
					message:
						typeof responseError === 'string'
							? response.error
							: t('estateEdit.error'),
					severity: 'error',
				});
			}
		}
	};

	return { onSubmit };
};

export const EstateEdit = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isAdmin } = useAuthRole();
	const { setToastMessage } = useContext(ToastContext);
	const { id } = useParams<RouteParams>();
	const navigate = useNavigate();

	const formMethods = useForm({ mode: 'onChange' });
	const { handleSubmit, setValue } = formMethods;
	const { isDirty } = formMethods.formState;

	const [estate, setEstate] = useState<EstateDBAdmin>({} as EstateDBAdmin);

	const { onSubmit } = useSubmit(estate, formMethods, (form) => {
		if (form.uploadPictures.length) {
			formMethods.setValue('uploadPictures', new DataTransfer().files);
		}
	});
	const deleteEstate = useDelete();

	const goBack = useCallback(() => {
		if (!id || id === 'new') {
			navigate(-1);
			return;
		}

		navigate(getEstateLink(estate), { replace: true });
	}, [navigate, estate, id]);

	const setFormValues = useCallback(
		(values: EstateDBAdmin) => {
			const { auctionDateRecurrence, auctionDate, ...rest } = values;
			const auctionDateType = getAuctionDateType(values);

			setValue('auctionDateType', auctionDateType);

			if (auctionDateType === AuctionDateType.RECURRENT) {
				setValue(
					'auctionDateRecurrence_interval',
					auctionDateRecurrence?.interval
				);
				setValue(
					'auctionDateRecurrence_day',
					auctionDateRecurrence?.day
				);
				setValue(
					'auctionDateRecurrence_hour',
					auctionDateRecurrence?.hour
				);
			}

			setValue('auctionDate', auctionDate);

			(Object.keys(rest) as [keyof EstateDB]).forEach((key) =>
				setValue(key, values[key])
			);
		},
		[setValue]
	);

	useEffect(() => {
		if (id === 'new' || !id) {
			if (!isDirty) setFormValues(initialValues);
		}
	}, [setFormValues, isDirty, id]);

	useEffect(() => {
		if (id === 'new' || !id) return;

		(async () => {
			try {
				const result = await api.getByIdAdmin(id);

				setEstate(result);
				setFormValues(result);
			} catch (err) {
				setToastMessage({
					message: t('estateEdit.error'),
					severity: 'error',
				});
			}
		})();
	}, [id, setToastMessage, setEstate, t, setFormValues]);

	return (
		<MainTemplate>
			<Container className={classes.container}>
				<FormProvider {...formMethods}>
					<EstateEditForm
						handleSubmit={handleSubmit(onSubmit)}
						goBack={goBack}
						onDelete={deleteEstate}
						nationalRegistry={estate.nationalRegistry}
						auctionUrl={estate.auctionUrl}
						providerUrl={estate.providerUrl}
						auctionDocument={estate.auctionDocument}
						salePublications={estate.salePublications}
						propertyMap={<EstateEditMap estate={estate} />}
					/>
				</FormProvider>

				{isAdmin && <EstateTasks estateId={estate._id} />}
			</Container>
		</MainTemplate>
	);
};
