import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useAuth, useAuthRole } from '../../../context/Auth';
import { useNotifications } from '../../../context/toast';
import { resendConfirmation } from '../../../api/auth';
import { getErrorFromResponse } from '../../../helpers/form';
import { Alert } from '@material-ui/lab';

const ResendConfirmationCode = () => {
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();

	const handleClick = useCallback(() => {
		(async () => {
			try {
				await resendConfirmation();

				setToastMessage({
					message: t('signup.confirmationCodeSent'),
					severity: 'success',
				});
			} catch (err) {
				setToastMessage({
					message: getErrorFromResponse(err) || t('signup.error'),
					severity: 'error',
				});
			}
		})();
	}, [setToastMessage, t]);

	return (
		<Button color="primary" onClick={handleClick}>
			{t('signup.resendConfirmation')}
		</Button>
	);
};

export const AccountConfirmation = () => {
	const { t } = useTranslation();

	const { user } = useAuth();
	const { isAdmin } = useAuthRole();

	if (!user.confirmed && !isAdmin) {
		return (
			<div>
				<Alert color="warning">
					{t('account.not_confirmed_account', { email: user.email })}
				</Alert>
				<br />
				<ResendConfirmationCode />
			</div>
		);
	}

	return <Alert color="success">{t('account.confirmed_account')}</Alert>;
};
