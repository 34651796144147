export interface Subscriber {
    name?: string;
    email: string;
    phone?: string;
    state?: string;
    states: string[];
    statesRaw?: string[];
    guestUuid: string;
    lastEmailSentAt?: Date;
}

export type SubscriberResponse = {
    getOne: {
        subscribed: boolean;
    };
};

export const EMAIL_ESTATE_LIMIT = 10;
