import { Typography } from '@material-ui/core';
import { SubscriptionTypes } from 'foreclosure-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useAuthRole } from '../../context/Auth';
import { useBilling } from '../../hooks/auth';
import { Modal } from '../Modal';
import { BillingAddress } from './BillingAddress';

const RequireBillingModal = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(true);
	const { fetchUser } = useAuth();

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Typography variant="h5" style={{ marginBottom: 20 }}>
				{t('billing.require_billing')}
			</Typography>
			<BillingAddress onSubmit={fetchUser} />
		</Modal>
	);
};

export const BillingModal = () => {
	const { user, logged } = useAuth();
	const { isAdmin, isAgent } = useAuthRole();
	const { billingValid } = useBilling();

	if (!logged) return null;
	if (billingValid) return null;
	if (user.subscription === SubscriptionTypes.FREE) return null;
	if (isAdmin || isAgent) return null;

	return <RequireBillingModal />;
};
