import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import { Typography, Badge, Button } from '@material-ui/core';
import { AuthContext, useAuthRole } from '../../context/Auth';
import { AdminTopMenu, TopMenu } from './TopMenu';
import { AccountBadge } from '../AccountBadge';

const useStyles = makeStyles((theme) => ({
	button: {
		textTransform: 'none',
		fontSize: '1rem',
	},
	login: {
		display: 'flex',
		flexWrap: 'nowrap',
		alignItems: 'center',
		justifyContent: 'center',
		textDecoration: 'none',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(6),
		},
	},
	loginIcon: {
		marginRight: theme.spacing(1),
	},
	topMenu: {
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			margin: `${theme.spacing(4)}px 0`,
			'& a': {
				marginBottom: theme.spacing(4),
			},
		},
	},
}));

export const HeaderLinks = () => {
	const classes = useStyles({});
	const { t } = useTranslation();
	const { logged } = useContext(AuthContext);
	const { isAdmin } = useAuthRole();

	return (
		<>
			{isAdmin ? (
				<AdminTopMenu className={classes.topMenu} />
			) : (
				<TopMenu className={classes.topMenu} />
			)}
			{logged ? (
				<Link to="/dashboard" className={classes.login}>
					<Button
						variant="text"
						className={classes.button}
						startIcon={<AccountCircle />}
					>
						<AccountBadge>{t('top_menu.my_account')}</AccountBadge>
					</Button>
				</Link>
			) : (
				<Link to="/login" className={classes.login}>
					<Badge
						overlap="rectangular"
						badgeContent={1}
						color="primary"
						className={classes.loginIcon}
					>
						<AccountCircle />
					</Badge>
					<Typography
						noWrap
						color="textPrimary"
						className={classes.button}
					>
						{t('login.title')}
					</Typography>
				</Link>
			)}
		</>
	);
};
