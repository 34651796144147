import {
	Box,
	Container,
	Hidden,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { Copyright } from '../Copyright';
import { footerBackground } from '../../assets';
import { FooterMenu } from './FooterMenu';
import { useTranslation } from 'react-i18next';
import { LegalMenu } from './LegalMenu';
import { MobileFooter } from './MobileFooter';

const useStyles = makeStyles((theme) => ({
	menu: {
		backgroundImage: `url(${footerBackground})`,
		backgroundSize: 'cover',
		height: 350,
		display: 'flex',
		alignItems: 'flex-end',
		color: theme.palette.common.white,
		paddingBottom: theme.spacing(6),
		[theme.breakpoints.down('sm')]: {
			height: 370,
			paddingBottom: theme.spacing(2),
		},
		[theme.breakpoints.up('xl')]: {
			height: 500,
		},
	},
	copyright: {
		backgroundColor: '#FBD050',
		color: theme.palette.common.white,
		padding: theme.spacing(2),
	},
	submitRequest: {
		color: theme.palette.common.white,
		backgroundColor: '#3752ea',
		textAlign: 'center',
		padding: theme.spacing(2),
	},
	link: {
		color: theme.palette.common.white,
		textDecoration: 'underline',
	},
	mobileMenuContainer: {
		display: 'flex',
		flexDirection: 'column',
		'& a': {
			marginBottom: theme.spacing(1),
		},
	},
	desktopMenuContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '60%',
	},
}));

export const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Box className={classes.menu}>
				<Container>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						{/* @ts-ignore */}
						<Hidden smDown>
							<Typography variant="h5" component="div">
								{t('app.title')}
							</Typography>
						</Hidden>
						{/* @ts-ignore */}
						<Hidden mdUp>
							<div className={classes.mobileMenuContainer}>
								<LegalMenu />
							</div>
							<div className={classes.mobileMenuContainer}>
								<FooterMenu />
							</div>
						</Hidden>
						{/* @ts-ignore */}
						<Hidden smDown>
							<Box
								className={classes.desktopMenuContainer}
								style={{ width: '50%' }}
							>
								<FooterMenu />
							</Box>
						</Hidden>
					</Box>
					{/* @ts-ignore */}
					<Hidden smDown>
						<Box
							display="flex"
							width="100%"
							justifyContent="flex-end"
						>
							<Box
								marginTop={5}
								className={classes.desktopMenuContainer}
							>
								<LegalMenu />
							</Box>
						</Box>
					</Hidden>
				</Container>
			</Box>
			<div className={classes.submitRequest}>
				<Typography variant="body1" component="div">
					{t('footer.submit_request')}{' '}
					<a
						className={classes.link}
						href="mailto:vanzari@licitatiibanci.ro"
					>
						vanzari@licitatiibanci.ro
					</a>
				</Typography>
			</div>
			<div className={classes.copyright}>
				<Copyright />
			</div>
			<MobileFooter />
		</>
	);
};
