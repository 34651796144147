import { IntervalFilter } from './IntervalFilter';
import { InnerFiltersProps } from '../types';

export const HouseFilters = ({ handleChange }: InnerFiltersProps) => {
	return (
		<>
			<IntervalFilter
				label="Dimensiune casa (mp)"
				fromKey="propertySizeFrom"
				toKey="propertySizeTo"
				sufix="mp"
				handleChange={handleChange}
				min={0}
				max={10000}
			/>
			<IntervalFilter
				label="Dimensiune teren (mp)"
				fromKey="landSizeFrom"
				toKey="landSizeTo"
				sufix="mp"
				handleChange={handleChange}
				min={0}
			/>
		</>
	);
};
