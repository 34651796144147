import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { FiltersContext } from '../../../../context/Filters';
import { InnerFiltersProps } from '../types';
import { IntervalFilter } from './IntervalFilter';
import { CurrencyPicker } from '../../../../components/CurrencyPicker';

const useStyles = makeStyles((theme) => ({
	currencyContainer: {
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

export const PriceFilter = (props: InnerFiltersProps) => {
	const classes = useStyles();
	const { filters } = useContext(FiltersContext);

	const currency = filters.currency || 'EUR';

	return (
		<IntervalFilter
			handleChange={props.handleChange}
			label={`Pret ${currency}`}
			fromKey="priceFrom"
			toKey="priceTo"
			sufix={currency}
			min={0}
		>
			<CurrencyPicker
				className={classes.currencyContainer}
				onChange={props.handleChange('currency')}
				value={currency}
			/>
		</IntervalFilter>
	);
};
