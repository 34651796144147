import { EstateDB, Perimeter, SimpleCoordinates } from 'foreclosure-types';
import { useCallback, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { googleMapsApiKey } from '../api/config';
import { getCoordinates } from '../api/coordinates';
import { emptyPixel } from '../assets';
import { usePaymentModal } from '../context/Payment';
import { ToastContext } from '../context/toast';
import { apm } from './elastic';
import { getErrorFromResponse } from './form';

export const extractCoordinates = (coordinatesObject: EstateDB['location']) => {
	const [lng, lat] = coordinatesObject.coordinates;

	return {
		lat,
		lng,
	};
};

type SimpleCoords = { lat: number; lng: number };

export const reducePrecision = <T extends SimpleCoords>(coordinates: T): T => {
	const precision = 8;
	const lat = parseFloat(coordinates.lat.toPrecision(precision));
	const lng = parseFloat(coordinates.lng.toPrecision(precision));

	return {
		...coordinates,
		lat,
		lng,
	};
};

export type GeocodeResponse = {
	lat: number;
	lng: number;
	address: string;
	county?: google.maps.GeocoderAddressComponent;
	cfCoordinatesId?: string;
};

const reverseGeocodeCache: Record<string, any> = {};

export const reverseGeocode = async (coord: SimpleCoordinates) => {
	return new Promise<GeocodeResponse>((resolve, reject) => {
		const cacheKey = JSON.stringify(coord);

		if (reverseGeocodeCache[cacheKey]) {
			return resolve(reverseGeocodeCache[cacheKey]);
		}

		const geocoder = new google.maps.Geocoder();

		const logSpan = apm.startSpan('reverseGeocode', 'geocode');
		logSpan?.addLabels({ location: JSON.stringify(coord) });

		geocoder.geocode({ location: coord }, (results, status) => {
			logSpan?.addLabels({ status });
			logSpan?.end();

			if (status === 'OK') {
				const { formatted_address, address_components } = results[0];
				const { lat, lng } = results[0].geometry.location;
				const county = address_components.find((el) =>
					el.types.includes('administrative_area_level_1')
				);

				const resultLocation = {
					lat: lat(),
					lng: lng(),
					address: formatted_address,
					county,
				};

				reverseGeocodeCache[cacheKey] = resultLocation;

				resolve(resultLocation);
			} else {
				reject(status);
			}
		});
	});
};

const geocodeCache: Record<string, any> = {};

export const geocode = async (locationSearch: string) => {
	return new Promise<GeocodeResponse>((resolve, reject) => {
		if (geocodeCache[locationSearch]) {
			return resolve(geocodeCache[locationSearch]);
		}

		const geocoder = new google.maps.Geocoder();

		const logSpan = apm.startSpan('geocode', 'geocode');
		logSpan?.addLabels({ address: locationSearch });

		geocoder.geocode({ address: locationSearch }, (results, status) => {
			logSpan?.addLabels({ status });
			logSpan?.end();

			if (status === 'OK') {
				const { formatted_address, address_components } = results[0];
				const { lat, lng } = results[0].geometry.location;
				const county = address_components.find((el) =>
					el.types.includes('administrative_area_level_1')
				);

				const resultLocation = {
					lat: lat(),
					lng: lng(),
					address: formatted_address,
					county,
				};

				geocodeCache[locationSearch] = resultLocation;

				resolve(resultLocation);
				/*const reverseGeocode = async (coord: SimpleCoordinates) => {
					return new Promise<GeocodeResponse>((resolve, reject) => {
						const geocoder = new google.maps.Geocoder();
				
						geocoder.geocode({ location: coord }, (results, status) => {
							if (status === 'OK') {
								const { formatted_address } = results[0];
								const { lat, lng } = results[0].geometry.location;
								const resultLocation = {
									lat: lat(),
									lng: lng(),
									address: formatted_address,
								};
				
								resolve(resultLocation);
							} else {
								reject(status);
							}
						});
					});
				};*/
			} else {
				reject(status);
			}
		});
	});
};

const getMiddle = (first: google.maps.LatLng, second: google.maps.LatLng) => {
	return new google.maps.LatLng(
		(first.lat() + second.lat()) / 2,
		(first.lng() + second.lng()) / 2
	);
};

export const drawArea = (
	map: google.maps.Map,
	coordinates: Perimeter,
	label = ''
) => {
	if (!coordinates || !coordinates.length || !map) return;

	const ariaCoords = coordinates.map(
		(pair) => new google.maps.LatLng(pair[0], pair[1])
	);

	const area = new google.maps.Polygon({
		paths: ariaCoords,
		strokeColor: '#FF0000',
		strokeOpacity: 0.5,
		strokeWeight: 2,
		fillColor: '#FF0000',
		fillOpacity: 0.35,
	});

	area.setMap(map);

	map.setCenter(ariaCoords[0]);

	setTimeout(() => map.setZoom(20), 2000);

	const addLabel = (label: string, position: google.maps.LatLng) => {
		return new google.maps.Marker({
			position,
			map,
			icon: emptyPixel,
			label: {
				color: '#FF0000',
				fontWeight: 'bold',
				text: label,
				fontSize: '16px',
			},
		});
	};

	const drawDistances = (label = '') => {
		return ariaCoords.flatMap((_el, index) => {
			if (index === 0) {
				// Show polygion area
				const polygonArea = google.maps.geometry.spherical
					.computeArea(ariaCoords)
					.toFixed(2);

				const bounds = new google.maps.LatLngBounds();

				ariaCoords.forEach((point) => bounds.extend(point));

				const center = bounds.getCenter();
				const markers = [addLabel(`${polygonArea} mp`, center)];

				if (label)
					markers.push(
						addLabel(
							label,
							new google.maps.LatLng(
								center.lat() + 0.00003,
								center.lng() - 0.00001
							)
						)
					);

				return markers;
			}

			// Show distances between points
			const distance = google.maps.geometry.spherical
				.computeDistanceBetween(
					ariaCoords[index - 1],
					ariaCoords[index]
				)
				.toFixed(2);

			return addLabel(
				distance + 'm',
				getMiddle(ariaCoords[index - 1], ariaCoords[index])
			);
		});
	};

	let markers: google.maps.Marker[] = [];

	map.addListener('zoom_changed', () => {
		if (map.getZoom() < 19) {
			if (markers.length && markers[0].getVisible() === true) {
				markers.forEach((el) => el.setVisible(false));
			}

			return;
		}

		if (markers.length === 0) {
			markers = drawDistances(label);
		}

		if (markers[0].getVisible() === false) {
			markers.forEach((el) => el.setVisible(true));
		}
	});
};

export type LocateCfParams = {
	agencyId?: string;
	state: string;
	uat: string;
	cf: string;
};

export const isCfLocationValid = (params: LocateCfParams) => {
	const { state, uat, cf } = params;
	const cfEmpty = !cf || [state, uat, cf].indexOf('_') > -1 || !state || !uat;

	if (cfEmpty) return false;

	if (Number.isNaN(parseInt(cf))) return false;

	return true;
};

export const useCfGeocode = () => {
	const { setToastMessage } = useContext(ToastContext);
	const { openPaymentModal } = usePaymentModal();

	const getCfLocation = useCallback(
		async (params: LocateCfParams) => {
			const { agencyId, state, uat, cf } = params;

			if (!isCfLocationValid(params)) {
				return;
			}

			const [, stateId] = state.split('_');

			const logSpan = apm.startSpan('getCfLocation', 'geocode');
			logSpan?.addLabels({ state, uat, cf });

			try {
				const standardCoordinates = await getCoordinates(
					parseInt(stateId),
					parseInt(uat),
					cf.trim(),
					agencyId?.trim()
				);

				logSpan?.addLabels({ status: 'OK' });

				return standardCoordinates;
			} catch (err) {
				const statusCode = (err as any)?.response?.status as number;

				if (statusCode === 402) {
					openPaymentModal();
					// window.alert('You have exceeded the limit of requests');
				}

				logSpan?.addLabels({ status: getErrorFromResponse(err) });

				setToastMessage({
					message: getErrorFromResponse(err),
					severity: 'error',
				});

				return;
			} finally {
				logSpan?.end();
			}
		},
		[setToastMessage, openPaymentModal]
	);

	return {
		getCfLocation,
	};
};

export const useCfMapDrawer = () => {
	const { getCfLocation } = useCfGeocode();

	const drawCfOnMap = async (
		map: google.maps.Map,
		params: LocateCfParams
	) => {
		const standardCoordinates = await getCfLocation(params);

		if (standardCoordinates) {
			drawArea(map, standardCoordinates.wgs84);
		}

		return standardCoordinates;
	};

	return {
		drawCfOnMap: useCallback(drawCfOnMap, [getCfLocation]),
	};
};

export const getGoogleMapsLink = (address: string) => {
	return `https://maps.google.com/?q=${address}`;
};

type LocateCfUrlParams = Partial<LocateCfParams>;

export const useCfUrlParams = () => {
	return useParams<LocateCfUrlParams>();
};

export const useInitialURLParams = () => {
	const initialParams = useRef<LocateCfUrlParams>({});
	const params = useParams<LocateCfUrlParams>();

	if (!Object.keys(initialParams.current).length) {
		initialParams.current = params;
	}

	return initialParams.current;
};

export const loadScript = (
	src: string,
	position: HTMLElement | null,
	id: string,
	onload?: () => void
) => {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	if (onload) script.onload = onload;
	script.src = src;
	position.appendChild(script);
};

export const loadMapsJS = () => {
	if (typeof window === 'undefined') return false;
	// fully loaded
	if (window.google) return true;
	// script loading
	if (document.querySelector('#google-maps')) return true;

	return new Promise<boolean>((resolve) => {
		// @ts-ignore
		window.initMap = () => {
			resolve(true);
		};

		loadScript(
			'https://maps.googleapis.com/maps/api/js?callback=initMap&libraries=places&key=' +
				googleMapsApiKey,
			document.querySelector('head'),
			'google-maps'
		);
	});
};
