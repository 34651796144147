import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { useAppScroll } from '../../helpers/browser';
import { usePaymentModal } from '../../context/Payment';
import { useUserCount } from '../../hooks/auth';

export const SCROLL_LIMIT = 5;

const SubscriptionText = () => {
	const { t } = useTranslation();
	const count = useUserCount();

	const textOptions = [
		t('subscriptionBar.text4', { count: count.pro }),
		t('subscriptionBar.text1'),
		t('subscriptionBar.text2'),
		t('subscriptionBar.text3'),
	];

	const [index, setIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((index) => (index + 1) % textOptions.length);
		}, 6000);

		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Typography noWrap>
			{textOptions[index]} {t('subscriptionBar.pressHere')}
		</Typography>
	);
};

const useStyles = makeStyles((theme) => ({
	subscriptionBarContainer: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		textAlign: 'center',
		padding: theme.spacing(1),
		zIndex: 999,
		cursor: 'pointer',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

const CONTAINER_ID = 'create-subscription';

export const SubscriptionBar = () => {
	const classes = useStyles();
	const { openPaymentModal } = usePaymentModal();

	useAppScroll((scroll) => {
		const element = document.querySelector(
			`#${CONTAINER_ID}`
		) as HTMLDivElement;

		if (!element) return;

		if (scroll > SCROLL_LIMIT) {
			element.style.display = 'none';
		} else {
			element.style.display = 'block';
		}
	}, []);

	return (
		<>
			<div
				id={CONTAINER_ID}
				className={classes.subscriptionBarContainer}
				onClick={openPaymentModal}
			>
				<SubscriptionText />
			</div>
		</>
	);
};
