import { makeStyles } from '@material-ui/core';

export const useFieldsStyles = makeStyles(() => ({
	fields: {
		'& .MuiFormControl-root': {
			minWidth: 'auto',
		},
		'& .MuiInputBase-root': {
			minWidth: 'auto',
			whiteSpace: 'nowrap',
			borderRadius: 30,
		},
		'& .MuiButton-root': {
			minWidth: 'auto',
			whiteSpace: 'nowrap',
			borderRadius: 30,
		},
	},
}));
