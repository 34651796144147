import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { AddressAutocomplete } from '../../../components/LocationSearch/Autocomplete';
import { Modal } from '../../../components/Modal';
import { geocode } from '../../../helpers/map';
import { useFormError } from './helper';
import { create, update, deleteOne } from '../../../api/auctionOwner';
import { AuctionOwner, AuctionOwnerDB } from 'foreclosure-types';
import { useApiWithNotifications } from '../../../hooks/form';
import { emailPattern, numberPattern } from '../../../helpers/form';
import { Alert } from '@material-ui/lab';

const AddressForm = () => {
	const { t } = useTranslation();
	const { getFormError } = useFormError();
	const { setValue, register } = useFormContext();

	register('location');

	return (
		<>
			<TextField
				defaultValue=""
				margin="normal"
				inputRef={register({
					required: t('form.required'),
					minLength: {
						value: 3,
						message: t('form.min_length'),
					},
				})}
				error={!!getFormError('name')}
				helperText={getFormError('name')}
				label="Nume"
				name="name"
				required
				fullWidth
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<Box paddingTop={2}></Box>

			<Controller
				name="address"
				render={({ onChange, value }) => (
					<AddressAutocomplete
						onLocationChange={async (locationString) => {
							const location = await geocode(locationString);

							onChange(location.address);

							setValue('location', {
								type: 'Point',
								coordinates: [location.lng, location.lat],
							});
						}}
						value={value}
						textFieldProps={{
							required: true,
							label: t('estateEdit.auctionAddress'),
						}}
					/>
				)}
			/>

			{getFormError('address') && (
				<Typography variant="body2" color="error">
					{getFormError('address')}
				</Typography>
			)}
			{getFormError('location') && (
				<Typography variant="body2" color="error">
					{getFormError('location')}
				</Typography>
			)}
			<Box paddingTop={1}></Box>
			<TextField
				defaultValue=""
				margin="normal"
				inputRef={register({
					required: t('form.required'),
					minLength: {
						value: 10,
						message: t('form.min_length'),
					},
					validate: (val) =>
						numberPattern.test(val)
							? undefined
							: t('form.valid_number'),
				})}
				error={!!getFormError('phone')}
				helperText={getFormError('phone')}
				label="Telefon"
				name="phone"
				required
				fullWidth
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				defaultValue=""
				margin="normal"
				inputRef={register({
					required: t('form.required'),
					validate: (val) =>
						emailPattern.test(val)
							? undefined
							: t('form.valid_email'),
				})}
				error={!!getFormError('email')}
				helperText={getFormError('email')}
				label="Email"
				name="email"
				required
				fullWidth
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</>
	);
};

const Buttons = (props: {
	enableDelete: boolean;
	onSecondary: () => void;
	onPrimary: () => void;
}) => {
	const { formState } = useFormContext();
	const { isValid } = formState;

	return (
		<Box display="flex" justifyContent="space-between" marginTop={3}>
			<Button
				color="secondary"
				variant="outlined"
				onClick={(event) => {
					event.preventDefault();

					props.onSecondary();
				}}
			>
				{props.enableDelete ? 'Șterge' : 'Anulează'}
			</Button>

			<Button
				type="submit"
				color="primary"
				variant="contained"
				disabled={!isValid}
				onClick={(event) => {
					event.preventDefault();
					event.stopPropagation();

					props.onPrimary();
				}}
			>
				Salvează
			</Button>
		</Box>
	);
};

const mapFormToAuctionAddress = (form: any) => {
	const { name, address, location, email, phone } = form;

	const result: AuctionOwner = {
		name,
		address,
		location,
		email,
		phone,
	};

	return result;
};

const defaultValues: Partial<AuctionOwnerDB> = {
	address: '',
	location: { type: 'Point', coordinates: [0, 0] },
	email: '',
	phone: '',
};

export const CreateUserAddress = (props: {
	address?: AuctionOwnerDB;
	onClose: () => void;
	onSuccess: (address: AuctionOwnerDB) => void;
}) => {
	const { address } = props;
	const { t } = useTranslation();
	const formMethods = useForm({
		mode: 'onChange',
		defaultValues,
	});
	const { reset } = formMethods;
	const createAddress = useApiWithNotifications(create);
	const updateAddress = useApiWithNotifications(update);
	const deleteAddress = useApiWithNotifications(deleteOne);

	useEffect(() => {
		if (!address) return;

		reset(address);
	}, [address, reset]);

	const onSubmit = async () => {
		const data = formMethods.getValues();
		const form = mapFormToAuctionAddress(data);

		const addressResponse = props.address
			? await updateAddress(props.address._id, form)
			: await createAddress(form);

		if (addressResponse) props.onSuccess(addressResponse);
	};

	const handleDelete = async () => {
		if (!address) return;

		if (!window.confirm(t('estateEdit.deleteAddressConfirmation'))) return;

		await deleteAddress(address._id);

		props.onClose();
	};

	return (
		<Modal open onClose={props.onClose}>
			<Box maxWidth="90vw" width="600px">
				<Box marginBottom={3}>
					<Typography variant="h5">
						Adaugă adresă licitație
					</Typography>
				</Box>

				<FormProvider {...formMethods}>
					<form onSubmit={formMethods.handleSubmit(onSubmit)}>
						<AddressForm />

						<Box marginTop={2}>
							<Alert severity="info">
								{t('form.required_fields_note')}
							</Alert>
						</Box>

						<Buttons
							onPrimary={onSubmit}
							onSecondary={
								props.address ? handleDelete : props.onClose
							}
							enableDelete={!!props.address}
						/>
					</form>
				</FormProvider>
			</Box>
		</Modal>
	);
};
