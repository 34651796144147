import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubscriberStatus } from '../api/mail';
import { useAuth } from '../context/Auth';
import { isBot, useAppScroll } from '../helpers/browser';
import { Modal } from './Modal';
import { SubscribeForm } from './SubscribeSection';

const SUBSCRIBE_DISMISSED = 'subscribe-dismissed';

export const SubscribeModal = () => {
	const { t } = useTranslation();
	const { loading, logged } = useAuth();
	const [isSubscribed, setSubscribed] = useState(true);
	const [hasScroll, setScroll] = useState(false);

	useEffect(() => {
		(async () => {
			const data = await getSubscriberStatus();

			if (!data.subscribed) setSubscribed(false);
		})();
	}, []);

	useAppScroll((scroll) => {
		if (scroll > 1200) {
			setScroll(true);
		}
	}, []);

	if (isSubscribed || loading || logged || !hasScroll) {
		return null;
	}

	const lastDismissed = localStorage.getItem(SUBSCRIBE_DISMISSED);

	if (isBot()) {
		return null;
	}

	if (
		lastDismissed &&
		moment(lastDismissed).isValid() &&
		moment(lastDismissed).isAfter(moment().subtract(7, 'day'))
	) {
		return null;
	}

	const handleClose = () => {
		localStorage.setItem(SUBSCRIBE_DISMISSED, moment().toISOString());
		setSubscribed(true);
	};

	return (
		<Modal open onClose={handleClose}>
			<Box maxWidth={400}>
				<Box textAlign="center" marginBottom={3}>
					<Typography variant="h5">
						{t('homepage.subscribe.title')}
					</Typography>
					<Box marginTop={2}>
						<Typography variant="subtitle1">
							{t('homepage.subscribe.subtitle')}
						</Typography>
					</Box>
				</Box>
				<SubscribeForm onSuccess={() => setSubscribed(true)} />
				<Box display="flex" justifyContent="center" marginTop={1}>
					<Button variant="outlined" onClick={handleClose}>
						{t('homepage.subscribe.skip')}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};
