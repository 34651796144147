import React from 'react';
import { Checkbox } from '@material-ui/core';

export const CellContent = (props: {
	children: unknown;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}): React.ReactElement => {
	const { children, onClick } = props;

	if (typeof children === 'boolean')
		return <Checkbox checked={!!children} onClick={onClick} />;

	return <>{children}</>;
};
