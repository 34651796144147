import { useEffect } from 'react';
import { isProduction } from '../config';
import { useAuth, useAuthRole } from '../context/Auth';

const scriptId = 'google-ads';
const src =
	'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2273633532364996';

export const useGoogleAds = () => {
	const { loading } = useAuth();
	const { isAdmin, subscribed } = useAuthRole();
	const shouldNotHaveAds = isAdmin || subscribed;

	useEffect(() => {
		if (!isProduction) return;

		if (loading) return;

		const existingScript = document.getElementById(scriptId);

		if (shouldNotHaveAds) {
			if (existingScript) {
				setTimeout(() => {
					// reload to avoid loading the ads
					window.location.reload();
				}, 200);
			}

			return;
		}

		if (!existingScript) {
			const script = document.createElement('script');

			script.src = src;
			script.crossOrigin = 'anonymous';
			script.async = true;
			script.id = scriptId;

			document.body.appendChild(script);
		}
	}, [loading, shouldNotHaveAds]);
};
