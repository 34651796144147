export const initFB = (appId: string): Promise<fb.FacebookStatic> =>
	new Promise((resolve) => {
		window.fbAsyncInit = () => {
			FB.init({
				appId: appId,
				//autoLogAppEvents : true,
				xfbml: false,
				version: 'v9.0',
				status: false,
			});
			FB.AppEvents.logPageView();

			resolve(FB);
		};

		((d, s, id) => {
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			const js = d.createElement(s) as HTMLScriptElement;
			js.id = id;
			js.src = '//connect.facebook.net/en_US/sdk.js';
			fjs.parentNode?.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
