import { makeStyles, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProgressButton } from '../../../components/Form/ProgressButton';
import { useErrorMessage } from '../../../helpers/form';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: theme.spacing(2),
	},
}));

export const AddEstateComment = (props: {
	onSubmit: (text: string) => any;
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { register, handleSubmit, errors, formState, reset } = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: {
			text: '',
		},
	});
	const { isValid, isSubmitting } = formState;
	const getErrorMessage = useErrorMessage();

	return (
		<form
			onSubmit={handleSubmit(async (values) => {
				await props.onSubmit(values.text);

				reset();
			})}
		>
			<TextField
				name="text"
				required
				multiline
				fullWidth
				minRows={4}
				variant="outlined"
				inputRef={register({ required: true, minLength: 5 })}
				label={t('estateView.comment.text')}
				placeholder={t('estateView.comment.defaultText')}
				helperText={getErrorMessage(errors.text)}
				error={!!errors.text}
				className={classes.textField}
				InputLabelProps={{
					shrink: true,
				}}
			/>

			<ProgressButton
				data-transaction-name="comment"
				type="submit"
				color="secondary"
				disabled={!isValid}
				loading={isSubmitting}
			>
				{t('estateView.auction.contact_button')}
			</ProgressButton>
		</form>
	);
};
