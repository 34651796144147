import {
	decreaseQuality,
	increaseQuality,
	LeadQuality,
} from 'foreclosure-types';
import { QualityInput } from './QualityInput';

const getQualityColor = (quality: LeadQuality) => {
	switch (quality) {
		case LeadQuality.BAD:
			return '#f44336';
		case LeadQuality.GOOD:
			return '#6fbf73';
		case LeadQuality.INVESTOR:
			return '#357a38';
		default:
			return '';
	}
};

export const LeadQualityText = (props: {
	quality: LeadQuality;
	controls?: boolean;
	onChange?: (quality: LeadQuality) => any;
}) => {
	const { quality, controls = false, onChange } = props;
	const color = getQualityColor(quality);

	return (
		<QualityInput
			color={color}
			quality={quality}
			controls={controls}
			onDecrease={() => onChange && onChange(decreaseQuality(quality))}
			onIncrease={() => onChange && onChange(increaseQuality(quality))}
		/>
	);
};
