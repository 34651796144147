import { useContext, SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { preventDefaultNStop } from '../../helpers/events';
import { CellContent } from './CellContent';
import { SelectableListContext } from './SelectableList';
import { Row } from './types';

const useStyles = makeStyles(() => ({
	select: {
		width: '100px',
	},
	header: {
		fontWeight: 'bold',
	},
}));

type Props = {
	headerLabels: string[];
	selectable: boolean;
};

export const selectAllClick: Row = { headerClick: true };

export const ListHead = (props: Props): JSX.Element => {
	const classes = useStyles({});
	const { onClick, isChecked } = useContext(SelectableListContext);
	const { headerLabels, selectable } = props;

	return (
		<TableHead>
			<TableRow>
				{selectable && (
					<TableCell
						classes={{ head: classes.header, root: classes.select }}
					>
						<Checkbox
							checked={isChecked(selectAllClick)}
							onClick={(event: SyntheticEvent): void => {
								preventDefaultNStop(event);

								onClick && onClick(selectAllClick);
							}}
						/>
					</TableCell>
				)}
				{headerLabels.map((name, index) => (
					<TableCell
						key={name.toString()}
						align={
							index === headerLabels.length - 1 ? 'right' : 'left'
						}
						classes={{ head: classes.header }}
					>
						<CellContent>{name}</CellContent>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
