import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden, Drawer, Badge, IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { useSavedSearchesCount } from '../../hooks/estate';
import { useAccountNotificationsCount } from '../../hooks/auth';
import { HeaderLinks } from './Links';
import { useMobileSize } from '../../hooks/responsive';
import { HeaderNotifications } from './Notifications';

const useStyles = makeStyles(() => ({
	drawer: {
		width: '75vw',
		maxWidth: 400,
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
}));

export const HeaderMenu = () => {
	const classes = useStyles();
	const { isMobile, isTablet } = useMobileSize();
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const { count: countSavedSearches } = useSavedSearchesCount();
	const { count: countAccountNotifications } = useAccountNotificationsCount();
	const count = countSavedSearches + countAccountNotifications;

	return (
		<>
			{/* @ts-ignore */}
			<Hidden lgUp>
				<>
					{(isMobile || isTablet) && (
						<IconButton color="secondary">
							<HeaderNotifications />
						</IconButton>
					)}
					<IconButton color="secondary">
						<Badge
							overlap="rectangular"
							color="primary"
							badgeContent={count}
						>
							<Menu onClick={() => setDrawerOpen(true)} />
						</Badge>
					</IconButton>
				</>
				<Drawer
					open={drawerOpen}
					onClose={() => setDrawerOpen(false)}
					onClick={() => setDrawerOpen(false)}
					anchor="right"
				>
					<div className={classes.drawer}>
						<HeaderLinks />
					</div>
				</Drawer>
			</Hidden>

			{/* @ts-ignore */}
			<Hidden mdDown>
				<HeaderLinks />
			</Hidden>
		</>
	);
};
