import { useEffect, useState } from 'react';
import { Task } from 'foreclosure-types';

import { getAll, getEstateTasks } from '../api/task';

let cachedList: Array<Task> = [];

export const useTaskList = () => {
	const [tasks, setTasks] = useState(cachedList);

	useEffect(() => {
		(async () => {
			cachedList = await getAll();

			setTasks(cachedList);
		})();
	}, [setTasks]);

	return {
		tasks,
	};
};

export const useEstateTaskList = (estateId: string) => {
	const [tasks, setTasks] = useState<Task[]>([]);

	useEffect(() => {
		if (!estateId) return;

		(async () => {
			const result = await getEstateTasks(estateId);

			setTasks(result);
		})();
	}, [setTasks, estateId]);

	return {
		tasks,
	};
};
