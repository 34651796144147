import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type Props = {
	title?: string;
	description?: string;
};

export const PageMeta = (props: Props) => {
	const { t } = useTranslation();
	const appTitle = t('app.title');

	const { title, description } = props;

	return (
		<Helmet>
			<title>
				{appTitle} {title ? `| ${title}` : ''}
			</title>

			{description && <meta name="description" content={description} />}
		</Helmet>
	);
};
