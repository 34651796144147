import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SubType } from 'foreclosure-types';
import { defaultPictures } from '../helpers/defaults';

const useStyles = makeStyles((theme) => ({
	picture: {
		width: 200,
		minWidth: 200,
		backgroundColor: theme.palette.grey[200],
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		borderRadius: 8,
		cursor: 'pointer',
	},
}));

type Props = {
	imageURL: string | null;
	type: SubType;
	onClick?: () => void;
	className?: string;
};

export const EstateImage = ({ imageURL, type, onClick, className }: Props) => {
	const classes = useStyles();
	const useDefaultImage = !imageURL;
	const picture = useDefaultImage ? defaultPictures[type] : imageURL;

	return (
		<div
			className={`${classes.picture} ${className || ''}`}
			style={{ backgroundImage: `url("${picture}")` }}
			onClick={onClick}
		></div>
	);
};
