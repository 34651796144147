import { EstateDB } from 'foreclosure-types';
import { ReactElement, Ref } from 'react';
import { useFormContext } from 'react-hook-form';
import { accessNestedObject } from '../../../helpers/form';

export const useDefaultTextProps = () => {
	const { register } = useFormContext();

	const defaultTextProps: Record<
		string,
		Ref<ReactElement> | string | boolean
	> = {
		defaultValue: ' ',
		inputRef: register,
		margin: 'normal',
	};

	return { defaultTextProps };
};

export const useFormError = () => {
	const { errors } = useFormContext();

	const getFormError = (field: keyof EstateDB | string) => {
		if (field.includes('.')) {
			return accessNestedObject(errors, field)?.message;
		}

		return errors[field]?.message;
	};

	return {
		getFormError,
	};
};
