import { useEffect, useState } from 'react';
import moment from 'moment';
import { EstateDB, EstateSafeDB } from 'foreclosure-types';

import {
	getAllAdmin,
	getForProvider,
	getSavedSearchesCount,
	getSelfEstates,
	getSelfEstatesCount,
} from '../api/estate';
import { valueIsMissing } from '../helpers/display';
import { useAuth } from '../context/Auth';

let cachedList: Array<EstateDB> = [];
let cachedMyEstates: Array<EstateDB> = [];

export const useAllEstateList = () => {
	const [estates, setEstates] = useState(cachedList);

	useEffect(() => {
		(async () => {
			cachedList = await getAllAdmin();

			setEstates(cachedList);
		})();
	}, [setEstates]);

	estates.forEach((estate) => {
		estate.auctionDate =
			valueIsMissing(estate.auctionDate) ||
			!moment(estate.auctionDate).isValid()
				? '-'
				: moment(estate.auctionDate).format('YYYY.MM.DD HH:mm');
	});

	return {
		estates,
	};
};

export const useProviderEstates = (provider: string) => {
	const [estates, setEstates] = useState<EstateSafeDB[]>([]);

	useEffect(() => {
		(async () => {
			const result = await getForProvider(provider);

			setEstates(result);
		})();
	}, [setEstates, provider]);

	estates.forEach((estate) => {
		estate.auctionDate =
			valueIsMissing(estate.auctionDate) ||
			!moment(estate.auctionDate).isValid()
				? '-'
				: moment(estate.auctionDate).format('YYYY.MM.DD HH:mm');
	});

	return {
		estates,
	};
};

export const useMyEstates = () => {
	const [estates, setEstates] = useState(cachedMyEstates);

	useEffect(() => {
		(async () => {
			cachedMyEstates = await getSelfEstates();

			setEstates(cachedMyEstates);
		})();
	}, [setEstates]);

	estates.forEach((estate) => {
		estate.auctionDate = valueIsMissing(estate.auctionDate)
			? '-'
			: moment(estate.auctionDate).format('YYYY.MM.DD HH:mm');
	});

	return {
		estates,
	};
};

let cachedCount = 0;
let selfEstatesCount = 0;

export const useSavedSearchesCount = () => {
	const { logged } = useAuth();
	const [count, setCount] = useState(cachedCount);

	useEffect(() => {
		if (!logged) return;

		if (count) return;

		(async () => {
			const response = await getSavedSearchesCount();

			cachedCount = response.count;
			setCount(cachedCount);
		})();
	}, [logged, count]);

	return { count };
};

export const useSelfEstatesCount = () => {
	const { logged, loading } = useAuth();
	const [count, setCount] = useState(selfEstatesCount);

	useEffect(() => {
		if (loading) return;

		if (!logged) return;

		(async () => {
			const data = await getSelfEstatesCount();

			selfEstatesCount = data.total;

			setCount(data.total);
		})();
	}, [logged, loading]);

	return { count };
};
