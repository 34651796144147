import { CSSProperties } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
	name: string;
	defaultValue?: string;
	label: string;
	required?: boolean;
	options: Record<string, string>;
	style?: CSSProperties;
};

export const SelectController = (props: Props) => {
	const { control, errors = {} } = useFormContext();
	const { name, defaultValue, label, required, options, style = {} } = props;

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			as={
				<TextField
					style={{ minWidth: 200, ...style }}
					margin="normal"
					error={!!errors[name]}
					helperText={name in errors && errors[name].message}
					label={label}
					required={!!required}
					select
				>
					{Object.keys(options).map((key) => (
						<MenuItem key={key} value={key}>
							{options[key]}
						</MenuItem>
					))}
				</TextField>
			}
		/>
	);
};
