import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { EditHistoryDb } from 'foreclosure-types';
import { getEditHistory } from '../../api/editHistory';
import { HistoryRow } from './HistoryRow';
import { UsersProvider } from '../../context/Users';
import { useAuth } from '../../context/Auth';
import { rolesThatCanEdit } from '../../config';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(7),
		width: '100%',
	},
}));

export const ListEditHistory = (props: {
	objectId: string;
	owner?: string;
}) => {
	const { objectId, owner } = props;
	const { t } = useTranslation();
	const { user } = useAuth();
	const classes = useStyles();
	const [data, setData] = useState<EditHistoryDb[]>([]);

	const canEdit = user._id === owner || rolesThatCanEdit.includes(user.role);

	useEffect(() => {
		if (!objectId || !canEdit) return;

		(async () => {
			const apiData = await getEditHistory(objectId);

			setData(apiData);
		})();
	}, [objectId, canEdit]);

	if (!Object.keys(data).length) return null;

	return (
		<div className={classes.container}>
			<Typography variant="h5">
				{t('estateView.history.title')}
			</Typography>

			<UsersProvider>
				{data.map((item) => (
					<HistoryRow {...item} />
				))}
			</UsersProvider>
		</div>
	);
};
