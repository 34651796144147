import { useContext } from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import { FiltersContext } from '../../../context/Filters';
import { InnerFiltersProps } from './types';
import { Autocomplete } from '@material-ui/lab';
import { useSubTypeOptions } from '../../../hooks/filters';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const notEmpty = <T,>(val?: T): val is T => !!val;

export const SubTypeFilter = ({ handleChange }: InnerFiltersProps) => {
	const { filters } = useContext(FiltersContext);
	const subTypeOptions = useSubTypeOptions();
	const value = filters?.subType
		?.map((v) => subTypeOptions.find((o) => o.key === v))
		.filter(notEmpty);

	return (
		<Autocomplete
			multiple
			options={subTypeOptions}
			getOptionLabel={(option) => option.label}
			value={value || []}
			onChange={(_event, value) => {
				handleChange('subType')(value.map((v) => v.key.toString()));
			}}
			// value={value}
			renderOption={(option, { selected }) => (
				<li key={option.key}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={selected}
					/>
					{option.label}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					// label="Categorie"
					placeholder="Categorie"
					size="small"
				/>
			)}
		/>
	);
};
