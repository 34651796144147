import React, { ReactNode, useState } from 'react';
import { emptyToast, ToastContext, ToastMessage } from './toast';

type Props = { children: ReactNode };

const ToastProvider = (props: Props): React.ReactElement => {
	const [toast, setToast] =
		useState<Omit<ToastMessage, 'setToastMessage'>>(emptyToast);

	return (
		<ToastContext.Provider
			value={{
				...toast,
				setToastMessage: setToast,
			}}
		>
			{props.children}
		</ToastContext.Provider>
	);
};

export default ToastProvider;
