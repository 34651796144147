import { createTheme } from '@material-ui/core/styles';
import Circular from './assets/fonts/Circular Std Font.otf';

const circular = {
	fontFamily: 'Circular',
	fontStyle: 'normal',
	fontWeight: 400,
	src: `
    local('Circular'),
    url(${Circular}) format('otf')
  `,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const defaultTheme = createTheme();

export const theme = createTheme({
	palette: {
		// Dark theme mode: https://v4.mui.com/customization/palette/#user-preference
		// type: 'dark',
		primary: {
			main: '#2C48E9',
		},
		secondary: {
			main: '#444',
		},
		text: {
			secondary: '#2E2E2E',
			hint: '#1BAB7D',
		},
	},
	props: {
		MuiButton: {
			variant: 'outlined',
		},
		MuiTextField: {
			variant: 'outlined',
		},
	},
	typography: {
		fontFamily:
			'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
		subtitle2: {
			color: '#999',
		},
		h6: {
			fontWeight: 'bold',
		},
		h5: {
			fontWeight: 'bold',
		},
		h4: {
			fontWeight: 'bold',
			[defaultTheme.breakpoints.down('md')]: {
				fontSize: '2rem',
			},
		},
		h3: {
			fontWeight: 'bold',
			[defaultTheme.breakpoints.down('md')]: {
				fontSize: '2.6rem',
			},
		},
		h2: {
			fontWeight: 'bold',
		},
		h1: {
			fontWeight: 'bold',
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [circular],
				// '.MuiAutocomplete-popper': {
				// 	[defaultTheme.breakpoints.down('sm')]: {
				// 		width: '250px !important',
				// 	},
				// },
				'.MuiAutocomplete-inputRoot': {
					padding: '9px !important',
				},
				'.MuiAutocomplete-input': {
					paddingTop: '9.5px !important',
					paddingBottom: '9.5px !important',
				},
				a: {
					textDecoration: 'none',
				},
				'#whatsapp_chat_widget': {
					position: 'relative',
					zIndex: 9,
					'@media print': {
						display: 'none !important',
					},
				},
				'.fade-enter': {
					opacity: 0,
					zIndex: 1,
				},
				'.fade-enter.fade-enter-active': {
					opacity: 1,
					transition: 'opacity 250ms ease-in',
				},
			},
		},
		MuiButton: {
			containedPrimary: {
				boxShadow: 'none',
			},
			outlinedSecondary: {
				borderColor: '#c0c0c0',
			},
			sizeLarge: {
				padding: '12px 22px',
				fontSize: '1rem',
			},
		},
		MuiSnackbar: {
			anchorOriginTopRight: {
				marginTop: 56,
			},
		},
		MuiDivider: {
			vertical: {
				background: '#eee',
			},
		},
		MuiCardMedia: {
			root: {
				height: '180px !important',
			},
		},
		MuiDrawer: {
			root: {
				// @ts-ignore
				zIndex: '16000161 !important',
			},
		},
	},
});
