import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.palette.common.white,
	},
}));

const footerLinks: Record<string, string> = {
	'/become-partner': 'top_menu.become_partner',
	'/how-it-works': 'top_menu.how_it_works',
	'/cf': 'top_menu.locate_cf',
	'/subscription': 'top_menu.subscription',
	'/statistics/ancpi': 'top_menu.ancpi_stats',
};

export const FooterMenu = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			{Object.keys(footerLinks).map((url) => (
				<Link to={url} key={url}>
					<Typography
						className={classes.text}
						variant="body1"
						component="span"
					>
						{t(footerLinks[url])}
					</Typography>
				</Link>
			))}
		</>
	);
};
