import { useCallback, useEffect, useState } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import {
	makeStyles,
	Paper,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Box,
	Slider,
} from '@material-ui/core';
import { Marker } from './Marker';
import { googleMapsApiKey } from '../../api/config';
import { SimpleCoordinates } from 'foreclosure-types';
import { AddressAutocomplete } from '../LocationSearch/Autocomplete';
import { useTranslation } from 'react-i18next';
import { defaultMapCenter } from '../../config';
import {
	geocode,
	GeocodeResponse,
	reducePrecision,
	reverseGeocode,
} from '../../helpers/map';
import { NavigateButton } from '../NavigateButton';

type MapLocation = SimpleCoordinates & {
	_id: string;
	title: string;
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		height: '100%',
		width: '100%',
		zIndex: 99,
	},
	slider: {
		marginTop: theme.spacing(2),
		'& .MuiSlider-markLabel': {
			color: 'black',
			fontWeight: 500,
		},
	},
	geocodeField: {
		position: 'absolute',
		left: theme.spacing(2),
		top: theme.spacing(2),
		zIndex: 99,
		padding: '0 5px',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	searchField: {
		width: 350,
		border: 'none',
		'& fieldset': {
			border: 0,
		},
	},
	locationSwitch: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: theme.spacing(5),
		paddingLeft: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
			marginTop: theme.spacing(2),
		},
	},
	updateCenter: {
		position: 'absolute',
		left: '50%',
		bottom: theme.spacing(2),
		transform: 'translateX(-50%)',
		zIndex: 99,
	},
	autocomplete: {
		width: 300,
		'& fieldset': {
			border: 0,
		},
	},
}));

export enum MapType {
	DEFAULT = 'roadmap',
	SATELLITE = 'satellite',
	HYBRID = 'hybrid',
	TERRAIN = 'terrain',
}

interface Props<T> {
	locations: Array<T>;
	center: SimpleCoordinates;
	highlighted?: T;
	defaultCenter?: SimpleCoordinates;
	zoom?: number;
	onLocationChange?: (location: GeocodeResponse) => void;
	updateMapOnSearch?: boolean;
	onSearch?: (address: string, rangeKM: number) => void;
	onMarkerClick?: (location: T) => void;
	disableSearch?: boolean;
	mapType?: MapType;
	switch?: {
		value: string;
		options: string[];
		onChange: (val: string) => void;
	};
	onChange?: (event: ChangeEventValue) => void;
	onRangeChange?: (rangeKM: number) => any;
	onMapMounted?: (map: google.maps.Map) => void;
	defaultAddress?: string;
	locateCf?: boolean;
	navigation?: boolean;
	hideMap?: boolean;
}

export const DEFAULT_RANGE = 30;

export const EstateMap = <T extends MapLocation>(props: Props<T>) => {
	const {
		// onLocationChange will not update on later re-renders!
		onLocationChange,
		locations,
		onMarkerClick,
		center,
		highlighted,
		zoom,
		onChange,
		defaultAddress,
		onSearch,
		disableSearch = false,
		updateMapOnSearch = true,
		onMapMounted,
		onRangeChange,
		navigation = false,
		mapType = MapType.DEFAULT,
		hideMap = false,
	} = props;

	const classes = useStyles({});
	const { t } = useTranslation();
	const [mapCenter, setMapCenter] = useState<SimpleCoordinates | null>(null);
	const [mapReady, setMapReady] = useState<boolean>(false);
	const [rangeKM, setRangeKM] = useState(DEFAULT_RANGE);

	const googleMapCenter = mapCenter || center || props.defaultCenter;

	const searchForAddressAndUpdateCenter = useCallback(
		async (address: string) => {
			if (address.length < 4) return;

			try {
				const result = await geocode(address);

				if (onLocationChange) {
					onLocationChange(reducePrecision(result));
				}

				setMapCenter(result);
			} catch (err) {
				console.warn(`Geocode was not successful: ${err}`);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	const search = async (address?: string) => {
		if (!address) return;

		if (onSearch) {
			onSearch(address, rangeKM);
		}

		if (!updateMapOnSearch) {
			return;
		}

		searchForAddressAndUpdateCenter(address);
	};

	useEffect(() => {
		if (!defaultAddress || !mapReady) {
			return;
		}

		searchForAddressAndUpdateCenter(defaultAddress);
	}, [mapReady, defaultAddress, searchForAddressAndUpdateCenter]);

	const renderSearch = () => (
		<div>
			<Paper>
				{onSearch && (
					<AddressAutocomplete
						className={classes.autocomplete}
						loadMapsAPI={false}
						onLocationChange={(val) => {
							search(val);
						}}
						textFieldProps={{
							placeholder: t('search.location'),
							size: 'small',
						}}
					/>
				)}
			</Paper>
			{onRangeChange && (
				<Slider
					defaultValue={DEFAULT_RANGE}
					getAriaLabel={(value) => `${value} KM`}
					getAriaValueText={(value) => `${value} KM`}
					aria-labelledby="discrete-slider"
					valueLabelDisplay="auto"
					step={5}
					className={classes.slider}
					onChangeCommitted={(_event, value) => {
						if (Array.isArray(value)) return;

						setRangeKM(value);

						onRangeChange(value);
					}}
					marks={[
						{
							value: 10,
							label: '10 KM',
						},
						{
							value: DEFAULT_RANGE,
							label: `Distanta: ${DEFAULT_RANGE} KM`,
						},
						{
							value: 50,
							label: '50 KM',
						},
					]}
					min={5}
					max={50}
				/>
			)}
		</div>
	);
	const renderSwitch = () => {
		return (
			<Box component={Paper} className={classes.locationSwitch}>
				<FormControl component="fieldset">
					<RadioGroup
						aria-label="Address Type"
						name="addressType"
						row
						value={props.switch?.value}
						onChange={(event) =>
							props.switch?.onChange(event.target.value)
						}
					>
						{props.switch?.options.map((option) => (
							<FormControlLabel
								key={option}
								value={option}
								control={<Radio />}
								label={option}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</Box>
		);
	};
	const renderNavigate = () => {
		if (locations.length === 0) return null;

		return (
			<Box component={Paper} className={classes.locationSwitch}>
				<NavigateButton
					coordinates={[locations[0].lat, locations[0].lng]}
				/>
			</Box>
		);
	};

	/*const [displayUpdateCenter, setDisplayUpdateCenter] = useState(false);
	const updateMapCenterEvent = useRef<ChangeEventValue>();

	const onMapCenterChange = (event: ChangeEventValue) => {
		if (event.center && onChange) {
			updateMapCenterEvent.current = event;
			setDisplayUpdateCenter(true);
		}
	};

	const renderUpdateCenter = () => {
		if (!displayUpdateCenter) return null;

		return (
			<Box component={Paper} className={classes.updateCenter}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						if (onChange && updateMapCenterEvent.current) {
							onChange(updateMapCenterEvent.current);
						}
						setDisplayUpdateCenter(false);
					}}
				>
					{t('search.updateMap')}
				</Button>
			</Box>
		);
	};*/

	return (
		<div className={classes.root}>
			<div className={classes.geocodeField}>
				{!disableSearch && renderSearch()}
				{props.switch && renderSwitch()}
				{navigation && renderNavigate()}
			</div>
			{/* {onChange && renderUpdateCenter()} */}
			{googleMapCenter && !hideMap && (
				// @ts-ignore
				<GoogleMapReact
					options={{
						mapTypeId: mapType,
					}}
					bootstrapURLKeys={{
						key: googleMapsApiKey,
						libraries: ['places', 'geometry'],
					}}
					defaultCenter={defaultMapCenter}
					center={mapReady ? googleMapCenter : undefined}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={(params) => {
						const map: google.maps.Map = params.map;

						onMapMounted && onMapMounted(map);
						setMapReady(true);
					}}
					// onChange={onMapCenterChange}
					onChange={onChange}
					defaultZoom={zoom || 16}
					onClick={
						onLocationChange &&
						(async (coordinates) => {
							const { address } = await reverseGeocode(
								coordinates
							);

							onLocationChange(
								reducePrecision({
									address,
									lat: coordinates.lat,
									lng: coordinates.lng,
								})
							);
						})
					}
				>
					{locations.map((location) => (
						<Marker
							highlighted={
								highlighted && location._id === highlighted._id
							}
							key={location._id}
							lat={location.lat}
							lng={location.lng}
							onClick={() =>
								onMarkerClick && onMarkerClick(location)
							}
						>
							{location.title}
						</Marker>
					))}
				</GoogleMapReact>
			)}
		</div>
	);
};
