import React, { useState } from 'react';
import { Row } from './types';
import { selectAllClick } from './ListHead';

export interface SelectableListProps<T> {
	selectedRows: Array<T>;
	onClick: (row: T) => void;
	isChecked: (row: T) => boolean;
}

const emptySelectableList: SelectableListProps<any> = {
	selectedRows: [],
	onClick: (row) => {
		console.log(row);
	},
	isChecked: () => false,
};

export const SelectableListContext = React.createContext(emptySelectableList);

const updateList = <T extends Row>(
	allRows: Array<T>,
	selection: Array<T>,
	row: T
): Array<T> => {
	if (row === selectAllClick) {
		if (selection.length === allRows.length) {
			return [];
		}

		return allRows;
	}
	const index = selection.indexOf(row);

	if (index > -1) {
		return selection.filter((el) => el !== row);
	}

	return [...selection, row];
};

type Props<T> = {
	children: JSX.Element;
	onChange?: (rows: Array<T>) => void;
	allRows: Array<T>;
};

export const SelectableList = <T extends Row>(
	props: Props<T>
): React.ReactElement => {
	const { children, onChange, allRows } = props;
	const [selection, setSelectionList] = useState<Array<T>>([]);
	const onClick = (row: T): void => {
		const newList = updateList(allRows, selection, row);

		setSelectionList(newList);

		onChange && onChange(newList);
	};
	const isChecked = (row: T): boolean => {
		if (row === selectAllClick) return selection.length === allRows.length;

		return selection.indexOf(row) > -1;
	};
	const value = {
		selectedRows: selection,
		onClick,
		isChecked,
	};

	return (
		<SelectableListContext.Provider value={value}>
			{children}
		</SelectableListContext.Provider>
	);
};
