import { Controller } from 'react-hook-form';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useStatesList } from '../helpers/statesList';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	autocomplete: {
		'&& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}));

export type StateOption = { key: string; label: string };

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const StateAutocomplete = (props: { name: string }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const states = useStatesList();
	const invalidState = t('form.choose_state');

	const options = Object.keys(states).reduce(
		(acc, state) => {
			const [stateName] = state.split('_');

			return [
				...acc,
				{
					key: state,
					label: stateName,
				},
			];
		},
		[
			{
				key: 'all',
				label: t('form.all_states'),
			},
		] as StateOption[]
	);

	return (
		<Controller
			name={props.name}
			defaultValue={[]}
			rules={{
				validate: (val: StateOption[]) => {
					if (!val.length) return invalidState;
				},
			}}
			render={({ onChange, value }) => (
				<Autocomplete
					multiple
					fullWidth
					openOnFocus
					className={classes.autocomplete}
					options={options}
					value={value || []}
					getOptionSelected={(
						option: StateOption,
						value: StateOption
					) => option.key === value.key}
					getOptionLabel={(option: StateOption) => option.label}
					onChange={(_event, val: StateOption[]) => {
						onChange(val);
					}}
					renderOption={(option, { selected }) => (
						<span key={option.key}>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.label}
						</span>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							placeholder={t('homepage.subscribe.state')}
							size="small"
						/>
					)}
				/>
			)}
		/>
	);
};
