import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Box, IconButton } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderMenu } from './Menu';
import { useMobileSize } from '../../hooks/responsive';
import { ArrowBackIos } from '@material-ui/icons';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		background: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	title: {
		flexGrow: 1,
		textDecoration: 'none',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
		},
	},
	back: {
		marginRight: 48,
	},
}));

const AppLogo = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={classes.title}>
			<Link to="/">
				<Typography
					component="span"
					variant="h5"
					color="primary"
					noWrap
				>
					{t('app.title')}
				</Typography>
			</Link>
		</div>
	);
};

export const HeaderToolbar = (props: { className?: string }) => {
	const classes = useStyles();
	const { className } = props;
	const { isTablet } = useMobileSize();
	const navigate = useNavigate();

	const goBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return (
		<Toolbar className={[classes.toolbar, className].join(' ')}>
			{isTablet ? (
				<>
					<IconButton onClick={goBack} className={classes.back}>
						<ArrowBackIos />
					</IconButton>
					<AppLogo />
				</>
			) : (
				<>
					<AppLogo />
					<Box flexGrow={1} />
				</>
			)}

			<HeaderMenu />
		</Toolbar>
	);
};
