import { useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: theme.spacing(1),
	},
}));

type Props = {
	images: string[];
};

export const GalleryResponsive = ({ images }: Props) => {
	const classes = useStyles();
	const galleryRef = useRef<ImageGallery>();
	const isFullScreen = useRef(false);

	const onClick = useCallback(() => {
		if (!galleryRef.current) return;

		if (isFullScreen.current) {
			galleryRef.current.exitFullScreen();
			isFullScreen.current = false;
		} else {
			galleryRef.current.fullScreen();
			isFullScreen.current = true;
		}
	}, []);

	return (
		<div className={classes.container}>
			<ImageGallery
				// @ts-ignore
				ref={galleryRef}
				items={images.map((img) => ({
					original: img,
					thumbnail: img,
				}))}
				showThumbnails
				showPlayButton={false}
				onClick={onClick}
			/>
		</div>
	);
};
