import axios from './cache';
import axiosOriginal from 'axios';
import { baseUrl } from './config';
import { UserDB, UserResponse } from 'foreclosure-types';
import { RegisterValues } from '../forms/login';

export const fetchLoggedUser = async (): Promise<UserDB> => {
	const response = await axios.get(baseUrl + '/auth/me');

	return response.data;
};

export const register = async (
	data: RegisterValues
): Promise<UserResponse['register']> => {
	const response = await axios.post(baseUrl + '/auth/register', data);

	return response.data;
};

export const confirmAccount = async (
	token: string
): Promise<UserResponse['login']> => {
	const response = await axios.patch(baseUrl + '/auth/confirm/' + token);

	return response.data;
};

export const resendConfirmation = async () => {
	await axios.post(baseUrl + '/auth/confirm');
};

export const login = async (
	email: string,
	password: string
): Promise<UserResponse['login']> => {
	const response = await axios.post(baseUrl + '/auth/login', {
		email,
		password,
	});

	return response.data;
};

export const fbLogin = async (
	accessToken: string,
	userId: string
): Promise<string> => {
	const response = await axios.post(baseUrl + '/auth/fb-login', {
		accessToken,
		userId,
	});

	return response.data;
};

export const googleLogin = async (token: string): Promise<string> => {
	const response = await axios.post(baseUrl + '/auth/google-login', {
		token,
	});

	return response.data;
};

export const requestLoginCode = async (params: {
	email?: string;
	phone?: string;
}) => {
	const response = await axios.post(baseUrl + '/auth/code/', params);

	return response.data;
};

export const loginWithCode = async (
	code: string
): Promise<UserResponse['login']> => {
	const response = await axios.patch(baseUrl + '/auth/code/', {
		code,
	});

	return response.data;
};

export const setAxiosToken = (token: string) => {
	// token length should be 153, using 50 to prevent manually set invalid tokens
	if (token && token.length > 50) {
		axios.defaults.headers.common.Authorization = 'Bearer ' + token;
		axiosOriginal.defaults.headers.common.Authorization = 'Bearer ' + token;
	} else {
		delete axios.defaults.headers.common.Authorization;
		delete axiosOriginal.defaults.headers.common.Authorization;
	}
};

export const setGuestUuid = (uuid: string) => {
	axios.defaults.headers.common['Guest-Uuid'] = uuid;
	axiosOriginal.defaults.headers.common['Guest-Uuid'] = uuid;
};

export const count = async () => {
	const response = await axios.get(`${baseUrl}/auth/count`);

	return response.data as UserResponse['count'];
};
