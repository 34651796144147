import { Button, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SpacedRow } from '../../components/SpacedRow';
import { MainTemplate } from '../../components/Templates/Main';

export const AddressList = () => {
	return (
		<MainTemplate>
			<Container>
				<SpacedRow style={{ marginTop: 50 }}>
					<Typography
						variant="h5"
						component="h1"
						style={{ marginBottom: 20 }}
					>
						Adrese Licitatii:
					</Typography>

					<Link to="/admin/auction-owner/new">
						<Button variant="outlined">Adauga</Button>
					</Link>
				</SpacedRow>
			</Container>
		</MainTemplate>
	);
};
