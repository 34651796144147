import axios from 'axios';
import { baseUrl } from './config';
import { UserDB, UserRole } from 'foreclosure-types';

const apiUrl = baseUrl + '/user';

export const get = async (
	params: {
		email?: string;
		role?: UserRole;
	} = {}
): Promise<UserDB[]> => {
	const response = await axios.get(apiUrl, { params });

	return response.data;
};

export const getById = async (id: string): Promise<UserDB> => {
	const response = await axios.get(`${apiUrl}/${id}`);

	return response.data;
};

export const getRealtors = async (): Promise<UserDB[]> => {
	const response = await axios.get(`${apiUrl}?role=REALTOR`);

	return response.data;
};

export const update = async (
	user: Partial<UserDB> & { _id: UserDB['_id'] }
): Promise<void> => {
	const { _id, ...body } = user;

	await axios.put(`${apiUrl}/${_id}`, body);
};

export const create = async (user: UserDB): Promise<{ id: number }> => {
	const { _id, ...body } = user;

	const response = await axios.post(apiUrl, body);

	return response.data;
};

export const remove = async (id: string): Promise<void> => {
	await axios.delete(`${apiUrl}/${id}`);
};
