import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { SchemaErrors } from '../../../types';
import * as api from '../../../api/user';
import { UserDetailsTemplate } from '../../../components/Templates/UserDetails';
import { UserDB } from 'foreclosure-types';
import { MainTemplate } from '../../../components/Templates/Main';
import { Container } from '@material-ui/core';

export const UserDetails = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { control, register, handleSubmit, errors, setValue } = useForm({});
	const onSubmit = useCallback(
		(user: UserDB) => {
			if (!id) return;

			(async () => {
				if (id === 'new') {
					const response = await api.create(user);

					navigate('/user/' + response.id);
				} else {
					await api.update({ ...user, _id: id });
				}
			})();
		},
		[id, navigate]
	);
	const goBack = () => navigate('/users');

	const remove = async () => {
		if (!id) return;

		if (Number.isInteger(Number(id))) {
			await api.remove(id);
		}

		goBack();
	};

	useEffect(() => {
		(async () => {
			if (id === 'new' || !id) return;

			const user = await api.getById(id);

			(Object.keys(user) as [keyof typeof user]).forEach(
				(key: keyof UserDB) => setValue(key, user[key])
			);
		})();
	}, [id, setValue]);

	return (
		<MainTemplate>
			<Container maxWidth="lg">
				<UserDetailsTemplate
					handleSubmit={handleSubmit(onSubmit)}
					errors={errors as SchemaErrors}
					register={register}
					control={control}
					goBack={goBack}
					onDelete={remove}
				/>
			</Container>
		</MainTemplate>
	);
};
