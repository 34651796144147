import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useAppScroll } from '../helpers/browser';
import { SCROLL_LIMIT } from './Payments/SubscriptionBar';

export const useStyles = makeStyles((theme) => ({
	appBarSpacer: {
		...theme.mixins.toolbar,
		[theme.breakpoints.down('sm')]: {
			marginTop: 8,
		},
	},
	extraSpace: {
		minHeight: 96,
	},
}));

const useScroll = () => {
	const [scroll, setScroll] = useState(false);

	useAppScroll((scrollTop) => {
		setScroll(scrollTop > SCROLL_LIMIT);
	}, []);

	return { hasScroll: scroll };
};

export const HeaderSpacer = (props: { extraSpace?: boolean }) => {
	const classes = useStyles(props);
	const { hasScroll } = useScroll();
	const { extraSpace } = props;

	return (
		<div
			className={[
				classes.appBarSpacer,
				extraSpace && !hasScroll ? classes.extraSpace : '',
			].join(' ')}
		/>
	);
};
