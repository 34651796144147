import { User } from './User';

export enum SubscriptionTypes {
    FREE = 'free',
    BASIC = 'basic',
    PRO = 'pro',
    AGENCY = 'agency',
}

export enum SubscriptionPrice {
    BASIC = 29,
    PRO = 49,
    AGENCY = 199,
}

export enum PaymentStatus {
    PENDING = 'pending',
    PAID = 'paid',
    FAILED = 'failed',
}

export enum PaymentType {
    BASE = 'base',
    RECURRENT = 'recurrent',
}

export interface PaymentDb {
    userId: string;
    status: PaymentStatus;
    type: PaymentType;
    subscriptionType: SubscriptionTypes;
    nonce: string;
    invoiceUrl?: string;
    body: PaymentResponseBody;
    billing?: User['billing'];
}

export enum PaymentAction {
    APPROVED = 0,
}

export enum PaymentSecurity {
    PENDING_VERIFICATION0 = 3,
    PENDING_VERIFICATION1 = 4,
    FRAUD0 = 5,
    FRAUD1 = 6,
    SAFE0 = 8,
    SAFE1 = 9,
}

export type PaymentResponseBody = {
    amount: Number; // "1.00"
    curr: 'RON' | 'EUR';
    invoice_id: string;
    ep_id: string;
    merch_id: string;
    action: PaymentAction | Number;
    message: string; // "Approved"
    approval: string; // "tstcrd"
    timestamp: Date; // "20220719205213"
    nonce: string; // "3"
    sec_status: PaymentSecurity;
    card_exp: string; // '01-24'
    recuring_max_amount: Number; // '200.00'
    recuring_exp: Date; // '2032-07-19';
    payment_channel: string | 'CARD';
    card_holder: string;
    card_issuer: string;
    email: string;
    fp_hash: string;
    gatewayoption?: Number;
};

/**
 * Example request from EuPlatesc {
  "body": {
    "amount": "1.00",
    "curr": "RON",
    "invoice_id": "123",
    "ep_id": "88B54A3EF28A719CF677D172EC0D1412C65C5A4F",
    "merch_id": "44840998816",
    "action": "0",
    "message": "Approved",
    "approval": "tstcrd",
    "timestamp": "20220719205213",
    "nonce": "9ee718700d11e846c66ee8264870fa97",
    "sec_status": "3",
    "card_exp": "01-24",
    "recuring_max_amount": "200.00",
    "recuring_exp": "2032-07-19",
    "payment_channel": "CARD",
    "card_holder": "Test",
    "card_issuer": "Unknown bank bin/",
    "email": "a.marian.alexandru@gmail.com",
    "fp_hash": "A36F0CB296A87CAA19792D6B1F64D0F3"
  },
  "query": {},
  "params": {}
}
 */
