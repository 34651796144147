import { Trans, useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
	Box,
	Button,
	InputBase,
	InputLabel,
	makeStyles,
	Paper,
	Typography,
	Checkbox,
	FormHelperText,
} from '@material-ui/core';
import { subscribe } from '../api/mail';
import { Link } from 'react-router-dom';
import { emailPattern, numberPattern } from '../helpers/form';
import { useNotifications } from '../context/toast';
import { StateAutocomplete, StateOption } from './StateAutocomplete';
import { useUserCount } from '../hooks/auth';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'stretch',
		width: 500,
		maxWidth: '90%',
	},
	input: {
		padding: theme.spacing(1),
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	inputWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		paddingBottom: theme.spacing(1),
	},
	select: {
		'&& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}));

const ControlledCheckbox = (props: { name: string }) => {
	return (
		<Controller
			name={props.name}
			defaultValue={false}
			render={({ onChange, value, onBlur }) => (
				<Checkbox
					onBlur={onBlur}
					checked={value}
					onChange={(_event, checked) => onChange(checked)}
				/>
			)}
		/>
	);
};

const TermsInput = () => {
	const { t } = useTranslation();

	return (
		<>
			<InputLabel>
				<ControlledCheckbox name="acceptTerms" />
				<Typography variant="body2" component="span">
					<Trans i18nKey="homepage.subscribe.accord1">
						Sunt de acord ca siteul să stocheze și să proceseze
						datele mele personale conform
						<Link to="/privacy">
							Politicii de Confidențialitate
						</Link>
						pe care am citi-o și am înțeles-o
					</Trans>
				</Typography>
			</InputLabel>
			<InputLabel>
				<ControlledCheckbox name="acceptNotifications" />
				<Typography variant="body2" component="span">
					{t('homepage.subscribe.accord2')}
				</Typography>
			</InputLabel>
		</>
	);
};

export const SubscribeForm = (props: { onSuccess?: () => any }) => {
	const classes = useStyles();

	const { t } = useTranslation();
	const validEmail = t('form.valid_email');
	const validNumber = t('form.valid_number');

	const formMethods = useForm({
		mode: 'onChange',
	});
	const { register, handleSubmit, watch, errors, reset, formState, trigger } =
		formMethods;
	const { setToastMessage } = useNotifications();

	const getError = (field: string) => {
		return errors[field]?.message;
	};

	const { isDirty, isValid } = formState;
	const acceptTerms = watch('acceptTerms');
	const acceptNotifications = watch('acceptNotifications');

	const onSubmit = async (data: any) => {
		try {
			const states = data.states.map(
				(state: StateOption) => state.key.split('_')[0]
			);

			await subscribe({
				...data,
				states,
			});

			if (props.onSuccess) {
				props.onSuccess();
			}
		} catch (err) {
			setToastMessage({
				severity: 'error',
				message: t('form.error'),
			});

			return;
		}

		reset(
			{ acceptTerms: false, acceptNotifications: false },
			{
				isDirty: false,
			}
		);

		setToastMessage({
			severity: 'success',
			message: t('form.success'),
		});
	};

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.inputWrapper}>
					<Paper className={classes.root}>
						<StateAutocomplete name="states" />
					</Paper>
					<FormHelperText error>{getError('state')}</FormHelperText>
				</div>
				<div className={classes.inputWrapper}>
					<Paper className={classes.root}>
						<InputBase
							name="phone"
							error={!!getError('phone')}
							inputRef={register({
								minLength: 10,
								validate: (val) =>
									numberPattern.test(val)
										? undefined
										: validNumber,
							})}
							className={classes.input}
							// prettier-ignore
							placeholder={`${t('estateView.contact.phone')} (${t('estateView.contact.optional')})`}
							inputProps={{
								inputMode: 'tel',
							}}
						/>
					</Paper>
					<FormHelperText error>{getError('state')}</FormHelperText>
				</div>
				<div className={classes.inputWrapper}>
					<Paper className={classes.root}>
						<InputBase
							name="email"
							type="email"
							error={!!getError('email')}
							inputRef={register({
								required: validEmail,
								validate: (val) =>
									emailPattern.test(val)
										? undefined
										: validEmail,
							})}
							onBlur={() => trigger()}
							required
							className={classes.input}
							placeholder={t('homepage.subscribe.email')}
							inputProps={{
								inputMode: 'email',
							}}
						/>
					</Paper>
					<FormHelperText error>{getError('email')}</FormHelperText>

					<FormHelperText error>
						{(!acceptTerms || !acceptNotifications) &&
							isDirty &&
							t('form.must_accept_terms')}
					</FormHelperText>
				</div>
				<Box
					maxWidth={500}
					marginLeft="auto"
					marginRight="auto"
					marginTop={2}
					paddingBottom={2}
					paddingLeft={1}
					paddingRight={1}
				>
					<TermsInput />
				</Box>

				<div className={classes.inputWrapper}>
					<Button
						data-transaction-name="subscribe"
						type="submit"
						color="primary"
						variant="contained"
						disabled={
							!acceptTerms || !acceptNotifications || !isValid
						}
					>
						{t('homepage.subscribe.button')}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
};

export const SubscribeSection = () => {
	const { t } = useTranslation();
	const count = useUserCount();

	return (
		<Box marginTop={15}>
			<Box textAlign="center">
				<Typography variant="h3" id="subscribe">
					{t('homepage.subscribe.title')}
				</Typography>
				<Box marginTop={2}>
					<Typography variant="subtitle1">
						{t('homepage.subscribe.subtitle')}
					</Typography>
					<Typography variant="subtitle1">
						<strong>
							{t('homepage.subscribe.subscribers', {
								count: count.newsletter,
							})}
						</strong>
					</Typography>
				</Box>
			</Box>
			<Box marginTop={6}>
				<SubscribeForm />
			</Box>
		</Box>
	);
};
