import { ReactNode, Fragment } from 'react';
import {
	isWidthDown,
	isWidthUp,
	WithWidth,
	withWidth,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

type Props = {
	lgDown?: ReactNode;
	lgUp?: ReactNode;
	mdDown?: ReactNode;
	mdUp?: ReactNode;
	smDown?: ReactNode;
	smUp?: ReactNode;
	xlDown?: ReactNode;
	xlUp?: ReactNode;
	xsDown?: ReactNode;
	xsUp?: ReactNode;
};

const shouldDisplay = (screenWidth: Breakpoint, breakpoint: keyof Props) => {
	const isDown = breakpoint.includes('Down');

	if (isDown) {
		const point = breakpoint.replace('Down', '') as Breakpoint;

		return isWidthDown(point, screenWidth);
	}

	const point = breakpoint.replace('Up', '') as Breakpoint;

	return isWidthUp(point, screenWidth);
};

const ResponsiveRenderInner = (props: WithWidth & Props) => {
	const { width: screenWidth, ...rest } = props;
	const allKeys = Object.keys(rest) as Array<keyof typeof rest>;

	return (
		<>
			{allKeys
				.filter((breakpoint) =>
					shouldDisplay(screenWidth, breakpoint as keyof Props)
				)
				.map((key) => (
					<Fragment key={key}>{rest[key]}</Fragment>
				))}
		</>
	);
};

export const ResponsiveRender = withWidth()(ResponsiveRenderInner);
