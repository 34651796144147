import {
	CSSProperties,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Check, Notifications } from '@material-ui/icons';
import {
	Badge,
	Box,
	Button,
	Popover,
	Typography,
	useTheme,
} from '@material-ui/core';
import { NotificationExtended, NotificationText } from 'foreclosure-types';
import { getUserNotifications, markAsRead } from '../../api/notification';
import { getEstateLink } from '../../helpers/form';
import { useAuth } from '../../context/Auth';

const TimePassed = (props: { date: string | Date }) => {
	const { date } = props;
	const daysPassed = moment().diff(date, 'days');
	const hoursPassed = moment().diff(date, 'hours');

	if (daysPassed > 0) {
		return <>acum {daysPassed} zile</>;
	}

	if (hoursPassed > 0) {
		return <>acum {hoursPassed} ore</>;
	}

	return <>acum câteva minute</>;
};

const NotificationRow = (props: {
	to?: string;
	title: string;
	body: ReactNode;
	date: string | Date;
	onClick?: () => any;
}) => {
	const theme = useTheme();
	const { to, title, body, date, onClick } = props;

	const content = (
		<Box
			borderBottom="1px solid #eee"
			padding={2}
			color={theme.palette.text.primary}
		>
			<Typography variant="body1">
				<strong>{title}</strong>
			</Typography>
			<Box marginBottom={1} marginTop={1}>
				<Typography variant="body2">{body}</Typography>
			</Box>
			<Typography variant="caption">
				<TimePassed date={date} />
			</Typography>
		</Box>
	);

	if (!to) {
		return content;
	}

	return (
		<Link to={to} onClick={onClick}>
			{content}
		</Link>
	);
};

const useUserNotifications = () => {
	const { logged } = useAuth();
	const [notifications, setNotifications] = useState<NotificationExtended[]>(
		[]
	);
	const refresh = useCallback(async () => {
		const response = await getUserNotifications();

		setNotifications(response);
	}, []);

	useEffect(() => {
		if (!logged) return;

		refresh();
	}, [logged, refresh]);

	return { notifications, refresh };
};

const useNotificationText = () => {
	const getNotificationText = useCallback(
		(notification: NotificationExtended, link?: string) => {
			switch (notification.text) {
				case NotificationText.NEW_COMMENT:
					return {
						title: 'Comentariu nou',
						body: (
							<>
								Un nou comentariu a fost adaugat pentru{' '}
								<a href={link}>{notification.referenceTitle}</a>
							</>
						),
					};
				case NotificationText.NEW_LEGAL_REFERRENCE:
					return {
						title: 'Noutăți juridice',
						body: (
							<>
								O noua referinta legala a fost adaugata pentru{' '}
								<a href={link}>{notification.referenceTitle}</a>
							</>
						),
					};
				default:
					return null;
			}
		},
		[]
	);

	return { getNotificationText };
};

const NoNotifications = () => {
	const theme = useTheme();

	return (
		<NotificationRow
			title="Fără notificări"
			body={
				<Box padding={2} display="flex" justifyItems="center">
					<Check
						style={{
							marginRight: 8,
							color: theme.palette.success.main,
						}}
					/>{' '}
					<span>Nu sunt notificari noi</span>
				</Box>
			}
			date=""
		/>
	);
};

const MarkAllAsRead = (props: { mark: () => any }) => {
	const theme = useTheme();
	const mark = async () => {
		if (
			!window.confirm(
				'Sigur doriți să marcați toate notificările ca văzute?'
			)
		) {
			return;
		}

		props.mark();
	};

	return (
		<Box
			borderBottom="1px solid #eee"
			padding={2}
			color={theme.palette.text.primary}
		>
			<Button
				size="small"
				variant="text"
				color="primary"
				startIcon={<Check />}
				onClick={mark}
			>
				Marchează toate ca văzute
			</Button>
		</Box>
	);
};

const UserNotifications = (props: {
	notifications: NotificationExtended[];
	refresh: () => any;
}) => {
	const { notifications, refresh } = props;
	const { getNotificationText } = useNotificationText();

	if (!notifications.length) {
		return <NoNotifications />;
	}

	return (
		<div>
			<MarkAllAsRead
				mark={async () => {
					await Promise.all(
						notifications.map((notification) =>
							markAsRead(notification._id)
						)
					);

					refresh();
				}}
			/>
			{notifications.map((notification) => {
				const link = getEstateLink({
					_id: notification.referenceId,
					type: 'ESTATE',
					title: notification.referenceTitle,
				});
				const text = getNotificationText(notification, link);

				if (!text) return null;

				return (
					<NotificationRow
						onClick={async () => {
							try {
								await markAsRead(notification._id);
							} catch (err) {
								console.error(err);
							}
						}}
						to={link}
						key={notification._id}
						title={text.title}
						body={text.body}
						date={notification.createdAt}
					/>
				);
			})}
		</div>
	);
};

export const HeaderNotifications = (props: { style?: CSSProperties }) => {
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const { notifications, refresh } = useUserNotifications();
	const count = notifications.length;

	return (
		<>
			<Badge
				overlap="rectangular"
				badgeContent={count}
				color="primary"
				style={props.style}
				onClick={() => setOpen(true)}
			>
				<Notifications ref={ref} />
			</Badge>
			<Popover
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={ref.current}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				PaperProps={{
					style: {
						maxHeight: '50vh',
					},
				}}
			>
				<UserNotifications
					notifications={notifications}
					refresh={refresh}
				/>
			</Popover>
		</>
	);
};
