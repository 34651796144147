import axios from 'axios';
import {
	CommentResponse,
	CommentStatus,
	ReferenceCollection,
} from 'foreclosure-types';
import { baseUrl } from './config';

const apiUrl = baseUrl + '/comment';

export const createEstateComment = async (text: string, estateId: string) => {
	const response = await axios.post(apiUrl, {
		text,
		referenceId: estateId,
		referenceCollection: ReferenceCollection.ESTATE,
	});

	return response.data as CommentResponse['updateOne'];
};

export const getEstateComments = async (estateId: string) => {
	const response = await axios.get(apiUrl + '/estate/' + estateId);

	return response.data as CommentResponse['estateComments'];
};

export const getAdminEstates = async () => {
	const response = await axios.get(apiUrl + '/estate');

	return response.data as CommentResponse['estateComments'];
};

export const updateCommentStatus = async (
	commentId: string,
	status: CommentStatus
) => {
	const response = await axios.patch(apiUrl + '/' + commentId, {
		status,
	});

	return response.data as CommentResponse['updateOne'];
};
