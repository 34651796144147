import { useTranslation } from 'react-i18next';
import { Button, Container, Divider, Typography } from '@material-ui/core';
import { ExitToAppOutlined } from '@material-ui/icons';
import { MainTemplate } from '../../components/Templates/Main';
import { AccountConfirmation } from './components/AccountConfirmation';
import { SubscriptionStatus } from './components/SubscriptionStatus';
import { UserInfo } from './components/UserInfo';
import { useAuth } from '../../context/Auth';
import { BillingAddress } from '../../components/Payments/BillingAddress';

const SectionDivider = () => (
	<Divider variant="fullWidth" style={{ marginTop: 30, marginBottom: 30 }} />
);

const Logout = () => {
	const { t } = useTranslation();
	const { logout } = useAuth();

	return (
		<Button
			endIcon={<ExitToAppOutlined />}
			onClick={() => {
				if (window.confirm(t('login.confirm_logout'))) {
					logout();
				}
			}}
		>
			{t('login.logout')}
		</Button>
	);
};

export const UserDashboard = () => {
	const { t } = useTranslation();

	return (
		<MainTemplate displayHeaderShadow={false}>
			<Container>
				<Typography variant="h6">{t('account.title')}</Typography>
				<br />

				<UserInfo />
				<br />

				<AccountConfirmation />
				<br />

				<Logout />

				<SectionDivider />

				<SubscriptionStatus />

				<SectionDivider />

				<BillingAddress />
			</Container>
		</MainTemplate>
	);
};
