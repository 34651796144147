import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type PaymentModal = {
	paymentModalOpen: boolean;
	openPaymentModal: () => void;
	closePaymentModal: () => void;
};

export const emptyPaymentModal: Readonly<PaymentModal> = {
	paymentModalOpen: false,
	openPaymentModal: () => {},
	closePaymentModal: () => {},
};

export const PaymentModalContext = React.createContext(emptyPaymentModal);

export const usePaymentModal = () => {
	return useContext(PaymentModalContext);
};

export const PaymentModalProvider = (props: { children: any }) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	// const openPaymentModal = useCallback(() => setOpen(true), []);
	const closePaymentModal = useCallback(() => setOpen(false), []);
	const openPaymentModal = useCallback(() => {
		navigate('/subscription');
	}, [navigate]);

	return (
		<PaymentModalContext.Provider
			value={{
				paymentModalOpen: open,
				openPaymentModal,
				closePaymentModal,
			}}
		>
			{props.children}
		</PaymentModalContext.Provider>
	);
};
