import axios from './cache';
import { AuctionOwner, AuctionOwnerResponse } from 'foreclosure-types';
import { baseUrl } from './config';

const apiUrl = baseUrl + '/auction-owner';

export const getAll = async (): Promise<AuctionOwnerResponse['getList']> => {
	const response = await axios.get(`${apiUrl}/`);

	return response.data;
};

export const getUserAddresses = async (): Promise<
	AuctionOwnerResponse['getList']
> => {
	const response = await axios.get(`${apiUrl}/self`);

	return response.data;
};

export const getOne = async (
	id: string
): Promise<AuctionOwnerResponse['getOne']> => {
	const response = await axios.get(`${apiUrl}/${id}`);

	return response.data;
};

export const update = async (
	id: string,
	data: AuctionOwner
): Promise<AuctionOwnerResponse['patch']> => {
	const response = await axios.put(`${apiUrl}/${id}`, data);

	return response.data;
};

export const patch = async (
	id: string,
	data: Pick<AuctionOwner, 'quality'>
) => {
	await axios.patch(`${apiUrl}/${id}`, data);
};

export const create = async (data: AuctionOwner) => {
	const response = await axios.post(apiUrl, data);

	return response.data as AuctionOwnerResponse['create'];
};

export const deleteOne = async (id: string) => {
	await axios.delete(`${apiUrl}/${id}`);
};

export const updateNotes = async (id: string, notes: AuctionOwner['notes']) => {
	await axios.put(`${apiUrl}/${id}/notes`, { notes });
};
