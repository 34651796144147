import { ReactNode } from 'react';
import { Badge } from '@material-ui/core';
import { useAuth, useAuthRole } from '../context/Auth';
import { useAccountNotificationsCount } from '../hooks/auth';

export const AccountBadge = (props: { children: ReactNode }) => {
	const { user } = useAuth();
	const { isAdmin, isAgent } = useAuthRole();
	const { count } = useAccountNotificationsCount();

	return (
		<Badge
			overlap="rectangular"
			badgeContent={(() => {
				if (isAdmin) return 'ADMIN';

				if (isAgent) return 'AGENT';

				if (count) return count;

				return user.subscription?.toUpperCase();
			})()}
			color="primary"
		>
			{props.children}
		</Badge>
	);
};
