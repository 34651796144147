import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	makeStyles,
	Tooltip,
	Typography,
	useTheme,
} from '@material-ui/core';
import {
	CheckOutlined,
	DeleteOutlineOutlined,
	AccountCircleOutlined,
} from '@material-ui/icons';
import { EstateComment, CommentStatus } from 'foreclosure-types';
import { useAuthRole } from '../../../context/Auth';
import { updateCommentStatus } from '../../../api/comment';
import { useNotifications } from '../../../context/toast';
import { getErrorFromResponse } from '../../../helpers/form';

const EditCommentStatus = (props: { data: EstateComment }) => {
	const { data } = props;
	const [status, setStatus] = useState(data.status);
	const { setToastMessage } = useNotifications();
	const theme = useTheme();

	useEffect(() => {
		setStatus(data.status);
	}, [data]);

	const changeStatus = useCallback(async () => {
		try {
			const result = await updateCommentStatus(
				data._id,
				status === CommentStatus.APPROVED
					? CommentStatus.REJECTED
					: CommentStatus.APPROVED
			);

			setStatus(result.status);

			setToastMessage({
				severity: 'success',
				message: 'Success',
			});
		} catch (err: any) {
			setToastMessage({
				severity: 'error',
				message: getErrorFromResponse(err) || 'Server error',
			});
		}
	}, [data, setToastMessage, status]);

	return (
		<>
			<Tooltip title={data.guestUuid || ''}>
				<Link to={`/admin/leads?guestUuid=${data.guestUuid}`}>
					<Button
						variant="text"
						startIcon={<AccountCircleOutlined />}
					>
						Lead link
					</Button>
				</Link>
			</Tooltip>
			<Button
				variant="text"
				disabled
				style={{
					color:
						status === CommentStatus.APPROVED
							? theme.palette.success.main
							: theme.palette.error.main,
				}}
			>
				Status: {status}
			</Button>
			<Button
				variant="text"
				onClick={changeStatus}
				startIcon={
					status === CommentStatus.APPROVED ? (
						<DeleteOutlineOutlined />
					) : (
						<CheckOutlined />
					)
				}
			>
				{status === CommentStatus.APPROVED ? 'Dezactiveaza' : 'Aproba'}
			</Button>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
	},
	card: {
		marginBottom: theme.spacing(2),
	},
}));

const CommentItem = (props: { data: EstateComment }) => {
	const { data } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const { isAdmin } = useAuthRole();

	return (
		<Card elevation={0} className={classes.card}>
			<CardHeader
				avatar={
					<Avatar className={classes.avatar}>
						{data.authorName[0]?.toUpperCase()}
					</Avatar>
				}
				action={isAdmin && <EditCommentStatus data={data} />}
				title={data.authorName}
				subheader={
					t('estateView.comment.date') +
					' ' +
					moment(data.createdAt).format('YYYY-MM-DD HH:mm')
				}
			/>
			<CardContent>
				<Typography variant="body2" color="textSecondary">
					{data.text}
				</Typography>
			</CardContent>
		</Card>
	);
};

export const CommentsList = (props: { list: EstateComment[] }) => {
	return (
		<>
			{props.list.map((comment) => (
				<CommentItem data={comment} key={comment._id} />
			))}
		</>
	);
};
