export enum CommentStatus {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

export enum ReferenceCollection {
    ESTATE = 'ESTATE',
}

export interface Comment {
    referenceCollection: ReferenceCollection;
    referenceId: string;
    authorId: string;
    guestUuid?: string;
    text: string;
    status: CommentStatus;
    media?: string[];
    createdAt: Date;
    updatedAt: Date;
}

export interface CommentDB extends Comment {
    _id: string;
}

export type EstateComment = CommentDB & { authorName: string };

export interface CommentResponse {
    estateComments: Array<EstateComment>;
    updateOne: CommentDB;
}
