import { EstateLandType } from 'foreclosure-types';
import { Select } from '../../../../components/Select';
import { InnerFiltersProps } from '../types';
import { IntervalFilter } from './IntervalFilter';

const defaultStatusFilter = 'default';

const landTypeOptions: Record<
	EstateLandType | typeof defaultStatusFilter,
	string
> = {
	[defaultStatusFilter]: 'Toate',
	URBAN: 'Intravilan',
	RURAL: 'Extravilan',
};

export const LandFilters = ({ handleChange }: InnerFiltersProps) => {
	return (
		<>
			<Select
				label="Tip teren"
				options={landTypeOptions}
				onChange={handleChange('landType')}
				defaultValue={defaultStatusFilter}
			/>

			<IntervalFilter
				label="Dimensiune teren (mp)"
				fromKey="landSizeFrom"
				toKey="landSizeTo"
				sufix="mp"
				handleChange={handleChange}
				min={0}
			/>
		</>
	);
};
