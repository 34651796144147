import { SubscriptionTypes, User } from 'foreclosure-types';
import { useCallback } from 'react';
import { cancelSubscription, createSubscription } from '../api/payment';
import { useAuth } from '../context/Auth';
import { useApiWithNotifications } from '../hooks/form';

export const useSubscription = () => {
	const { fetchUser } = useAuth();

	const create = useCallback(
		async (
			type: SubscriptionTypes,
			billing?: Required<User>['billing']
		) => {
			const result = await createSubscription(type, billing);

			window.location.href = result.url;
		},
		[]
	);

	const cancel = useApiWithNotifications(async () => {
		const reason = prompt(
			'Care este motivul anulării abonamentului? Ce putem face mai bine?'
		);

		await cancelSubscription(reason || '');

		fetchUser();
	});

	return { create, cancel };
};
