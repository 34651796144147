import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Container } from '@material-ui/core';
import { useAuth, useAuthRole } from '../../context/Auth';
import { useAppScroll } from '../../helpers/browser';
import { SubscriptionBar } from '../Payments/SubscriptionBar';
import { HeaderSpacer } from '../HeaderSpacer';
import { HeaderToolbar } from './Toolbar';

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		background: theme.palette.background.default,
		[theme.breakpoints.down('sm')]: {
			// paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
	},
	appBarShadow: {
		boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 12px',
	},
	toolbar: {
		background: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	toolBarWithContainer: {
		padding: 0,
	},
	menuButton: {
		marginRight: theme.spacing(3),
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
		textDecoration: 'none',
	},
	emailBadge: {},
	email: {
		marginLeft: 10,
		maxWidth: 200,
		[theme.breakpoints.down('sm')]: {
			margin: 0,
			maxWidth: '60%',
		},
	},
}));

interface Props {
	displayShadow?: boolean;
	useContainer?: boolean;
}

const useShadow = (displayShadow?: boolean) => {
	const [shadowState, setShadowState] = useState<'hidden' | 'visible'>(
		displayShadow ? 'visible' : 'hidden'
	);

	useAppScroll(
		displayShadow
			? null
			: (scroll) => {
					setShadowState((prev) => {
						if (prev === 'hidden' && scroll > 0) {
							return 'visible';
						} else if (scroll === 0 && !displayShadow) {
							return 'hidden';
						}

						return prev;
					});
			  },
		[displayShadow]
	);

	return { shadowState };
};

export const Header = (props: Props) => {
	const classes = useStyles();
	const { displayShadow, useContainer } = props;
	const { loading } = useAuth();
	const { subscribed } = useAuthRole();
	const { shadowState } = useShadow(displayShadow);

	const displaySubscriptionBar = !subscribed && !loading;

	return (
		<>
			<AppBar
				position="fixed"
				className={[
					classes.appBar,
					shadowState === 'visible' ? classes.appBarShadow : '',
				].join(' ')}
				elevation={0}
			>
				{displaySubscriptionBar && <SubscriptionBar />}
				{useContainer ? (
					<Container>
						<HeaderToolbar
							className={
								useContainer ? classes.toolBarWithContainer : ''
							}
						/>
					</Container>
				) : (
					<HeaderToolbar />
				)}
			</AppBar>

			<HeaderSpacer extraSpace={displaySubscriptionBar} />
		</>
	);
};
