import { useTranslation } from 'react-i18next';

export const TranslationEnhanced = (props: { translationKey: string }) => {
	const { t } = useTranslation();

	return (
		<>
			{t(props.translationKey)
				.split('\n')
				.map((text) => {
					if (!text) return <br />;

					return text;
				})}
		</>
	);
};
