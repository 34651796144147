import { Suspense, useContext, lazy } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
	useLocation,
	Location,
} from 'react-router-dom';
import Login from './pages/Auth/Login';
import { Signup } from './pages/Auth/Signup';
import { EstateList } from './pages/EstateList';
import { EstateEdit } from './pages/EstateEdit';
import { UserList } from './pages/Admin/UserList';
import { UserDetails } from './pages/Admin/UserDetails';
import { Homepage } from './pages/Homepage';
import { EstateView } from './pages/EstateView';
import { Cookies, Privacy, TermsAndConditions } from './pages/Legal';
import { AuthContext, authState } from './context/Auth';
import { LocateCfPage } from './pages/LocateCF';
import { HowItWorks } from './pages/HowItWorks';
import { LeadDetails } from './pages/Admin/LeadList/LeadDetails';
import { NextAuctions } from './pages/Email/NextAuctions';
import { Unsubscribe } from './pages/Unsubscribe';
import { Fallback } from './components/Fallback';
import { Analytics } from './components/Analytics';
import { ConfirmAccount } from './pages/Auth/ConfirmAccount';
import { AllSavedSearches } from './pages/Search/List';
import { RequestAuthCode } from './pages/Auth/RequestCode';
import { LoginWithCode } from './pages/Auth/LoginWithCode';
import { Payment } from './pages/Payment';
import { BecomePartner } from './pages/BecomePartner';
import { SubscriptionPage } from './pages/Payment/Subscription';
import { UserDashboard } from './pages/UserDashboard';
import ComposeProviders from './components/ComposeProviders';
import { PaymentModalProvider } from './context/Payment';
import { AddressList } from './pages/Address/list';
import { useWhatsAppWidget } from './hooks/whatsAppWidget';

const EstateLeads = lazy(() => import('./pages/Admin/LeadList/EstateLeads'));
const AllLeads = lazy(() => import('./pages/Admin/LeadList/AllLeads'));
const AdminEstateList = lazy(
	() => import('./pages/Admin/EstateList/AdminEstates')
);
const MyEstates = lazy(() => import('./pages/Admin/EstateList/MyEstates'));
const AuctionOwnerList = lazy(() => import('./pages/Admin/AuctionOwner/List'));
const AuctionOwnerEdit = lazy(() => import('./pages/Admin/AuctionOwner/Edit'));
const AuctionCommentsList = lazy(
	() => import('./pages/Admin/EstateComments/List')
);
const TasksList = lazy(() => import('./pages/Admin/TaskList'));
const NationalRegistryList = lazy(
	() => import('./pages/Admin/NationalRegistry/List')
);
const AncpiStats = lazy(() => import('./pages/Statistics/AncpiStats'));

const WaitForAuthRoute = (props: { children: JSX.Element }) => {
	const { loading } = useContext(AuthContext);

	if (loading) {
		return <Fallback />;
	}

	return props.children;
};

const PrivateRoute = (props: { children: JSX.Element }) => {
	const { logged, loading } = useContext(AuthContext);

	if (loading) {
		return <Fallback />;
	}

	if (logged) return props.children;

	authState.redirectToUrlAfterLogin = window.location.pathname;

	return <Navigate to="/login" replace />;
};

const AdminRoutes = () => {
	return (
		<Routes>
			<Route path="auction-owner/:id" element={<AuctionOwnerEdit />} />
			<Route path="auction-owner" element={<AuctionOwnerList />} />

			<Route path="tasks" element={<TasksList />} />
			<Route path="estates" element={<AdminEstateList />} />
			<Route
				path="national-registry"
				element={<NationalRegistryList />}
			/>

			<Route path="comments" element={<AuctionCommentsList />} />

			<Route path="leads">
				<Route path=":id/*" element={<LeadDetails />} />

				<Route index element={<AllLeads />} />
			</Route>
		</Routes>
	);
};

const AppRoutes = (props: { location: Location }) => {
	useWhatsAppWidget();

	return (
		<Routes location={props.location}>
			<Route
				path="/edit/*"
				element={
					<PrivateRoute>
						<Outlet />
					</PrivateRoute>
				}
			>
				<Route path="users" element={<UserList />} />
				<Route path="user/:id" element={<UserDetails />} />

				<Route path="estate">
					<Route path=":id/:title" element={<EstateEdit />} />
					<Route path=":id" element={<EstateEdit />} />
				</Route>

				<Route path="movable">
					<Route path=":id/:title" element={<EstateEdit />} />
					<Route path=":id" element={<EstateEdit />} />
				</Route>
			</Route>

			<Route
				path="/estate/:id/leads"
				element={
					<PrivateRoute>
						<EstateLeads />
					</PrivateRoute>
				}
			/>

			<Route
				path="/estate/:id/*"
				element={
					<>
						<EstateList display={false} />
						<EstateView />
					</>
				}
			/>

			<Route path="/movable/:id/*">
				<Route
					path="leads"
					element={
						<PrivateRoute>
							<EstateLeads />
						</PrivateRoute>
					}
				/>

				<Route path="*" element={<EstateView />} />
			</Route>

			<Route
				path="/admin/*"
				element={
					<PrivateRoute>
						<AdminRoutes />
					</PrivateRoute>
				}
			/>

			<Route
				path="/search/*"
				element={
					<WaitForAuthRoute>
						<Outlet />
					</WaitForAuthRoute>
				}
			>
				<Route
					path=":searchId/:location/:rangeKM"
					element={<EstateList />}
				/>
				<Route path=":searchId" element={<EstateList />} />
				<Route path=":searchId/:since" element={<EstateList />} />

				<Route path="pub/:userId" element={<AllSavedSearches />} />
				<Route path="*" element={<AllSavedSearches />} />
			</Route>

			<Route
				path="/dashboard/*"
				element={
					<PrivateRoute>
						<Outlet />
					</PrivateRoute>
				}
			>
				<Route path="*" element={<UserDashboard />} />
			</Route>

			<Route path="/investments" element={<EstateList />} />
			<Route path="/investments/:location" element={<EstateList />} />
			<Route
				path="/investments/:location/:rangeKM"
				element={<EstateList />}
			/>

			<Route path="/living" element={<EstateList />} />
			<Route path="/living/:location" element={<EstateList />} />
			<Route path="/living/:location/:rangeKM" element={<EstateList />} />

			<Route path="/estates" element={<EstateList />} />
			<Route
				path="/estates/provider/:providerId"
				element={<AdminEstateList />}
			/>
			<Route path="/estates/:location" element={<EstateList />} />
			<Route
				path="/estates/:location/:rangeKM"
				element={<EstateList />}
			/>

			<Route path="/next-auctions/:county" element={<NextAuctions />} />
			<Route path="/unsubscribe/:type/:id" element={<Unsubscribe />} />

			<Route path="/cf" element={<LocateCfPage />} />
			<Route path="/cf/:state/:uat/:cf" element={<LocateCfPage />} />
			<Route
				path="/share-cf/:agencyId/:state/:uat/:cf"
				element={<LocateCfPage />}
			/>

			<Route path="/become-partner" element={<BecomePartner />} />
			<Route
				path="/self/*"
				element={
					<PrivateRoute>
						<Outlet />
					</PrivateRoute>
				}
			>
				<Route path="address/*">
					<Route path="list" element={<AddressList />} />
				</Route>

				<Route path="list" element={<MyEstates />} />
				<Route path="add" element={<EstateEdit />} />
				<Route path=":id" element={<EstateEdit />} />
			</Route>

			<Route path="/how-it-works" element={<HowItWorks />} />
			<Route path="/privacy" element={<Privacy />} />
			<Route
				path="/terms-and-condition"
				element={<TermsAndConditions />}
			/>
			<Route path="/cookies" element={<Cookies />} />

			<Route path="/signup" element={<Signup />} />
			<Route path="/login" element={<Login />} />
			<Route path="/request-code" element={<RequestAuthCode />} />
			<Route path="/login-with-code" element={<LoginWithCode />} />
			<Route path="/confirm/:token" element={<ConfirmAccount />} />

			<Route path="/payment/:status" element={<Payment />} />
			<Route path="/subscription" element={<SubscriptionPage />} />

			<Route path="/statistics/*">
				<Route path="ancpi" element={<AncpiStats />} />
			</Route>

			<Route path="*" element={<Homepage />} />
		</Routes>
	);
};

const AnimatedRoutes = () => {
	const location = useLocation();

	return (
		// <TransitionGroup>
		// 	<CSSTransition
		// 		key={location.pathname}
		// 		classNames="fade"
		// 		timeout={300}
		// 	>
		<AppRoutes location={location} />
		// 	</CSSTransition>
		// </TransitionGroup>
	);
};

const RoutesWrapper = () => {
	return (
		<Router>
			<ComposeProviders providers={[Analytics, PaymentModalProvider]}>
				<Suspense fallback={<Fallback />}>
					<AnimatedRoutes />
				</Suspense>
			</ComposeProviders>
		</Router>
	);
};

export default RoutesWrapper;
