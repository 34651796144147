import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { AuthTemplate } from '../../components/Templates/Auth';
import { useApiWrapper } from '../../helpers/api';
import { loginWithCode } from '../../api/auth';
import { useAuth } from '../../context/Auth';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const LoginWithCode = () => {
	const { t } = useTranslation();
	const { setToken } = useAuth();
	const navigate = useNavigate();
	const classes = useStyles();
	const formProps = useForm();
	const apiWrapper = useApiWrapper();

	const handleSubmit = async (values: any) => {
		const { code } = values;

		await apiWrapper(async () => {
			const response = await loginWithCode(`${code}`);

			setToken(response.token);

			navigate('/');
		});
	};

	const form = (
		<form
			className={classes.form}
			onSubmit={formProps.handleSubmit(handleSubmit)}
		>
			<Controller
				name="code"
				defaultValue=""
				rules={{ required: true }}
				render={({ onBlur, onChange, value }) => (
					<NumberFormat
						allowNegative={false}
						decimalScale={0}
						customInput={TextField}
						mask="_"
						format="# # # #"
						onBlur={onBlur}
						value={value}
						onValueChange={(values) => {
							onChange(values.floatValue);
						}}
						fullWidth
						variant="outlined"
						error={!!formProps.errors.code}
						label={t('login.codeLabel')}
						name="code"
						required
						InputProps={{
							inputProps: {
								inputMode: 'numeric',
							},
						}}
					/>
				)}
			/>

			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			>
				{t('login.submit')}
			</Button>

			<Box marginTop={2}>
				<Link to="/request-code">
					<Button fullWidth>{t('login.requestNewCode')}</Button>
				</Link>
			</Box>
		</form>
	);

	return (
		<AuthTemplate
			title={t('login.loginWithCodeTitle')}
			form={<FormProvider {...formProps}>{form}</FormProvider>}
		/>
	);
};
