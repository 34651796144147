import { useCallback, useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import * as api from '../../api/estate';
import { MainTemplate } from '../../components/Templates/Main';
import { HomepageIntroduction } from './Introduction';
import { HowItWorks } from '../../components/HowItWorks';
import { SubscribeSection } from '../../components/SubscribeSection';
import { useTranslation } from 'react-i18next';
import { PageMeta } from '../../components/PageMeta';
import { SubscribeModal } from '../../components/SubscribeModal';
import { EstateSafeDB, SubType } from 'foreclosure-types';
import { emptyEstateList } from '../../helpers/defaults';
import { useNotifications } from '../../context/toast';
import { EstateGrid } from '../../components/EstateGrid';
import { useAuth } from '../../context/Auth';
import { HomepageSubscription } from './HomepageSubscription';
import { GoogleAdsBanner } from '../../components/GoogleAds';

const useHomepageEstates = (subType: SubType[]) => {
	const [list, setList] = useState<EstateSafeDB[]>(emptyEstateList);

	useEffect(() => {
		(async () => {
			const getList = (nearMe?: boolean) =>
				api.get(
					{
						type: 'ESTATE',
						subType,
						limit: 4,
						promoted: true,
						nearMe,
					},
					{
						auctionDate: 'desc',
					}
				);

			let list = await getList(true);

			if (list.length === 0) {
				// If nothing found near me, return default
				list = await getList(false);
			}

			setList(list);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subType.join('-')]);

	return list;
};

const useMyEstates = () => {
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();
	const { loading } = useAuth();
	const [list, setList] = useState<EstateSafeDB[]>([]);

	const refetch = useCallback(async () => {
		if (loading) return;

		const list = await api.getSubscribedEstates(
			{},
			{
				auctionDate: 'asc',
			}
		);

		setList(list);
	}, [loading]);

	const unsubscribe = useCallback(
		async (id: string) => {
			if (!window.confirm(t('estate_list.confirmUnsubscribe'))) {
				return;
			}

			await api.unsubscribeFromEstate(id);

			try {
				await refetch();

				setToastMessage({
					severity: 'success',
					message: t('estate_list.unsubscribeSuccess'),
				});
			} catch (err) {
				console.log(err);

				setToastMessage({
					severity: 'error',
					message: t('estate_list.unsubscribeError'),
				});
			}
		},
		[refetch, setToastMessage, t]
	);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { list, unsubscribe };
};

export const Homepage = () => {
	const { t } = useTranslation();
	const living = useHomepageEstates(['APARTMENT', 'HOUSE']);
	const investment = useHomepageEstates([
		'LAND',
		'FACTORY',
		'COMERCIAL',
		'OTHER',
	]);
	const { list: myEstates, unsubscribe } = useMyEstates();

	return (
		<MainTemplate displayHeaderShadow={false}>
			<PageMeta
				title={t('homepage.title')}
				description={t('homepage.subtitle')}
			/>

			<SubscribeModal />

			<Box overflow="hidden" width="100%">
				<Container>
					<HomepageIntroduction />

					{myEstates.length > 0 && (
						<EstateGrid
							title={t('homepage.subscribed_estates_title')}
							list={myEstates}
							unsubscribe
							onUnsubscribe={unsubscribe}
						/>
					)}

					<EstateGrid
						title={t('homepage.living_title')}
						list={living}
					/>

					<GoogleAdsBanner />

					<EstateGrid
						title={t('homepage.investment_title')}
						list={investment}
					/>

					<HomepageSubscription />

					<HowItWorks />

					<SubscribeSection />
				</Container>
			</Box>
		</MainTemplate>
	);
};
