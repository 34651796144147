import { ComponentProps } from 'react';
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';

export const CurrencyPicker = (
	props: ComponentProps<typeof RadioGroup> & {
		className?: string;
		inputRef?: any;
	}
) => {
	const { className, inputRef, ...rest } = props;

	return (
		<FormControl component="fieldset" className={className}>
			<RadioGroup
				row
				aria-label="currency"
				name="currency"
				ref={inputRef}
				{...rest}
			>
				<FormControlLabel
					value="EUR"
					control={<Radio />}
					label="EURO"
				/>
				<FormControlLabel value="RON" control={<Radio />} label="RON" />
			</RadioGroup>
		</FormControl>
	);
};
