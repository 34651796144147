import { Fragment, ReactElement, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Grid,
	makeStyles,
	Typography,
	Link as MaterialLink,
} from '@material-ui/core';
import {
	AuctionOwnerDB,
	EstateDB,
	EstateResponse,
	EstateSafeDB,
	isEstateDB,
	SubscriptionTypes,
} from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import {
	displayEstateDate,
	displayPrice,
	formatOwnerPhone,
	valueIsMissing,
} from '../../helpers/display';
import { useAuth, useAuthRole } from '../../context/Auth';
import { rolesThatCanEdit } from '../../config';
import { Link } from 'react-router-dom';
import { getEstateLink, getEstateState } from '../../helpers/form';
import { AuctionForm } from './AuctionForm';
import { useAuctionOwner } from '../../hooks/auctionOwner';
import { Alert } from '@material-ui/lab';
import { getGoogleMapsLink } from '../../helpers/map';
import { getGoogleCalendarLink } from '../../helpers/events';
import moment from 'moment';
import { contact, getEstatePublications } from '../../api/estate';
import { TranslationEnhanced } from '../../components/TranslationEnhanced';
import { ProgressButton } from '../../components/Form/ProgressButton';
import { usePaymentModal } from '../../context/Payment';
import { LegalReferences } from './LegalReferences';
import {
	getPublicationUrl,
	getSalePublicationUrl,
	translateOwner,
} from '../../helpers/estate';
import { REQUEST_HISTORY_BUTTON, SALE_PUBLICATION_BUTTON } from './Tour';
import { Modal } from '../../components/Modal';
// import { AuctionDetailsDocument } from './AuctionDetailsDocument';

const useStyles = makeStyles((theme) => ({
	auctionContainer: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.up('md')]: {
			margin: 0,
			paddingLeft: theme.spacing(4),
		},
	},
	documentTitle: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
}));

const AuctionDetailsRowContent = (props: {
	content?: string | ReactElement | Array<ReactElement | string | null>;
}) => {
	const { content } = props;

	if (Array.isArray(content)) {
		return (
			<>
				{content.map((text, index) =>
					typeof text === 'string' ? (
						<Typography key={text}>{text}</Typography>
					) : (
						<Fragment key={index}>{text}</Fragment>
					)
				)}
			</>
		);
	}

	return <Typography>{content}</Typography>;
};

const AuctionDetailsTitle = (props: { children: string }) => {
	return (
		<Box marginBottom={1}>
			<Typography variant="h5">{props.children}</Typography>
		</Box>
	);
};

const AuctionDetailsRow = (props: {
	leftTitle: string;
	leftContent?: string | ReactElement | Array<ReactElement | string | null>;
	rightTitle: string;
	rightContent?: string | ReactElement | Array<ReactElement | string | null>;
}) => {
	const { leftTitle, leftContent, rightTitle, rightContent } = props;

	return (
		<Box marginTop={5}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<AuctionDetailsTitle>{leftTitle}</AuctionDetailsTitle>
					<AuctionDetailsRowContent content={leftContent} />
				</Grid>
				<Grid item xs={12} md={6}>
					<AuctionDetailsTitle>{rightTitle}</AuctionDetailsTitle>
					<AuctionDetailsRowContent content={rightContent} />
				</Grid>
			</Grid>
		</Box>
	);
};

const GoogleCalendar = (props: { estate: EstateDB; owner: AuctionOwnerDB }) => {
	const { t } = useTranslation();
	const { estate, owner } = props;
	const date = displayEstateDate(estate);

	if (!date || !moment(date).isValid()) return <>{date}</>;

	const details = [
		owner.name,
		t('advertCard.executionNumber') + ' ' + estate.executionNumber,
		owner.email || '',
		owner.phone || '',
		'',
		estate.description,
	].join(' \n');
	const link = getGoogleCalendarLink({
		text: `${t('estateView.map.auction')}: ${estate.title}`,
		details,
		location: owner.address || '',
		dates: { start: date },
	});

	return (
		<>
			{date}
			<br />
			<a href={link} target="_blank" rel="noreferrer">
				{t('estateView.auction.add_to_calendar')}
			</a>
		</>
	);
};

export const AuctionAddress = (props: { estate: EstateDB | EstateSafeDB }) => {
	const { t } = useTranslation();
	const { estate } = props;
	const { owner: auctionOwner } = useAuctionOwner(
		isEstateDB(estate) ? estate.auctionOwnerId : undefined
	);

	// TODO: display email and phone of user

	if (!isEstateDB(estate)) {
		return null;
	}

	return (
		<>
			{!valueIsMissing(auctionOwner.name) && (
				<>
					<br />
					<Alert severity="info">
						{t('estateView.auction.contactForMoreInfo')}
					</Alert>
				</>
			)}

			<AuctionDetailsRow
				leftTitle={t('estateView.auction.address')}
				leftContent={
					valueIsMissing(auctionOwner.address) ? (
						t('advertCard.undefinedAddress')
					) : (
						<>
							{auctionOwner.address}
							<br />
							<a
								href={getGoogleMapsLink(
									auctionOwner.address || ''
								)}
								target="_blank"
								rel="noreferrer"
							>
								{t('estateView.property.navigate')}
							</a>
						</>
					)
				}
				rightTitle={t('estateView.auction.date')}
				rightContent={
					<GoogleCalendar estate={estate} owner={auctionOwner} />
				}
			/>
			{!valueIsMissing(auctionOwner.name) && (
				<AuctionDetailsRow
					leftTitle={translateOwner(estate.auctionType)}
					leftContent={[
						auctionOwner.name,
						<Typography>
							{t('advertCard.executionNumber')}:{' '}
							{estate.executionNumber}
						</Typography>,
					]}
					rightTitle={t('estateView.auction.contact')}
					rightContent={[
						!valueIsMissing(auctionOwner.email) ? (
							<a
								key={auctionOwner.email}
								href={`email: ${auctionOwner.email}`}
							>
								<Typography>{auctionOwner.email}</Typography>
							</a>
						) : null,
						...formatOwnerPhone(auctionOwner.phone || '').map(
							(tel) => (
								<a key={tel} href={`tel: ${tel}`}>
									<Typography>{tel}</Typography>
								</a>
							)
						),
					]}
				/>
			)}
		</>
	);
};

const PreviousPublications = (props: { estateId: string }) => {
	const { t } = useTranslation();
	const { estateId } = props;
	const [open, setOpen] = useState(false);
	const [publications, setPublications] = useState<
		EstateResponse['publications']
	>([]);

	useEffect(() => {
		if (!estateId) return;

		(async () => {
			try {
				const publications = await getEstatePublications(estateId);

				if (publications) setPublications(publications);
			} catch (err) {
				// nothing
			}
		})();
	}, [estateId]);

	if (!publications.length) {
		return (
			<Alert severity="warning">
				{t('estateView.auction.noPublications')}
			</Alert>
		);
	}

	return (
		<>
			<ProgressButton
				color="primary"
				variant="contained"
				onClick={() => setOpen(true)}
				loading={false}
				className={REQUEST_HISTORY_BUTTON}
			>
				{t('estateView.auction.request_history')}
			</ProgressButton>

			<Modal open={open} onClose={() => setOpen(false)}>
				<Box
					overflow="auto"
					maxHeight="70vh"
					maxWidth="90vw"
					width="700px"
					padding={2}
				>
					<Box marginBottom={2}>
						<Typography variant="h4">
							{t('estateView.auction.previousPublications')}
						</Typography>
					</Box>

					{publications.map((el, index) => (
						<Box marginTop={2} key={el}>
							<Typography
								component={MaterialLink}
								href={getSalePublicationUrl(el)}
								target="_blank"
							>
								Publicație {index + 1}
							</Typography>
						</Box>
					))}
				</Box>
			</Modal>
		</>
	);
};

const RequestHistory = (props: { estateId: string }) => {
	const { t } = useTranslation();
	const { logged, hasSubscription } = useAuth();
	const { openPaymentModal } = usePaymentModal();

	if (!logged) {
		return (
			<>
				<Alert severity="warning">
					{t('estateView.auction.request_history_not_logged')}
				</Alert>
				<Box marginTop={2}>
					<Link to="/login">
						<Button color="primary" variant="contained">
							{t('login.title')}
						</Button>
					</Link>
				</Box>
			</>
		);
	}

	if (!hasSubscription(SubscriptionTypes.PRO)) {
		return (
			<>
				<Alert severity="warning">
					{t('estateView.auction.request_history_not_subscribed')}
				</Alert>
				<Box marginTop={2}>
					<Button
						color="primary"
						variant="contained"
						onClick={openPaymentModal}
					>
						{t('payment.subscribe')}
					</Button>
				</Box>
			</>
		);
	}

	return <PreviousPublications estateId={props.estateId} />;
};

const AuctionPromotionDisclaimer = () => {
	const { t } = useTranslation();
	const { isOperator } = useAuthRole();

	return (
		<Box marginBottom={3}>
			{isOperator ? (
				<Alert severity="warning">
					<div>
						Ești operator 🛠️ al site-ului: completează formularul de
						mai jos pentru a adăuga manual un lead în sistem.
					</div>
					Automatizarea pentru WhatsApp este dezactivată pentru
					lead-urile adăugate manual.
				</Alert>
			) : (
				<Alert severity="success">
					{t('estateView.auction.promotionDisclaimer')
						.split('\n')
						.map((text) => (
							<Box marginBottom={1}>{text}</Box>
						))}
				</Alert>
			)}
		</Box>
	);
};

const RequestAuctionDetailsWhileLogged = (props: {
	estateId: string;
	onSuccessContact: (estate: EstateDB) => void;
}) => {
	const { estateId, onSuccessContact } = props;
	const { user } = useAuth();
	const { t } = useTranslation();
	const { email, phone, name } = user;
	const [loading, setLoading] = useState(false);

	if (!name || !phone) return null;

	const handleClick = async () => {
		setLoading(true);

		const estateDetails = await contact(estateId, {
			name,
			email,
			phone,
			text: t('estateView.contact.defaultText'),
		});

		onSuccessContact(estateDetails);

		setLoading(false);
	};

	return (
		<>
			<Box marginBottom={3}>
				<Alert severity="info">
					<Typography variant="body1">
						<TranslationEnhanced translationKey="estateView.auction.areYouInterested" />
					</Typography>
				</Alert>
			</Box>
			<ProgressButton
				loading={loading}
				color="primary"
				onClick={handleClick}
			>
				{t('estateView.auction.seeAuctioneer')}
			</ProgressButton>
		</>
	);
};

const DocumentsLinks = (props: { estate: Props['estate'] }) => {
	const { estate } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	if (estate.promoted || !isEstateDB(estate)) return null;

	if (estate.auctionType === 'INSOLVENCY') {
		return (
			<Box marginTop={2} display="flex" flexDirection="column">
				<Box marginBottom={1}>
					<Typography className={classes.documentTitle}>
						<Alert severity="info">
							{t('estateView.auction.document_subtitle')}
						</Alert>
					</Typography>
				</Box>
				{estate.auctionDocuments?.map((el, index) => (
					<a
						href={getSalePublicationUrl(el.path)}
						target="_blank"
						rel="noreferrer"
					>
						<Button
							color="primary"
							variant="text"
							className={SALE_PUBLICATION_BUTTON}
						>
							{t('estateView.auction.document_title') +
								' ' +
								(index + 1)}
						</Button>
					</a>
				))}

				<Box marginTop={2}>
					<a
						href={getPublicationUrl(estate)}
						target="_blank"
						rel="noreferrer"
					>
						<Button
							color="primary"
							variant="contained"
							className={SALE_PUBLICATION_BUTTON}
						>
							{t('estateView.auction.estate_link')}
						</Button>
					</a>
				</Box>
			</Box>
		);
	}

	if (!estate.auctionUrl) return null;

	return (
		<div>
			<Box marginBottom={2}>
				<Typography className={classes.documentTitle}>
					<Alert severity="info">
						{t('estateView.auction.document_subtitle')}
					</Alert>
				</Typography>
			</Box>
			<a
				href={getPublicationUrl(estate)}
				target="_blank"
				rel="noreferrer"
			>
				<Button
					color="primary"
					variant="contained"
					className={SALE_PUBLICATION_BUTTON}
				>
					{t('estateView.auction.document_title')}
				</Button>
			</a>
		</div>
	);
};

type Props = {
	estate: EstateDB | EstateSafeDB;
	onSuccessContact: (estate: EstateDB) => void;
};

export const AuctionDetails = (props: Props) => {
	const { t } = useTranslation();
	const { estate } = props;
	const classes = useStyles();
	const { user, logged } = useAuth();
	const { isOperator } = useAuthRole();
	const isOwner = user._id === estate.user;
	const canEdit = isOwner || rolesThatCanEdit.includes(user.role);
	// @ts-ignore
	const biggestOffer = estate?.biggestOffer || 0;

	const getForm = () => {
		// if (isOwner && !isOperator) return null;

		if (estate.promoted) {
			const { email, phone, name } = user;

			if (
				!isOperator &&
				!isEstateDB(estate) &&
				logged &&
				email &&
				phone &&
				name
			) {
				return (
					<>
						<AuctionPromotionDisclaimer />
						<RequestAuctionDetailsWhileLogged
							estateId={estate._id}
							onSuccessContact={props.onSuccessContact}
						/>
					</>
				);
			}

			return (
				<>
					<AuctionPromotionDisclaimer />
					<AuctionForm
						estate={estate}
						onSuccessContact={props.onSuccessContact}
					/>
				</>
			);
		}

		return (
			<Alert severity="warning">
				<Typography variant="body1">
					{t('estateView.auction.auctionNotPromoted')}
				</Typography>
			</Alert>
		);
	};

	return (
		<div className={classes.auctionContainer}>
			<Box
				marginBottom={1}
				paddingTop={2}
				display="flex"
				justifyContent="space-between"
			>
				<Typography color="primary" variant="h6">
					{t('estateView.auction.title')}
				</Typography>

				{canEdit && (
					<Box display="flex" flexWrap="nowrap">
						<Box marginRight={2}>
							<Link to={`/estate/${estate._id}/leads`}>
								<Button color="secondary">
									{t('estateEdit.leads')}
								</Button>
							</Link>
						</Box>
						<Link to={`${getEstateLink(estate, true)}`}>
							<Button color="secondary">
								{t('estateEdit.edit')}
							</Button>
						</Link>
					</Box>
				)}
			</Box>
			<Box>
				<Typography variant="h4" component="h1">
					{displayPrice(estate.price, estate.currency)} -{' '}
					{getEstateState(estate.state)}
				</Typography>
			</Box>
			<Box>
				<Typography variant="h5">
					{estate.marketPrice
						? t('estateView.auction.market_price', {
								price: displayPrice(
									estate.marketPrice,
									estate.currency
								),
						  })
						: null}
				</Typography>

				{!!biggestOffer && (
					<Typography variant="subtitle1">
						{t('estateView.auction.biggest_offer', {
							price: displayPrice(biggestOffer, estate.currency),
						})}
					</Typography>
				)}

				<Typography variant="subtitle2">
					{estate.viewCount} {t('estateView.auction.views')}
				</Typography>
			</Box>

			<AuctionAddress estate={estate} />

			{/* <AuctionDetailsDocument document={estate.auctionDocument} /> */}
			<DocumentsLinks estate={estate} />

			<Box marginTop={3}>
				<Typography variant="h5">
					{t('estateView.auction.legalReferences')}
				</Typography>

				<Box marginTop={2} marginBottom={2}>
					<Alert severity="info">
						{t('estateView.auction.legalReferencesNote')}
					</Alert>
				</Box>

				<Box marginTop={1}>
					<LegalReferences
						references={
							'legalReferrences' in estate
								? estate.legalReferrences
								: null
						}
					/>
				</Box>
			</Box>

			<Box marginTop={3}>
				<Typography variant="h5">
					{t('estateView.auction.history')}
				</Typography>

				<Box marginTop={2} marginBottom={2}>
					<Alert severity="info">
						{t('estateView.auction.historyNote')}
					</Alert>
				</Box>

				<Box marginTop={1}>
					<RequestHistory estateId={estate._id} />
				</Box>
			</Box>

			<Box marginTop={5}>{getForm()}</Box>
		</div>
	);
};
