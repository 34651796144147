import { Grid, IconButton, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';

export const QualityInput = (props: {
	quality: string;
	controls?: boolean;
	color: string;
	onDecrease?: () => any;
	onIncrease?: () => any;
}) => {
	const { quality, color, controls = false, onDecrease, onIncrease } = props;

	const text = (
		<Typography variant="body2" style={{ color }}>
			{capitalize(quality)}
		</Typography>
	);

	if (!controls) {
		return text;
	}

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="flex-end"
			spacing={2}
		>
			<Grid item>
				<IconButton
					size="small"
					onClick={(e) => {
						e.stopPropagation();

						onDecrease && onDecrease();
					}}
				>
					<RemoveCircleOutline />
				</IconButton>
			</Grid>

			<Grid item>{text}</Grid>

			<Grid item>
				<IconButton
					size="small"
					onClick={(e) => {
						e.stopPropagation();

						onIncrease && onIncrease();
					}}
				>
					<AddCircleOutline />
				</IconButton>
			</Grid>
		</Grid>
	);
};
