import { useEffect } from 'react';

export const GoogleAdsArticle = () => {
	useEffect(() => {
		// @ts-ignore
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}, []);

	return (
		<ins
			className="adsbygoogle"
			style={{ display: 'block', textAlign: 'center' }}
			data-ad-layout="in-article"
			data-ad-format="fluid"
			data-ad-client="ca-pub-2273633532364996"
			data-ad-slot="8092179569"
		/>
	);
};

export const GoogleAdsFluid = () => {
	useEffect(() => {
		// @ts-ignore
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}, []);

	return (
		<ins
			className="adsbygoogle"
			style={{ display: 'block' }}
			data-ad-format="fluid"
			data-ad-layout-key="-fb+5w+4e-db+86"
			data-ad-client="ca-pub-2273633532364996"
			data-ad-slot="5458239372"
		/>
	);
};

export const GoogleAdsBanner = () => {
	useEffect(() => {
		// @ts-ignore
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	}, []);

	return (
		<ins
			className="adsbygoogle"
			style={{ display: 'block', marginTop: 24 }}
			data-ad-client="ca-pub-2273633532364996"
			data-ad-slot="2549839152"
			data-ad-format="auto"
			data-full-width-responsive="true"
		/>
	);
};
