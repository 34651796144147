import React, { ReactElement } from 'react';
import { Link, Typography } from '@material-ui/core';

export const Copyright = (): ReactElement => (
	<Typography variant="body2" align="center">
		Copyright © 2020 - {new Date().getFullYear()}{' '}
		<Link color="inherit" href="https://licitatiibanci.ro/">
			Beaverr Territory SRL CUI 41616858
		</Link>
	</Typography>
);
