import { Button, makeStyles } from '@material-ui/core';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Overlay } from './Overlay';
import { Footer } from './Footer';
import { Header } from './Header';
import { ResponsiveRender } from '../ResponsiveRender';
import { useMobileSize } from '../../hooks/responsive';
import { useFilterValue } from '../../pages/EstateList/filters/hooks';
import { FilterKeys } from '../../pages/EstateList/filters/types';
import { useFieldsStyles } from '../../pages/EstateList/filters/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	overlay: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up('lg')]: {
			borderRadius: 12,
			backgroundColor: theme.palette.background.paper,
		},
		[theme.breakpoints.down('md')]: {
			'& > *': {
				backgroundColor: theme.palette.background.paper,
			},
		},
	},
}));

type Props = {
	label: string;
	children: ReactNode;
	keys?: Array<FilterKeys>;
	sufix?: string;
	onSave?: () => void;
	onReset?: () => void;
};

export const OverlayFilter = (props: Props) => {
	const escappedLabel = props.label.replace(/ /g, '-').replace(/[()]/g, '');
	const overlayId = 'filter-' + escappedLabel;

	const classes = useStyles();
	const fieldsClasses = useFieldsStyles();

	const [open, setOpen] = useState(false);

	const value = useFilterValue(props.keys || []);

	const { isMobile: isPhone, isTablet } = useMobileSize();
	const isMobile = isPhone || isTablet;
	const { onSave } = props;

	const handleSave = useCallback(() => {
		if (isMobile) {
			setOpen(false);
		}

		if (onSave) onSave();
	}, [onSave, isMobile, setOpen]);

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const isFilter = target.classList.contains(overlayId);
			const hasFilterParent = !!target.closest(`.${overlayId}`);

			if (isFilter || hasFilterParent) {
				return;
			}

			setOpen(false);
		};

		document.body.addEventListener('click', handleClick);

		return () => document.body.removeEventListener('click', handleClick);
	}, [overlayId]);

	return (
		<div className={classes.root}>
			<Button
				color="secondary"
				className={[overlayId].join(' ')}
				onClick={() => setOpen((prev) => !prev)}
			>
				{value ? [value, props.sufix].join(' ') : props.label}
			</Button>
			<Overlay overlayId={overlayId} open={open}>
				<div
					className={[classes.overlay, fieldsClasses.fields].join(
						' '
					)}
				>
					<ResponsiveRender
						mdDown={
							<Header
								title={props.label}
								onClose={() => setOpen(false)}
							/>
						}
					/>

					{props.children}

					<Footer onSave={handleSave} onReset={props.onReset} />
				</div>
			</Overlay>
		</div>
	);
};
