export interface Timestamp {
    createdAt: Date;
    updatedAt: Date;
}

export const hasTimestamp = <T extends Object>(
    obj: T
): obj is T & Timestamp => {
    return 'createdAt' in obj && 'updatedAt' in obj;
};
