import axios from './cache';
import { CfCoordinatesResponse } from 'foreclosure-types';
import { baseUrl } from './config';

export const getCoordinates = async (
	state: number,
	county: number,
	cf: string,
	agencyId?: string
) => {
	const response = await axios.get(
		`${baseUrl}/map/cf-coordinates/${agencyId || ''}`,
		{
			params: {
				state,
				county,
				cf,
			},
		}
	);

	return response.data as CfCoordinatesResponse['getOne'];
};

export const getCoordinatesById = async (id: string) => {
	const response = await axios.get(`${baseUrl}/map/${id}`);

	return response.data as CfCoordinatesResponse['getById'];
};

export const getCfHistory = async () => {
	const response = await axios.get(`${baseUrl}/map/cf/history`);

	return response.data as CfCoordinatesResponse['history'];
};

export const getMyLocation = async () => {
	const response = await axios.get(`${baseUrl}/map/my-location`);

	return response.data as CfCoordinatesResponse['myLocation'];
};
