import { makeStyles } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';

const useStyles = makeStyles(() => ({
	markdown: {
		background: 'transparent',
		fontSize: 'inherit',
		color: 'rgba(0, 0, 0, 0.87)',
		'& h1, h2, h3, h4, h5, h6': {
			border: 'none',
		},
	},
}));

type Props = {
	children?: string;
};

export const Markdown = (props: Props) => {
	const classes = useStyles();

	return (
		<MDEditor.Markdown
			className={classes.markdown}
			source={props.children}
		/>
	);
};
