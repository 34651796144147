import { SubType } from 'foreclosure-types';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { EstateCategory } from '../../types/estate';
import { getDisplayEstateList } from '../../context/EstateList';

export const useCacheBasedOnDisplayEstateList = <T>(value: T): T => {
	const displayEstateList = getDisplayEstateList();
	const cache = useRef<T>();

	if (displayEstateList) {
		cache.current = value;
	}

	return cache.current || value;
};

export const useEstateListUrlParams = () => {
	return useCacheBasedOnDisplayEstateList(
		useParams<{
			location?: string;
			rangeKM?: string;
			searchId?: string;
			since?: string;
		}>()
	);
};

export const usePropertyTypeFromUrl = (): {
	label: EstateCategory;
	translation: string;
	types: SubType[];
} => {
	const { pathname } = useCacheBasedOnDisplayEstateList(useLocation());
	const { t } = useTranslation();

	return useMemo(() => {
		if (pathname.includes(EstateCategory.INVESTMENTS)) {
			return {
				label: EstateCategory.INVESTMENTS,
				translation: t('top_menu.investments'),
				types: ['COMERCIAL', 'FACTORY', 'LAND', 'CAR', 'OTHER'],
			};
		}

		if (pathname.includes(EstateCategory.LIVING)) {
			return {
				label: EstateCategory.LIVING,
				translation: t('top_menu.living'),
				types: ['HOUSE', 'APARTMENT'],
			};
		}

		return {
			label: EstateCategory.ALL,
			translation: t('top_menu.all_estates'),
			types: [],
		};
	}, [pathname, t]);
};
