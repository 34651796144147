import { useContext } from 'react';
import { EstateFilters } from 'foreclosure-types';
import { FiltersContext } from '../../../context/Filters';

const getFilterValue = (key: keyof EstateFilters, values: EstateFilters) => {
	const value = values[key];

	if (Array.isArray(value)) {
		return value.join(', ');
	}

	if (typeof value === 'object') {
		return undefined;
	}

	return value;
};

export const useFilterValue = (
	values: Array<keyof Omit<EstateFilters, 'auctionLocation' | 'location'>>
) => {
	const { filters } = useContext(FiltersContext);

	if (values.length === 1) {
		const [key] = values;
		const value = getFilterValue(key, filters);

		if (value) {
			return `${value}`;
		}
	}

	if (values.length === 2) {
		const [key1, key2] = values;

		if (filters[key1] && filters[key2]) {
			return `${getFilterValue(key1, filters)} - ${getFilterValue(
				key2,
				filters
			)}`;
		}

		if (filters[key1]) {
			return `De la ${getFilterValue(key1, filters)}`;
		}

		if (filters[key2]) {
			return `Până la ${getFilterValue(key2, filters)}`;
		}
	}

	return values
		.map((key) => getFilterValue(key, filters))
		.filter(Boolean)
		.join(', ');
};
