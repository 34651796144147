import React, { ComponentType, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { green, red } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Check, Error } from '@material-ui/icons';
import { CircularProgress, Button, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		wrapper: {
			position: 'relative',
			display: 'inline-block',
		},
		buttonSuccess: {
			backgroundColor: green[500],
			'&:hover': {
				backgroundColor: green[700],
			},
		},
		buttonError: {
			backgroundColor: red[500],
			'&:hover': {
				backgroundColor: red[700],
			},
		},
		buttonProgress: {
			color: green[500],
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

type Props = {
	loading: boolean;
	error?: boolean;
	component?: ComponentType;
};

export const ProgressButton = (
	props: ButtonProps & Props
): React.ReactElement => {
	const classes = useStyles({});
	const [success, setSuccess] = React.useState(false);
	const { loading, error, component, className, ...buttonProps } = props;
	const loadingRef = useRef<boolean>(false);
	const timer = useRef<ReturnType<typeof setTimeout>>();
	const buttonClassname = clsx({
		[classes.buttonSuccess]: success && !error,
		[classes.buttonError]: error,
	});
	const ButtonComponent = component || Button;

	useEffect(() => {
		return (): void => {
			if (timer.current) clearTimeout(timer.current);
		};
	}, []);

	useEffect(() => {
		if (loadingRef.current === true && loading === false) {
			setSuccess(true);

			timer.current = setTimeout(() => {
				setSuccess(false);
			}, 1000);
		}

		loadingRef.current = loading;
	}, [loading]);

	return (
		<div className={classes.wrapper}>
			<ButtonComponent
				{...buttonProps}
				className={`${buttonClassname} ${className || ''}`}
				disabled={loading || buttonProps.disabled}
			>
				{success ? error ? <Error /> : <Check /> : buttonProps.children}
			</ButtonComponent>
			{loading && (
				<CircularProgress
					size={24}
					className={classes.buttonProgress}
				/>
			)}
		</div>
	);
};
