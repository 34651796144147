import { makeStyles } from '@material-ui/core';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexGrow: 1,
		height: '100vh',
		flexDirection: 'column',
	},
	content: {
		flexGrow: 1,
		position: 'relative',
		[theme.breakpoints.up('md')]: {
			overflow: 'auto',
		},
		[theme.breakpoints.down('md')]: {
			overflowX: 'hidden',
		},
	},
}));

interface Props {
	children: unknown;
	displayFooter?: boolean;
	displayHeaderShadow?: boolean;
	useContainerForHeader?: boolean;
	contentClassName?: string;
	style?: CSSProperties;
}

export const MainTemplate = (props: Props) => {
	const classes = useStyles();
	const {
		children,
		displayFooter = true,
		displayHeaderShadow = true,
		useContainerForHeader = true,
		contentClassName = '',
		style = {},
	} = props;

	return (
		<main className={classes.container} style={style}>
			<Header
				displayShadow={displayHeaderShadow}
				useContainer={useContainerForHeader}
			/>
			<div
				id="app-content"
				className={classes.content + ' ' + contentClassName}
			>
				<>
					{children}
					{displayFooter && <Footer />}
				</>
			</div>
		</main>
	);
};
