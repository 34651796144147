import { SubscriptionTypes } from './Payment';

export enum UserRole {
    ADMIN = 'ADMIN',
    LOCAL_ADMIN = 'LOCAL_ADMIN',
    AGENT = 'AGENT',
    USER = 'USER',
}

export interface UserSearch {
    lastViewedAt: Date;
    previousLastViewedAt: Date;
    filter: string;
    display: string;
    sort: string;
    address: string;
}

export interface User {
    email: string;
    notificationsEmail?: string;
    password: string;
    name?: string;
    phone?: string;
    facebookId?: string;
    googleId?: string;
    state?: string;
    role: UserRole;
    organization?: string;
    confirmed?: boolean;
    loginWithCode?: {
        code: string;
        expires: Date;
    };
    locateCfHistory: string[];
    searches: UserSearch[];
    watchList: {
        estateId: string;
    }[];
    subscription?: SubscriptionTypes;
    cancelReason?: string;
    skipPaymentUntil?: Date | null;
    paymentDisabled?: boolean;
    billing?: {
        name: string;
        phone: string;
        email: string;
        state: string;
        city: string;
        address: string;
        company?: string;
        cui?: string;
    };
}

export interface UserDB extends User {
    _id: string;
}

export interface UserResponse {
    login: { token: string };
    register: { token: string };
    count: {
        basic: number;
        pro: number;
        agency: number;
        newsletter: number;
    };
}
