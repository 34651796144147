import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Grid,
	makeStyles,
	Paper,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { History, LocationOn } from '@material-ui/icons';
import { ProgressButton } from '../../components/Form/ProgressButton';
import { UatList, useStatesList } from '../../helpers/statesList';
import { NavigateButton } from '../../components/NavigateButton';
import { ShareButton } from '../../components/ShareButton';
import { useAuth } from '../../context/Auth';
import { CfCoordinates, Perimeter, SubscriptionTypes } from 'foreclosure-types';
import { getCfHistory } from '../../api/coordinates';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	paperContainer: {
		'& fieldset': {
			border: 0,
		},
	},
	button: {
		height: 56,
		maxHeight: '100%',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		'&&': {
			border: 'none',
			minWidth: 'auto',
			paddingLeft: 0,
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
	},
	searchButton: {
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
}));

const getInputValue = (val?: string) => {
	if (val === '_') return '';

	return val;
};

const useRunOnce = (callback: () => any) => {
	const didRun = useRef(false);

	useEffect(() => {
		if (didRun.current) {
			return;
		}

		didRun.current = true;
		callback();
	}, [callback]);
};

const SelectState = (props: {
	value?: string;
	disabled?: boolean;
	onChange: (state: string) => any;
}) => {
	const { t } = useTranslation();
	const states = useStatesList();
	const { value, onChange, disabled } = props;

	const selected =
		value && Object.keys(states).find((state) => state.includes(value));

	return (
		<Select
			fullWidth
			variant="outlined"
			native
			value={selected}
			disabled={disabled}
			onChange={({ target }) => {
				onChange(target.value as string);
			}}
		>
			<option>{t('locateCF.chooseState')}</option>
			{Object.keys(states).map((state) => {
				const [stateName] = state.split('_');

				return (
					<option value={state} key={state}>
						{stateName}
					</option>
				);
			})}
		</Select>
	);
};

const useUatFinder = () => {
	const states = useStatesList();

	return useCallback(
		(uatCode: number) => {
			const state = Object.keys(states).find((key) => {
				return Object.values(states[key]).includes(uatCode);
			});

			if (!state) {
				return {
					state: '',
					uat: '',
				};
			}

			const uat = Object.keys(states[state]).find(
				(key) => states[state][key] === uatCode
			);

			return {
				state,
				uat,
			};
		},
		[states]
	);
};

const SelectUAT = (props: {
	value?: string;
	disabled?: boolean;
	onChange: (state: string) => any;
	state: string;
}) => {
	const { t } = useTranslation();
	const { value, onChange, state, disabled } = props;
	const states = useStatesList();
	const [uats, setUats] = useState<UatList>({});

	useEffect(() => {
		if (state) {
			const selectedState = Object.keys(states).find((row) =>
				row.includes(state)
			);

			if (selectedState) setUats(states[selectedState]);
		} else setUats({});
	}, [states, state]);

	return (
		<Select
			fullWidth
			variant="outlined"
			native
			value={value}
			disabled={disabled}
			onChange={(event) => onChange(event.target.value as string)}
		>
			<option>{t('locateCF.chooseCounty')}</option>
			{Object.keys(uats).map((uat) => (
				<option value={uats[uat]} key={uat}>
					{uat}
				</option>
			))}
		</Select>
	);
};

const useCountyFromShortName = (shortName?: string) => {
	const states = useStatesList();

	if (!shortName) return '';

	if (shortName.length > 2) return shortName;

	return Object.keys(states).find((state) => {
		const [, , code] = state.split('_');

		return code === shortName;
	});
};

const useCfSharing = (params: {
	state?: string;
	uat?: string;
	cf?: string;
}) => {
	const { t } = useTranslation();
	const { state, uat, cf } = params;
	const { user } = useAuth();

	const isAgency = user.subscription === SubscriptionTypes.AGENCY;

	const share = useCallback(() => {
		if (!isAgency) {
			window.alert(t('locateCF.shareConstrain'));
			return false;
		}

		const link = [
			window.location.origin,
			'share-cf',
			user._id,
			state,
			uat,
			cf,
		].join('/');
		navigator.clipboard.writeText(link);

		return true;
	}, [isAgency, state, uat, cf, t, user]);

	return {
		disabled: !state || !uat || !cf,
		share,
	};
};

const useCfHistory = () => {
	const { logged } = useAuth();
	const [cfHistory, setHistory] = useState<
		Array<
			CfCoordinates & {
				state: string;
				stateLabel: string;
				uatLabel: string;
			}
		>
	>([]);
	const findUat = useUatFinder();

	useEffect(() => {
		if (!logged) return;

		(async () => {
			try {
				const result = await getCfHistory();
				const history = result.map((row) => {
					const { state, uat } = findUat(Number(row.county));

					return {
						...row,
						state,
						stateLabel: state.split('_')[0] || '',
						uatLabel: uat || '',
					};
				});

				setHistory(history);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [logged, findUat]);

	return {
		cfHistory,
	};
};

type Props = {
	initialValues?: {
		state?: string;
		uat?: string;
		cf?: string;
	};
	history?: boolean;
	readOnly?: boolean;
	loading?: boolean;
	navigate?: boolean;
	share?: boolean;
	preventSearchOnMount?: boolean;
	googleMapsCoordinates?: Array<number>;
	columnView?: boolean;
	onSubmit?: (state: string, county: string, cf: string) => any;
	onAutocomplete?: (coordinates: { wgs84: Perimeter }) => any;
	onChange?: (state: string, county: string, cf: string) => any;
};

type AutocompleteCoordinates = CfCoordinates & {
	state: string;
	stateLabel: string;
	uatLabel: string;
};
type AutocompleteCfHistoryValue = string | AutocompleteCoordinates;
const AutocompleteCfHistory = (props: {
	onChange: (value: AutocompleteCfHistoryValue) => void;
	value: string;
}) => {
	const { onChange, value } = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const { cfHistory } = useCfHistory();
	const [selected, setSelected] = useState<AutocompleteCoordinates[]>([]);

	const handleChange = useCallback(
		(_event: unknown, value: AutocompleteCfHistoryValue | null) => {
			onChange(value || '');

			if (value && typeof value !== 'string') {
				setSelected((prev) => [...prev, value].filter(Boolean));
			}
		},
		[onChange]
	);

	return (
		<Autocomplete
			getOptionLabel={(option) => [option.county, option.cf].join(' ')}
			fullWidth
			clearOnEscape
			freeSolo
			filterOptions={(options) =>
				options.filter((option) => {
					return `${option.stateLabel} ${option.uatLabel} ${option.cf}`
						.toLowerCase()
						.includes(value.toLowerCase());
				})
			}
			options={cfHistory.filter(
				(row) => !selected.find((selected) => row.cf === selected.cf)
			)}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={null}
			onInputChange={handleChange}
			onChange={handleChange}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps,
						value,
					}}
					placeholder={t('locateCF.inputCF')}
				/>
			)}
			renderOption={(option) => (
				<Grid container alignItems="center">
					<Grid item>
						<History className={classes.icon} />
					</Grid>
					<Grid item xs>
						{option.cf}
						<Typography variant="body2" color="textSecondary">
							{[option.stateLabel, option.uatLabel].join(' - ')}
						</Typography>
					</Grid>
				</Grid>
			)}
		/>
	);
};

export const LocateCfForm = (props: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		onChange,
		onSubmit,
		onAutocomplete,
		readOnly = false,
		initialValues = {},
		googleMapsCoordinates = [],
		navigate = false,
		share = false,
		history = false,
		preventSearchOnMount = false,
		loading: overrideLoading,
		columnView = false,
	} = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>(null);

	const initialCounty = useCountyFromShortName(initialValues.state);
	const [state, setState] = useState(initialCounty || '');
	const [uat, setUat] = useState(initialValues.uat || '');
	const [cf, setCf] = useState(initialValues.cf || '');

	useEffect(() => {
		if (initialCounty) setState(initialCounty);
		if (initialValues.uat) setUat(initialValues.uat);
		if (initialValues.cf) setCf(initialValues.cf);
	}, [initialValues.cf, initialCounty, initialValues.uat]);

	useEffect(() => {
		onChange && onChange(state, uat, cf);
	}, [state, uat, cf, onChange]);

	const handleSubmit = useCallback(
		(event?: React.SyntheticEvent) => {
			if (event) {
				event.preventDefault();
			}

			(async () => {
				try {
					setLoading(true);

					if (onSubmit) {
						await onSubmit(state, uat, cf);
					}
				} catch (err) {
					setError(err);

					setTimeout(() => setError(null), 2000);
				}

				setLoading(false);
			})();
		},
		[onSubmit, uat, cf, state]
	);

	const { disabled: sharingDisabled, share: shareClick } = useCfSharing({
		state,
		uat,
		cf,
	});

	useRunOnce(() => {
		!preventSearchOnMount && handleSubmit();
	});

	const textInput =
		history && !readOnly ? (
			<AutocompleteCfHistory
				value={getInputValue(cf) || ''}
				onChange={(value) => {
					if (typeof value === 'string') return setCf(value);

					setCf(value?.cf || '');
					setUat(value?.county || '');
					setState(value?.state || '');

					if (value?.wgs84 && onAutocomplete) {
						onAutocomplete({
							wgs84: value.wgs84,
						});
					}
				}}
			/>
		) : (
			<TextField
				fullWidth
				disabled={readOnly}
				value={getInputValue(cf)}
				onChange={(event) => setCf(event.target.value)}
				placeholder={t('locateCF.inputCF')}
			/>
		);
	const submitButton = !readOnly && (
		<ProgressButton
			loading={overrideLoading !== undefined ? overrideLoading : loading}
			error={!!error}
			className={
				columnView
					? ''
					: [classes.button, classes.searchButton].join(' ')
			}
			endIcon={<LocationOn />}
			onClick={handleSubmit}
			variant={columnView ? 'contained' : 'text'}
			color={columnView ? 'primary' : 'default'}
		>
			{t('locateCF.submit')}
		</ProgressButton>
	);
	const navigateButton = navigate && (
		<NavigateButton
			coordinates={[googleMapsCoordinates[0], googleMapsCoordinates[1]]}
		/>
	);
	const shareButton = !readOnly && share && (
		<ShareButton disabled={sharingDisabled} onClick={shareClick} />
	);

	if (columnView) {
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<SelectState
						disabled={readOnly}
						value={getInputValue(state)}
						onChange={setState}
					/>
				</Grid>

				<Grid item xs={12}>
					<SelectUAT
						disabled={readOnly}
						value={getInputValue(uat)}
						onChange={setUat}
						state={state}
					/>
				</Grid>

				<Grid item xs={12}>
					{textInput}
				</Grid>

				<Grid item container xs={12} justifyContent="flex-end">
					{submitButton}
					{navigateButton}
					{shareButton}
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Paper className={classes.paperContainer}>
					<Grid container>
						<Grid item xs={5}>
							<SelectState
								disabled={readOnly}
								value={getInputValue(state)}
								onChange={setState}
							/>
						</Grid>

						<Grid item xs={7}>
							<SelectUAT
								disabled={readOnly}
								value={getInputValue(uat)}
								onChange={setUat}
								state={state}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper className={classes.paperContainer}>
					<Grid container wrap="nowrap" alignItems="center">
						{textInput}
						{submitButton}
						{navigateButton}
						{shareButton}
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
};
