import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import {
	AuctionDateType,
	RecurrenceInterval,
	WeekDays,
} from 'foreclosure-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ControlledSelect } from '../../../components/Select';
import {
	getValueForDatePicker,
	valueIsMissing,
} from '../../../helpers/display';
import { useFormError } from './helper';

const getRecurrenceDayValues = () => {
	const recurrenceDay: Record<string, string> = {};

	for (const weekDay of Object.keys(WeekDays)) {
		recurrenceDay[weekDay] = weekDay;
	}

	for (let i = 1; i <= 31; i++) {
		recurrenceDay[i.toString()] = i.toString();
	}

	return recurrenceDay;
};

const useRecurrenceDayOptions = () => {
	const { t } = useTranslation();
	const recurrenceDay = getRecurrenceDayValues();
	const options: Record<string, string> = {};

	for (const day of Object.keys(recurrenceDay)) {
		if (day.length > 2) {
			options[day] = t(`estateEdit.date.${day.toLowerCase()}`);
		} else {
			options[day] = day;
		}
	}

	return options;
};

const useRecurrenceIntervalOptions = () => {
	const { t } = useTranslation();
	const options: Record<string, string> = {};

	for (const interval of Object.keys(RecurrenceInterval)) {
		options[interval] = t(`estateEdit.date.${interval.toLowerCase()}`);
	}

	return options;
};

const RecurrentDate = () => {
	const { getFormError } = useFormError();
	const intervalOptions = useRecurrenceIntervalOptions();
	const dayOptions = useRecurrenceDayOptions();
	const { watch } = useFormContext();

	const dateType: AuctionDateType = watch('auctionDateType');

	return (
		<>
			<Controller
				name="auctionDateRecurrence_interval"
				defaultValue={RecurrenceInterval.WEEKLY}
				render={({ value, onChange, onBlur }) => (
					<ControlledSelect
						label="Recurență"
						size="medium"
						options={intervalOptions}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						disabled={dateType !== AuctionDateType.RECURRENT}
						style={{ width: 200 }}
					/>
				)}
			/>
			<Controller
				name="auctionDateRecurrence_day"
				defaultValue={[WeekDays.MONDAY]}
				render={({ value, onChange, onBlur }) => (
					<ControlledSelect
						label="În fiecare"
						size="medium"
						SelectProps={{
							multiple: true,
						}}
						options={dayOptions}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						disabled={dateType !== AuctionDateType.RECURRENT}
						style={{ width: 200, flexGrow: 1 }}
					/>
				)}
			/>
			<Controller
				name="auctionDateRecurrence_hour"
				defaultValue="10:00"
				render={({ value, onChange, onBlur }) => (
					<TextField
						margin="none"
						error={!!getFormError('auctionDateRecurrence_hour')}
						helperText={getFormError('auctionDateRecurrence_hour')}
						label="Ora licitatie"
						type="time"
						InputLabelProps={{
							shrink: true,
						}}
						value={value}
						disabled={dateType !== AuctionDateType.RECURRENT}
						onChange={onChange}
						onBlur={onBlur}
					/>
				)}
			/>
		</>
	);
};

const SingleDate = () => {
	const { getFormError } = useFormError();
	const { watch } = useFormContext();

	const dateType: AuctionDateType = watch('auctionDateType');

	return (
		<Controller
			name="auctionDate"
			defaultValue="none"
			render={({ value, onChange, onBlur }) => (
				<TextField
					margin="none"
					error={!!getFormError('auctionDate')}
					helperText={getFormError('auctionDate')}
					label="Data licitatie"
					type="datetime-local"
					InputLabelProps={{
						shrink: true,
					}}
					value={
						valueIsMissing(`${value}`)
							? ''
							: getValueForDatePicker(value)
					}
					disabled={dateType !== AuctionDateType.SINGLE}
					onChange={onChange}
					onBlur={onBlur}
				/>
			)}
		/>
	);
};

export const SelectDateType = () => {
	return (
		<Controller
			name="auctionDateType"
			defaultValue={AuctionDateType.DISABLED}
			render={({ value, onChange }) => (
				<>
					<FormControlLabel
						style={{ width: 180 }}
						control={
							<Checkbox
								color="primary"
								name="terms"
								checked={value === AuctionDateType.DISABLED}
								onChange={(event) => {
									if (event.target.checked) {
										onChange(AuctionDateType.DISABLED);
									} else {
										onChange(AuctionDateType.SINGLE);
									}
								}}
							/>
						}
						label={<div>Licitatie in curs de organizare</div>}
					/>

					<FormControlLabel
						style={{ width: 130 }}
						control={
							<Checkbox
								color="primary"
								disabled={value === AuctionDateType.DISABLED}
								checked={value === AuctionDateType.RECURRENT}
								onChange={(event) => {
									if (event.target.checked) {
										onChange(AuctionDateType.RECURRENT);
									} else {
										onChange(AuctionDateType.SINGLE);
									}
								}}
							/>
						}
						label={<div>Licitatie recurenta</div>}
					/>
				</>
			)}
		/>
	);
};

export const DateField = () => {
	return (
		<>
			<SelectDateType />

			<SingleDate />
			<RecurrentDate />
		</>
	);
};
