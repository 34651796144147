import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EstateMap } from '../../components/EstateMap';
import { EstateSafeDB, SimpleCoordinates } from 'foreclosure-types';
import {
	extractCoordinates,
	geocode,
	GeocodeResponse,
	loadMapsJS,
	reducePrecision,
} from '../../helpers/map';
import { defaultMapCenter } from '../../config';
import { replaceDiacritics } from '../../helpers/string';
import { displayPrice } from '../../helpers/display';
import { useEstateListUrlParams, usePropertyTypeFromUrl } from './hooks';
import { useTranslation } from 'react-i18next';
import { useMobileSize } from '../../hooks/responsive';
import { getEstateLink } from '../../helpers/form';
import { ChangeEventValue } from 'google-map-react';
import { useAuth } from '../../context/Auth';

const getEstateId = (params: { _id: string }) => {
	return `estate${params._id}`;
};

interface Props<T> {
	list: T[];
	hideMap?: boolean;
	onHighlighted: (selected?: T) => void;
	highlighted?: T;
	mapCenter?: SimpleCoordinates;
	onMapCenterChange?: (center: SimpleCoordinates) => void;
	disableSearch?: boolean;
	onLocationChange?: (location: GeocodeResponse) => void;
}

export const EstatesListMap = <T extends EstateSafeDB>(props: Props<T>) => {
	const { t } = useTranslation();
	const {
		list,
		hideMap,
		onHighlighted,
		highlighted,
		mapCenter,
		onMapCenterChange,
		disableSearch,
		onLocationChange,
	} = props;
	const { location } = useEstateListUrlParams();
	const navigate = useNavigate();
	const params = usePropertyTypeFromUrl();
	const { isTablet } = useMobileSize();
	const { location: userLocation, loading: authLoading } = useAuth();
	const [mapLoaded, setMapLoaded] = useState(false);

	const listOnMap = useMemo(
		() =>
			list.map((estate) => ({
				_id: estate._id,
				title:
					t(
						'estate_list.sub_type_singular.' +
							estate.subType.toLowerCase()
					) +
					' ' +
					displayPrice(estate.price, estate.currency),
				...extractCoordinates(estate.location),
			})),
		[list, t]
	);

	const handleAddressSearch = useCallback(
		(address: string, rangeKM: number) => {
			navigate(
				replaceDiacritics(
					`/${params.label}/${address}/${rangeKM}${window.location.search}`
				)
			);
		},
		[params.label, navigate]
	);
	const handleRangeChange = useCallback(
		(rangeKM: number) => {
			navigate(
				replaceDiacritics(
					`/${params.label}/${location || '_'}/${rangeKM}${
						window.location.search
					}`
				)
			);
		},
		[params.label, location, navigate]
	);
	const handleMarkerClick = useCallback(
		(location: { _id: string }) => {
			const selected = list.find((estate) => estate._id === location._id);

			onHighlighted(selected);

			if (selected) {
				if (isTablet) {
					navigate(getEstateLink(selected));

					return;
				}

				document
					.querySelector(`#${getEstateId(selected)}`)
					?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'nearest',
					});
			}
		},
		[list, onHighlighted, isTablet, navigate]
	);
	const handleOnChange = useCallback(
		(event: ChangeEventValue) => {
			if (onMapCenterChange) onMapCenterChange(event.center);
		},
		[onMapCenterChange]
	);

	useEffect(() => {
		// <EstateMap /> loads Google Maps JS on mount
		if (!hideMap) return;
		if (mapLoaded) return;

		(async () => {
			setMapLoaded(await loadMapsJS());
		})();
	}, [hideMap, mapLoaded]);

	useEffect(() => {
		if (!location) return;
		if (!onMapCenterChange) return;
		if (!mapLoaded) return;

		if (hideMap && !mapCenter) {
			(async () => {
				const res = await geocode(location);
				const { lat, lng } = reducePrecision(res);

				if (res) onMapCenterChange({ lat, lng });
			})();
		}
	}, [location, hideMap, mapCenter, onMapCenterChange, mapLoaded]);

	if (authLoading) return null;

	if (hideMap) return null;

	return (
		<EstateMap
			onSearch={disableSearch ? undefined : handleAddressSearch}
			updateMapOnSearch={false}
			defaultAddress={location}
			locations={listOnMap}
			center={mapCenter || userLocation || defaultMapCenter}
			highlighted={
				highlighted
					? {
							_id: highlighted._id,
							title: '',
							...extractCoordinates(highlighted.location),
					  }
					: undefined
			}
			onMarkerClick={handleMarkerClick}
			zoom={12}
			onChange={handleOnChange}
			onLocationChange={onLocationChange}
			onRangeChange={disableSearch ? undefined : handleRangeChange}
		/>
	);
};
