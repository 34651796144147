import { Currency, WatiMessage, WatiTemplate } from '..';

export enum EstateContactStatus {
    NOT_INTERESTED = 'NOT_INTERESTED',
    CONTACT = 'CONTACT',
    BUY_OFFER = 'BUY_OFFER',
    WANTS_TO_PARTICIPATE = 'WANTS_TO_PARTICIPATE',
    OFFER_SUBMITTED = 'OFFER_SUBMITTED',
    PARTICIPATED = 'PARTICIPATED',
    WON = 'WON',
}

export enum Financing {
    SELF = 'SELF',
    CREDIT = 'CREDIT',
}

export interface LeadContact {
    status: EstateContactStatus;
    text: string;
    estateId: string;
    createdAt: string;
    updatedAt: string;
}

export interface LeadOffer extends LeadContact {
    price: number;
    currency: Currency;
    financing: Financing;
}

export interface LeadNote {
    text: string;
    author: string;
    estateId?: string;
}

interface LeadNoteDb extends LeadNote {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export enum LeadQuality {
    BAD = 'BAD',
    AVERAGE = 'AVERAGE',
    GOOD = 'GOOD',
    INVESTOR = 'INVESTOR',
}

export enum LeadStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
}

export interface Lead {
    _id: string;
    name: string;
    email: string;
    phone: string;
    guestUuid: string[];
    estates: Array<LeadContact | LeadOffer>;
    user?: string;
    wa_id?: string;
    subscribed: boolean;
    whatsappAutomation?: boolean;
    notes: LeadNoteDb[];
    quality: LeadQuality;
    status: LeadStatus;
    createdAt: string;
    updatedAt: string;
}

export const isEstateOffer = (
    estate: LeadContact | LeadOffer
): estate is LeadOffer => {
    return 'price' in estate && !!estate.price;
};

export type LeadResponse = {
    estateLeads: Lead[];
    getList: Lead[];
    getOne: Lead;
    whatsAppMessages: WatiMessage[];
    sendWhatsMessage: WatiMessage;
    watiUrl: {
        url: string;
    };
    watiTemplates: WatiTemplate[];
};

const qualityValues = [
    LeadQuality.BAD,
    LeadQuality.AVERAGE,
    LeadQuality.GOOD,
    LeadQuality.INVESTOR,
];

export const decreaseQuality = (quality: LeadQuality) => {
    const index = qualityValues.indexOf(quality);

    if (index > 1) {
        return qualityValues[index - 1];
    }

    return quality;
};

export const increaseQuality = (quality: LeadQuality) => {
    const index = qualityValues.indexOf(quality);

    if (index < qualityValues.length - 1) {
        return qualityValues[index + 1];
    }

    return quality;
};
