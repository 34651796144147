export enum AncpiStatsType {
    SALE = 'SALE',
    MORGAGE = 'MORGAGE',
}

export interface AncpiStats {
    date: Date;
    type: AncpiStatsType;
    judet: string;
    extravilan: {
        agricol: number;
        neagricol: number;
    };
    intravilan: {
        cuConstructii: number;
        faraConstructii: number;
    };
    nrUnitatiIndividuale: number;
    total: number;
}
