export enum TaskStatus {
    PENDING = 'PENDING',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
}

export enum TaskPriority {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export interface Task {
    _id: string;
    title: string;
    description?: string;
    status: TaskStatus;
    priority: TaskPriority;
    ownerId?: string;
    userId?: string;
    estateId?: string;
    leadId?: string;
    createdAt?: string;
    updatedAt?: string;
    dueDate: string;
}

export type TaskResponse = {
    getList: Task[];
    getOne: Task;
};
