import { useEffect, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { Checkbox, Tooltip } from '@material-ui/core';

import { Task, TaskPriority, TaskStatus } from 'foreclosure-types';
import { useAllEstateList } from '../hooks/estate';
import { patchOne } from '../api/task';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { capitalize } from 'lodash';
import { Markdown } from './Markdown';

type Row = {
	data: Task;
};

export const TaskList = (props: { tasks: Task[]; editable?: boolean }) => {
	const { editable = false } = props;
	const { estates } = useAllEstateList();
	const [gridApi, setGridApi] = useState<GridApi>();
	const [tasks, setTasks] = useState<Task[]>([]);

	useEffect(() => {
		setTasks(
			props.tasks.sort(
				(a, b) =>
					parseInt(b.priority as any as string) -
					parseInt(a.priority as any as string)
			)
		);
	}, [props.tasks]);

	useEffect(() => {
		if (tasks.length && gridApi) {
			gridApi.sizeColumnsToFit();
		}
	}, [gridApi, tasks]);

	const update = (task: Task) => {
		const existingTask = tasks.find((t) => t._id === task._id);

		if (!existingTask) return;

		Object.assign(existingTask, task);

		setTasks([...tasks]);
	};
	const completeTask = async (task: Task) => {
		update(await patchOne(task._id, { status: TaskStatus.DONE }));
	};
	const cancelTask = async (task: Task) => {
		update(await patchOne(task._id, { status: TaskStatus.CANCELLED }));
	};

	return (
		<AgGridReact
			rowData={tasks}
			pagination
			paginationPageSize={20}
			domLayout="autoHeight"
			onFirstDataRendered={(params) => {
				setGridApi(params.api);
			}}
			defaultColDef={{
				// editable: true,
				sortable: true,
				filter: true,
				resizable: true,
				wrapText: true,
				autoHeight: true,
			}}
			gridOptions={{
				enableCellTextSelection: true,
			}}
		>
			<AgGridColumn
				field="status"
				headerName="Status"
				cellRendererFramework={(params: Row) => {
					return (
						<Tooltip title="Mergi la referinta pentru a completa task-ul">
							<div>
								<Checkbox
									checked={
										params.data.status === TaskStatus.DONE
									}
									disabled={!editable}
									onChange={async (e) => {
										if (e.target.checked) {
											await completeTask(params.data);
										} else {
											await cancelTask(params.data);
										}
									}}
								/>
								<span>{capitalize(params.data.status)}</span>
							</div>
						</Tooltip>
					);
				}}
			/>

			<AgGridColumn field="title" headerName="Titlu" />
			<AgGridColumn
				field="description"
				headerName="Descriere"
				cellRendererFramework={(params: Row) => {
					return <Markdown>{params.data.description}</Markdown>;
				}}
			/>
			<AgGridColumn
				field="priority"
				headerName="Prioritate"
				cellRenderer={(params: Row) => {
					// eslint-disable-next-line eqeqeq
					if (params.data.priority == TaskPriority.MEDIUM) {
						return 'Mediu';
					}
					// eslint-disable-next-line eqeqeq
					if (params.data.priority == TaskPriority.LOW) {
						return 'Scazut';
					}

					return 'Urgent';
				}}
			/>
			<AgGridColumn
				headerName="Referinta"
				cellRendererFramework={(params: Row) => {
					const estate = estates.find(
						(el) => el._id === params.data.estateId
					);

					if (!estate) {
						return <span>-</span>;
					}

					return (
						<Link to={`/edit/estate/${estate._id}`}>
							{estate.title}
						</Link>
					);
				}}
			/>
		</AgGridReact>
	);
};
