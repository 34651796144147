import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const legalLinks: Record<string, string> = {
	'/privacy': 'legal_menu.privacy',
	'/terms-and-condition': 'legal_menu.terms',
	'/cookies': 'legal_menu.cookie',
	'http://www.anpc.gov.ro/': 'ANPC',
	'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO?ref=footer_4_5':
		'ODR',
};

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.palette.common.white,
	},
}));

export const LegalMenu = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			{Object.keys(legalLinks).map((url) => {
				const isExternal = url.includes('http');

				const text = (
					<Typography
						variant="body1"
						component="span"
						className={classes.text}
					>
						{legalLinks[url].includes('legal_menu')
							? t(legalLinks[url])
							: legalLinks[url]}
					</Typography>
				);

				if (isExternal) {
					return (
						<a href={url} key={url}>
							{text}
						</a>
					);
				}

				return (
					<Link to={url} key={url}>
						{text}
					</Link>
				);
			})}
		</>
	);
};
