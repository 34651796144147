import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle, NoteAdd } from '@material-ui/icons';
import { useSelfEstatesCount } from './estate';

export const useAddAdvertLink = () => {
	const { t } = useTranslation();
	const { count: selfEstatesCount } = useSelfEstatesCount();

	return useMemo(() => {
		if (selfEstatesCount > 0) {
			return {
				to: '/self/list',
				label: t('top_menu.my_estates'),
				icon: <NoteAdd />,
				selfEstatesCount,
			};
		}

		return {
			to: '/self/add',
			label: t('top_menu.add_estate'),
			icon: <AddCircle />,
			selfEstatesCount,
		};
	}, [selfEstatesCount, t]);
};
