import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { initFB } from '../helpers/initFB';

type FacebokContextValue = {
	facebookInstance?: fb.FacebookStatic;
	authStatus?: fb.AuthResponse;
	logout: () => void;
	login: () => Promise<fb.StatusResponse>;
};

export const FacebookContext = React.createContext<FacebokContextValue>({
	logout: () => {},
	login: () => null as any,
});

type Props = {
	children: ReactNode;
	appId: string;
};

export const isStatusResponse = (obj: any): obj is fb.StatusResponse => {
	return !!obj.status;
};

const LOCAL_STORAGE_KEY = 'facebookAuth';

enum AuthStatus {
	Logged = 'logged',
	NotLogged = 'not_logged',
}

export const FacebookProvider = (props: Props) => {
	const [facebookInstance, setFacebookInstance] =
		useState<fb.FacebookStatic>();
	const [authStatus, setAuthStatus] = useState<fb.AuthResponse>();

	const logout = useCallback(() => {
		setAuthStatus(undefined);
		localStorage.setItem(LOCAL_STORAGE_KEY, AuthStatus.NotLogged);

		if (authStatus) {
			facebookInstance?.logout((response) => {
				console.log('logout', response);
			});
		}
	}, [facebookInstance, authStatus]);

	const login = useCallback(async (): Promise<fb.StatusResponse> => {
		return new Promise((resolve, reject) => {
			if (!facebookInstance?.login) {
				return reject(new Error('Facebook is not initialized'));
			}

			facebookInstance.login(
				(response) => {
					if (response.status === 'connected') {
						localStorage.setItem(
							LOCAL_STORAGE_KEY,
							AuthStatus.Logged
						);

						resolve(response);
					} else {
						reject(response);
					}
				},
				{
					scope: 'public_profile,email',
				}
			);
		});
	}, [facebookInstance]);

	useEffect(() => {
		(async () => {
			try {
				const facebookAPI = await initFB(props.appId);

				setFacebookInstance(facebookAPI);

				if (
					localStorage.getItem(LOCAL_STORAGE_KEY) ===
					AuthStatus.NotLogged
				) {
					return;
				}

				facebookAPI.getLoginStatus((response) => {
					if (response.status === 'connected') {
						localStorage.setItem(
							LOCAL_STORAGE_KEY,
							AuthStatus.Logged
						);
						setAuthStatus(response.authResponse);
					}
				}, true);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [props.appId]);

	return (
		<FacebookContext.Provider
			value={{ facebookInstance, authStatus, logout, login }}
		>
			{props.children}
		</FacebookContext.Provider>
	);
};
