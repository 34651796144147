import { Container, Typography } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HowItWorks as HowItWorksGraphic } from '../../components/HowItWorks';
import { Markdown } from '../../components/Markdown';
import { PageMeta } from '../../components/PageMeta';
import { MainTemplate } from '../../components/Templates/Main';
import textPath from './text.md';

export const HowItWorks = () => {
	const { t } = useTranslation();
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		(async () => {
			const response = await axios.get(textPath);

			setMarkdown(response.data);
		})();
	}, []);

	return (
		<MainTemplate>
			<PageMeta title={t('top_menu.how_it_works')} />
			<Container>
				<HowItWorksGraphic style={{ marginTop: 30 }} />

				<Typography component="div" style={{ marginTop: 30 }}>
					<Markdown>{markdown}</Markdown>
				</Typography>
			</Container>
		</MainTemplate>
	);
};
