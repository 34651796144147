import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/Auth';

export const UserInfo = () => {
	const { t } = useTranslation();
	const { user } = useAuth();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography>
					{t('account.info.name')}: {user.name}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography>
					{t('account.info.email')}: {user.email}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography>
					{t('account.info.phone')}: {user.phone}
				</Typography>
			</Grid>
		</Grid>
	);
};
