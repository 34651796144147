import { useEffect, useState } from 'react';
import { EstateDB, EstateSafeDB, isEstateDB } from 'foreclosure-types';
import { EstateMap, MapType } from '../../components/EstateMap';
import { useTranslation } from 'react-i18next';
import { displayEstateDate, displayPrice } from '../../helpers/display';
import { drawArea, extractCoordinates } from '../../helpers/map';
import { makeStyles, Typography } from '@material-ui/core';
import { defaultMapCenter } from '../../config';
import { useAuctionOwner } from '../../hooks/auctionOwner';
import { googleMapsImage } from '../../assets';
import { useCfLocation } from '../../hooks/map';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		height: 350,
		[theme.breakpoints.down('sm')]: {
			height: '40vh',
		},
	},
	overlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		background: 'rgba(255, 255, 255, 0.85)',
		zIndex: 999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	googleMapsPlaceholder: {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url(${googleMapsImage})`,
		backgroundSize: 'cover',
	},
	note: {
		padding: theme.spacing(2),
	},
}));

const useSwitchOptions = () => {
	const { t } = useTranslation();

	const estateOption = t('estateView.map.estate');
	const satelliteOption = t('estateView.map.satellite');
	const auctionOption = t('estateView.map.auction');

	const switchOptions = [estateOption, satelliteOption, auctionOption];

	return {
		switchOptions,
		estateOption,
		satelliteOption,
		auctionOption,
	};
};

const useMapCenter = (estate: EstateSafeDB | EstateDB, switchValue: string) => {
	const { t } = useTranslation();
	const { auctionOption } = useSwitchOptions();
	const { owner: auctionOwner } = useAuctionOwner(
		isEstateDB(estate) ? estate.auctionOwnerId : undefined
	);

	if (!estate.location) {
		return null;
	}

	if (switchValue !== auctionOption || !isEstateDB(estate)) {
		return {
			_id: estate._id,
			title:
				estate.title +
				' ' +
				displayPrice(estate.price, estate.currency),
			...extractCoordinates(estate.location),
		};
	}

	if (!auctionOwner.location) {
		return null;
	}

	return {
		_id: estate._id,
		title:
			t('estateView.auction.map_title') + ' ' + displayEstateDate(estate),
		...extractCoordinates(auctionOwner.location),
	};
};

export const EstateViewMap = ({ estate }: { estate: EstateSafeDB }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { switchOptions, estateOption, satelliteOption } = useSwitchOptions();
	const [switchValue, setSwitchValue] = useState<string>(estateOption);
	const mapLocation = useMapCenter(estate, switchValue);
	const locations: Array<NonNullable<typeof mapLocation>> = mapLocation
		? [mapLocation]
		: [];
	const displayOverlay = (() => {
		if (!isEstateDB(estate)) {
			return false;
		} else {
			if (!estate.promoted && estate.cfCoordinatesId) {
				return false;
			}
		}

		if (!estate.auctionUrl) return false;

		return true;
	})();

	const [map, setMap] = useState<google.maps.Map>();
	// @ts-ignore
	const { coordinates } = useCfLocation(estate.cfCoordinatesId);

	useEffect(() => {
		if (!map || !coordinates.wgs84) return;

		drawArea(map, coordinates.wgs84);
	}, [map, coordinates]);

	return (
		<div className={classes.container}>
			{displayOverlay ? (
				<>
					<div className={classes.googleMapsPlaceholder}></div>
					<div className={classes.overlay}>
						<Typography variant="h5" className={classes.note}>
							{t('estateView.map.details')}
						</Typography>
					</div>
				</>
			) : (
				<EstateMap
					navigation
					onMapMounted={setMap}
					locations={locations}
					center={
						displayOverlay || !mapLocation
							? defaultMapCenter
							: mapLocation
					}
					disableSearch
					switch={{
						value: switchValue,
						options: switchOptions,
						onChange: setSwitchValue,
					}}
					mapType={
						switchValue === satelliteOption
							? MapType.SATELLITE
							: MapType.DEFAULT
					}
				/>
			)}
		</div>
	);
};
