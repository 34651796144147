import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { Gallery } from './Gallery';
import { CloudUploadOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

type Props = {
	name: string;
};

export const UploadGallery = ({ name }: Props) => {
	const { t } = useTranslation();
	const { control, register, setValue } = useFormContext();

	register(name);

	const uploadedPictures = useWatch<FileList>({
		control,
		name: 'uploadPictures',
	});

	const serverPictures = useWatch<[string]>({
		control,
		name,
	});

	const picturesURL = useMemo(() => {
		if (!uploadedPictures) return [];

		return Object.values(uploadedPictures).map(URL.createObjectURL);
	}, [uploadedPictures]);

	const handleDelete = useCallback(
		(imageName: string) => {
			if (!uploadedPictures) {
				return;
			}

			const fileList = new DataTransfer();

			for (let index = 0; index < uploadedPictures.length; index++) {
				const file = uploadedPictures.item(index);
				const data = picturesURL[index];

				if (file && data !== imageName) {
					fileList.items.add(file);
				}
			}

			if (fileList.files.length === uploadedPictures.length) {
				// Delete from server
				setValue(
					name,
					serverPictures?.filter((el) => el !== imageName)
				);
			} else {
				setValue('uploadPictures', fileList.files);
			}
		},
		[setValue, uploadedPictures, picturesURL, name, serverPictures]
	);

	return (
		<>
			<input
				type="file"
				hidden
				multiple
				id="upload-picture"
				name="uploadPictures"
				// @ts-ignore
				ref={register}
			/>
			<Gallery
				canDelete
				onDelete={handleDelete}
				images={
					Array.isArray(serverPictures)
						? [...picturesURL, ...serverPictures]
						: picturesURL
				}
			>
				<label htmlFor="upload-picture">
					<Button
						color="secondary"
						component="span"
						size="large"
						startIcon={<CloudUploadOutlined />}
						style={{ border: 'none' }}
					>
						{t('estateEdit.upload_photo')}
					</Button>
				</label>
			</Gallery>
		</>
	);
};
