import { useTheme } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Joyride, { STATUS, LIFECYCLE, StoreHelpers } from 'react-joyride';

export const WATCH_BUTTON = 'watch-button';
export const LOCATE_CF_BUTTON = 'locate-cf-button';
export const SALE_PUBLICATION_BUTTON = 'sale-publication-button';
export const REQUEST_HISTORY_BUTTON = 'request-history-button';
export const REQUEST_LEGAL_HISTORY_BUTTON = 'request-legal-history-button';
export const COMMENTS_SECTION = 'comments-section';

const steps: Joyride['props']['steps'] = [
	{
		target: `.${WATCH_BUTTON}`,
		content:
			'Adaugă la favorite pentru a primi notificări despre acest imobil: comentarii noi, sentințe juridice, modificări de preț, etc.',
	},
	{
		target: `.${LOCATE_CF_BUTTON}`,
		content:
			'Vezi unde se află acest imobil pe hartă folosind numărul de carte funciară (pe scurt CF). CF-ul îl găsești în publicația de vânzare.',
	},
	{
		target: `.${SALE_PUBLICATION_BUTTON}`,
		content:
			'Publicația de vânzare este echivalentul unei hotărâri judecătorești și conține toate informațiile despre imobil: CF, locație, termen licitație etc.',
	},
	{
		target: `.${REQUEST_LEGAL_HISTORY_BUTTON}`,
		content:
			'Vezi istoricul juridic al executării: procese, sentințe, etc.',
	},
	{
		target: `.${REQUEST_HISTORY_BUTTON}`,
		content:
			'Vezi istoricul licitației pe site-ul nostru: prețuri anterioare licitate, etc.',
	},
	{
		target: `.${COMMENTS_SECTION}`,
		content:
			'Lasă un comentariu (anonim dacă vrei) dacă ai găsit informații despre această licitație sau dacă ai întrebări.',
	},
];
const LOCAL_STORAGE_KEY = 'tour-done';

export const PropertyDetailsTour = () => {
	const theme = useTheme();
	const [run, setRun] = useState(false);
	const helpers = useRef<StoreHelpers>();

	useEffect(() => {
		const tourDone = localStorage.getItem(LOCAL_STORAGE_KEY);

		if (!tourDone || moment().diff(tourDone, 'month') > 1) {
			setRun(true);
		}
	}, []);

	return (
		<Joyride
			run={run}
			continuous
			showSkipButton
			scrollOffset={120}
			getHelpers={(val) => {
				helpers.current = val;
			}}
			styles={{
				options: {
					primaryColor: theme.palette.primary.main,
				},
			}}
			callback={(data) => {
				if (
					data.lifecycle === LIFECYCLE.READY &&
					data.status === STATUS.RUNNING
				) {
					setTimeout(() => helpers.current?.open(), 500);
				}

				if (data.status === STATUS.ERROR) {
					helpers.current?.next();
				}

				if (
					data.status === STATUS.FINISHED ||
					data.status === STATUS.SKIPPED
				) {
					localStorage.setItem(LOCAL_STORAGE_KEY, moment().format());
				}
			}}
			locale={{
				back: 'Înapoi',
				close: 'Închide',
				last: 'Final',
				next: 'Înainte',
				skip: 'Sari peste',
			}}
			steps={steps}
		/>
	);
};
