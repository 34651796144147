export enum NotificationStatus {
    NEW = 'NEW',
    SEEN = 'SEEN',
}

export enum NotificationReferenceCollection {
    ESTATE = 'ESTATE',
    COMMENT = 'COMMENT',
}

export enum NotificationText {
    NEW_COMMENT = 'NEW_COMMENT',
    NEW_LEGAL_REFERRENCE = 'NEW_LEGAL_REFERRENCE',
}

export interface Notification {
    userId: string;
    text: NotificationText;
    status: NotificationStatus;
    referenceCollection: NotificationReferenceCollection;
    referenceId: string;
    guestUuid?: string;
    media?: string[];
}

export interface NotificationDB extends Notification {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface NotificationExtended extends NotificationDB {
    referenceTitle: string;
}

export interface NotificationResponse {
    userNotifications: NotificationExtended[];
    updateOne: Notification;
}
