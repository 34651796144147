import React, { FormEvent, Ref, ReactElement } from 'react';
import { SchemaErrors } from '../../types';
import { UserRole } from 'foreclosure-types';
import {
	TextField,
	Button,
	Box,
	makeStyles,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { Paper } from '../Paper';
import { Controller, Control } from 'react-hook-form';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
	fieldsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'space-between',
		'& > *': {
			margin: theme.spacing(2),
			minWidth: 200,
		},
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing(2),
	},
}));

type Props = {
	handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
	errors: SchemaErrors;
	register: Ref<ReactElement>;
	control: Control;
	goBack: () => void;
	onDelete: () => void;
};

const roleOptions: Record<UserRole, string> = {
	USER: 'User',
	AGENT: 'Agent',
	LOCAL_ADMIN: 'Local Admin',
	ADMIN: 'Admin',
};

export const UserDetailsTemplate = (props: Props) => {
	const { handleSubmit, errors, register, control } = props;
	const classes = useStyles({});
	const defaultTextProps: Record<
		string,
		Ref<ReactElement> | string | boolean
	> = {
		defaultValue: '_',
		inputRef: register,
		margin: 'normal',
		variant: 'outlined',
	};
	const getError = (field: string) =>
		field in errors && errors[field].message;

	return (
		<Paper>
			<form onSubmit={handleSubmit}>
				<Box className={classes.fieldsContainer}>
					<TextField
						{...defaultTextProps}
						error={!!errors.email}
						helperText={getError('email')}
						label="Email"
						name="email"
						type="email"
					/>
					<Controller
						name="role"
						control={control}
						defaultValue="USER"
						as={
							<TextField
								{...defaultTextProps}
								error={!!errors.role}
								helperText={getError('role')}
								label="Role"
								required
								select
							>
								{(Object.keys(roleOptions) as UserRole[]).map(
									(key) => (
										<MenuItem key={key} value={key}>
											{roleOptions[key]}
										</MenuItem>
									)
								)}
							</TextField>
						}
					/>
				</Box>

				<Box className={classes.fieldsContainer}>
					<Controller
						name="skipPaymentUntil"
						defaultValue="none"
						control={control}
						render={({ value, onChange, onBlur }) => (
							<>
								<FormControlLabel
									style={{ width: 100 }}
									control={
										<Checkbox
											color="primary"
											name="terms"
											checked={!!value}
											onChange={(event) => {
												if (event.target.checked) {
													onChange(
														moment()
															.add(1, 'month')
															.format(
																'YYYY-MM-DD'
															)
													);
												} else {
													onChange(null);
												}
											}}
										/>
									}
									label={<div>Trial activ</div>}
								/>

								<TextField
									margin="none"
									error={!!errors.skipPaymentUntil}
									helperText={getError('skipPaymentUntil')}
									label="Trial pana la"
									type="date"
									InputLabelProps={{
										shrink: true,
									}}
									value={value}
									disabled={!value}
									onChange={onChange}
									onBlur={onBlur}
								/>
							</>
						)}
					/>
				</Box>

				<Box marginTop={5} className={classes.buttonsContainer}>
					<Button
						onClick={props.onDelete}
						variant="outlined"
						color="secondary"
					>
						Delete
					</Button>

					<div className={classes.buttonsContainer}>
						<Button
							variant="outlined"
							color="default"
							onClick={props.goBack}
						>
							Go Back
						</Button>
						<Button
							type="submit"
							variant="outlined"
							color="primary"
						>
							Submit
						</Button>
					</div>
				</Box>
			</form>
		</Paper>
	);
};
