import { MomentInput } from 'moment';
import moment from 'moment-timezone';
import { SyntheticEvent } from 'react';
import { localTimezone } from '../config';

export const preventDefaultNStop = (event: SyntheticEvent): void => {
	event.preventDefault();
	event.stopPropagation();
};

export const getGoogleCalendarLink = (params: {
	text: string;
	dates: {
		start: MomentInput;
		// Default: start + 1 hour
		end?: MomentInput;
	};
	details: string;
	location: string;
}) => {
	const parseDate = (date: unknown) =>
		moment.tz(date, localTimezone).clone().tz('UTC');

	const start = parseDate(params.dates.start);
	const end = params.dates.end
		? parseDate(params.dates.end)
		: start.clone().add(1, 'hour');

	const DATE_FORMAT = 'YMMD\\THHmm00\\Z';
	const linkParams = [
		'action=TEMPLATE',
		`text=${params.text}`,
		`dates=${start.format(DATE_FORMAT)}/${end.format(DATE_FORMAT)}`,
		`details=${params.details}`,
		`location=${params.location}`,
		'trp=false',
	];

	return `http://www.google.com/calendar/render?${encodeURI(
		linkParams.join('&')
	)}`;
};
