import { Button } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { watchEstate } from '../api/estate';
import { useAuth } from '../context/Auth';
import { useApiWithNotifications } from '../hooks/form';
import { WATCH_BUTTON } from '../pages/EstateView/Tour';

export const AddToWatchListButton = (props: {
	estateId: string;
	className?: string;
}) => {
	const { t } = useTranslation();
	const { user } = useAuth();

	const subscribed = user.watchList
		.map((el) => el.estateId)
		.includes(props.estateId);
	const handleClick = useApiWithNotifications(async () => {
		await watchEstate(props.estateId);
	});

	return (
		<Button
			color="primary"
			className={[props.className, WATCH_BUTTON]
				.filter(Boolean)
				.join(' ')}
			onClick={handleClick}
			startIcon={!subscribed && <Favorite />}
		>
			{t(
				subscribed
					? 'estateView.auction.unsubscribe'
					: 'estateView.auction.subscribe'
			)}
		</Button>
	);
};
