import { EditHistory, isEditRow } from 'foreclosure-types';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useUsers } from '../../context/Users';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	listWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	listItem: {
		padding: theme.spacing(1),
	},
	field: {
		textDecoration: 'underline',
		textAlign: 'center',
	},
	author: {
		paddingLeft: theme.spacing(1),
	},
}));

export const HistoryRow = (props: EditHistory) => {
	const classes = useStyles();
	const { users } = useUsers();

	if (!isEditRow(props)) return null;

	const { changes, createdAt, authorId } = props;

	const author = users.find((user) => user._id === authorId);

	return (
		<div className={classes.container}>
			<div>
				{moment(createdAt).format('YYYY-MM-DD HH:mm')}
				<span className={classes.author}>
					{author?.name || author?.email}
				</span>
			</div>
			<div className={classes.listWrapper}>
				{Object.entries(changes).map(([field, item]) => (
					<div key={field} className={classes.listItem}>
						<div className={classes.field}>{field}</div>
						<div>
							{item.old} ➡️ {item.new}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
