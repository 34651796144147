import './i18n';
import './helpers/elastic';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Routes from './routes';
import { theme } from './theme';
import { FacebookProvider } from './context/Facebook';
import { facebookAppId } from './api/config';
import AuthProvider from './context/Auth';
import ToastProvider from './context/ToastProvider';
import { Toast } from './components/Toast';
import ComposeProviders from './components/ComposeProviders';
import { CheckIfLoggedWithFacebook } from './components/CheckIfLoggedWithFacebook';
import { useGoogleAds } from './hooks/googleAds';
import { useServiceWorkerNotifications } from './hooks/notifications';
import { BillingModal } from './components/Payments/BillingModal';
// import { PaymentModalProvider } from './context/Payment';
import { SubscriptionPickerModal } from './components/Payments/SubscriptionPicker';
import { GoogleLoginProvider } from './components/GoogleLogin';

const ProvidersThatRequireAuth = () => {
	useGoogleAds();
	useServiceWorkerNotifications();

	return null;
};

export const App = () => {
	return (
		<ComposeProviders
			providers={[AuthProvider, ToastProvider, GoogleLoginProvider]}
		>
			<ProvidersThatRequireAuth />
			<Toast />
			<FacebookProvider appId={facebookAppId}>
				<CheckIfLoggedWithFacebook />
				<ThemeProvider theme={theme}>
					<BillingModal />
					<SubscriptionPickerModal />
					<CssBaseline />
					<Routes />
				</ThemeProvider>
			</FacebookProvider>
		</ComposeProviders>
	);
};
