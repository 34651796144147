import { ComponentProps } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { TextField } from '@material-ui/core';

export const ReactHookField = (
	props: ComponentProps<typeof TextField> & { rules?: RegisterOptions }
) => {
	const formContext = useFormContext();
	const { name, rules } = props;

	if (!name) {
		return <div>Missing name</div>;
	}

	if (!formContext) {
		return <div>Missing form context</div>;
	}

	const { errors, register } = formContext;

	return (
		<TextField
			fullWidth
			required
			margin="normal"
			variant="outlined"
			error={!!errors[name]}
			helperText={errors?.[name]?.message}
			id={name}
			inputRef={register(rules)}
			{...props}
		/>
	);
};
