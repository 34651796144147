import { NationalRegistry, UserSearch } from '..';

// the bank that provided the estate for promotion
export enum EstateProvider {
    BCR = 'BCR',
    EOS_KSI = 'EOS_KSI',
    // TODO: add more here as we grow
}

export enum EstateStateEnum {
    AVAILABLE = 'AVAILABLE',
    BOUGHT = 'BOUGHT',
    DISABLED = 'DISABLED',
    NEW = 'NEW',
}

export type EstateState = keyof typeof EstateStateEnum;
export enum Currency {
    RON = 'RON',
    EUR = 'EUR',
}
export type PropertyType = 'ESTATE' | 'MOVABLE';
export type EstateType =
    | 'APARTMENT'
    | 'HOUSE'
    | 'LAND'
    | 'COMERCIAL'
    | 'FACTORY';
export type MovableType = 'CAR' | 'OTHER';
export type SubType = EstateType | MovableType;
export type EstateLandType = 'URBAN' | 'RURAL';
export type AuctionType =
    | 'BANK'
    | 'BAILIFF'
    | 'INSOLVENCY'
    | 'PRIVATE'
    | 'COMPANY';

export const estateStates: EstateState[] = [
    'AVAILABLE',
    'BOUGHT',
    'DISABLED',
    'NEW',
];
export const auctionType: AuctionType[] = [
    'BANK',
    'BAILIFF',
    'INSOLVENCY',
    'PRIVATE',
    'COMPANY',
];
export const estateCurrencies = Object.values(Currency);
export const propertyType: PropertyType[] = ['ESTATE', 'MOVABLE'];
export const estateTypes: EstateType[] = [
    'APARTMENT',
    'HOUSE',
    'LAND',
    'COMERCIAL',
    'FACTORY',
];
export const movableTypes: MovableType[] = ['CAR', 'OTHER'];
export const subTypes: Array<SubType> = [...estateTypes, ...movableTypes];
export const estateLandType: EstateLandType[] = ['URBAN', 'RURAL'];

export type GeoPoint = {
    type: 'Point';
    // lng (longitude) first and then lat (latitude)
    coordinates: [number, number];
};

export enum AiResponseStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
}

export enum WeekDays {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

// string number between 1 and 31
export type RecurrenceDay = WeekDays | string;

export enum RecurrenceInterval {
    WEEKLY = 'WEEKLY',
    BI_WEEKLY = 'BI_WEEKLY',
    MONTHLY = 'MONTHLY',
}

export enum AuctionDateType {
    DISABLED = 'DISABLED',
    SINGLE = 'SINGLE',
    RECURRENT = 'RECURRENT',
}

export type AuctionDateRecurrence = {
    day: RecurrenceDay[];
    hour: string;
    interval: RecurrenceInterval;
};

export interface EstateSafe {
    user: string;
    address: string;
    auctionDate: string;
    auctionDateRecurrence?: AuctionDateRecurrence;
    auctionType: AuctionType;
    organization?: string;
    title: string;
    description: string;
    features: string[];
    year: number;
    pictures: string[];
    propertySize: number;
    landSize: number;
    landType: EstateLandType;
    rooms: number;
    price: number;
    updatedPrice: number;
    marketPrice: number;
    currency: Currency;
    location: GeoPoint;
    state: EstateState;
    type: PropertyType;
    // 1-2 letters code e.g. CJ, BT, B etc
    county: string;
    subType: EstateType | MovableType;
    contactCount: number;
    viewCount: number;
    commentsCount: number;
    promoted?: boolean;
}

export enum AuctionDocumentPathType {
    REMOTE = 'REMOTE',
    LOCAL = 'LOCAL',
}

export interface Estate extends EstateSafe {
    provider?: EstateProvider | null;
    externalId?: string;
    nationalRegistryId?: string;
    executionNumber: string;
    auctionUrl: string;
    auctionOwnerId: string;
    auctionDocument?: string;
    auctionDocuments?: Array<{
        path: string;
        date: Date;
        pathType?: AuctionDocumentPathType;
        text?: string;
        aiResponse?: string;
        aiResponseStatus?: AiResponseStatus;
    }>;
    landCF?: string;
    cfCoordinatesId?: string | null;
    depositIBAN?: string;
    legalDocText?: string;
    aiResponse?: string;
    legalReferrences?: Object;
    facebookPostDate?: Date;
}

export interface Timestamps {
    createdAt: string;
    updatedAt: string;
}

export interface EstateDB extends Estate, Timestamps {
    _id: string;
}

export interface EstateSafeDB extends EstateSafe {
    _id: string;
}

export interface EstateDBAdmin extends EstateDB {
    providerUrl: string;
    nationalRegistry: NationalRegistry[];
    salePublications: string[];
}

export type EstateResponse = {
    post: { _id: string };
    put: void;
    delete: void;
    getOne: EstateSafeDB & { biggestOffer: number };
    getList: EstateSafeDB[];
    adminGetOne: EstateDBAdmin & { userName: string };
    adminGetAll: EstateDB[];
    contact: EstateDB;
    count: { active: number; total: number };
    allSavedSearches: {
        userSearch: UserSearch & { _id?: string };
        list: EstateSafeDB[];
    }[];
    savedSearchCount: {
        count: number;
    };
    publications: string[];
};

export type SimpleCoordinates = {
    lat: number;
    lng: number;
};

export type EstateFilters = Partial<{
    executionNumber: string;
    promoted: boolean;
    nearMe: boolean;
    propertySizeFrom: number;
    propertySizeTo: number;
    landSizeFrom: number;
    landSizeTo: number;
    priceFrom: number;
    priceTo: number;
    currency: string;
    roomsFrom: number;
    roomsTo: number;
    yearFrom: number;
    yearTo: number;
    state: EstateState | 'ALL';
    type: PropertyType;
    subType: Array<EstateType | MovableType>;
    landType: EstateLandType;
    limit: number;
    offset: number;
    county: string;
    rangeKM: number;
    location: {
        lat: number;
        lng: number;
    };
    auctionLocation: {
        lat: number;
        lng: number;
    };
}>;

type SortOptions = 'asc' | 'desc';

export type EstateSort = Partial<{
    auctionDate: SortOptions;
    price: SortOptions;
    year: SortOptions;
    contactCount: SortOptions;
}>;

export type EstateSortKeys = keyof EstateSort;

export const ESTATE_LIST_LIMIT = 100;

export const isEstateDB = (
    estate: EstateDB | EstateSafeDB
): estate is EstateDB => {
    return '_id' in estate && 'auctionOwnerId' in estate;
};

export const recurrentDayIsWeekDay = (day: RecurrenceDay): day is WeekDays => {
    return day.length > 3;
};

export const getAuctionDateType = (
    estate: Pick<EstateSafe, 'auctionDate' | 'auctionDateRecurrence'>
) => {
    const { auctionDate, auctionDateRecurrence } = estate;

    if (auctionDateRecurrence?.interval) {
        return AuctionDateType.RECURRENT;
    }

    if (
        !auctionDate ||
        auctionDate.toString().toLowerCase().trim() === 'none'
    ) {
        return AuctionDateType.DISABLED;
    }

    return AuctionDateType.SINGLE;
};
