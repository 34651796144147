import { Currency, EstateDB, EstateDBAdmin } from 'foreclosure-types';
import { defaultMapCenter } from '../../config';

const defaultCoords: EstateDBAdmin['location']['coordinates'] = [
	defaultMapCenter.lng,
	defaultMapCenter.lat,
];

export const initialEstate: EstateDB = {
	auctionOwnerId: '',
	auctionType: 'BAILIFF',
	_id: '',
	createdAt: '',
	updatedAt: '',
	features: [],
	pictures: [],
	landType: 'URBAN',
	currency: Currency.RON,
	state: 'NEW',
	type: 'ESTATE',
	subType: 'HOUSE',
	provider: null,
	externalId: '',
	auctionDate: 'none',
	title: 'Anunt nou',
	description: '',
	price: 0,
	updatedPrice: 0,
	address: 'none',
	location: {
		coordinates: defaultCoords,
		type: 'Point',
	},
	propertySize: 0,
	landSize: 0,
	marketPrice: 0,
	rooms: 0,
	year: 0,
	user: 'Admin',
	depositIBAN: ' ',
	landCF: '',
	cfCoordinatesId: '',
	county: '',
	viewCount: 0,
	contactCount: 0,
	commentsCount: 0,
	executionNumber: '',
	auctionUrl: '',
};

export const initialValues: EstateDBAdmin = {
	...initialEstate,
	providerUrl: '',
	nationalRegistry: [],
	salePublications: [],
};
