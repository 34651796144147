import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { ReactNode } from 'react';

const TitleCore = (props: {
	children: ReactNode;
	classes: { [key: string]: string };
}): JSX.Element => {
	return (
		<Typography
			component="h2"
			variant="h6"
			color="primary"
			gutterBottom
			classes={props.classes}
		>
			{props.children}
		</Typography>
	);
};

export const Title = withStyles((theme) => ({
	root: {
		marginBottom: (props: {
			marginBotton?: string | number;
		}): string | number => props.marginBotton || theme.spacing(2),
	},
}))(TitleCore);
