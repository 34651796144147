import {
	TableContainer,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import { Lead, LeadStatus } from 'foreclosure-types';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { getWatiUrl, patchOne } from '../../../../api/leads';
import { ProgressButton } from '../../../../components/Form/ProgressButton';
import { LeadBoolean } from '../../../../components/LeadBoolean';
import { LeadQualityText } from '../../../../components/LeadQualityText';
import { Select } from '../../../../components/Select';

const PhoneButton = (props: { leadId: string; phone: string }) => {
	const { leadId, phone } = props;
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);

		const { url } = await getWatiUrl(leadId);

		setLoading(false);

		window.open(url, '_blank', 'noreferrer');
	};

	return (
		<ProgressButton loading={loading} onClick={handleClick}>
			{phone}
		</ProgressButton>
	);
};

const prettifyValue = (value: any) => {
	if (typeof value === 'boolean') {
		return value ? 'Da' : 'Nu';
	}

	if (Array.isArray(value)) {
		if (value.length) return value.map((v) => <p>{v}</p>);

		return '-';
	}

	const date = moment(value);
	if (date.isValid()) {
		return date.format('YYYY-MM-DD HH:mm');
	}

	if (!value) return '-';

	return value;
};

const LeadProfileValue = (props: {
	lead: Lead;
	field: keyof Lead;
	onChange?: (field: keyof Lead, value: unknown) => any;
}) => {
	const { lead, field, onChange } = props;
	const value = lead[field];

	switch (field) {
		case 'phone':
			return <PhoneButton leadId={lead._id} phone={lead.phone} />;
		case 'email':
			return (
				<a
					href={`mailto:${lead.email || ''}`}
					target="_blank"
					rel="noreferrer"
				>
					{(lead.email || '').toLowerCase()}
				</a>
			);
		case 'subscribed':
			return (
				<LeadBoolean
					value={lead.subscribed}
					onChange={(checked) =>
						onChange && onChange('subscribed', checked)
					}
				/>
			);
		case 'whatsappAutomation':
			return (
				<LeadBoolean
					value={!!lead.whatsappAutomation}
					onChange={(checked) =>
						onChange && onChange('whatsappAutomation', checked)
					}
				/>
			);
		case 'status':
			if (!lead.status) return <>'-'</>;

			return (
				<Select
					options={LeadStatus}
					label="Status"
					defaultValue={lead.status}
					onChange={(value) => {
						onChange && onChange('status', value);
					}}
				/>
			);
		case 'quality':
			return (
				<LeadQualityText
					quality={lead.quality}
					controls
					onChange={(quality) =>
						onChange && onChange('quality', quality)
					}
				/>
			);
	}

	return <>{prettifyValue(value)}</>;
};

const profileKeys: Array<keyof Lead> = [
	'name',
	'email',
	'phone',
	'wa_id',
	'status',
	'quality',
	'subscribed',
	'whatsappAutomation',
	'createdAt',
	'updatedAt',
	'guestUuid',
];

const getLabel = (field: string) => {
	const regExp = /[A-Z]{1}/g;
	const label = field.replace(regExp, (match) => ` ${match}`);

	return capitalize(label.replace('At', ''));
};

export const LeadProfile = (props: {
	lead: Lead;
	updateLead?: (lead: Lead) => any;
}) => {
	const { lead, updateLead } = props;

	const patch = async (field: keyof Lead, value: unknown) => {
		const result = await patchOne(lead._id, { [field]: value });

		if (updateLead) {
			updateLead(result);
		}
	};

	return (
		<TableContainer component={Paper}>
			<Table aria-label="profile table of user">
				<TableBody>
					{profileKeys
						.map((field) => {
							const value = lead[field];

							// Don't render objects
							if (
								typeof value === 'object' &&
								!Array.isArray(value) &&
								value !== null
							) {
								return null;
							}

							return (
								<TableRow key={field}>
									<TableCell component="th" scope="row">
										{getLabel(field)}
									</TableCell>
									<TableCell align="right">
										<LeadProfileValue
											lead={lead}
											field={field}
											onChange={patch}
										/>
									</TableCell>
								</TableRow>
							);
						})
						.filter(Boolean)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
