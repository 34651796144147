import { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { MainTemplate } from '../../components/Templates/Main';
import GoogleMapReact from 'google-map-react';
import { googleMapsApiKey } from '../../api/config';
import { TermsDialog } from './TermsDialog';
import { LocateCfForm } from './form';
import { defaultMapCenter } from '../../config';
import { useNavigate } from 'react-router-dom';
import {
	LocateCfParams,
	useCfGeocode,
	drawArea,
	useInitialURLParams,
	isCfLocationValid,
} from '../../helpers/map';
import { useTranslation } from 'react-i18next';
import { PageMeta } from '../../components/PageMeta';
import { CfCoordinatesResponse, Perimeter } from 'foreclosure-types';
import { locateCfPlaceholder } from '../../assets';
import { useAuth } from '../../context/Auth';

const useStyles = makeStyles((theme) => ({
	searchContainer: {
		position: 'absolute',
		top: theme.spacing(4),
		left: theme.spacing(2),
		width: 1200,
		zIndex: 2,
		[theme.breakpoints.down('sm')]: {
			top: 10,
			left: 10,
			width: '94vw',
			'& > div > div:first-child': {
				width: '80vw',
				flexBasis: '80vw',
			},
		},
	},
	pageContainer: {
		display: 'flex',
	},
	mapContainer: {
		flex: 1,
		position: 'relative',
		zIndex: 99,
	},
	mapPlaceholder: {
		backgroundImage: `url(${locateCfPlaceholder})`,
		backgroundSize: 'cover',
		width: '100%',
		height: '100%',
	},
}));

const MapPlaceholder = () => {
	const classes = useStyles();

	return <div className={classes.mapPlaceholder}></div>;
};

const SelectCF = (props: {
	mapCenter: Array<number>;
	onSubmit: (cfParams: LocateCfParams) => any;
	onClear?: () => any;
	onAutocomplete: (coordinates: { wgs84: Perimeter }) => any;
	loading?: boolean;
}) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const params = useInitialURLParams();
	const { onClear } = props;

	return (
		<div className={classes.searchContainer}>
			<LocateCfForm
				navigate
				share
				history
				readOnly={!!params.agencyId}
				loading={props.loading}
				initialValues={params}
				googleMapsCoordinates={props.mapCenter}
				onAutocomplete={props.onAutocomplete}
				onSubmit={(state, uat, cf) =>
					props.onSubmit({
						state,
						uat,
						cf,
						agencyId: params.agencyId,
					})
				}
				onChange={useCallback(
					(state: string, county: string, cf: string) => {
						const values = [state, county, cf]
							.map((el) => encodeURIComponent(el || '_'))
							.join('/');

						if (!cf && onClear) {
							onClear();
						}

						navigate('/cf/' + values, { replace: true });
					},
					[navigate, onClear]
				)}
			/>
		</div>
	);
};

export const LocateCfPage = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { loading: authLoading } = useAuth();
	const [loading, setLoading] = useState(false);
	const [map, setMap] = useState<google.maps.Map>();
	const [cfParams, setCfParams] = useState({} as LocateCfParams);
	const [cfLocation, setCfLocation] = useState<Omit<
		CfCoordinatesResponse['getOne'],
		'_id'
	> | null>(null);

	const { getCfLocation } = useCfGeocode();

	useEffect(() => {
		if (authLoading) return;
		if (!isCfLocationValid(cfParams)) return;

		(async () => {
			setLoading(true);

			const coordinates = await getCfLocation(cfParams);

			setCfLocation(coordinates || null);
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cfParams, authLoading]);

	useEffect(() => {
		if (!map) return;
		if (!cfLocation) return;

		drawArea(map, cfLocation.wgs84);
		setLoading(false);
	}, [map, cfLocation]);

	return (
		<MainTemplate
			displayFooter={false}
			contentClassName={classes.pageContainer}
		>
			<PageMeta title={t('top_menu.locate_cf')} />
			<TermsDialog />

			<div className={classes.mapContainer}>
				<SelectCF
					loading={loading}
					onSubmit={setCfParams}
					onClear={useCallback(
						() => setCfParams({} as LocateCfParams),
						[]
					)}
					onAutocomplete={setCfLocation}
					mapCenter={cfLocation ? cfLocation.wgs84[0] : []}
				/>

				{cfLocation ? (
					<GoogleMapReact
						options={{
							mapTypeId: 'satellite',
						}}
						bootstrapURLKeys={{
							key: googleMapsApiKey,
							libraries: ['places'],
						}}
						defaultCenter={defaultMapCenter}
						yesIWantToUseGoogleMapApiInternals
						onGoogleApiLoaded={(params) => {
							const map: google.maps.Map = params.map;

							setMap(map);
						}}
						defaultZoom={16}
					/>
				) : (
					<MapPlaceholder />
				)}
			</div>
		</MainTemplate>
	);
};
