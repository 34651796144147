import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
	email: yup.string().email('Email must be valid').required('Email required'),
	password: yup
		.string()
		.matches(
			/^[A-Za-z0-9\d@$!%*#?&-_]{4,}$/,
			'Password must be at least 4 characters, have at least a letter, at least a digit and at least a special character'
		)
		.required('Password required'),
});

export const RegisterSchema = yup.object().shape({
	email: yup.string().email('Email must be valid').required('Email required'),
	name: yup.string().min(4).required('Name required'),
	phone: yup
		.string()
		.min(10)
		.matches(/^\+{0,1}[0-9]+$/)
		.required('Email required'),
	password: yup
		.string()
		.matches(
			/^[A-Za-z0-9\d@$!%*#?&-_]{4,}$/,
			'Password must be at least 4 characters, have at least a letter, at least a digit and at least a special character'
		)
		.required('Password required'),
});

export type LoginValues = {
	email: string;
	password: string;
};

export type RegisterValues = {
	email: string;
	phone: string;
	name: string;
	password: string;
};
