import axios from 'axios';
// import { setup } from 'axios-cache-adapter';
// Cache solution for web & progressive web apps
// import localforage from 'localforage';
// import { isProduction } from '../config';

export const CACHE_STORE_NAME = 'axios-cache';

// TODO: renable network cache, but invalidate based on query params
// const axiosChache = setup({
// 	cache: {
// 		ignoreCache: !isProduction,
// 		// 15 min for production
// 		maxAge: 15 * 60 * 1000,
// 		// Attempt reading stale cache data when getting an error (not connected to network)
// 		readOnError: true,
// 		// Deactivate `clearOnStale` option so that we can actually read stale cache data
// 		clearOnStale: false,
// 		exclude: { query: false },
// 		// key: (req) => req.url + JSON.stringify(req.params),
// 		store: localforage.createInstance({
// 			storeName: CACHE_STORE_NAME,
// 		}),
// 		// TODO: invalidate search cache after a PATCH /search
// 		// invalidate: (config, req) => {},
// 	},
// });

export default axios;
