import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { AuctionOwnerDB } from 'foreclosure-types';
import { getAll, getOne, getUserAddresses } from '../api/auctionOwner';

let cache: Array<AuctionOwnerDB> = [];

export const useAuctionOwnerList = () => {
	const [rows, setRows] = useState(cache);

	rows.forEach((row) => {
		row.createdAt = moment(row.createdAt).format('YYYY.MM.DD HH:mm');
	});

	useEffect(() => {
		(async () => {
			try {
				cache = await getAll();

				setRows(cache);
			} catch (err: any) {
				console.error(err);
			}
		})();
	}, [setRows]);

	return rows;
};

export const useUserAddressList = () => {
	const [rows, setRows] = useState<Array<AuctionOwnerDB>>([]);

	useEffect(() => {
		(async () => {
			try {
				const result = await getUserAddresses();

				setRows(result);
			} catch (err: any) {
				console.error(err);
			}
		})();
	}, [setRows]);

	return rows;
};

const ownerCache: Record<string, AuctionOwnerDB> = {};
const ownerStatus: Record<string, 'loading' | 'done'> = {};
const ownerListeners: Record<
	string,
	Array<(owner: AuctionOwnerDB) => void>
> = {};

export const useAuctionOwner = (ownerId?: string) => {
	const [owner, setOwner] = useState({} as AuctionOwnerDB);

	const refetch = useCallback(
		async (force = false) => {
			if (!ownerId || ownerId.length !== 24) return;

			if (ownerCache[ownerId] && !force) {
				setOwner(ownerCache[ownerId]);
				return;
			}

			if (ownerStatus[ownerId] === 'loading') {
				if (ownerListeners[ownerId]) {
					ownerListeners[ownerId].push(setOwner);
				} else {
					ownerListeners[ownerId] = [setOwner];
				}
				return;
			}

			ownerStatus[ownerId] = 'loading';

			const result = await getOne(ownerId);

			setOwner(result);

			ownerCache[ownerId] = result;
			ownerStatus[ownerId] = 'done';

			ownerListeners[ownerId]?.forEach((listener) => listener(result));
			ownerListeners[ownerId] = [];
		},
		[ownerId, setOwner]
	);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return {
		owner,
		refetch,
	};
};
