export const cookieTextRo = `
Politică privind fișierele cookies

1. Introducere

Prezenta Politică privind fișierele cookies se aplică tuturor utilizatorilor paginii de internet licitatiibanci.ro. Informațiile prezentate în continuare au ca scop informarea utilizatorilor acestei pagini de internet cu privire la plasarea, utilizarea și administrarea cookie-urilor de către Beaverr Territory SRL în contextul navigării utilizatorilor pe această pagină de internet.

2. Ce sunt cookie-urile?

Folosim termenul „cookie”-uri pentru a ne referi la modulele cookie și la tehnologiile similare prin intermediul cărora pot fi colectate informații în mod automat.
Un „Internet Cookie” (termen cunoscut și sub denumirea de „browser cookie” sau „HTTP cookie” ori „cookie”) reprezintă un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe computerul, terminalul mobil sau pe alte echipamente ale unui utilizator prin intermediul cărora se accesează internetul.
Cookie-urile sunt instalate prin solicitarea emisă de un web-server către un browser (de ex.: Internet Explorer, Firefox, Chrome). Cookie-urile odată instalate au o durată de existență determinată, rămânând „pasive”, în sensul că nu conțin programe software, viruși sau spyware și nu vor accesa informațiile de pe hard driverul utilizatorului pe al cărui echipament au fost instalate.
Un cookie este format din două părți:
numele cookie-ului; și
conținutul sau valoarea cookie-ului.
Din punct de vedere tehnic, doar web-serverul care a trimis cookie-ul îl poate accesa din nou în momentul în care un utilizator se întoarce pe pagina de internet asociată web-serverului respectiv.

3. Pentru ce scopuri sunt utilizate cookie-urile prin intermediul acestei pagini de internet:

Cookie-urile sunt utilizate pentru a furniza utilizatorilor acestei pagini de internet o experiență mai bună de navigare și servicii adaptate nevoilor și interesului fiecărui utilizator în parte și anume pentru:

îmbunătățirea utilizării acestei pagini de internet, inclusiv prin identificarea oricăror erori care apar în timpul vizitării/utilizării acesteia de către utilizatori;
furnizarea de statistici anonime cu privire la modul în care este utilizată această pagină de internet către Beaverr Territory SRL, în calitate de deținător al acestei pagini de internet;
anticiparea unor eventuale bunuri care vor fi în viitor puse la dispoziția utilizatorilor prin intermediul acestei pagini de internet, în funcție de serviciile / produsele accesate.
Pe baza feedback-ului transmis prin cookie-uri în legătură cu modul în care se utilizează această pagină de internet, Beaverr Territory SRL poate adopta măsuri pentru ca această pagină de internet să fie mai eficientă și mai accesibilă pentru utilizatori.
Astfel, utilizarea cookie-urilor permite memorarea anumitor setări/preferințe stabilite de către utilizatorii acestei pagini de internet, precum:

limba în care este vizualizată o pagină de internet;
moneda în care se exprimă anumite prețuri sau tarife;
facilitarea accesului în contul utilizatorilor;
postarea comentariilor pe site.
4. Care este durata de viață a cookie-urilor?

Durata de viață a cookie-urilor poate varia semnificativ, depinzând de scopul pentru care este plasat. Există următoarele categorii de cookie-uri care determină și durata de viață a acestora:

Cookie-uri de sesiune – Un „cookie de sesiune” este un cookie care este șters automat când utilizatorul își închide browserul.
Cookie-uri persistente sau fixe – Un „cookie persistent” sau „fix” este un cookie care rămâne stocat în terminalul utilizatorului până când atinge o anumită dată de expirare (care poate fi în câteva minute, zile sau câțiva ani în viitor) sau până la ștegerea acestuia de către utilizator în orice moment prin intermediul setărilor browserului.
5. Ce sunt cookie-urile plasate de terți?

Anumite secțiuni de conținut de pe pagina de internet pot fi furnizate prin intermediul unor terți, adică nu de către Beaverr Territory SRL, caz în care aceste cookie-uri sunt denumite cookie-uri plasate de terți („third party cookie-uri”).
Terții furnizori ai cookie-urilor trebuie să respecte, de asemenea, regulile în materie de protecție a datelor și Politica de Confidențialitate disponibilă pe această pagină de internet.
Aceste cookie-uri pot proveni de la urmatorii terți: Google Analytics, Facebook.

6. Ce cookie-uri sunt folosite prin intermediul acestei pagini de internet:

Prin utilizarea/vizitarea paginii de internet pot fi plasate următoarele cookie-uri:

a. Cookie-uri de performanță a paginii de internet;
b. Cookie-uri de analiză a utilizatorilor;
c. Cookie-uri pentru geotargetting;
d. Cookie-uri de înregistrare;
e. Cookie-uri pentru publicitate;
f. Cookie-uri ale furnizorilor de publicitate;

a. Cookie-uri de performanță

Prin acest tip de cookie-uri sunt memorate preferințele utilizatorului acestei pagini de internet, astfel încât setarea din nou a preferințelor în cazul vizitării ulterioare a paginii de internet nu mai este necesară.

b. Cookie-uri de analiză a utilizatorilor

Aceste cookie-uri ne informează dacă un anumit utilizator al paginii de internet a mai vizitat/utilizat această pagină de internet anterior. Aceste cookie-uri sunt utilizate doar în scopuri statistice.

c. Cookie-uri pentru geotargetting

Aceste cookie-uri sunt utilizate de către un soft care stabilește țara de proveniență a utilizatorului paginii de internet. Vor fi primite aceleași reclame indiferent de limba selectată.

d. Cookie-uri pentru înregistrare

Atunci când vă înregistrați pe acest site, se generează cookie-uri care memorează acest demers. Serverele utilizează aceste cookie-uri pentru a ne arăta contul cu care sunteți înregistrat. De asemenea, utilizarea acestor cookie-uri permite să asociem orice comentariu postat pe pagina de internet cu username-ul contului folosit. În cazul în care nu a fost selectată opțiunea „păstrează-mă înregistrat”, aceste cookie-uri se vor șterge automat la momentul terminării sesiunii de navigare.

e. Cookie-uri pentru publicitate

Aceste cookie-uri permit aflarea vizualizării de către un utilizator a unei reclame online, tipul acesteia și timpul scurs de la momentul vizualizării respectviului mesaj publicitar. Ca atare, astfel de cookie-uri sunt folosite pentru targetarea publicității online. Aceste cookie-uri sunt anonime, stocând informații despre contentul vizualizat, nu și despre utilizatori.

7. Ce tip de informații sunt stocate și accesate prin intermediul cookie-urilor?

Cookie-urile păstrează informații într-un fișier text de mici dimensiuni care permit recunoașterea browserului. Această pagină de internet recunoaște browserul până când cookie-urile expiră sau sunt șterse.

8. Particularizarea setările browserului în ceea ce privește cookie-urile

În cazul în care utilizarea cookie-urilor nu este deranjantă iar calculatorul sau echipamentul tehnic utilizat pentru navigarea pe această pagină de internet este folosit doar de către dumneavoastră, pot fi setate termene lungi de expirare pentru stocrarea istoricului de navigare.
În cazul în care calculatorul sau echipamentul tehnic utilizat pentru navigarea pe această pagină de internet este folosit de mai multe persoane, poate fi luată în considerare setarea pentru ștergerea datelor individuale de navigare de fiecare dată când browserul este închis.

9. Cum pot fi oprite cookie-urile?

Dezactivarea și refuzul de a primi cookie-uri pot face această pagină de internet dificil de vizitat, atrăgând după sine limitări ale posibilităților de utilizare ale acesteia.
Utilizatorii își pot configura browserul să respingă fișierele cookie sau să fie acceptate cookie-uri de la o pagină de internet anume.
Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări pot fi accesate, ca regulă, în secțiunea „opțiuni” sau în meniul de „preferințe” al browserului tău.
Totuși, refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu veți mai primi publicitate online – ci doar ca aceasta nu va fi adaptată preferințelor și interesele dumneavoastră, evidențiate prin comportamentul de navigare.

Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare:

Cookie settings in Internet Explorer
Cookie settings in Firefox
Cookie settings in Chrome
Cookie settings in Safari
Pentru orice întrebări suplimentare cu privire la modul în sunt utilizate cookie-urile prin intermediul acestei pagini de internet, vă rugăm să vă adresați la: marian.alecu@licitatiibanci.ro.
`;
