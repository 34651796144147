import { init as initApm } from '@elastic/apm-rum';
import { isProduction } from '../config';

export const apm = initApm({
	serviceName: isProduction ? 'ui-prod' : 'ui-dev',
	// prettier-ignore
	serverUrl: 'https://8b1c91795fc14547976df843c099f94d.apm.eu-west-1.aws.cloud.es.io:443',
	// Set service version (required for sourcemap feature)
	serviceVersion: '1.0.0',
	environment: isProduction ? 'production' : 'development',
	active: isProduction,
});
