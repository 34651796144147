import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SubType } from 'foreclosure-types';
import { SelectController } from './SelectController';

const mobileSubTypes: Partial<Record<SubType, string>> = {
	CAR: 'Masina',
	OTHER: 'Altele',
};
const estateSubTypes: Partial<Record<SubType, string>> = {
	APARTMENT: 'Apartament',
	HOUSE: 'Casa',
	LAND: 'Teren',
	COMERCIAL: 'Comercial',
	FACTORY: 'Fabrica',
};

export const SubTypeField = () => {
	const { control, setValue } = useFormContext();
	const auctionType = useWatch({
		control,
		name: 'type',
		defaultValue: 'ESTATE',
	});

	useEffect(() => {
		setValue('subType', auctionType === 'ESTATE' ? 'HOUSE' : 'CAR');
	}, [auctionType, setValue]);

	return (
		<SelectController
			name="subType"
			label="Tip proprietate"
			required
			options={auctionType === 'ESTATE' ? estateSubTypes : mobileSubTypes}
			defaultValue={auctionType === 'ESTATE' ? 'HOUSE' : 'CAR'}
		/>
	);
};
