import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { EstateDB, EstateSafeDB, isEstateDB, SubType } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import { PropertyDetailsFeatures } from './PropertyDetailsFeatures';
import { PropertyDetailsGallery } from './PropertyDetailsGallery';
import { Markdown } from '../../components/Markdown';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { getGoogleMapsLink } from '../../helpers/map';
import { AddToWatchListButton } from '../../components/AddToWatchListButton';
import { useAuth, useAuthRole } from '../../context/Auth';
import { LOCATE_CF_BUTTON } from './Tour';
import { GoogleAdsArticle } from '../../components/GoogleAds';

const useStyles = makeStyles((theme) => ({
	borderRight: {
		height: '100%',
		[theme.breakpoints.up('md')]: {
			borderRight: '1px solid ' + theme.palette.grey[300],
			paddingRight: theme.spacing(4),
		},
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1),
		paddingTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	watchButton: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
	},
}));

const LocateCFInfo = () => {
	const { t } = useTranslation();

	return (
		<Box marginTop={5}>
			<Typography variant="h5">
				{t('estateView.property.locateCfTitle')}
			</Typography>
			<Box marginTop={2} marginBottom={2}>
				<Alert severity="info">
					{t('estateView.property.locateCfDescription')}
				</Alert>
			</Box>
			<Link to="/cf" target="_blank">
				<Button
					color="primary"
					variant="outlined"
					className={LOCATE_CF_BUTTON}
				>
					{t('estateView.property.locateCfButton')}
				</Button>
			</Link>
		</Box>
	);
};

const subTypesWithCF: SubType[] = ['LAND', 'COMERCIAL', 'FACTORY', 'HOUSE'];

export const PropertyDetails = (props: { estate: EstateDB | EstateSafeDB }) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { estate } = props;
	const { user } = useAuth();
	const { isAdmin } = useAuthRole();

	const renderTitle = () => (
		<>
			<div className={classes.titleContainer}>
				<Typography color="primary" variant="h6">
					{t('estateView.property.title')}
				</Typography>

				{estate.user !== user._id && (
					<AddToWatchListButton
						className={classes.watchButton}
						estateId={estate._id}
					/>
				)}
			</div>
			<Box>
				<Typography variant="h4" component="h1">
					{estate.title}
				</Typography>
				<a href="#comments" style={{ textDecoration: 'none' }}>
					<Typography variant="subtitle2" component="span">
						{estate.commentsCount === 1
							? t('advertCard.comment')
							: t('advertCard.comments', {
									count: estate.commentsCount,
							  })}
					</Typography>
				</a>
			</Box>
			{isEstateDB(estate) && (
				<a
					href={getGoogleMapsLink(estate.address)}
					target="_blank"
					rel="noreferrer"
				>
					<Typography variant="subtitle2">
						{estate.address}
						<br />
						{t('estateView.property.navigate')}
					</Typography>
				</a>
			)}
		</>
	);
	const renderFeatures = () => {
		const values = [
			estate.year,
			estate.rooms,
			estate.propertySize,
			estate.landSize,
			estate.subType === 'LAND' ? estate.landType : null,
		];
		const nonEmptyValues = values.filter(Boolean);

		if (!nonEmptyValues.length) return null;

		return (
			<Box marginTop={5}>
				<Typography variant="h5">
					{t('estateView.property.features')}
				</Typography>
				<PropertyDetailsFeatures estate={estate} />
			</Box>
		);
	};
	const renderGallery = () => (
		<Box marginTop={5}>
			<Typography variant="h5">
				{t('estateView.property.gallery')}
			</Typography>
			<Box marginTop={2}>
				<PropertyDetailsGallery
					pictures={estate.pictures}
					type={estate.subType}
				/>
			</Box>
		</Box>
	);
	const renderDescription = () => (
		<Box marginTop={5}>
			<Typography variant="h5">
				{t('estateView.property.description')}
			</Typography>
			<Box marginTop={1}>
				<Typography variant="body1" component="div">
					<Markdown>{estate.description}</Markdown>
				</Typography>
			</Box>
			{isAdmin && 'legalDocText' in estate && estate.legalDocText && (
				<Box marginTop={3}>
					<Typography variant="h5">
						{t('estateView.property.saleText')}
					</Typography>
					<Box marginTop={1}>
						<Typography variant="body1" component="div">
							<Markdown>{estate.legalDocText}</Markdown>
						</Typography>
					</Box>
				</Box>
			)}
		</Box>
	);

	return (
		<div className={classes.borderRight}>
			{renderTitle()}

			{renderGallery()}
			{renderFeatures()}
			{!estate.promoted && subTypesWithCF.includes(estate.subType) && (
				<LocateCFInfo />
			)}
			<Box marginTop={1} marginBottom={1}>
				<GoogleAdsArticle />
			</Box>
			{renderDescription()}
		</div>
	);
};
