import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Box } from '@material-ui/core';
import { Title } from '../Title';
import { PaperFooter } from '../PaperFooter';
import { ListHead } from './ListHead';
import { ListBody } from './ListBody';
import { Row, EventHandler } from './types';
import { SelectableList } from './SelectableList';
import { isString } from 'lodash';

const useStyles = makeStyles((theme) => ({
	top: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	topContent: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(4),
		'& > *': {
			marginLeft: 5,
			marginRight: 5,
		},
	},
}));

type Props<P extends Row, T extends Partial<{ [K in keyof P]: string }>> = {
	title?: string;
	rows: Array<P>;
	header: T;
	children?: JSX.Element;
	renderFilters?: () => JSX.Element;
	renderActions?: () => JSX.Element;
	onRowClick?: EventHandler<P>;
	onCheckClick?: EventHandler<P>;
	onSelectChange?: (rows: Array<P>) => void;
	selectable?: boolean;
	highlight?: P;
};

export const List = <
	P extends Row,
	T extends Partial<{ [K in keyof P]: string }>
>(
	props: Props<P, T>
): React.ReactElement => {
	const classes = useStyles({});
	const {
		header,
		rows,
		children,
		selectable,
		onRowClick,
		onCheckClick,
		highlight,
	} = props;
	const { title, renderFilters, renderActions } = props;
	const keys = Object.keys(header);
	const headerLabels = Object.values(header).filter(isString);
	const content = (
		<>
			<Box className={classes.top}>
				{title && <Title marginBotton="0">{title}</Title>}
			</Box>
			<Box className={classes.topContent}>
				{renderFilters && <Box>{renderFilters()}</Box>}
				{renderActions && <Box>{renderActions()}</Box>}
			</Box>
			<Table>
				<ListHead
					selectable={!!selectable}
					headerLabels={headerLabels}
				/>
				<ListBody
					selectable={selectable}
					headerKeys={keys}
					rows={rows}
					onRowClick={onRowClick}
					onCheckClick={onCheckClick}
					highlight={highlight}
				/>
			</Table>
			{children && <PaperFooter>{children}</PaperFooter>}
		</>
	);

	if (selectable) {
		return (
			<SelectableList onChange={props.onSelectChange} allRows={rows}>
				{content}
			</SelectableList>
		);
	}

	return content;
};
