import { useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../api/auth';
import { LoginTemplate } from './LoginTemplate';
import { LoginSchema, LoginValues } from '../../forms/login';
import { useYupValidationResolver } from '../../forms/resolver';
import { SchemaErrors } from '../../types/SchemaErrors';
import { FacebookLogin } from '../../components/FacebookLogin';
import { AuthContext, authState } from '../../context/Auth';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { getErrorFromResponse } from '../../helpers/form';
import { GoogleLoginButton } from '../../components/GoogleLogin';

const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setToken, logout, logged } = useContext(AuthContext);
	const loginResolver = useYupValidationResolver(LoginSchema);
	const formProps = useForm({
		// @ts-ignore
		resolver: loginResolver,
	});
	const { register, handleSubmit, errors, setError } = formProps;

	// logout existing user when it gets to login
	useEffect(logout, [logout]);

	useEffect(() => {
		if (logged) {
			navigate(authState.redirectToUrlAfterLogin || '/');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [logged]);

	const setTokenAndRedirect = async (token: string) => {
		setToken(token);

		await new Promise((resolve) => setTimeout(resolve, 500));

		navigate(authState.redirectToUrlAfterLogin || '/');
	};
	const onSubmit: SubmitHandler<LoginValues> = async (data, e) => {
		e?.preventDefault();

		try {
			const response = await login(data.email, data.password);

			setTokenAndRedirect(response.token);
		} catch (err: any) {
			const msg = getErrorFromResponse(err) || t('login.error');

			setError('password', { message: msg });
		}
	};

	return (
		<FormProvider {...formProps}>
			<LoginTemplate
				title={t('login.title')}
				handleSubmit={handleSubmit(onSubmit)}
				errors={errors as SchemaErrors}
				emailRef={register}
				passwordRef={register}
			>
				<Box marginTop={4} marginBottom={2}>
					<GoogleLoginButton
						onSuccess={setTokenAndRedirect}
						onFail={console.error}
					/>
				</Box>
				<FacebookLogin
					onSuccess={setTokenAndRedirect}
					onFail={console.error}
				/>
				<Box marginTop={2}>
					<Link to="/request-code">
						<Button fullWidth>
							{t('login.loginWithCodeTitle')}
						</Button>
					</Link>
				</Box>
				<Box marginTop={4}>
					<Link to="/signup">
						<Typography variant="body2">
							{t('login.createNewAccount')}
						</Typography>
					</Link>
				</Box>
			</LoginTemplate>
		</FormProvider>
	);
};

export default Login;
