import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../context/toast';

type Callback = () => unknown | Promise<unknown>;

export const useApiWrapper = () => {
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();

	return useCallback(
		async <T extends Callback>(
			callback: T,
			params: {
				successMsg?: string;
				errorMsg?: string | ((err: any) => string);
				displayToastOnSuccess?: boolean;
			} = {}
		) => {
			try {
				const response = await callback();

				if (params.displayToastOnSuccess !== false) {
					setToastMessage({
						severity: 'success',
						message: params.successMsg || t('form.success'),
					});
				}

				return response;
			} catch (err) {
				console.error(err);

				const message =
					typeof params.errorMsg === 'function'
						? params.errorMsg(err)
						: params.errorMsg;

				setToastMessage({
					severity: 'error',
					message:
						(typeof message === 'string' && message) ||
						t('form.error'),
				});
			}
		},
		[t, setToastMessage]
	);
};
