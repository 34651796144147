import { Container } from '@material-ui/core';
import { EstateSafeDB } from 'foreclosure-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../api/estate';
import { SubscribeSection } from '../../components/SubscribeSection';
import { MainTemplate } from '../../components/Templates/Main';
// import { EmailFooter } from '../../email/Footer';
import { NextAuctionsEmail } from '../../email/NextAuctions';

export const NextAuctions = () => {
	const { county } = useParams<{ county: string }>();
	const [list, setList] = useState<{ [county: string]: EstateSafeDB[] }>({});

	useEffect(() => {
		if (!county) return;

		(async () => {
			const result = await get({
				county: county.toUpperCase(),
			});

			setList(
				result.reduce(
					(acc, estate) => {
						const { county } = estate;

						if (!acc[county]) {
							acc[county] = [estate];
						} else {
							acc[county].push(estate);
						}

						return acc;
					},
					{} as {
						[county: string]: EstateSafeDB[];
					}
				)
			);
		})();
	}, [county]);

	return (
		<MainTemplate>
			<Container maxWidth="sm">
				<NextAuctionsEmail list={list} />

				<SubscribeSection />
				{/* <EmailFooter /> */}
			</Container>
		</MainTemplate>
	);
};
