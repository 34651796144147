import { makeStyles, TextField } from '@material-ui/core';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { OverlayFilter } from '../../../../components/OverlayFilter';
import { FiltersContext } from '../../../../context/Filters';
import { FilterKeys, InnerFiltersProps } from '../types';

const useStyles = makeStyles((theme) => ({
	fields: {
		display: 'flex',
		padding: theme.spacing(1),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		'& > *': {
			margin: theme.spacing(1),
			width: 110,
			[theme.breakpoints.down('sm')]: {
				width: 'auto',
				flexGrow: 1,
			},
		},
	},
}));

type Props = {
	label: string;
	fromKey: FilterKeys;
	toKey: FilterKeys;
	sufix?: string;
	min?: number;
	max?: number;
	children?: ReactNode;
};

const parseNumber = (value?: string) => {
	if (!value || value === '0') {
		return '';
	}

	return value;
};

export const IntervalFilter = (props: InnerFiltersProps & Props) => {
	const { t } = useTranslation();
	const { filters } = useContext(FiltersContext);
	const classes = useStyles();

	const { handleChange, label = '', fromKey, toKey, sufix } = props;
	const { min, max } = props;

	const [from, setFrom] = useState(filters[fromKey]?.toString());
	const [to, setTo] = useState(filters[toKey]?.toString());

	const isInInterval = ({ floatValue: val = 0 }: { floatValue?: number }) => {
		if (min !== undefined && val < min) {
			return false;
		}

		if (max !== undefined && val > max) {
			return false;
		}

		return true;
	};

	return (
		<OverlayFilter
			label={label}
			keys={[fromKey, toKey]}
			sufix={sufix}
			onSave={() => {
				handleChange(fromKey)(parseNumber(from));
				handleChange(toKey)(parseNumber(to));
			}}
			onReset={() => {
				setFrom('');
				setTo('');
			}}
		>
			<div>
				<div className={classes.fields}>
					<NumberFormat
						allowNegative={min !== undefined && min < 0}
						customInput={TextField}
						isAllowed={isInInterval}
						label={t('estate_list.filters.from')}
						onValueChange={(values) => setFrom(values.value)}
						size="small"
						thousandSeparator=" "
						value={from}
						variant="outlined"
						InputProps={{
							inputProps: {
								inputMode: 'numeric',
							},
						}}
					/>
					<NumberFormat
						allowNegative={min !== undefined && min < 0}
						customInput={TextField}
						isAllowed={isInInterval}
						label={t('estate_list.filters.to')}
						onValueChange={(values) => setTo(values.value)}
						size="small"
						thousandSeparator=" "
						value={to}
						variant="outlined"
						InputProps={{
							inputProps: {
								inputMode: 'numeric',
							},
						}}
					/>
				</div>
				{props.children}
			</div>
		</OverlayFilter>
	);
};
