import {
	Fade,
	makeStyles,
	Modal as MaterialModal,
	Paper,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalBody: {
		outline: 'none',
		padding: theme.spacing(2),
		maxWidth: '95vw',
		position: 'relative',
		overflowY: 'auto',
		maxHeight: '75vh',
	},
	close: {
		cursor: 'pointer',
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
}));

export const Modal = (props: {
	children: any;
	open?: boolean;
	onClose?: () => any;
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(!!props.open);

	useEffect(() => {
		setOpen(!!props.open);
	}, [props.open]);

	const handleClose = () => {
		if (props.open === undefined) {
			setOpen(false);
		}

		if (props.onClose) {
			props.onClose();
		}
	};

	return (
		<MaterialModal
			open={open}
			onClose={handleClose}
			className={classes.modal}
			style={{ zIndex: 99999999 }}
		>
			<Fade in={open}>
				<Paper className={classes.modalBody}>
					<Close className={classes.close} onClick={handleClose} />

					{props.children}
				</Paper>
			</Fade>
		</MaterialModal>
	);
};
