import { useCallback, useContext, useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { emptyToast, ToastContext } from '../context/toast';
import { useResponsiveSize } from '../hooks/responsive';

export const Toast = () => {
	const toast = useContext(ToastContext);
	const { sm: isMobile } = useResponsiveSize();
	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		setOpen(!!toast.message);
	}, [toast]);

	const close = useCallback(() => {
		setOpen(false);

		toast.setToastMessage(emptyToast);
	}, [toast]);

	return (
		<Snackbar
			open={open}
			autoHideDuration={toast.timeout || 3000}
			TransitionProps={{
				style: isMobile
					? {
							width: '100%',
							marginTop: 64,
							fontSize: 18,
					  }
					: {
							marginTop: 64,
					  },
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			onClose={close}
			onClick={close}
			ClickAwayListenerProps={{
				onClickAway: close,
			}}
		>
			<Alert severity={toast.severity}>{toast.message}</Alert>
		</Snackbar>
	);
};
