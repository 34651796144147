import { useState } from 'react';
import {
	TextField,
	MenuItem,
	makeStyles,
	TextFieldProps,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	fixedWidth: {
		width: 150,
	},
}));

type SelectProps = Omit<TextFieldProps, 'onChange'> & {
	options: Record<string, string>;
	onChange?: (value: any) => any;
};

export const ControlledSelect = (props: SelectProps & { value?: any }) => {
	const classes = useStyles();
	const {
		options,
		onChange,
		defaultValue,
		value,
		className = '',
		size = 'small',
		...rest
	} = props;

	return (
		<TextField
			select
			variant="outlined"
			onChange={(event) => onChange && onChange(event.target.value)}
			value={value || defaultValue}
			className={[
				className,
				props.fullWidth ? '' : classes.fixedWidth,
			].join(' ')}
			size={size}
			{...rest}
		>
			{Object.keys(options).map((key) => (
				<MenuItem key={key} value={key}>
					{options[key]}
				</MenuItem>
			))}
		</TextField>
	);
};

export const Select = (props: SelectProps) => {
	const { onChange, defaultValue } = props;
	const [value, setValue] = useState<any>(defaultValue);

	const onChangeLocal = (val: string) => {
		setValue(val);

		if (onChange) onChange(val);
	};

	return (
		<ControlledSelect {...props} value={value} onChange={onChangeLocal} />
	);
};
