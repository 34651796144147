import React from 'react';

interface Props {
	providers: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
	children: React.ReactNode;
}

export default function ComposeProviders(props: Props) {
	const { providers = [], children } = props;

	return (
		<>
			{providers.reduceRight((acc, Comp) => {
				return <Comp>{acc}</Comp>;
			}, children)}
		</>
	);
}
