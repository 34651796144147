import { GeoPoint, Timestamps } from '..';

export interface OwnerNote {
    _id: string;
    author: string;
    text: string;
    createdAt: string;
    updatedAt: string;
}

export enum AuctionOwnerQuality {
    GOOD = 'GOOD',
    AVERAGE = 'AVERAGE',
    POOR = 'POOR',
}

export interface AuctionOwner {
    name: string;
    quality?: AuctionOwnerQuality;
    user?: string;
    nationalRegistryName?: string;
    phone?: string;
    email?: string;
    address?: string;
    location?: GeoPoint;
    notes?: Array<OwnerNote>;
}

export interface AuctionOwnerDB extends AuctionOwner, Timestamps {
    _id: string;
}

export type AuctionOwnerResponse = {
    getOne: AuctionOwnerDB;
    getList: Array<AuctionOwnerDB>;
    create: AuctionOwnerDB;
    patch: AuctionOwnerDB;
};

export const decreaseOwnerQuality = (quality: AuctionOwnerQuality) => {
    if (quality === AuctionOwnerQuality.GOOD)
        return AuctionOwnerQuality.AVERAGE;

    return AuctionOwnerQuality.POOR;
};

export const increaseOwnerQuality = (quality: AuctionOwnerQuality) => {
    if (quality === AuctionOwnerQuality.POOR)
        return AuctionOwnerQuality.AVERAGE;

    return AuctionOwnerQuality.GOOD;
};
