import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const ro = require('./ro.json');

const resources = {
	ro: { translation: ro },
};

i18n.use(initReactI18next)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'ro',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
