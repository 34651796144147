import { makeStyles, Typography } from '@material-ui/core';
import { EstateSafeDB } from 'foreclosure-types';
import { Advert } from '../../components/Advert';
import { usePropertyTypeFromUrl } from './hooks';
import { GoogleAdsFluid } from '../../components/GoogleAds';

const useStyles = makeStyles((theme) => ({
	top: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(2),
		},
	},
}));

const getEstateId = (params: { _id: string }) => {
	return `estate${params._id}`;
};

interface Props<T> {
	list: T[];
	onHighlighted: (selected?: T) => void;
	highlighted?: T;
	filters?: React.ReactNode;
}

export const EstateGrid = <T extends EstateSafeDB>(props: Props<T>) => {
	const classes = useStyles({});
	const { list, highlighted, onHighlighted, filters } = props;
	const params = usePropertyTypeFromUrl();

	const getEstateContent = (estate: T) => {
		return (
			<Advert
				id={getEstateId(estate)}
				onMouseEnter={() => onHighlighted(estate)}
				key={estate._id}
				content={estate}
				highlighted={
					highlighted ? highlighted._id === estate._id : undefined
				}
			/>
		);
	};

	return (
		<>
			<div className={classes.top}>
				<Typography variant="h4" component="h1">
					{params.translation}
				</Typography>
			</div>

			{filters}

			<div>
				{list.filter((list) => list.promoted).map(getEstateContent)}
				<GoogleAdsFluid />
				{list.filter((list) => !list.promoted).map(getEstateContent)}
			</div>
		</>
	);
};
