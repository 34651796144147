import { CfCoordinates } from 'foreclosure-types';
import { useEffect, useState } from 'react';
import { getCoordinatesById } from '../api/coordinates';

export const useCfLocation = (id?: string | null) => {
	const [coordinates, setCoordinates] = useState({} as CfCoordinates);

	useEffect(() => {
		if (!id) return;

		(async () => {
			const result = await getCoordinatesById(id);

			setCoordinates(result);
		})();
	}, [id]);

	return { coordinates };
};
