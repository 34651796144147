import * as yup from 'yup';

export const useYupValidationResolver =
	(validationSchema: yup.ObjectSchema) => async (data: any) => {
		try {
			const values = await validationSchema.validate(data, {
				abortEarly: false,
			});

			return {
				values,
				errors: {},
			};
		} catch (errors) {
			return {
				values: {},
				// @ts-ignore
				errors: errors.inner.reduce(
					// @ts-ignore
					(allErrors, currentError) => ({
						...allErrors,
						[currentError.path]: {
							type: currentError.type ?? 'validation',
							message: currentError.message,
						},
					}),
					{}
				),
			};
		}
	};
