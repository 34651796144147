import { useContext } from 'react';
import { Button } from '@material-ui/core';
import { Facebook } from '@material-ui/icons';
import { FacebookContext } from '../context/Facebook';
import { useTranslation } from 'react-i18next';
import { fbLogin } from '../api/auth';

type Props = {
	onSuccess: (token: string) => void;
	onFail: (message: string) => void;
};

export const FacebookLogin = (props: Props) => {
	const { t } = useTranslation();
	const { login } = useContext(FacebookContext);

	const handleLogin = async () => {
		try {
			const { authResponse } = await login();

			const token = await fbLogin(
				authResponse.accessToken,
				authResponse.userID
			);

			props.onSuccess(token);
		} catch (err) {
			console.error('Facebook login error', err);

			props.onFail('Facebook login failed');
		}
	};

	return (
		<Button
			onClick={handleLogin}
			startIcon={<Facebook />}
			color="primary"
			fullWidth
		>
			{t('login.facebook')}
		</Button>
	);
};
