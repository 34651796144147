import axios from 'axios';
import { TaskResponse, Task } from 'foreclosure-types';
import { baseUrl } from './config';

export const getAll = async (): Promise<TaskResponse['getList']> => {
	const response = await axios.get(`${baseUrl}/task`);

	return response.data;
};

export const getEstateTasks = async (
	estateId: string
): Promise<TaskResponse['getList']> => {
	const response = await axios.get(`${baseUrl}/task/estate/${estateId}`);

	return response.data;
};

export const patchOne = async (taskId: string, body: Pick<Task, 'status'>) => {
	const response = await axios.patch(`${baseUrl}/task/${taskId}`, body);

	return response.data as TaskResponse['getOne'];
};
