import { Color } from '@material-ui/lab';
import React, { useContext } from 'react';

export type ToastMessage = {
	message: string;
	timeout?: number;
	severity: Color;
	setToastMessage: (params: Omit<ToastMessage, 'setToastMessage'>) => void;
};

export const emptyToast: Readonly<ToastMessage> = {
	message: '',
	severity: 'info',
	timeout: 0,
	setToastMessage: () => {},
};

export const ToastContext = React.createContext(emptyToast);

export const useNotifications = () => {
	return useContext(ToastContext);
};
