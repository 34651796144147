import axios from 'axios';
import { NotificationResponse, NotificationStatus } from 'foreclosure-types';
import { baseUrl } from './config';

const apiUrl = baseUrl + '/notification';

export const getUserNotifications = async () => {
	const response = await axios.get(apiUrl);

	return response.data as NotificationResponse['userNotifications'];
};

export const markAsRead = async (id: string) => {
	const response = await axios.put(`${apiUrl}/${id}`, {
		status: NotificationStatus.SEEN,
	});

	return response.data as NotificationResponse['updateOne'];
};
