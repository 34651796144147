import { SubscriptionTypes, User } from 'foreclosure-types';
import axios from './cache';
import { baseUrl } from './config';

export const createSubscription = async (
	type: SubscriptionTypes,
	billing?: Required<User>['billing']
) => {
	const response = await axios.post(
		`${baseUrl}/payment/subscription/${type}`,
		{ billing }
	);

	return response.data as { url: string };
};

export const cancelSubscription = async (reason: string) => {
	await axios.post(`${baseUrl}/payment/cancel`, { reason });
};
