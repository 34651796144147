import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../context/toast';
import { EventEmitter } from '../helpers/eventEmitter';

export const NOTIFICATIONS_CHANNEL = 'notifications';
export const globalNotificationsBroadcaster = new EventEmitter();

export const sendGlobalNotification = (data: string) => {
	globalNotificationsBroadcaster.emit(NOTIFICATIONS_CHANNEL, data, true);
};

export const useServiceWorkerNotifications = () => {
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();

	useEffect(() => {
		globalNotificationsBroadcaster.on(NOTIFICATIONS_CHANNEL, (data) => {
			if (typeof data === 'string') {
				setToastMessage({
					severity: 'info',
					message: t(data),
				});
			} else {
				console.log('Unknown notification', data);
			}
		});
	}, [setToastMessage, t]);

	return null;
};
