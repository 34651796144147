import React, { useCallback, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const TermsDialog = () => {
	const { t } = useTranslation();
	const termsAccepted = localStorage.getItem('locateCfTermsAccepted');
	const [open, setOpen] = useState<boolean>(!!termsAccepted);
	const handleClose = useCallback(() => {
		setOpen(false);
		localStorage.setItem('locateCfTermsAccepted', 'yes');
	}, []);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{t('cfLocalization.terms.title')}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('cfLocalization.terms.text')
						.split('\n')
						.map((text) => (
							<Typography key={text} component="p">
								{text}
							</Typography>
						))}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary">
					{t('cfLocalization.terms.decline')}
				</Button>
				<Button onClick={handleClose} color="primary" autoFocus>
					{t('cfLocalization.terms.accept')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
