import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AuctionOwnerDB } from 'foreclosure-types';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { useAuthRole } from '../../../context/Auth';
import {
	useAuctionOwnerList,
	useUserAddressList,
} from '../../../hooks/auctionOwner';
import { useFormError } from './helper';
import { CreateUserAddress } from './CreateUserAddress';
import { useMobileSize } from '../../../hooks/responsive';
import { Link, useNavigate } from 'react-router-dom';
import { AddCircle } from '@material-ui/icons';

type Props = {
	addresses: AuctionOwnerDB[];
	onEdit?: (address: AuctionOwnerDB) => void;
	onNew?: (address: string) => void;
	disableCreate?: boolean;
};

const useAddressFromAuctionOwner = (owners: AuctionOwnerDB[]) => {
	const { watch, setValue } = useFormContext();
	const auctionOwnerId = watch('auctionOwnerId');

	const owner = owners.find((o) => o._id === auctionOwnerId);

	useEffect(() => {
		setValue('auctionAddress', owner?.address);
		setValue('auctionPhone', owner?.phone);
		setValue('auctionEmail', owner?.email);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [owner?._id, setValue]);
};

const useAddressJoin = (list1: AuctionOwnerDB[], list2: AuctionOwnerDB[]) => {
	return useMemo(() => {
		const list2Filtered = list2.filter(
			(el) => !list1.find((e) => e._id === el._id)
		);

		return [...list1, ...list2Filtered];
	}, [list1, list2]);
};

const SelectAuctionOwner = (props: Props) => {
	const { addresses } = props;
	const owners = useAuctionOwnerList();
	const list = useAddressJoin(addresses, owners);

	return (
		<AuctionOwnerAutocomplete
			{...props}
			owners={list}
			isUserAddress={false}
		/>
	);
};

const SelectUserAddress = (props: Props) => {
	const { addresses } = props;
	const userAddresses = useUserAddressList();
	const list = useAddressJoin(addresses, userAddresses);

	return <AuctionOwnerAutocomplete {...props} owners={list} isUserAddress />;
};

const getAddressLabel = (option: AuctionOwnerDB) => {
	if (option._id === 'none' || option._id === 'new') return option.name;

	const address = option.name === option.address ? '' : option.address;

	return [option.name, option.phone, option.email, address]
		.filter(Boolean)
		.join('; ');
};

const useDefaultOwner = (isUserAddress: boolean, listLenth: number) => {
	const defaultOwner: AuctionOwnerDB = useMemo(
		() => ({
			_id: 'none',
			name:
				listLenth === 0
					? 'Nu ai adrese salvate, adaugă una nouă'
					: isUserAddress
					? 'Selectează o adresă din istoric'
					: 'Selecteaza un organizator',
			createdAt: '',
			updatedAt: '',
		}),
		[isUserAddress, listLenth]
	);

	return defaultOwner;
};

const filter = createFilterOptions<AuctionOwnerDB>();

const AuctionOwnerAutocomplete = (props: {
	owners: AuctionOwnerDB[];
	isUserAddress: boolean;
	onEdit?: (address: AuctionOwnerDB) => void;
	onNew?: (address: string) => void;
	disableCreate?: boolean;
}) => {
	const {
		owners,
		isUserAddress,
		onNew,
		onEdit,
		disableCreate = false,
	} = props;
	const { getFormError } = useFormError();
	const { isMobile } = useMobileSize();
	const defaultOwner = useDefaultOwner(isUserAddress, owners.length);

	const options = useMemo(() => {
		if (!owners.length) return [defaultOwner];

		return [defaultOwner, ...owners];
	}, [owners, defaultOwner]);

	useAddressFromAuctionOwner(owners);

	const renderEditOption = (option: AuctionOwnerDB) => {
		if (['none', 'new'].includes(option._id)) {
			return option.name;
		}

		return (
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				width="100%"
			>
				<span>{getAddressLabel(option)}</span>
				<Button
					variant="text"
					color="primary"
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();

						if (onEdit) onEdit(option);
					}}
				>
					Editează
				</Button>
			</Box>
		);
	};

	return (
		<Controller
			name="auctionOwnerId"
			defaultValue={defaultOwner._id}
			render={({ value, onBlur, onChange }) => {
				const address = owners.find((el) => el._id === value);

				return (
					<Autocomplete
						openOnFocus
						clearOnBlur
						handleHomeEndKeys
						fullWidth={isMobile}
						value={address || defaultOwner}
						options={options}
						getOptionLabel={(option) => getAddressLabel(option)}
						onBlur={onBlur}
						onOpen={() => {
							if (!disableCreate) return;

							if (owners.length === 0) {
								onNew?.('');
							}
						}}
						getOptionSelected={(option, value) =>
							option._id === value._id
						}
						onChange={(_event, owner) => {
							if (owner?._id === 'new') {
								if (onNew && owner.address)
									onNew(owner.address);
							} else {
								onChange(owner?._id);
							}
						}}
						noOptionsText="Nu am găsit adresa în istoric. Adaugă o nouă adresă prin butonul de mai sus."
						renderOption={renderEditOption}
						filterOptions={
							disableCreate
								? undefined
								: (options, params) => {
										const filtered = filter(
											options,
											params
										);

										// Suggest the creation of a new value
										if (params.inputValue !== '') {
											filtered.push({
												...defaultOwner,
												_id: 'new',
												address: params.inputValue,
												name: `Crează o nouă adresă: "${params.inputValue}"`,
											});
										}

										return filtered;
								  }
						}
						renderInput={(params) => (
							<TextField
								{...params}
								InputProps={{
									...params.InputProps,
									style: {
										paddingRight: 45,
									},
								}}
								error={!!getFormError('auctionOwnerId')}
								helperText={getFormError('auctionOwnerId')}
								variant="outlined"
								label={
									isUserAddress
										? 'Adresă licitație'
										: 'Organizator licitatie'
								}
							/>
						)}
						style={{ flexGrow: 1 }}
					/>
				);
			}}
		/>
	);
};

const AuctionAddressAutocomplete = (props: Props) => {
	const { isAdmin, isAgent } = useAuthRole();

	if (isAdmin || isAgent) return <SelectAuctionOwner {...props} />;

	return <SelectUserAddress {...props} />;
};

const auctionAddressStyles = makeStyles(() => ({
	addAddressContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export const AuctionAddress = () => {
	const classes = auctionAddressStyles();
	const [open, setOpen] = useState(false);
	const [addresses, setAddresses] = useState<AuctionOwnerDB[]>([]);
	const [editAddress, setEditAddress] = useState<AuctionOwnerDB | null>(null);
	const userRole = useAuthRole();
	const navigate = useNavigate();

	const isAdmin = userRole.isAdmin || userRole.isAgent;

	useEffect(() => {
		if (!open) setEditAddress(null);
	}, [open]);

	return (
		<>
			{isAdmin ? (
				<Link
					to="/admin/auction-owner/new"
					className={classes.addAddressContainer}
				>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<AddCircle />}
					>
						Adaugă organizator
					</Button>
				</Link>
			) : (
				<div className={classes.addAddressContainer}>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<AddCircle />}
						onClick={() => setOpen(true)}
					>
						Adaugă adresă
					</Button>
				</div>
			)}

			<AuctionAddressAutocomplete
				addresses={addresses}
				disableCreate={isAdmin}
				onNew={useCallback(() => {
					setOpen(true);
				}, [])}
				onEdit={useCallback(
					(address: AuctionOwnerDB) => {
						const text =
							'Vrei să editezi acest organizator? Vei pierde toate modificările nesalvate din formularul curent.';

						if (isAdmin) {
							if (window.confirm(text)) {
								navigate(`/admin/auction-owner/${address._id}`);
							}

							return;
						}

						setEditAddress(address);
						setOpen(true);
					},
					[isAdmin, navigate]
				)}
			/>

			{open && (
				<CreateUserAddress
					address={editAddress || undefined}
					onClose={() => setOpen(false)}
					onSuccess={(address) => {
						setAddresses((prev) => {
							const existing = prev.find(
								(el) => el._id === address._id
							);

							if (existing) {
								Object.assign(existing, address);

								return [...prev];
							}

							return [address, ...prev];
						});

						setOpen(false);
					}}
				/>
			)}
		</>
	);
};
