import { makeStyles } from '@material-ui/core';
import { SubType } from 'foreclosure-types';
import { GalleryResponsive } from '../../components/GalleryResponsive';
import { defaultPictures } from '../../helpers/defaults';

const useStyles = makeStyles((theme) => ({
	gallery: {
		paddingTop: theme.spacing(2),
		flexWrap: 'nowrap',
	},
	defaultImage: {
		display: 'inline-block',
		width: 180,
		height: 180,
		backgroundColor: theme.palette.grey[200],
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
}));

type Props = {
	pictures?: string[];
	type: SubType;
};

export const PropertyDetailsGallery = ({ pictures, type }: Props) => {
	const classes = useStyles();

	if (pictures?.length) {
		return <GalleryResponsive images={pictures} />;
	}

	return (
		<div
			className={classes.defaultImage}
			style={{
				backgroundImage: `url(${defaultPictures[type ?? 'HOUSE']})`,
			}}
		></div>
	);
};
