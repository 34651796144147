import { EstateSafeDB } from 'foreclosure-types';
import { Advert } from '../components/Advert';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { homepageLogo } from '../assets';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStatesList } from '../helpers/statesList';
import { capitalize } from 'lodash';
import {
	HowItWorksCarousel,
	HowItWorksSubtitle,
} from '../components/HowItWorks';
import { promotedAuctionsFirst } from '../helpers/estate';
import { Alert } from '@material-ui/lab';
import { ReactNode } from 'react';
import { CheckCircle } from '@material-ui/icons';

// https://developers.google.com/gmail/design/reference/supported_css
// Gmail doesn't support any flex property!!
const useStyles = makeStyles((theme) => ({
	header: {
		paddingTop: theme.spacing(2),
		display: 'block',
		textAlign: 'center',
	},
	image: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: theme.spacing(2),
		width: 350,
		display: 'block',
	},
	title: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(2),
	},
	howItWorks: {
		marginTop: theme.spacing(3),
		textAlign: 'center',
		'&& img': {
			height: 200,
		},
	},
	moreDetails: {
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(4),
	},
	moreEstates: {
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},
	trimmedDisclaimer: {
		marginTop: theme.spacing(9),
		textAlign: 'center',
		'& button': {
			marginTop: theme.spacing(2),
		},
	},
}));

type AdvertsByState = {
	[county: string]: EstateSafeDB[];
};

type Props = {
	list: AdvertsByState;
};

const useCountyName = () => {
	const statesList = useStatesList();

	return (county: string) => {
		const names = Object.keys(statesList);

		const regex = new RegExp(`_${county}$`, 'i');
		const countyName = names.find((name) => regex.test(name));

		if (countyName) {
			const [longName] = countyName.split('_');

			return capitalize(longName);
		}

		return county;
	};
};

const EmailAdvertList = (props: { list: AdvertsByState }) => {
	const { t } = useTranslation();
	const { list = {} } = props;
	const classes = useStyles();
	const getCountyName = useCountyName();

	const result = Object.entries(list).map(([county, list]) => {
		const countyName = getCountyName(county);

		return (
			<div key={county}>
				<Typography variant="h5" className={classes.title}>
					{t('email.nextAuctions')} {countyName}
				</Typography>

				{promotedAuctionsFirst(list).map((estate) => (
					<Advert
						key={estate._id}
						content={estate}
						highlighted
						forceMobile
					/>
				))}
			</div>
		);
	});

	return <>{result}</>;
};

const ListRow = (props: { children: ReactNode }) => {
	return (
		<Box marginBottom={1}>
			<CheckCircle
				color="primary"
				style={{ top: 6, position: 'relative' }}
			/>{' '}
			<Typography component="span">{props.children}</Typography>
		</Box>
	);
};

const SubscriptionContent = () => {
	return (
		<Box marginTop={5}>
			<Box marginBottom={2}>
				<Typography variant="h5">
					Ești executor judecatoresc, practician în insolvenţă sau ai
					o agenție imobiliară?
				</Typography>
			</Box>
			<Typography>
				Îți punem la dispoziție o platformă de promovare a licitațiilor
				(complet gratuit) sau propriul site de licitații personalizat -
				scrie-ne direct la vanzari@licitatiibanci.ro !
			</Typography>

			<Box marginBottom={2} marginTop={2}>
				<Typography variant="h5">Ești investitor?</Typography>
			</Box>
			<Typography>
				Cauți mai multe informații pentru licitațiile din executări sau
				insolvențe? Ai văzut ceva interesant, dar nu știi care este
				riscul? Sau pur și simplu nu poți găsi terenul pe hartă?
			</Typography>
			<br />
			<Alert severity="success">
				Maximizați-vă investiția cu instrumentele noastre profesionale!
			</Alert>
			<br />
			<img
				width="100%"
				src="https://www.licitatiibanci.ro/locate-cf.png"
				alt="Localizare CF pe Google Maps"
			/>
			<ListRow>
				- Descoperă potențialul următoarei achiziții de terenuri prin
				informații precise, contur, arie, poziție pe Google Maps în 2
				click-uri dupa numărul CF! Fără extras CF! Instant!
			</ListRow>
			<ListRow>
				- Protejează-ți investiția cu o privire de ansamblu asupra
				istoricului juridic al licitațiilor - cu cât mai mic istoricul,
				cu atât mai puține riscuri!
			</ListRow>
			<ListRow>
				- Vezi la ce prețuri a fost scos la vânzare imobilul în trecut
				pentru a analiza trendul!
			</ListRow>
			<ListRow>
				- Fii primul care află de cele mai bune licitații prin
				notificări zilnice din județul tău. Cu afli mai repede, cu atât
				mai mult timp ai să te decizi!
			</ListRow>
			<Box marginBottom={3}>
				<Alert severity="success">
					Câștigi un abonament Ageție pentru 12 luni dacă ne trimiți
					dovada câștigării unei licitații <strong>promovate</strong>{' '}
					la adresa vanzari@licitatiibanci.ro.
					<br />
					Valoarea abonamentului este de 2 388 RON, iar tu îl primești
					gratuit!
				</Alert>
			</Box>
			<Box textAlign="center" marginTop={3}>
				<Link to="/subscription" target="_blank">
					<Button variant="contained" color="primary">
						Abonează-te acum
					</Button>
				</Link>
			</Box>
		</Box>
	);
};

export const NextAuctionsEmail = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Link to="/" className={classes.header}>
				<div>
					<img
						className={classes.image}
						src={homepageLogo}
						alt="Landing"
						title="Landing"
						width={350}
						height={230}
					/>
				</div>
				<Typography component="div" variant="h4" color="primary" noWrap>
					{t('app.title')}
				</Typography>
			</Link>

			<SubscriptionContent />

			<EmailAdvertList list={props.list} />

			<div className={classes.howItWorks}>
				<HowItWorksSubtitle />

				<HowItWorksCarousel />

				<Typography variant="body1" className={classes.moreDetails}>
					{t('email.moreDetails')}
				</Typography>
				<Link to="/how-it-works" target="_blank">
					<Button variant="contained" color="primary">
						{t('email.buttonDetails')}
					</Button>
				</Link>
			</div>
		</>
	);
};
