import { Box, Container, LinearProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { unsubscribe } from '../api/mail';
import { MainTemplate } from '../components/Templates/Main';

export const Unsubscribe = () => {
	const { t } = useTranslation();
	const { type, id } = useParams<{ type: string; id: string }>();
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		if (!id) return;

		(async () => {
			if (type === 'sub' && id.length === 24) {
				await unsubscribe(id);

				setSuccess(true);
			}
		})();
	}, [type, id]);

	return (
		<MainTemplate>
			{!success && <LinearProgress />}
			<Container>
				<Box marginTop={4}>
					<Typography align="center" variant="h5">
						{success
							? t('email.unsubscribeSuccess')
							: t('email.unsubscribeLoading')}
					</Typography>
				</Box>
			</Container>
		</MainTemplate>
	);
};
