import { useNotifications } from '../context/toast';
import { getErrorFromResponse } from '../helpers/form';

export const useApiWithNotifications = <T extends Array<any>, U>(
	fn: (...args: T) => U,
	finallyFn?: () => any
) => {
	const { setToastMessage } = useNotifications();

	return async (...args: T) => {
		try {
			const result = await fn(...args);

			setToastMessage({
				message: 'Succes',
				severity: 'success',
			});

			return result;
		} catch (err: any) {
			setToastMessage({
				message: getErrorFromResponse(err) || 'Eroare server',
				severity: 'error',
			});

			finallyFn && finallyFn();
		}
	};
};
