import NumberFormat from 'react-number-format';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Currency, EstateDB, EstateSafeDB } from 'foreclosure-types';
import { sendBuyOffer } from '../../../api/estate';
import {
	emailPattern,
	numberPattern,
	useErrorMessage,
} from '../../../helpers/form';
import { useNotifications } from '../../../context/toast';
import { CurrencyPicker } from '../../../components/CurrencyPicker';
import { ProgressButton } from '../../../components/Form/ProgressButton';

const PriceField = () => {
	const { t } = useTranslation();
	const getErrorMessage = useErrorMessage();
	const { errors } = useFormContext();

	return (
		<Controller
			name="price"
			rules={{ required: true }}
			render={({ onBlur, onChange, value }) => (
				<NumberFormat
					allowNegative={false}
					thousandSeparator=" "
					decimalSeparator=","
					decimalScale={2}
					customInput={TextField}
					onBlur={onBlur}
					value={value}
					onValueChange={(values) => {
						onChange(values.floatValue);
					}}
					fullWidth
					variant="outlined"
					error={!!errors.price}
					helperText={getErrorMessage(errors.text)}
					label={t('estateView.buy.price')}
					name="price"
					required
					InputProps={{
						inputProps: {
							inputMode: 'decimal',
						},
					}}
				/>
			)}
		/>
	);
};

const CurrencyField = (props: { default: Currency }) => {
	return (
		<Controller
			name="currency"
			defaultValue={props.default}
			render={(props) => {
				const { ref, ...rest } = props;

				return <CurrencyPicker {...rest} />;
			}}
		/>
	);
};

const FinancingField = () => {
	return (
		<Controller
			name="financing"
			defaultValue="self"
			render={(props) => {
				const { ref, ...rest } = props;

				return (
					<FormControl component="fieldset">
						<RadioGroup row aria-label="currency" {...rest}>
							<FormControlLabel
								control={<Radio />}
								value="self"
								label="Finanțare proprie"
							/>
							<FormControlLabel
								control={<Radio />}
								value="credit"
								label="Rate / Vreau credit"
							/>
						</RadioGroup>
					</FormControl>
				);
			}}
		/>
	);
};

type Props = {
	estate: EstateDB | EstateSafeDB;
	onSuccessContact: (estate: EstateDB) => void;
	switch?: React.ReactNode;
};

export const OfferForm = (props: Props) => {
	const { estate, onSuccessContact } = props;

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();

	const validEmail = t('form.valid_email');
	const validNumber = t('form.valid_number');

	const formValues = useForm({
		mode: 'onChange',
	});
	const { register, handleSubmit, errors, formState } = formValues;
	const { isValid, isSubmitting } = formState;
	const getErrorMessage = useErrorMessage();

	const onSubmit = async (values: any) => {
		try {
			const estateResponse = await sendBuyOffer(estate._id, values);

			setToastMessage({
				severity: 'success',
				message: t('form.offer_success'),
			});

			formValues.reset({
				terms: false,
				price: '',
			});

			onSuccessContact(estateResponse);
		} catch (err) {
			console.log(err);

			setToastMessage({
				severity: 'error',
				message: t('form.error'),
			});
		}

		navigate(`/estate/${estate._id}/offer-sent`, { replace: true });
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				{props.switch && (
					<Grid item xs={12}>
						{props.switch}
					</Grid>
				)}

				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({ required: true, minLength: 3 })}
						defaultValue=""
						error={!!errors.name}
						helperText={getErrorMessage(errors.name)}
						label={t('estateView.contact.name')}
						name="name"
						required
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({
							required: true,
							validate: (val) =>
								emailPattern.test(val) ? undefined : validEmail,
						})}
						defaultValue=""
						error={!!errors.email}
						helperText={getErrorMessage(errors.email)}
						label={t('estateView.contact.email')}
						name="email"
						required
						InputProps={{
							inputProps: {
								inputMode: 'email',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<TextField
						variant="outlined"
						fullWidth
						inputRef={register({
							required: true,
							minLength: 10,
							validate: (val) =>
								numberPattern.test(val)
									? undefined
									: validNumber,
						})}
						defaultValue=""
						error={!!errors.phone}
						helperText={getErrorMessage(errors.phone)}
						label={t('estateView.contact.phone')}
						name="phone"
						required
						InputProps={{
							inputProps: {
								inputMode: 'tel',
							},
						}}
					/>
				</Grid>
				<FormProvider {...formValues}>
					<Grid item xs={12} md={8}>
						<PriceField />
					</Grid>
					<Grid item xs={12} md={4}>
						<CurrencyField default={estate.currency} />
					</Grid>
					<Grid item xs={12}>
						<FinancingField />
					</Grid>
				</FormProvider>

				<Grid item xs={12}>
					<TextField
						multiline
						fullWidth
						rows={4}
						variant="outlined"
						inputRef={register({ required: true, minLength: 10 })}
						defaultValue={t('estateView.buy.defaultText')}
						error={!!errors.text}
						helperText={getErrorMessage(errors.text)}
						label={t('estateView.contact.text')}
						name="text"
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Controller
								name="terms"
								control={formValues.control}
								rules={{
									validate: (val) => {
										const error = t(
											'form.must_accept_terms'
										);

										return val ? undefined : error;
									},
								}}
								defaultValue={false}
								render={({ onChange, value }) => (
									<Checkbox
										color="primary"
										name="terms"
										onChange={(e) => {
											onChange(e.target.checked);
										}}
										checked={value}
									/>
								)}
							/>
						}
						label={
							<div>
								{t('estateView.contact.I_understand')}{' '}
								<Link to="/terms-and-condition">
									{t('legal_menu.terms')}
								</Link>{' '}
								şi{' '}
								<Link to="/privacy">
									{t('legal_menu.privacy')}
								</Link>
							</div>
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<ProgressButton
						data-transaction-name="offer"
						type="submit"
						color="secondary"
						disabled={!isValid}
						loading={isSubmitting}
					>
						{t('estateView.auction.contact_button')}
					</ProgressButton>
				</Grid>
			</Grid>
		</form>
	);
};
