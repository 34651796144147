import { AuctionType, EstateDB, EstateSafeDB } from 'foreclosure-types';
import { baseUrl } from '../api/config';
import i18n from '../i18n';

const randomPromotedFirst = <T extends Object>(
	promoted: T[],
	notPromoted: T[]
): T[] => {
	const first2Random = promoted.sort(() => Math.random() - 0.5).slice(0, 2);
	const promotedRest = promoted.filter((e) => !first2Random.includes(e));
	const first3NotPromoted = notPromoted.slice(0, 3);
	const notPromotedRest = notPromoted.filter(
		(e) => !first3NotPromoted.includes(e)
	);

	return [
		...first2Random,
		...first3NotPromoted,
		...promotedRest,
		...notPromotedRest,
	];
};

export const promotedAuctionsFirst = <T extends EstateSafeDB | EstateDB>(
	list: Array<T>
): Array<T> => {
	if (list.length === 0) {
		return list;
	}

	const promoted = list.filter((e) => e.promoted);
	const promotedIds = promoted.map((e) => e._id);
	const notPromoted = list.filter((e) => !promotedIds.includes(e._id));

	return randomPromotedFirst(promoted, notPromoted);
};

export const translateOwner = (type: AuctionType): string => {
	if (type === 'BAILIFF') {
		return i18n.t('estateView.auction.bailiffOwned');
	}
	if (type === 'PRIVATE') {
		return i18n.t('estateView.auction.privateOwned');
	}
	if (type === 'COMPANY') {
		return i18n.t('estateView.auction.companyOwned');
	}

	return i18n.t('estateView.auction.insolvency');
};

export const getSalePublicationUrl = (docId: string) => {
	return `${baseUrl}/estate/publication/${docId}`;
};

export const getPublicationUrl = (estate: EstateDB) => {
	if (estate.auctionType === 'INSOLVENCY') {
		return estate.auctionUrl;
	}

	return getExecutoriUrl(estate.auctionUrl);
};

const getExecutoriUrl = (auctionUrl: string) => {
	const segments = auctionUrl.split('/');
	const id = segments.pop();
	const caseId = segments.pop();

	return `https://prod.executori.ro/repes/api/auctions/${caseId}/${id}/publication/`;
};
