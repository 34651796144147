import React, { FormEvent } from 'react';
import { NationalRegistry } from 'foreclosure-types';
import { Button, Box, makeStyles, Typography } from '@material-ui/core';
import { PropertyForm } from './Property';
import { AuctionForm } from './Auction';
import { useFormContext } from 'react-hook-form';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Props = {
	handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
	goBack: () => void;
	onDelete: () => void;
	propertyMap: React.ReactNode;
	auctionUrl?: string;
	providerUrl?: string;
	auctionDocument?: string;
	salePublications?: string[];
	nationalRegistry?: NationalRegistry[];
};

const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		display: 'flex',
		alignItems: 'space-between',
		'& button': {
			margin: theme.spacing(2),
			minWidth: 200,
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
	},
}));

const FormWarning = () => {
	const { t } = useTranslation();
	const { formState } = useFormContext();
	const { errors } = formState;

	if (!errors || !Object.keys(errors).length) return null;

	return <Alert severity="error">{t('estateEdit.formErrors')}</Alert>;
};

export const EstateEditForm = (props: Props) => {
	const { id } = useParams<{ id: string }>();
	const { t } = useTranslation();
	const { handleSubmit, providerUrl, nationalRegistry, salePublications } =
		props;

	const classes = useStyles({});

	return (
		<form onSubmit={handleSubmit}>
			<AuctionForm
				providerUrl={providerUrl}
				nationalRegistry={nationalRegistry}
				salePublications={salePublications}
			/>

			<PropertyForm map={props.propertyMap} />

			<Box marginTop={2}>
				<Box paddingLeft={2} paddingRight={2}>
					<FormWarning />
					<Box marginTop={2}>
						<Alert severity="info">
							<Typography>
								{t('form.required_fields_note')}
							</Typography>
						</Alert>
					</Box>
				</Box>

				<Box className={classes.buttonsContainer}>
					{id && id !== 'new' && (
						<Button onClick={props.onDelete} color="secondary">
							Sterge
						</Button>
					)}
					<Box flexGrow="1" display="flex" justifyContent="flex-end">
						<Button color="default" onClick={props.goBack}>
							Mergi inapoi
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
						>
							Salveaza
						</Button>
					</Box>
				</Box>
			</Box>
		</form>
	);
};
