import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles, Box, Hidden } from '@material-ui/core';
import { homepageLogo } from '../../assets';
import { Search } from '../../components/LocationSearch';
import { useEffect, useState } from 'react';
import { getCount } from '../../api/estate';
import { EstateResponse } from 'foreclosure-types';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
		},
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	titleColorSection: {
		color: theme.palette.primary.main,
	},
	subtitleColor: { color: theme.palette.text.secondary },
	image: {
		width: '100%',
		marginBottom: theme.spacing(6),
		[theme.breakpoints.up('md')]: {
			maxWidth: 1200,
			width: '55vw',
			margin: 0,
			marginLeft: theme.spacing(5),
		},
	},
	searchContainer: {
		marginTop: theme.spacing(5),
	},
}));

const useEstateCount = () => {
	const [count, setCount] = useState<EstateResponse['count']>({
		active: 10,
		total: 100,
	});

	useEffect(() => {
		(async () => {
			const response = await getCount();

			setCount(response);
		})();
	}, []);

	return count;
};

export const HomepageIntroduction = () => {
	const classes = useStyles({});
	const { t } = useTranslation();
	const count = useEstateCount();

	const landingImage = (
		<Grid item xs={12} md={6}>
			<img className={classes.image} src={homepageLogo} alt="Landing" />
		</Grid>
	);

	return (
		<Grid container className={classes.container}>
			{/* @ts-ignore */}
			<Hidden mdUp>{landingImage}</Hidden>
			<Grid
				container
				direction="column"
				justifyContent="center"
				item
				xs={12}
				md={6}
			>
				<Box maxWidth="100%">
					<Typography variant="h3" className={classes.title}>
						{t('homepage.title')}
						<span className={classes.titleColorSection}>
							{t('homepage.title_blue_section')}
						</span>
					</Typography>

					<Box marginTop={2}>
						<Typography
							variant="body2"
							className={classes.subtitleColor}
						>
							{t('homepage.subtitle')}
						</Typography>
					</Box>

					<div className={classes.searchContainer}>
						<Search />
						<Box marginTop={1}>
							<Typography variant="subtitle2">
								{t('homepage.auctions', {
									active: count.active,
									total: count.total,
								})}
							</Typography>
						</Box>
					</div>
				</Box>
			</Grid>
			{/* @ts-ignore */}
			<Hidden smDown>{landingImage}</Hidden>
		</Grid>
	);
};
