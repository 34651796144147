import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { EstateDB, EstateSafeDB, isEstateDB } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import { ContactForm } from './ContactForm';
import { OfferForm } from './OfferForm';
import { useMobileSize } from '../../../hooks/responsive';
import { Email, WhatsApp } from '@material-ui/icons';
import { useAuthRole } from '../../../context/Auth';

type Props = {
	estate: EstateDB | EstateSafeDB;
	onSuccessContact: (estate: EstateDB) => void;
};

const isOfferUrl = () => {
	return (window.location.hash || '').includes('offer');
};

const ContactDisclaimer = () => {
	const { t } = useTranslation();
	const { isMobile } = useMobileSize();

	const text = t('estateView.contact.disclaimer', {
		whatsAppIcon: '__icon__',
		emailIcon: '__icon__',
	});

	const parts = text.split('__icon__');

	return (
		<Box
			marginTop={1}
			display={isMobile ? 'block' : 'flex'}
			alignItems="center"
			component={Typography}
		>
			{parts[0]} <WhatsApp style={{ marginLeft: 8 }} />
			{parts[1]} <Email style={{ marginLeft: 8 }} /> {parts[2]}
		</Box>
	);
};

export const AuctionForm = (props: Props) => {
	const { isMobile } = useMobileSize();
	const { isOperator } = useAuthRole();
	const { estate } = props;
	const { t } = useTranslation();
	const [displayOfferForm, setDisplayOfferForm] = useState(
		!isOperator && (isEstateDB(estate) || isOfferUrl())
	);

	useEffect(() => {
		if (displayOfferForm && isMobile) {
			setTimeout(() => {
				document.querySelector('#offer')?.scrollIntoView();
			}, 300);
		}
	}, [displayOfferForm, isMobile]);

	useEffect(() => {
		if (isEstateDB(estate)) {
			setDisplayOfferForm(true);
		}
	}, [estate]);

	const formSwitch = (
		<FormControlLabel
			control={
				<Checkbox
					color="primary"
					name="terms"
					onChange={(event) => {
						setDisplayOfferForm(event.target.checked);
					}}
					checked={displayOfferForm}
				/>
			}
			label={<div>{t('estateView.contact.switch_to_offer')}</div>}
		/>
	);

	if (displayOfferForm) {
		return (
			<>
				{!isOperator && (
					<>
						<Typography variant="h5" id="offer">
							{t('estateView.buy.title')}
						</Typography>
						<Box marginTop={1}>
							<Typography>
								{t('estateView.buy.disclaimer')}
							</Typography>
						</Box>
						<Box marginTop={1}>
							<Typography>
								{t('estateView.contact.disclaimer')}
							</Typography>
						</Box>
					</>
				)}
				<Box marginTop={2}>
					<OfferForm
						estate={estate}
						onSuccessContact={props.onSuccessContact}
						switch={formSwitch}
					/>
				</Box>
			</>
		);
	}

	return (
		<>
			{!isOperator && (
				<>
					<Typography variant="h5">
						{t('estateView.auction.contactDetails')}
					</Typography>
					<ContactDisclaimer />
				</>
			)}
			<Box marginTop={2}>
				<ContactForm
					estateId={estate._id}
					onSuccessContact={props.onSuccessContact}
					switch={formSwitch}
				/>
			</Box>
		</>
	);
};
