import { Currency } from './Estate';

export interface Contact {
    name: string;
    email: string;
    phone: string;
    text: string;
    guestUuid: string;
    estate: string;
    user?: string;
    wa_id?: string;
    subscribed: boolean;
    auctionsWon?: string[];
}

export interface BuyOffer extends Contact {
    price: number;
    currency: Currency;
    financing: 'self' | 'credit';
}

export type AnyContact = Contact | BuyOffer;

export const isBuyOffer = (contact: AnyContact): contact is BuyOffer =>
    'price' in contact && !!contact.price;

type TimeStamps = {
    createdAt: string;
    updatedAt: string;
};

export type LeadWithTimeStamps = (Contact | BuyOffer) &
    TimeStamps & {
        _id: string;
    };

export type ContactResponse = {
    estateLeads: Array<LeadWithTimeStamps>;
    getList: Array<LeadWithTimeStamps>;
};
