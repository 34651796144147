import { useContext } from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { FiltersContext } from '../../../../context/Filters';
import { InnerFiltersProps } from '../types';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../context/Auth';
import { SubscriptionTypes } from 'foreclosure-types';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingLeft: theme.spacing(2),
	},
}));

export const StateFilter = (props: InnerFiltersProps) => {
	const classes = useStyles();
	const { filters } = useContext(FiltersContext);
	const { t } = useTranslation();
	const { user } = useAuth();
	const subscribed =
		user.subscription && user.subscription !== SubscriptionTypes.FREE;

	const checked = filters.state === 'ALL';

	return (
		<FormControlLabel
			className={classes.container}
			control={
				<Checkbox
					color="primary"
					checked={checked}
					onChange={(_e, checked) => {
						if (!subscribed) {
							alert(t('estate_list.filters.pro_required'));
							return;
						}

						const val = checked ? 'ALL' : 'AVAILABLE';

						props.handleChange('state')(val);
					}}
				/>
			}
			label={t('estate_list.filters.past_due')}
		/>
	);
};
