import { CSSProperties } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { EstateSafe } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import { firstStep, fourthStep, secondStep, thirdStep } from '../assets';
import { displayEstateDate } from '../helpers/display';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(15),
		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(7),
		},
	},
	image: {
		maxHeight: 450,
		maxWidth: '90%',
		marginBottom: -theme.spacing(3),
		marginTop: -theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			maxHeight: 300,
		},
	},
	steps: {
		textAlign: 'center',
	},
	step: {
		display: 'inline-block',
		width: '25%',
		padding: theme.spacing(2),
		verticalAlign: 'top',
		[theme.breakpoints.down('sm')]: {
			width: '50%',
		},
	},
}));

const Step = (props: {
	imageSrc: string;
	title: string;
	subtitle?: string;
}) => {
	const classes = useStyles();
	const { imageSrc, title, subtitle } = props;

	return (
		<div className={classes.step}>
			<img
				className={classes.image}
				src={imageSrc}
				alt={title}
				title={title}
			/>
			<Typography variant="h6">{title}</Typography>
			{subtitle && (
				<Typography variant="subtitle2">{subtitle}</Typography>
			)}
		</div>
	);
};

type Props = Partial<
	Pick<EstateSafe, 'auctionDate' | 'auctionDateRecurrence'>
> & {
	className?: string;
	style?: CSSProperties;
};

export const HowItWorksCarousel = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.steps}>
			<Step
				imageSrc={firstStep}
				// prettier-ignore
				title={`${t('homepage.how_it_works.deposit')} 10%`}
				subtitle={t('homepage.how_it_works.deposit_timeline')}
			/>
			<Step
				imageSrc={secondStep}
				title={t('homepage.how_it_works.auction')}
				subtitle={displayEstateDate(props)}
			/>
			<Step
				imageSrc={thirdStep}
				title={t('homepage.how_it_works.payment')}
				subtitle={t('homepage.how_it_works.payment_interval')}
			/>
			<Step
				imageSrc={fourthStep}
				title={t('homepage.how_it_works.ownership')}
			/>
		</div>
	);
};

export const HowItWorksSubtitle = () => {
	const { t } = useTranslation();

	return (
		<Typography variant="subtitle1">
			{t('homepage.how_it_works.subtitle')}
		</Typography>
	);
};

export const HowItWorks = (props: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div
			style={props.style}
			className={[classes.container, props.className || ''].join(' ')}
		>
			<Box display="flex" alignItems="center" flexDirection="column">
				<Typography variant="h3" component="div">
					{t('homepage.how_it_works.title')}
				</Typography>
				<Box marginTop={2}>
					<HowItWorksSubtitle />
				</Box>
			</Box>
			<HowItWorksCarousel {...props} />
		</div>
	);
};
