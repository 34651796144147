import { PaymentStatus } from 'foreclosure-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { MainTemplate } from '../../components/Templates/Main';
import { useAuth } from '../../context/Auth';
import { useNotifications } from '../../context/toast';
import { Homepage } from '../Homepage';
import { UserDashboard } from '../UserDashboard';

export const Payment = () => {
	const { status } = useParams<{ status: PaymentStatus }>();
	const { t } = useTranslation();
	const { setToastMessage } = useNotifications();
	const { logged, loading } = useAuth();

	useEffect(() => {
		if (status === PaymentStatus.PAID) {
			setToastMessage({
				severity: 'success',
				message: t('payment.success'),
			});
		} else {
			setToastMessage({
				severity: 'error',
				message: t('payment.error'),
			});
		}
	}, [status, setToastMessage, t]);

	if (loading) {
		return (
			<MainTemplate>
				<LinearProgress />
			</MainTemplate>
		);
	}

	if (logged) {
		return <UserDashboard />;
	}

	return <Homepage />;
};
