import { DependencyList, useEffect } from 'react';

export const isBot = () => {
	const name = window.navigator.userAgent;

	return ['Googlebot', 'AdsBot', 'Mediapartners', 'APIs-Google'].some((bot) =>
		name.includes(bot)
	);
};

export const useScrollToTop = () => {
	useEffect(() => {
		const appContent = document.querySelector(
			'#app-content'
		) as HTMLDivElement;

		if (appContent) {
			appContent.scrollTo(0, 0);
		}

		window.scrollTo(0, 0);
	}, []);
};

export const useAppScroll = (
	callback: ((scroll: number) => any) | null,
	deps: DependencyList
) => {
	useEffect(() => {
		if (!callback) return;

		const handleScroll = () => {
			const appContent = document.querySelector(
				'#app-content'
			) as HTMLDivElement;

			if (!appContent) return;

			callback(appContent.scrollTop || window.scrollY);
		};

		window.addEventListener('scroll', handleScroll);
		document
			.querySelector('#app-content')
			?.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
			document
				.querySelector('#app-content')
				?.removeEventListener('scroll', handleScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};
