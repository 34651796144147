import { UserRole } from 'foreclosure-types';

// Bucharest
export const defaultMapCenter = { lat: 44.4304489, lng: 26.0979342 };

export const rolesThatCanEdit: UserRole[] = [
	UserRole.LOCAL_ADMIN,
	UserRole.ADMIN,
];

export const isProduction =
	typeof window !== 'undefined' &&
	!window.location.host.includes('localhost');

export const localTimezone = 'Europe/Bucharest';

export const testKey = 'hp91u2buasd';

export const googleLoginClientId = isProduction
	? '648497034085-ah56lfnfe81lkivudu19s37gdl2snqv5.apps.googleusercontent.com'
	: '648497034085-kf60d6pg07tevuvpng8q6kjrv3lg7vg1.apps.googleusercontent.com';

export const isPWA =
	// @ts-ignore
	typeof window !== 'undefined' && window?.navigator?.standalone === true;

export const isIphone =
	typeof navigator !== 'undefined' &&
	([
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod',
	].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document));
