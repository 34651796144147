import React, { useContext } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { register } from '../../api/auth';
import { Link, useNavigate } from 'react-router-dom';
import { RegisterSchema, RegisterValues } from '../../forms/login';
import { useYupValidationResolver } from '../../forms/resolver';
import { AuthContext } from '../../context/Auth';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	makeStyles,
} from '@material-ui/core';
import { getErrorFromResponse } from '../../helpers/form';
import { ReactHookField } from '../../components/ReactHookField';
import { AuthTemplate } from '../../components/Templates/Auth';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export const Signup = (): React.ReactElement => {
	const { t } = useTranslation();
	const classes = useStyles({});
	const navigate = useNavigate();
	const { setToken } = useContext(AuthContext);
	const signupResolver = useYupValidationResolver(RegisterSchema);
	const formMethods = useForm({
		// @ts-ignore
		resolver: signupResolver,
	});
	const { handleSubmit, setError } = formMethods;

	const onSubmit: SubmitHandler<RegisterValues & { terms: boolean }> = async (
		form,
		e
	) => {
		e?.preventDefault();

		const { terms, ...data } = form;

		if (!terms) {
			setError('terms', {
				type: 'manual',
				message: t('form.must_accept_terms'),
			});
			return;
		}

		try {
			const response = await register(data);

			setToken(response.token);

			navigate('/');
		} catch (err: any) {
			const msg: string = getErrorFromResponse(err) || t('signup.error');

			if (msg.toLowerCase().includes('email')) {
				setError('email', { message: msg });
			} else if (msg.toLowerCase().includes('phone')) {
				setError('phone', { message: msg });
			} else {
				setError('password', { message: msg });
			}
		}
	};

	return (
		<AuthTemplate
			title={t('signup.title')}
			form={
				<FormProvider {...formMethods}>
					<form
						className={classes.form}
						onSubmit={handleSubmit(onSubmit)}
					>
						<ReactHookField
							autoFocus
							autoComplete="email"
							label={t('signup.email')}
							type="email"
							name="email"
						/>
						<ReactHookField
							autoComplete="name"
							label={t('signup.name')}
							name="name"
						/>
						<ReactHookField
							autoComplete="phone"
							label={t('signup.phone')}
							name="phone"
						/>
						<ReactHookField
							autoComplete="current-password"
							label={t('signup.password')}
							name="password"
							type="password"
						/>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="terms"
									inputRef={formMethods.register({
										validate: (val) => !!val,
									})}
								/>
							}
							label={
								<Box marginTop={2}>
									{t('estateView.contact.I_understand')}{' '}
									<Link to="/terms-and-condition">
										{t('legal_menu.terms')}
									</Link>{' '}
									şi{' '}
									<Link to="/privacy">
										{t('legal_menu.privacy')}
									</Link>
									{formMethods.errors.terms && (
										<Box color="error.main">
											{formMethods.errors.terms?.message}
										</Box>
									)}
								</Box>
							}
						/>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							{t('signup.submit')}
						</Button>
					</form>
				</FormProvider>
			}
		/>
	);
};
