import { CSSProperties, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}));

type Props = {
	children: ReactNode;
	style?: CSSProperties;
	className?: string;
};

export const SpacedRow = (props: Props) => {
	const classes = useStyles();
	const { children, style, className } = props;

	return (
		<div
			className={[className || '', classes.root].join(' ')}
			style={style}
		>
			{children}
		</div>
	);
};
