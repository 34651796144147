import { Checkbox, Grid, Typography } from '@material-ui/core';

export const LeadBoolean = (props: {
	value: boolean;
	onChange?: (checked: boolean) => any;
}) => {
	const { value, onChange } = props;

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="flex-end"
			spacing={2}
		>
			<Grid item>
				<Checkbox
					checked={!!value}
					onChange={(_e, checked) => onChange && onChange(checked)}
				/>
			</Grid>
			<Grid item>
				<Typography>{value ? 'Da' : 'Nu'}</Typography>
			</Grid>
		</Grid>
	);
};
