import {
	AuctionType,
	EstateProvider,
	NationalRegistry,
} from 'foreclosure-types';
import { TextField, Box, Typography, Link } from '@material-ui/core';
// import { CloudUploadOutlined, DescriptionOutlined } from '@material-ui/icons';
import { SelectController } from './SelectController';
import { useTranslation } from 'react-i18next';
import { estateStatusOptions } from '../../../helpers/form';
import { useAuthRole } from '../../../context/Auth';
import { useStyles } from './style';
import { useDefaultTextProps, useFormError } from './helper';
import { DateField } from './DateField';
import { AuctionAddress } from './AuctionAddress';
import { Alert } from '@material-ui/lab';
import { getSalePublicationUrl } from '../../../helpers/estate';

const auctionTypeOptions: Record<AuctionType, string> = {
	BAILIFF: 'Executor Judecătoresc',
	INSOLVENCY: 'Insolvență - Lichidator Judiciar',
	BANK: 'Licitație Bancară',
	PRIVATE: 'Persoană Fizică',
	COMPANY: 'Persoană Juridică',
};

type Props = {
	providerUrl?: string;
	nationalRegistry?: NationalRegistry[];
	salePublications?: string[];
};

const AuctionLinks = (props: Props) => {
	const classes = useStyles({});
	const { providerUrl, nationalRegistry, salePublications } = props;
	const { t } = useTranslation();

	return (
		<>
			<Box className={classes.fieldsRow}>
				{nationalRegistry?.length ? (
					nationalRegistry.map((el) => (
						<Typography
							component={Link}
							href={el.url}
							target="_blank"
						>
							{t('estateEdit.auctionUrl')}
						</Typography>
					))
				) : (
					<Typography>Fara link catre executori.ro</Typography>
				)}
			</Box>
			<Box className={classes.fieldsRow}>
				{salePublications?.length ? (
					salePublications.map((el, index) => (
						<Typography
							component={Link}
							href={getSalePublicationUrl(el)}
							target="_blank"
						>
							Publicație {index + 1}
						</Typography>
					))
				) : (
					<Typography>Fara publicații de vânzare</Typography>
				)}
			</Box>
			<Box className={classes.fieldsRow}>
				{providerUrl ? (
					<Typography
						component={Link}
						href={providerUrl}
						target="_blank"
					>
						{t('estateEdit.providerUrl')}
					</Typography>
				) : (
					<Typography>Fara link catre Provider</Typography>
				)}
			</Box>
		</>
	);
};

export const AuctionForm = (props: Props) => {
	const classes = useStyles({});

	const { isAdmin, isAgent } = useAuthRole();
	const { getFormError } = useFormError();
	const { defaultTextProps } = useDefaultTextProps();

	return (
		<>
			<Box marginBottom={3} marginTop={4}>
				<Typography variant="h5">Detalii licitatie</Typography>

				<Box marginTop={2}>
					<Alert severity="info">
						Pentru orice întrebări scrie-ne la adresa
						vanzari@licitatiibanci.ro
					</Alert>
				</Box>
			</Box>
			<Box className={classes.fieldsContainer}>
				<Box className={classes.fieldsRow}>
					<TextField
						{...defaultTextProps}
						name="userName"
						label="Autor"
						fullWidth
						disabled
					/>
				</Box>
				<Box className={classes.fieldsRow}>
					<AuctionAddress />
				</Box>
				<Box className={classes.fieldsRow}>
					<DateField />
				</Box>
				<Box className={classes.fieldsRow}>
					<SelectController
						name="state"
						label="Status licitatie"
						required
						options={estateStatusOptions}
						defaultValue="AVAILABLE"
					/>

					<SelectController
						name="auctionType"
						label="Tip licitatie"
						required
						options={auctionTypeOptions}
						defaultValue="BAILIFF"
					/>

					<TextField
						{...defaultTextProps}
						error={!!getFormError('executionNumber')}
						helperText={getFormError('executionNumber')}
						label="Numar dosar"
						name="executionNumber"
						style={{ flexGrow: 1 }}
					/>
					{isAdmin && (
						<SelectController
							name="provider"
							label="Provider"
							options={{
								'': 'Selecteaza Provider',
								...EstateProvider,
							}}
							defaultValue={''}
						/>
					)}
					<TextField
						{...defaultTextProps}
						error={!!getFormError('externalId')}
						helperText={getFormError('externalId')}
						label={isAdmin ? 'ID Provider' : 'ID Extern'}
						name="externalId"
					/>
				</Box>

				{/* <Box className={classes.fieldsRow}>
					<input
						type="file"
						hidden
						id="upload-legal-doc"
						name="auctionDocumentFile"
						// @ts-ignore
						ref={register}
					/>
					<label htmlFor="upload-legal-doc">
						<Button
							color="secondary"
							component="span"
							size="large"
							startIcon={<CloudUploadOutlined />}
						>
							Incarca document legal
						</Button>
					</label>
					{auctionDocument ? (
						<Button
							color="secondary"
							size="large"
							startIcon={<DescriptionOutlined />}
							onClick={displayAuctionDocument}
						>
							Vezi document
						</Button>
					) : (
						<Typography>Niciun document legal selectat</Typography>
					)}
				</Box> */}

				{(isAdmin || isAgent) && <AuctionLinks {...props} />}
			</Box>
		</>
	);
};
