import { useTranslation } from 'react-i18next';
import { Button, Link, makeStyles } from '@material-ui/core';
import { Navigation } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	button: {
		// height: 56,
		maxHeight: '100%',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		'&&': {
			border: 'none',
			minWidth: 'auto',
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
		},
	},
}));

export const NavigateButton = (props: { coordinates?: [number, number] }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { coordinates = [] } = props;
	const disabled = coordinates.filter(Boolean).length === 0;

	return (
		<Link
			href={
				disabled
					? '#'
					: `https://maps.google.com/?q=${coordinates.join(',')}`
			}
			target="_blank"
			component={Button}
			className={classes.button}
			endIcon={<Navigation />}
			disabled={disabled}
		>
			{t('locateCF.navigate')}
		</Link>
	);
};
