import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Grid } from '@material-ui/core';
import { EstateDB, EstateSafeDB, isEstateDB } from 'foreclosure-types';

import * as api from '../../api/estate';
import { ToastContext } from '../../context/toast';
import { MainTemplate } from '../../components/Templates/Main';
import { PropertyDetails } from './PropertyDetails';
import { AuctionDetails } from './AuctionDetails';
import { EstateViewMap } from './EstateViewMap';
import { HowItWorks } from '../../components/HowItWorks';
import { ResponsiveRender } from '../../components/ResponsiveRender';
import { SubscribeSection } from '../../components/SubscribeSection';
import { PageMeta } from '../../components/PageMeta';
import { ListEditHistory } from '../../components/ListEditHistory';
import { EstateComments } from './EstateComments';
import { PropertyDetailsTour } from './Tour';

export const EstateView = () => {
	const { id } = useParams<{ id: string }>();
	const { setToastMessage } = useContext(ToastContext);
	const [estate, setEstate] = useState<EstateSafeDB | EstateDB>(
		{} as EstateSafeDB
	);
	const displayMap = (() => {
		// loading
		if (typeof estate._id === 'undefined') return false;

		if (isEstateDB(estate) && estate.cfCoordinatesId) return true;

		if (estate.promoted) return true;

		return !Boolean('auctionUrl' in estate && estate?.auctionUrl);
	})();

	// useScrollToTop();

	useEffect(() => {
		(async () => {
			if (id === 'new' || !id) return;

			try {
				setEstate(await api.getById(id));
			} catch (err) {
				setToastMessage({
					message: 'Eroare din partea serverului',
					severity: 'error',
				});
			}
		})();
	}, [id, setToastMessage]);

	return (
		<MainTemplate>
			<PageMeta
				title={estate.title}
				description={(estate.description || '').slice(0, 160)}
			/>

			{displayMap && (
				<ResponsiveRender
					mdUp={
						<Container>
							<EstateViewMap estate={estate} />
						</Container>
					}
					smDown={<EstateViewMap estate={estate} />}
				/>
			)}

			<Container>
				<Grid container>
					<Grid item md={6} xs={12}>
						<PropertyDetails estate={estate} />
					</Grid>
					<Grid item md={6} xs={12}>
						<AuctionDetails
							estate={estate}
							onSuccessContact={(estateDB) => setEstate(estateDB)}
						/>
					</Grid>
				</Grid>

				<Grid container>
					<ListEditHistory
						objectId={estate._id}
						owner={estate.user}
					/>
				</Grid>

				<Box marginTop={4} marginBottom={4}>
					<EstateComments estateId={estate._id} />
				</Box>

				<Box marginTop={4} marginBottom={4}>
					<HowItWorks {...estate} />
				</Box>

				<SubscribeSection />
			</Container>

			<PropertyDetailsTour />
		</MainTemplate>
	);
};
