import { ReactNode } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainTemplate } from '../../components/Templates/Main';
import { cookieTextRo } from './cookie';
import privacyTextRo from './privacy.md';
import termsTextRo from './terms.md';
import { Markdown } from '../../components/Markdown';
import { useMarkDownText } from '../../helpers/display';

const LegalPage = (props: {
	title: string;
	text?: string;
	children?: ReactNode;
}) => {
	return (
		<MainTemplate>
			<Container>
				<Box marginBottom={3} marginTop={6}>
					<Typography variant="h4" component="h1">
						{props.title}
					</Typography>
				</Box>
				{props.text && (
					<Typography variant="body1">
						{props.text.split('\n').map((text) => (
							<p>{text}</p>
						))}
					</Typography>
				)}

				{props.children}
			</Container>
		</MainTemplate>
	);
};

export const Privacy = () => {
	const { markdown } = useMarkDownText(privacyTextRo);

	return (
		<LegalPage title={useTranslation().t('legal_menu.privacy')}>
			<Markdown>{markdown}</Markdown>
		</LegalPage>
	);
};

export const TermsAndConditions = () => {
	const { markdown } = useMarkDownText(termsTextRo);

	return (
		<LegalPage title={useTranslation().t('legal_menu.terms')}>
			<Markdown>{markdown}</Markdown>
		</LegalPage>
	);
};

export const Cookies = () => (
	<LegalPage
		title={useTranslation().t('legal_menu.cookie')}
		text={cookieTextRo}
	/>
);
