import { TaskList } from '../../components/TaskList';
import { useEstateTaskList } from '../../hooks/task';

export const EstateTasks = (props: { estateId: string }) => {
	const { tasks } = useEstateTaskList(props.estateId);

	return (
		<div
			className="ag-theme-material"
			style={{
				minWidth: 900,
				width: '100%',
				marginTop: 50,
				marginBottom: 100,
			}}
		>
			<TaskList tasks={tasks} editable />
		</div>
	);
};
