import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import {
	Box,
	Button,
	Container,
	Grid,
	Input,
	LinearProgress,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { EstateResponse, EstateSafeDB, UserSearch } from 'foreclosure-types';
import {
	deleteSearch,
	getAllSavedSearches,
	markAsRead,
} from '../../../api/estate';
import { MainTemplate } from '../../../components/Templates/Main';
import { PageMeta } from '../../../components/PageMeta';
import {
	EstateGridContent,
	EstateGridTitle,
} from '../../../components/EstateGrid';
import { Link, useParams } from 'react-router-dom';
import { useApiWrapper } from '../../../helpers/api';
import { useAuth } from '../../../context/Auth';
import { useSubTypeOptions } from '../../../hooks/filters';

const useStyles = makeStyles((theme) => ({
	container: {
		'& .MuiTypography-root': {
			textAlign: 'left',
		},
		[theme.breakpoints.down('md')]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
	},
	datePicker: {
		fontSize: 22,
		fontWeight: 'bold',
		margin: '0 5px',
	},
}));

const useSavedSearches = (params: { since?: string } = {}) => {
	const { userId } = useParams<{ userId?: string }>();
	const { user } = useAuth();
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState<EstateResponse['allSavedSearches']>([]);
	const { since } = params;

	const refetch = useCallback(async () => {
		setLoading(true);

		const list = await getAllSavedSearches(userId, { since });

		setList(list);
		setLoading(false);
	}, [userId, since]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { refetch, list, loading, isOwner: user?._id === userId || !userId };
};

const getSavedSearchTitle = (search: UserSearch) => {
	return search.address;
};

const useSearchPrettyFilters = (userSearch: UserSearch) => {
	const { t } = useTranslation();
	const subTypeOptions = useSubTypeOptions();
	let subTypes: string[] = [];

	try {
		const filters = JSON.parse(userSearch.filter);

		if (typeof filters?.subType?.$in[0] === 'string') {
			subTypes = filters.subType.$in.map(
				(key: string) =>
					subTypeOptions.find((option) => option.key === key)
						?.label || key
			);
		}
	} catch (err) {
		// nothing
	}

	return [
		getSavedSearchTitle(userSearch) || t('search.unknownTitle'),
		...subTypes,
	].join(', ');
};

const SavedSearchTitle = (props: {
	isOwner: boolean;
	onDateChange?: (date: string) => void;
	onDelete: () => void;
	onMarkAsRead: () => void;
	length: number;
	userSearch: UserSearch & { _id?: string };
}) => {
	const {
		onDelete,
		onMarkAsRead,
		length,
		userSearch,
		isOwner,
		onDateChange,
	} = props;

	const classes = useStyles();
	const { t } = useTranslation();
	const [date, setDate] = useState('');

	const prettyFilters = useSearchPrettyFilters(userSearch);
	const title = t('search.count', {
		count: length,
		date: '<<>>',
		filters: prettyFilters,
	}).split('<<>>');

	useEffect(() => {
		setDate(moment.utc(userSearch.lastViewedAt).format('YYYY-MM-DD'));
	}, [userSearch]);

	return (
		<Grid container item className={classes.container}>
			<Grid item lg={8} xs={12}>
				<EstateGridTitle small>
					<span>{title[0]}</span>
					<Input
						className={classes.datePicker}
						type="date"
						onChange={(event) => {
							setDate(event.target.value);

							if (onDateChange) {
								onDateChange(event.target.value);
							}
						}}
						inputProps={{
							min: moment()
								.subtract(2, 'months')
								.format('YYYY-MM-DD'),
							max: moment().format('YYYY-MM-DD'),
						}}
						disabled={!onDateChange}
						value={date}
					/>
					<span>{title[1]}</span>
				</EstateGridTitle>
			</Grid>

			<Grid
				container
				item
				lg={4}
				xs={12}
				justifyContent="space-between"
				alignItems="center"
			>
				{isOwner && (
					<Button onClick={onDelete}>{t('search.delete')}</Button>
				)}

				<Link
					to={length > 0 ? `/search/${userSearch._id}/${date}` : '#'}
				>
					<Button color="primary" disabled={length === 0}>
						{t('search.seeMap')}
					</Button>
				</Link>

				<Button
					onClick={onMarkAsRead}
					color="primary"
					disabled={length === 0}
				>
					{t('search.markAsRead')}
				</Button>
			</Grid>
		</Grid>
	);
};

const SavedSearchItem = (props: {
	userSearch: UserSearch & { _id?: string };
	list: EstateSafeDB[];
	isOwner: boolean;
	onDateChange?: (date: string) => void;
	onRefresh?: () => any;
}) => {
	const { t } = useTranslation();
	const { userSearch, list, onRefresh, isOwner, onDateChange } = props;
	const apiWrapper = useApiWrapper();

	const handleDelete = useCallback(async () => {
		if (!window.confirm(t('search.deleteConfirm'))) return;

		const id = userSearch._id;

		if (!id) return;

		await apiWrapper(() => deleteSearch(id));

		onRefresh && onRefresh();
	}, [t, userSearch._id, apiWrapper, onRefresh]);

	const handleMarkAsRead = useCallback(async () => {
		if (!window.confirm(t('search.readConfirm'))) return;

		const id = userSearch._id;

		if (!id) return;

		await apiWrapper(() => markAsRead(id));

		onRefresh && onRefresh();
	}, [t, userSearch._id, apiWrapper, onRefresh]);

	return (
		<Grid container>
			<SavedSearchTitle
				isOwner={isOwner}
				onDelete={handleDelete}
				onMarkAsRead={handleMarkAsRead}
				onDateChange={onDateChange}
				length={list.length}
				userSearch={userSearch}
			/>

			{list.length === 0 ? (
				<Box marginBottom={2} width="100%">
					<Alert severity="info">
						<Typography>{t('search.noResults')}</Typography>
					</Alert>
				</Box>
			) : (
				<EstateGridContent list={list} />
			)}
		</Grid>
	);
};

export const AllSavedSearches = () => {
	const { t } = useTranslation();
	const [date, setDate] = useState('');
	const { list, refetch, isOwner, loading } = useSavedSearches({
		since: date,
	});

	return (
		<MainTemplate displayHeaderShadow={false}>
			<PageMeta title={t('search.savedTitle')} />

			{loading && <LinearProgress />}

			<Container>
				{list.length === 0 && (
					<Alert severity="info">
						<Typography>{t('search.noSaves')}</Typography>
					</Alert>
				)}

				{list.map((search) => (
					<SavedSearchItem
						key={JSON.stringify(search.userSearch)}
						onRefresh={refetch}
						onDateChange={(date) => setDate(date)}
						isOwner={isOwner}
						{...search}
					/>
				))}
			</Container>
		</MainTemplate>
	);
};
