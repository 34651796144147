import { EstateFilters } from 'foreclosure-types';
import qs from 'qs';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFiltersFromUrl } from '../hooks/filters';

export const FiltersContext = React.createContext<{
	filters: EstateFilters;
	setFilters: (filters: EstateFilters) => void;
}>({
	filters: {},
	setFilters: () => {},
});

type Props = {
	children: ReactNode;
};

export const FiltersProvider = (props: Props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const urlFilters = useFiltersFromUrl();
	const [filters, setFilters] = useState<EstateFilters>(urlFilters);

	const set = useCallback(
		(val: EstateFilters) => {
			setFilters((prev) => {
				if (Object.keys(val).length === 0) {
					return {};
				}

				return {
					...prev,
					...val,
				};
			});
		},
		[setFilters]
	);

	useEffect(() => {
		const encodedFilters = qs.stringify(filters, { encode: false });

		navigate(location.pathname + '?' + encodedFilters, { replace: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	return (
		<FiltersContext.Provider value={{ filters, setFilters: set }}>
			{props.children}
		</FiltersContext.Provider>
	);
};
