import { Lead } from 'foreclosure-types';
import { useEffect, useState } from 'react';
import { updateNotes } from '../../../../api/leads';
import { Notes } from '../../../../components/Notes';
import { useNotifications } from '../../../../context/toast';
import { useLeadEstates } from './helper';

type LeadNote = Lead['notes'][0];

const emptyNote: Readonly<LeadNote> = {
	_id: '',
	author: '',
	updatedAt: '',
	createdAt: '',
	text: '',
	estateId: 'none',
};

export const LeadNotes = (props: { lead: Lead; refresh: () => void }) => {
	const { lead, refresh } = props;
	const [notes, setNotes] = useState<LeadNote[]>([emptyNote]);
	const { estates } = useLeadEstates(lead._id);
	const { setToastMessage } = useNotifications();

	useEffect(() => {
		if (lead.notes?.length) {
			setNotes([...lead.notes, emptyNote] || [emptyNote]);
		}
	}, [lead]);

	const handleSubmit = async () => {
		try {
			await updateNotes(
				lead._id,
				notes
					.filter((el) => !!el.text)
					.map((note) => {
						// @ts-ignore
						delete note.authorName;

						return note;
					})
			);

			setToastMessage({
				message: 'Notițele au fost salvate',
				severity: 'success',
			});

			refresh();
		} catch (err) {
			setToastMessage({
				message: 'Eroare server',
				severity: 'error',
			});
		}
	};

	return (
		<Notes
			values={notes.map((el) => {
				if (!el.estateId) {
					return el;
				}

				return {
					...el,
					selectProps: {
						value: el.estateId || 'none',
					},
				};
			})}
			selectProps={{
				options: {
					none: 'Selectează licitația',
					...lead.estates?.reduce((acc, leadEstate) => {
						const estate = estates.find(
							(el) => el._id === leadEstate.estateId
						);

						acc[leadEstate.estateId] = estate
							? [estate.externalId, estate.title]
									.filter(Boolean)
									.join(' - ')
							: leadEstate.estateId;

						return acc;
					}, {} as Record<string, string>),
				},
			}}
			onSubmit={handleSubmit}
			onChange={({ text, select }, index) => {
				setNotes((prev) => {
					prev[index] = {
						...prev[index],
						text: text ?? prev[index].text,
						estateId: select ?? prev[index].estateId,
					};

					return [
						...prev.filter((el, index) => {
							if (index === prev.length - 1) {
								return true;
							}

							return !!el.text;
						}),
					];
				});
			}}
		/>
	);
};
