import { IconButton, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		padding: theme.spacing(2),
		borderBottom: '0.5px solid rgba(118, 118, 118, 0.28)',
		textAlign: 'center',
		borderTopLeftRadius: 30,
		borderTopRightRadius: 30,
		fontSize: 16,
		fontWeight: 'bold',
	},
	close: {
		position: 'absolute',
		top: 5,
		left: 10,
	},
}));

export const Header = (props: { title: string; onClose?: () => void }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{props.title}

			<IconButton className={classes.close} onClick={props.onClose}>
				<CloseOutlined />
			</IconButton>
		</div>
	);
};
