import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setGuestUuid } from '../api/auth';
import { apm } from '../helpers/elastic';
import { Fallback } from './Fallback';

const generateGuestSession = () => {
	const previousUuid = localStorage.getItem('guest-token');

	if (previousUuid) {
		return previousUuid;
	}

	const randomUuid = uuidv4();

	localStorage.setItem('guest-token', randomUuid);
	localStorage.setItem('guest-date', new Date().toISOString());

	return randomUuid;
};

const setGuestSession = () => {
	const uuid = generateGuestSession();

	setGuestUuid(uuid);

	apm.addLabels({
		guestUuid: uuid,
	});
	apm.setUserContext({
		id: uuid,
	});
};

const UrlSource = (props: { children: ReactElement }) => {
	const navigate = useNavigate();
	const { pathname, hash } = useLocation();
	const [isEmail, setEmail] = useState(false);

	if (!isEmail && pathname.startsWith('/email')) {
		apm.addLabels({
			source: 'email',
		});

		setTimeout(() => {
			navigate(pathname.replace('/email', '') + hash, { replace: true });

			setEmail(true);
		}, 100);

		return <Fallback />;
	}

	return props.children;
};

export const Analytics = (props: { children: ReactElement }) => {
	setGuestSession();

	return <UrlSource {...props} />;
};
