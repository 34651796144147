import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { confirmAccount } from '../../api/auth';
import { Fallback } from '../../components/Fallback';
import { AuthContext } from '../../context/Auth';
import { useNotifications } from '../../context/toast';
import { getErrorFromResponse } from '../../helpers/form';

export const ConfirmAccount = () => {
	const { t } = useTranslation();
	const { fetchUser, setToken, logout } = useContext(AuthContext);
	const { setToastMessage } = useNotifications();
	const { token } = useParams<{ token: string }>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (!token) return;

		(async () => {
			try {
				const response = await confirmAccount(token);

				logout();

				await new Promise((resolve) => setTimeout(resolve, 500));

				setToken(response.token);
				fetchUser();

				setToastMessage({
					message: t('signup.confirmed'),
					severity: 'success',
				});
			} catch (err) {
				setToastMessage({
					message:
						getErrorFromResponse(err) || t('signup.confirmError'),
					severity: 'error',
				});
			}

			setLoading(false);
		})();
	}, [token, setToastMessage, t, fetchUser, setToken, logout]);

	if (loading) {
		return <Fallback />;
	}

	return <Navigate to="/" replace />;
};
