import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EstateDBAdmin } from 'foreclosure-types';
import {
	Box,
	FormControlLabel,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import {
	geocode,
	GeocodeResponse,
	reducePrecision,
	reverseGeocode,
	useCfGeocode,
} from '../../../helpers/map';
import { LocateCfForm } from '../../LocateCF/form';
import { useStatesList } from '../../../helpers/statesList';
import { useCfLocation } from '../../../hooks/map';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { AddressAutocomplete } from '../../../components/LocationSearch/Autocomplete';
import { Modal } from '../../../components/Modal';

const useStateFromUat = (uat: number) => {
	const states = useStatesList();

	if (Number.isNaN(uat)) return undefined;

	return Object.keys(states).find((state) => {
		const uats = states[state];

		return Object.values(uats).includes(uat);
	});
};

const useCfData = () => {
	const { watch } = useFormContext();
	const cfCoordinatesId = watch('cfCoordinatesId');
	const { coordinates } = useCfLocation(cfCoordinatesId);
	const state = useStateFromUat(parseInt(coordinates.county));
	const [name] = (state || '').split('_');

	const value = name ? [name, `CF ${coordinates.cf}`].join(', ') : '';

	return {
		value,
		state,
		coordinates,
	};
};

const useCfFields = () => {
	const { register } = useFormContext();

	register('cfCoordinatesId');
	register('county');
	register('landCF');
};

const SelectCfInput = (props: { estate: EstateDBAdmin }) => {
	const { estate } = props;
	const { setValue } = useFormContext();

	const { updateLocationFields } = useLocationFieldsUpdate();
	const { getCfLocation } = useCfGeocode();
	const [open, setOpen] = useState(false);
	const cfData = useCfData();

	useEffect(() => {
		if (!estate.cfCoordinatesId) {
			setOpen(true);
		} else {
			setValue('cfCoordinatesId', estate.cfCoordinatesId);
		}
	}, [estate, setValue]);

	const handleSubmit = useCallback(
		async (state: string, uat: string, cf: string) => {
			const standardCoordinates = await getCfLocation({
				state,
				cf,
				uat,
			});

			if (!standardCoordinates) return;

			setValue('cfCoordinatesId', standardCoordinates._id);
			setValue('landCF', cf);

			const [lat, lng] = standardCoordinates.wgs84[0];
			const { address, county } = await reverseGeocode({
				lat,
				lng,
			});

			updateLocationFields({
				...reducePrecision({
					address,
					lat,
					lng,
				}),
				county,
			});

			setOpen(false);
		},
		[getCfLocation, setValue, updateLocationFields]
	);

	return (
		<>
			<TextField
				fullWidth
				label="Număr CF"
				onClick={() => setOpen(true)}
				value={cfData.value}
			/>

			<Modal open={open} onClose={() => setOpen(false)}>
				<Box padding={3}>
					<Box marginBottom={2}>
						<Typography variant="h5">Caută numărul CF</Typography>
					</Box>
					<Box marginBottom={2}>
						<Alert severity="info">
							Dacă nu este găsit numărul CF în baza de date,
							folosește adresa în funcție de stradă și oraș
						</Alert>
					</Box>
					{/* <Box height={400} position="relative">
						<EstateMap
							locateCf
							// Just to display the autocomplete search box
							onSearch={() => {}}
							onLocationChange={updateLocationFields}
							locations={estate.location ? [propertyMarker] : []}
							center={propertyMarker}
							defaultCenter={defaultMapCenter}
							onMapMounted={setMap}
						/>
					</Box> */}

					<LocateCfForm
						columnView
						preventSearchOnMount
						initialValues={{
							state: cfData?.state,
							cf: cfData?.coordinates.cf,
							uat: cfData?.coordinates.county,
						}}
						onSubmit={handleSubmit}
					/>
				</Box>
			</Modal>
		</>
	);
};

const useLocationFieldsUpdate = () => {
	const { setValue } = useFormContext();

	const updateLocationFields = useCallback(
		(location: GeocodeResponse) => {
			setValue('address', location.address);
			setValue('location', {
				type: 'Point',
				coordinates: [location.lng, location.lat],
			});

			if (location.county) {
				const countyName = location.county.short_name;
				// default to Bucharest
				setValue('county', countyName.length === 2 ? countyName : 'B');
			}
		},
		[setValue]
	);

	return { updateLocationFields };
};

export const EstateEditMap = (props: { estate: EstateDBAdmin }) => {
	const { t } = useTranslation();
	const { estate } = props;
	const { cfCoordinatesId } = estate;

	const { setValue } = useFormContext();
	const [displayLocateCf, setDisplayLocateCf] = useState(!!cfCoordinatesId);
	const { updateLocationFields } = useLocationFieldsUpdate();

	useCfFields();

	useEffect(() => {
		setDisplayLocateCf(!!cfCoordinatesId);
	}, [cfCoordinatesId]);

	useEffect(() => {
		if (!displayLocateCf) {
			setValue('cfCoordinatesId', '');
			setValue('landCF', '');
		}
	}, [displayLocateCf, setValue]);

	return (
		<Box padding={2}>
			<FormControlLabel
				control={<Switch color="primary" checked={displayLocateCf} />}
				label={t('estateEdit.addressSwitchLabel')}
				onChange={() => setDisplayLocateCf((prev) => !prev)}
			/>

			<Box marginBottom={2} marginTop={2}>
				<Alert severity="info">{t('estateEdit.cfNote')}</Alert>
			</Box>

			{!displayLocateCf && (
				<AddressAutocomplete
					onLocationChange={async (locationString) => {
						const result = await geocode(locationString);

						updateLocationFields(result);
					}}
					value={estate.address}
					textFieldProps={{
						required: true,
						label: t('estateEdit.addressPlaceholder'),
					}}
				/>
			)}

			{displayLocateCf && <SelectCfInput estate={props.estate} />}
		</Box>
	);
};
