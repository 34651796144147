import { useState } from 'react';
import { Fade, Link, makeStyles, Modal } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	container: {
		overflow: 'auto',
		maxWidth: '100%',
		whiteSpace: 'nowrap',
		paddingBottom: theme.spacing(1),
	},
	image: {
		display: 'inline-block',
		verticalAlign: 'top',
		marginRight: theme.spacing(1),
		width: 180,
		height: 180,
		position: 'relative',
		backgroundColor: theme.palette.grey[200],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	},
	hover: {
		cursor: 'pointer',
	},
	children: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	modalImage: {
		maxHeight: '80vh',
		maxWidth: '90vw',
		outline: 'none',
	},
	delete: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
	},
}));

type Props = {
	images: string[];
	children?: any;
	canDelete?: boolean;
	onDelete?: (name: string) => void;
};

export const Gallery = ({ images, children, canDelete, onDelete }: Props) => {
	const classes = useStyles();
	const [pictureModal, setPictureModal] = useState<string>();

	return (
		<div className={classes.container}>
			{children && (
				<div className={classes.image}>
					<div className={classes.children}>{children}</div>
				</div>
			)}
			{images.map((url) => (
				<div
					className={`${classes.image} ${classes.hover}`}
					key={url}
					style={{ backgroundImage: `url("${url}")` }}
					onClick={() => setPictureModal(url)}
				>
					{canDelete && (
						<Link
							className={classes.delete}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onDelete && onDelete(url);
							}}
						>
							<Delete />
						</Link>
					)}
				</div>
			))}
			<Modal
				open={!!pictureModal}
				onClose={() => setPictureModal(undefined)}
				closeAfterTransition
				className={classes.modal}
			>
				<Fade in={!!pictureModal}>
					<img
						src={pictureModal}
						className={classes.modalImage}
						alt="Property Gallery"
					/>
				</Fade>
			</Modal>
		</div>
	);
};
