import { withStyles, Paper as CorePaper } from '@material-ui/core';

export const Paper = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		marginBottom: theme.spacing(2),
	},
}))(CorePaper);
