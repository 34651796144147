import React, {
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import localforage from 'localforage';
import { SubscriptionTypes, UserDB, UserRole } from 'foreclosure-types';
import { fetchLoggedUser, setAxiosToken } from '../api/auth';
import { CACHE_STORE_NAME } from '../api/cache';
import { defaultMapCenter, testKey } from '../config';
import { getMyLocation } from '../api/coordinates';

export const authState = {
	redirectToUrlAfterLogin: '',
};

const emptyUser: Readonly<UserDB> = {
	_id: '0',
	email: '',
	password: '',
	role: UserRole.USER,
	searches: [],
	watchList: [],
	locateCfHistory: [],
};

type UserContextValue = {
	user: UserDB;
	loading: boolean;
	logged: boolean;
	logout: () => void;
	setToken: (token: string) => void;
	fetchUser: () => any;
	setFbLoading: (val: boolean) => void;
	location: { lat: number; lng: number };
	hasSubscription: (requiredSubscription: SubscriptionTypes) => boolean;
};

export const AuthContext = React.createContext<UserContextValue>({
	user: emptyUser,
	loading: true,
	logged: false,
	logout: () => {},
	setToken: () => {},
	fetchUser: () => {},
	setFbLoading: () => {},
	location: defaultMapCenter,
	hasSubscription: () => false,
});

type Props = { children: ReactNode };

enum TokenState {
	PENDING = 'pending',
	EMPTY = 'empty',
}

const AuthProvider = (props: Props) => {
	const [loading, setLoading] = useState(true);
	const [fbLoading, setFbLoading] = useState(true);
	const [locationLoading, setLcationLoading] = useState(true);
	const [token, setToken] = useState<TokenState | string>(TokenState.PENDING);
	const [user, setUser] = useState<UserDB>(emptyUser);
	const [location, setLocation] = useState(defaultMapCenter);

	const logout = useCallback(() => {
		setUser(emptyUser);
		setToken('');

		try {
			localforage.dropInstance({ storeName: CACHE_STORE_NAME });
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const myLocation = await getMyLocation();

				if (myLocation) setLocation(myLocation);
			} catch (err) {
				console.log(err);
			}

			setLcationLoading(false);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			const initialToken = (await localforage.getItem('token')) as string;

			if (initialToken) {
				setToken(initialToken);
			} else {
				setToken(TokenState.EMPTY);
			}
		})();
	}, []);

	const fetchUser = useCallback(async () => {
		try {
			setUser(await fetchLoggedUser());
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (token === TokenState.PENDING) {
			return;
		}

		setAxiosToken(token || '');

		if (token && token !== TokenState.EMPTY) {
			localforage.setItem('token', token);

			fetchUser();
		} else {
			localforage.removeItem('token');

			setLoading(false);
		}
	}, [token, fetchUser]);

	const hasSubscription = (requiredSubscription: SubscriptionTypes) => {
		if (requiredSubscription === SubscriptionTypes.AGENCY) {
			return user.subscription === SubscriptionTypes.AGENCY;
		}

		if (requiredSubscription === SubscriptionTypes.PRO) {
			return (
				user.subscription === SubscriptionTypes.PRO ||
				user.subscription === SubscriptionTypes.AGENCY
			);
		}

		if (requiredSubscription === SubscriptionTypes.BASIC) {
			return (
				user.subscription === SubscriptionTypes.BASIC ||
				user.subscription === SubscriptionTypes.PRO ||
				user.subscription === SubscriptionTypes.AGENCY
			);
		}

		return false;
	};

	return (
		<AuthContext.Provider
			value={{
				loading: loading || fbLoading || locationLoading,
				user,
				logged: !!user.email,
				setToken,
				logout,
				fetchUser,
				setFbLoading,
				location,
				hasSubscription,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};

export const useAuthRole = () => {
	const { user } = useContext(AuthContext);
	const subscription = user.subscription || SubscriptionTypes.FREE;
	const isTestUser =
		// (user.email || '').includes('licitatiibanci.ro') ||
		localStorage.getItem('test-key') === testKey;

	const isAdmin =
		user.role === UserRole.ADMIN || user.role === UserRole.LOCAL_ADMIN;
	const isAgent = user.role === UserRole.AGENT;
	const isOperator = user.role !== UserRole.USER;

	return {
		isAdmin,
		isAgent,
		isOperator,
		isTestUser,
		subscribed: subscription !== SubscriptionTypes.FREE,
	};
};

export default AuthProvider;
