import { useEffect, useState } from 'react';
import { EstateContactStatus, EstateDB } from 'foreclosure-types';
import { getEstates } from '../../../../api/leads';

export const LeadEstateStatus: Record<EstateContactStatus, string> = {
	NOT_INTERESTED: 'Nu este interesat',
	CONTACT: 'Contactat',
	BUY_OFFER: 'Ofertă de cumpărare',
	OFFER_SUBMITTED: 'Ofertă depusă',
	PARTICIPATED: 'Participat',
	WANTS_TO_PARTICIPATE: 'Dorește să participe',
	WON: 'Câștigător',
};

let cachedEstates: EstateDB[] = [];

export const useLeadEstates = (leadId: string) => {
	const [estates, setEstates] = useState(cachedEstates);

	useEffect(() => {
		if (!leadId) return;

		(async () => {
			cachedEstates = await getEstates(leadId);

			setEstates(cachedEstates);
		})();
	}, [leadId]);

	return { estates };
};
