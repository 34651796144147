import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	fieldsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		alignItems: 'space-between',
	},
	fieldsRow: {
		display: 'flex',
		width: 'auto',
		minWidth: 200,
		'& > *': {
			margin: theme.spacing(2),
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
		},
	},
}));
