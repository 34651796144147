import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { displayEstateDate, displayPrice } from '../helpers/display';
import { AuctionDateRecurrence, Currency } from 'foreclosure-types';
import { PromotedLabel } from './PromotedLabel';

const useStyles = makeStyles((theme) => ({
	media: {
		height: 140,
		position: 'relative',
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	title: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		[theme.breakpoints.up('md')]: {
			display: '-webkit-box',
			'-webkit-line-clamp': 2,
			'-webkit-box-orient': 'vertical',
			height: 64,
		},
	},
	date: {
		[theme.breakpoints.up('md')]: {
			height: 44,
		},
	},
	price: {
		color: theme.palette.text.hint,
	},
}));

interface Props {
	title: string;
	address: string;
	price: number;
	currency: Currency;
	size?: number;
	year?: number;
	auctionDate: string;
	auctionDateRecurrence?: AuctionDateRecurrence;
	image: string;
	link: string;
	promoted?: boolean;
	unsubscribe?: boolean;
	onUnsubscribe?: () => any;
}

export const AdvertCard = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const renderCardMedia = () => {
		return (
			<CardMedia
				className={classes.media}
				image={props.image}
				title="Property photo"
			>
				{props.promoted && <PromotedLabel />}
			</CardMedia>
		);
	};

	const renderCardContent = () => {
		const { title, address, price, currency, size, year } = props;
		const yearString = year ? t('advertCard.year') + ' ' + year : '';
		const sizeString = year ? t('advertCard.size') + ' ' + size : '';

		return (
			<CardContent>
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
				<Typography variant="subtitle2" className={classes.ellipsis}>
					{address}
				</Typography>
				<Typography variant="h6" className={classes.price}>
					{displayPrice(price, currency)}
				</Typography>
				<Typography variant="subtitle2" color="textSecondary">
					{sizeString || yearString}
				</Typography>
				<Typography variant="subtitle2" className={classes.date}>
					{t('advertCard.date') + ' ' + displayEstateDate(props)}
				</Typography>
			</CardContent>
		);
	};

	const renderCardButton = () => {
		return (
			<CardActions>
				<Button
					size="medium"
					color="primary"
					variant="contained"
					fullWidth
					{...(props.link ? { component: Link, to: props.link } : {})}
				>
					{t('advertCard.button_label')}
				</Button>
				{props.unsubscribe && (
					<Button
						size="medium"
						color="secondary"
						variant="contained"
						fullWidth
						onClick={props.onUnsubscribe}
					>
						{t('advertCard.unsubscribe')}
					</Button>
				)}
			</CardActions>
		);
	};

	return (
		<Card>
			<CardActionArea>
				{renderCardMedia()}
				{renderCardContent()}
			</CardActionArea>
			{renderCardButton()}
		</Card>
	);
};
