import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const markerStyles = makeStyles((theme) => ({
	root: {
		// width: 100,
		display: 'inline-block',
		backgroundColor: theme.palette.background.default,
		boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
		padding: theme.spacing(1),
		borderRadius: 35,
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		position: 'relative',
		transition: 'all .3s',
	},
	highlighted: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		zIndex: 1,
		transform: 'scale(1.1)',
	},
	hover: {
		transform: 'scale(1.2)',
	},
}));

export const Marker = (props: {
	lat: number;
	lng: number;
	children: any;
	onClick?: () => void;
	onMouseEnter?: () => void;
	highlighted?: boolean;
}) => {
	const classes = markerStyles({});
	const [hover, setHover] = useState(false);

	return (
		<div
			className={[
				classes.root,
				props.highlighted && classes.highlighted,
				hover && classes.hover,
			]
				.filter(Boolean)
				.join(' ')}
			onClick={props.onClick}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
		>
			{props.children}
		</div>
	);
};
