import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { UserDB, UserRole } from 'foreclosure-types';
import { get } from '../api/user';
import { useAuthRole } from './Auth';

export const UsersContext = createContext({
	users: [] as UserDB[],
});

export const UsersProvider = (props: {
	children: ReactNode;
}): React.ReactElement => {
	const { isAdmin } = useAuthRole();
	const [users, setUsers] = useState<UserDB[]>([]);

	useEffect(() => {
		if (!isAdmin) return;

		(async () => {
			const adminRoles = [
				UserRole.ADMIN,
				UserRole.LOCAL_ADMIN,
				UserRole.AGENT,
			];
			const data = await Promise.all(
				adminRoles.map((role) => get({ role }))
			);

			setUsers(data.flat());
		})();
	}, [isAdmin]);

	return (
		<UsersContext.Provider value={{ users }}>
			{props.children}
		</UsersContext.Provider>
	);
};

export const useUsers = () => {
	return useContext(UsersContext);
};
