import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	promoted: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		fontWeight: 'bold',
		padding: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderTopRightRadius: 4,
	},
}));

export const PromotedLabel = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return <div className={classes.promoted}>{t('advertCard.promoted')}</div>;
};
