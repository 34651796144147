import { TextField } from '@material-ui/core';
import { ComponentProps, ComponentPropsWithoutRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useErrorMessage } from '../../helpers/form';

type Props = {
	name?: string;
	label?: string;
	rules?: ComponentProps<typeof Controller>['rules'];
	required?: boolean;
	TextFieldProps?: Omit<
		ComponentPropsWithoutRef<typeof TextField>,
		'defaultValue' | 'type'
	>;
};

export const PriceField = (props: Props) => {
	const { t } = useTranslation();
	const getErrorMessage = useErrorMessage();
	const { errors } = useFormContext();

	const name = props.name || 'price';
	const label = props.label || t('estateView.buy.price');

	return (
		<Controller
			name={name}
			defaultValue={0}
			rules={{
				...props.rules,
				...(props.required ? { required: true } : {}),
			}}
			render={({ onBlur, onChange, value }) => {
				return (
					<NumberFormat
						allowNegative={false}
						thousandSeparator=" "
						decimalSeparator=","
						decimalScale={2}
						customInput={TextField}
						onBlur={onBlur}
						value={value}
						onValueChange={(values) => {
							onChange(values.floatValue);
						}}
						variant="outlined"
						error={!!errors[name]}
						helperText={getErrorMessage(errors[name])}
						label={label}
						name={name}
						required={props.required}
						InputProps={{
							inputProps: {
								inputMode: 'decimal',
							},
						}}
						{...props.TextFieldProps}
					/>
				);
			}}
		/>
	);
};
