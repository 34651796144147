import {
	Badge,
	BottomNavigation,
	BottomNavigationAction,
	makeStyles,
} from '@material-ui/core';
import { AccountCircle, Favorite, Home } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { isIphone, isPWA } from '../../config';
import { useAuth } from '../../context/Auth';
import { useSavedSearchesCount } from '../../hooks/estate';
import { useAddAdvertLink } from '../../hooks/menu';
import { useMobileSize } from '../../hooks/responsive';
import { AccountBadge } from '../AccountBadge';

const isIphoneApp = isPWA && isIphone;

const useStyles = makeStyles(() => ({
	root: {
		zIndex: 1,
		position: 'fixed',
		bottom: 0,
		left: 0,
		width: '100%',
		height: isIphoneApp ? 72 : 56,
		paddingBottom: isIphoneApp ? 16 : 0,
	},
	spacer: {
		height: isIphoneApp ? 72 : 56,
	},
}));

enum Tab {
	Home = 'home',
	Favorites = 'favorites',
	Account = 'account',
	AddAdvert = 'add-advert',
}

const useActiveTabFromLocation = (): Tab | null => {
	const { pathname } = useLocation();

	if (pathname === '/') {
		return Tab.Home;
	}

	if (pathname === '/search') {
		return Tab.Favorites;
	}

	if (pathname === '/dashboard') {
		return Tab.Account;
	}

	if (pathname === '/add-advert') {
		return Tab.AddAdvert;
	}

	return null;
};

export const MobileFooter = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { isTablet } = useMobileSize();
	const activeTab = useActiveTabFromLocation();
	const navigate = useNavigate();
	const { logged } = useAuth();
	const { count: savedSearchesCount } = useSavedSearchesCount();
	const addAdvertLink = useAddAdvertLink();

	if (!isTablet) return null;

	const handleChange = (val: Tab) => {
		switch (val) {
			case Tab.Home:
				navigate('/');
				break;
			case Tab.Favorites:
				navigate('/search');
				break;
			case Tab.AddAdvert:
				navigate(addAdvertLink.to);
				break;
			case Tab.Account:
				if (logged) {
					navigate('/dashboard');
				} else {
					navigate('/login');
				}
				break;
			default:
			// do nothing
		}
	};

	return (
		<>
			<div className={classes.spacer}></div>
			<BottomNavigation
				value={activeTab}
				onChange={(event, val) => {
					event.preventDefault();
					event.stopPropagation();

					handleChange(val);
				}}
				showLabels
				className={classes.root}
			>
				<BottomNavigationAction
					value={Tab.Home}
					label={t('top_menu.home')}
					icon={<Home />}
				/>
				{logged && (
					<BottomNavigationAction
						value={Tab.Favorites}
						label={t('top_menu.favourites')}
						icon={
							<Badge
								overlap="rectangular"
								badgeContent={savedSearchesCount}
								color="primary"
							>
								<Favorite />
							</Badge>
						}
					/>
				)}
				<BottomNavigationAction
					value={Tab.AddAdvert}
					label={addAdvertLink.label}
					icon={addAdvertLink.icon}
				/>
				<BottomNavigationAction
					value={Tab.Account}
					label={t('top_menu.my_account')}
					icon={
						<AccountBadge>
							<AccountCircle />
						</AccountBadge>
					}
				/>
			</BottomNavigation>
		</>
	);
};
