import React, { FormEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Divider,
	makeStyles,
	MenuItem,
	Paper,
	Select,
} from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { preventDefaultNStop } from '../../helpers/events';
import { AddressAutocomplete } from './Autocomplete';
import { EstateCategory } from '../../types/estate';
import { ResponsiveRender } from '../ResponsiveRender';
import { useNavigate } from 'react-router-dom';
import { replaceDiacritics } from '../../helpers/string';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			flexDirection: 'column',
		},
	},
	row: {
		paddingLeft: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	autocomplete: {
		maxWidth: '100%',
		flexGrow: 1,
	},
	input: {
		padding: theme.spacing(1),
		marginLeft: theme.spacing(1),
		'& fieldset': {
			border: 0,
		},
	},
	select: {
		minWidth: 120,
		'& fieldset': {
			border: 'none',
		},
	},
	button: {
		height: '100%',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		'&&': {
			border: 'none',
			[theme.breakpoints.up('md')]: {
				paddingLeft: theme.spacing(4),
				paddingRight: theme.spacing(4),
			},
		},
	},
}));

const SearchInput = ({ locationRef }: { locationRef: React.Ref<any> }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<SearchOutlined />
			<AddressAutocomplete
				className={classes.autocomplete}
				textFieldProps={{
					className: classes.input,
					placeholder: t('search.location'),
					inputRef: locationRef,
				}}
			/>
		</>
	);
};

const SearchButtons = ({ typeRef }: { typeRef: React.Ref<any> }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<Select
				className={classes.select}
				variant="outlined"
				defaultValue="estates"
				inputRef={typeRef}
			>
				<MenuItem value="estates">{t('top_menu.all')}</MenuItem>
				<MenuItem value={EstateCategory.LIVING}>
					{t('top_menu.living')}
				</MenuItem>
				<MenuItem value={EstateCategory.INVESTMENTS}>
					{t('top_menu.investments')}
				</MenuItem>
			</Select>
			<Divider orientation="vertical" flexItem />
			<Button size="medium" className={classes.button} type="submit">
				{t('search.submit')}
			</Button>
		</>
	);
};

export const Search = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const locationRef = useRef<HTMLInputElement>();
	const typeRef = useRef<HTMLSelectElement>();

	const handleSubmit = useCallback(
		(event: FormEvent) => {
			preventDefaultNStop(event);

			const location = locationRef.current?.value || '';
			const type = typeRef.current?.value;

			return navigate(replaceDiacritics(`/${type}/${location}`));
		},
		[navigate]
	);

	return (
		<ResponsiveRender
			mdUp={
				<Paper>
					<form className={classes.root} onSubmit={handleSubmit}>
						<SearchInput locationRef={locationRef} />
						<Divider orientation="vertical" flexItem />
						<SearchButtons typeRef={typeRef} />
					</form>
				</Paper>
			}
			smDown={
				<form className={classes.root} onSubmit={handleSubmit}>
					<Paper className={classes.row}>
						<SearchInput locationRef={locationRef} />
					</Paper>
					<Paper className={classes.row}>
						<SearchButtons typeRef={typeRef} />
					</Paper>
				</form>
			}
		/>
	);
};
