import { UserResponse } from 'foreclosure-types';
import { useEffect, useState } from 'react';
import { count } from '../api/auth';
import { useAuth, useAuthRole } from '../context/Auth';

export const useAccountNotificationsCount = () => {
	const { user } = useAuth();
	const { isAdmin } = useAuthRole();

	let count = 0;

	if (!user.confirmed && !isAdmin) count++;

	return {
		count,
	};
};

export const useBilling = () => {
	const { user } = useAuth();
	const billingAddress = user?.billing?.address || '';
	const billingCity = user?.billing?.city || '';

	return {
		billingValid: billingAddress.length > 0 && billingCity.length > 0,
	};
};

const DEFAULT_BASIC_COUNT = 20;
const userCountCache: UserResponse['count'] = {
	agency: 10,
	basic: DEFAULT_BASIC_COUNT,
	pro: 50,
	newsletter: 1000,
};

export const useUserCount = () => {
	const [data, setData] = useState(userCountCache);

	useEffect(() => {
		if (data.basic !== DEFAULT_BASIC_COUNT) return;

		(async () => {
			const response = await count();

			Object.assign(userCountCache, response);

			setData(response);
		})();
	}, [data]);

	return data;
};
