import { Timestamp } from './Timestamp';

export enum EditObjectType {
    estate = 'estate',
    cfCoordinates = 'cfCoordinates',
    user = 'user',
}

export enum OperationType {
    EDIT = 'EDIT',
    DELETE = 'DELETE',
}

export interface EditHistory<T = any> extends Timestamp {
    guestUuid: string;
    authorId: string;
    objectId: string;
    objectType: EditObjectType;
    operationType: OperationType;
    changes: Record<string, T>;
}

export type EditHistoryDb = EditHistory & { _id: string };

export interface EditRow {
    old: any;
    new: any;
}

export interface EditHistoryResponse {
    get: EditHistoryDb[];
}

export const isEditRow = (
    row: EditHistory<any>
): row is EditHistory<EditRow> => {
    if (!row?.changes) return false;

    return row.operationType === OperationType.EDIT;
};
