import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
	Grid,
	InputProps,
	TextFieldProps,
	Typography,
} from '@material-ui/core';
import { User } from 'foreclosure-types';
import { update } from '../../api/user';
import { ProgressButton } from '../Form/ProgressButton';
import { ReactHookField } from '../ReactHookField';
import { ControlledSelect } from '../Select';
import { useAuth } from '../../context/Auth';
import { useApiWrapper } from '../../helpers/api';
import { cuiPattern, emailPattern, numberPattern } from '../../helpers/form';
import { useStatesList } from '../../helpers/statesList';

type FormKeys = keyof Required<User>['billing'];

const formFields: Array<FormKeys> = [
	'name',
	'phone',
	'email',
	'state',
	'city',
	'address',
	'company',
	'cui',
];
const optionalFields: Array<FormKeys> = ['company', 'cui'];

const useStates = () => {
	const list = useStatesList();
	const states = Object.keys(list).reduce((acc, key) => {
		const [name] = key.split('_') as string[];

		if (!name) return acc;

		return {
			...acc,
			[name]: name
				.split(' ')
				.map((e) => capitalize(e))
				.join(' '),
		};
	}, {});
	const defaultState = Object.keys(states)[0];

	return {
		states,
		defaultState,
	};
};
const StateField = () => {
	const { states, defaultState } = useStates();

	return (
		<Controller
			name="state"
			defaultValue={defaultState}
			render={({ value, onChange }) => (
				<ControlledSelect
					fullWidth
					margin="normal"
					size="medium"
					options={states}
					value={value}
					defaultValue={defaultState}
					onChange={onChange}
				/>
			)}
		/>
	);
};

const Field = (props: { name: FormKeys }) => {
	const { t } = useTranslation();
	const { name } = props;

	if (name === 'state') {
		return <StateField />;
	}

	const required = !optionalFields.includes(name as any);

	let type: TextFieldProps['type'] = 'text';
	let inputMode: InputProps['inputMode'] = 'text';

	if (name === 'phone') {
		type = 'phone';
		inputMode = 'tel';
	}

	if (name === 'email') {
		type = 'email';
		inputMode = 'email';
	}

	return (
		<ReactHookField
			name={name}
			label={t(`billing.${name}`)}
			required={required}
			rules={{
				required,
				minLength: required
					? { value: 3, message: t('form.min_length') }
					: undefined,
				validate: (val) => {
					if (name === 'phone') {
						if (val.length < 10) {
							return t('form.min_length');
						}
						if (!numberPattern.test(val)) {
							return t('form.valid_number');
						}
					}

					if (name === 'email' && !emailPattern.test(val)) {
						return t('form.valid_email');
					}

					if (name === 'cui' && !cuiPattern.test(val)) {
						return t('form.valid_cui');
					}

					return undefined;
				},
			}}
			type={type}
			InputProps={{
				inputProps: {
					inputMode: inputMode,
				},
			}}
		/>
	);
};

const useFormInit = () => {
	const { user } = useAuth();
	const { defaultState } = useStates();

	return useForm({
		defaultValues: {
			name: user.billing?.name || user.name || '',
			phone: user.billing?.phone || user.phone || '',
			email: user.billing?.email || user.email || '',
			state: user.billing?.state || defaultState,
			city: user.billing?.city || '',
			address: user.billing?.address || '',
			company: user.billing?.company || '',
			cui: user.billing?.cui || '',
		},
	});
};

export const BillingAddress = (props: {
	onSubmit?: (values: Required<User>['billing']) => unknown;
}) => {
	const { t } = useTranslation();
	const formMethods = useFormInit();
	const { user, fetchUser, logged } = useAuth();
	const apiWrapper = useApiWrapper();

	const handleSubmit = async () => {
		const values = formMethods.getValues();

		if (logged) {
			await apiWrapper(() => update({ _id: user._id, billing: values }));

			fetchUser();
		}

		if (props.onSubmit) props.onSubmit(values);
	};

	return (
		<div>
			<Typography variant="h6">{t('billing.title')}</Typography>

			<FormProvider {...formMethods}>
				<Grid
					container
					spacing={1}
					component="form"
					onSubmit={formMethods.handleSubmit(handleSubmit)}
				>
					{formFields.map((key) => {
						return (
							<Grid item xs={12} sm={6} md={4} key={key}>
								<Field name={key as any} />
							</Grid>
						);
					})}

					<Grid item xs={12}>
						<Typography>
							{t('form.required_fields_note')}
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<ProgressButton
							color="primary"
							type="submit"
							loading={formMethods.formState.isSubmitting}
						>
							{t('billing.submit')}
						</ProgressButton>
					</Grid>
				</Grid>
			</FormProvider>
		</div>
	);
};
