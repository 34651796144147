import { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, makeStyles } from '@material-ui/core';
import { EstateSafeDB, EstateStateEnum } from 'foreclosure-types';
import { AdvertCard } from './AdvertCard';
import { defaultPictures } from '../helpers/defaults';
import { getEstateLink, getEstateState } from '../helpers/form';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(12.75),
		paddingBottom: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			paddingTop: theme.spacing(9),
		},
	},
	title: {
		paddingTop: theme.spacing(6.25),
		paddingBottom: theme.spacing(6.25),
		textAlign: 'center',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
		},
	},
}));

export const EstateGridTitle = ({
	children,
	small,
}: {
	children: ReactNode;
	small?: boolean;
}) => {
	const classes = useStyles();

	return (
		<Typography
			className={classes.title}
			variant={small ? 'h5' : 'h3'}
			component="div"
		>
			{children}
		</Typography>
	);
};

export const EstateGridContent = (props: {
	list: EstateSafeDB[];
	unsubscribe?: boolean;
	onUnsubscribe?: (id: string) => any;
}) => {
	const { list, unsubscribe, onUnsubscribe } = props;

	const listContent = list.map((estate: EstateSafeDB) => {
		const { type, subType, propertySize, landSize, year } = estate;
		const size =
			subType === 'APARTMENT' || subType === 'HOUSE'
				? propertySize
				: landSize;
		const customProps = type === 'ESTATE' ? { size } : { year };

		const picture =
			estate.pictures.length === 0
				? defaultPictures[subType]
				: estate.pictures[0];

		const stateLabel =
			estate.state !== EstateStateEnum.AVAILABLE
				? `${getEstateState(estate.state)} - `
				: '';

		return (
			<Grid key={estate._id} item xs={12} sm={6} md={4} lg={3}>
				<AdvertCard
					title={`${stateLabel}${estate.title}`}
					address={estate.address || ''}
					promoted={!!estate.promoted}
					price={estate.price}
					currency={estate.currency}
					auctionDate={estate.auctionDate}
					auctionDateRecurrence={estate.auctionDateRecurrence}
					image={picture}
					link={getEstateLink(estate)}
					unsubscribe={!!unsubscribe}
					onUnsubscribe={() =>
						onUnsubscribe && onUnsubscribe(estate._id)
					}
					{...customProps}
				/>
			</Grid>
		);
	});

	return (
		<Grid container direction="row" spacing={2}>
			{listContent}
		</Grid>
	);
};

export const EstateGrid = (props: {
	list: EstateSafeDB[];
	title?: string;
	subtitle?: string;
	unsubscribe?: boolean;
	onUnsubscribe?: (id: string) => any;
}) => {
	const classes = useStyles();
	const { list, title, subtitle, unsubscribe, onUnsubscribe } = props;

	return (
		<Grid container className={classes.container}>
			{title && <EstateGridTitle>{title}</EstateGridTitle>}
			{subtitle && <EstateGridTitle small>{subtitle}</EstateGridTitle>}

			<EstateGridContent
				list={list}
				unsubscribe={unsubscribe}
				onUnsubscribe={onUnsubscribe}
			/>
		</Grid>
	);
};
