import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { EstateSafeDB } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';
import {
	DescriptionOutlined,
	HouseOutlined,
	LandscapeOutlined,
	MeetingRoomOutlined,
	DateRangeOutlined,
} from '@material-ui/icons';

export const PropertyDetailsFeatures = (props: { estate: EstateSafeDB }) => {
	const { t } = useTranslation();
	const { estate } = props;

	return (
		<Box marginTop={2}>
			<Table size="small">
				<TableBody>
					{(estate.subType === 'HOUSE' ||
						estate.subType === 'APARTMENT' ||
						estate.type === 'MOVABLE') &&
						!!estate.year && (
							<TableRow>
								<TableCell>
									<DateRangeOutlined />
								</TableCell>
								<TableCell>
									{t('estateView.property.year')}
								</TableCell>
								<TableCell>{estate.year}</TableCell>
							</TableRow>
						)}
					{estate.subType === 'HOUSE' && !!estate.rooms && (
						<TableRow>
							<TableCell style={{ width: 50 }}>
								<MeetingRoomOutlined />
							</TableCell>
							<TableCell>
								{t('estateView.property.rooms_no')}
							</TableCell>
							<TableCell style={{ width: 100 }}>
								{estate.rooms}
							</TableCell>
						</TableRow>
					)}
					{(estate.subType === 'HOUSE' ||
						estate.subType === 'APARTMENT') &&
						!!estate.propertySize && (
							<TableRow>
								<TableCell>
									<HouseOutlined />
								</TableCell>
								<TableCell>
									{t('estateView.property.property_size')}
								</TableCell>
								<TableCell>{estate.propertySize} mp</TableCell>
							</TableRow>
						)}
					{(estate.subType === 'HOUSE' ||
						estate.subType === 'LAND') &&
						!!estate.landSize && (
							<TableRow>
								<TableCell>
									<LandscapeOutlined />
								</TableCell>
								<TableCell>
									{t('estateView.property.land_size')}
								</TableCell>
								<TableCell>{estate.landSize} mp</TableCell>
							</TableRow>
						)}
					{estate.subType === 'LAND' && (
						<TableRow>
							<TableCell>
								<DescriptionOutlined />
							</TableCell>
							<TableCell>
								{t('estateView.property.land_type')}
							</TableCell>
							<TableCell>
								{estate.landType === 'RURAL'
									? t('land_type.rural')
									: t('land_type.urban')}
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Box>
	);
};
