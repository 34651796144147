import { EstateSafeDB, EstateState } from 'foreclosure-types';
import { useTranslation } from 'react-i18next';

export const emailPattern =
	/* eslint-disable no-control-regex */
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const numberPattern = /^\+{0,1}[0-9]*$/;

// regex for number with optional "RO" prefix
export const cuiPattern = /^(RO)?\+{0,1}[0-9]*$/;

export const getErrorFromResponse = (axiosError: any) => {
	return (
		axiosError?.response?.data?.error ||
		axiosError?.response?.data ||
		axiosError?.message
	);
};

export const useErrorMessage = () => {
	const { t } = useTranslation();

	return (fieldError?: { type: string; message?: string }) => {
		if (!fieldError) {
			return null;
		}

		if (fieldError.type === 'minLength') {
			return t('form.min_length');
		}

		if (fieldError.message) {
			return fieldError.message;
		}

		return null;
	};
};

export const accessNestedObject = <T extends Record<string, any>>(
	object: T,
	path: string
) => {
	const pathArr = path
		.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
		.replace(/^\./, '') // strip a leading dot
		.split('.');
	let current = object;

	for (const key of pathArr) {
		if (current[key]) {
			current = current[key];
		} else {
			return;
		}
	}

	return current;
};

export const isEstateEmpty = <T extends { _id: string }>(estate: T) => {
	return `${estate?._id}`.length !== 24;
};

const cleanUrlTitle = (title: string) => {
	return (
		title
			.replace(/,/g, '')
			.replace(/[\s/]/g, '-')
			// eslint-disable-next-line no-useless-escape
			.replace(/[\.\%]/g, '')
	);
};

export const getEstateLink = <
	T extends Pick<EstateSafeDB, '_id' | 'type' | 'title'>
>(
	estate: T,
	editLink = false
) => {
	if (isEstateEmpty(estate)) {
		return '';
	}

	const { type, _id, title } = estate;
	const link = type === 'ESTATE' ? '/estate' : '/movable';
	const viewLink = [link, _id, cleanUrlTitle(title).toLowerCase()].join('/');

	if (editLink) {
		return '/edit' + viewLink;
	}

	return viewLink;
};

export const estateStatusOptions: Record<
	Exclude<EstateState, 'NEW'>,
	string
> = {
	AVAILABLE: 'Disponibil',
	BOUGHT: 'Cumparat',
	DISABLED: 'Dezactivat',
};

export const getEstateState = (state: EstateState) => {
	if (state === 'NEW') {
		return 'Nou';
	}

	return estateStatusOptions[state];
};

export const parseFormattedNumber = (value: string) => {
	return parseInt(value.replace(/ /g, ''), 10);
};
