import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';
import { useEffect } from 'react';
import {
	Controller,
	FormProvider,
	useForm,
	useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { requestLoginCode } from '../../api/auth';
import { ReactHookField } from '../../components/ReactHookField';
import { AuthTemplate } from '../../components/Templates/Auth';
import { useApiWrapper } from '../../helpers/api';

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

enum RequestType {
	EMAIL = 'email',
	PHONE = 'phone',
}

const PickOption = () => {
	return (
		<Controller
			name="requestType"
			defaultValue={RequestType.PHONE}
			render={(props) => {
				const { ref, ...rest } = props;

				return (
					<FormControl component="fieldset">
						<RadioGroup row aria-label="currency" {...rest}>
							<FormControlLabel
								control={<Radio />}
								value={RequestType.PHONE}
								label="WhatsApp"
							/>
							<FormControlLabel
								control={<Radio />}
								value={RequestType.EMAIL}
								label="Email"
							/>
						</RadioGroup>
					</FormControl>
				);
			}}
		/>
	);
};

const EmailOrPhone = () => {
	const formProps = useFormContext();
	const requestType = formProps.watch('requestType');

	useEffect(() => {
		formProps.setValue('codeChannel', '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestType]);

	// Use same input "name" because react-hook-form doesn't like dynamic rendering of fields
	if (requestType === RequestType.EMAIL)
		return (
			<ReactHookField
				name="codeChannel"
				label="Email"
				type="email"
				autoComplete="email"
				required
				InputProps={{
					inputProps: {
						inputMode: 'email',
					},
				}}
			/>
		);

	return (
		<ReactHookField
			name="codeChannel"
			label="Telefon"
			autoComplete="phone"
			required
			InputProps={{
				inputProps: {
					inputMode: 'tel',
				},
			}}
		/>
	);
};

const Form = () => {
	const { t } = useTranslation();
	const classes = useStyles({});
	const navigate = useNavigate();
	const formProps = useFormContext();
	const apiWrapper = useApiWrapper();

	const handleSubmit = async (values: any) => {
		const { codeChannel, requestType } = values;

		await apiWrapper(async () => {
			await requestLoginCode(
				requestType === RequestType.EMAIL
					? { email: codeChannel }
					: { phone: codeChannel }
			);

			navigate('/login-with-code');
		});
	};

	return (
		<form
			className={classes.form}
			onSubmit={formProps.handleSubmit(handleSubmit)}
		>
			<Box marginBottom={2} marginTop={2}>
				<Typography variant="body1">
					{t('login.requestCodeNote')}
				</Typography>
			</Box>

			<PickOption />

			<EmailOrPhone />

			<Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			>
				{t('login.submit')}
			</Button>

			<Box marginTop={2}>
				<Link to="/login-with-code">
					<Button fullWidth>{t('login.alreadyHaveACode')}</Button>
				</Link>
			</Box>
		</form>
	);
};

export const RequestAuthCode = () => {
	const { t } = useTranslation();
	const formProps = useForm({
		defaultValues: {
			requestType: RequestType.PHONE,
			codeChannel: '',
		},
	});

	return (
		<AuthTemplate
			title={t('login.loginWithCodeTitle')}
			form={
				<FormProvider {...formProps}>
					<Form />
				</FormProvider>
			}
		/>
	);
};
