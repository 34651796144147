import { useContext, useEffect, useRef } from 'react';
import { fbLogin } from '../api/auth';
import { useAuth } from '../context/Auth';
import { FacebookContext } from '../context/Facebook';

export const CheckIfLoggedWithFacebook = () => {
	const { setToken, setFbLoading, logged } = useAuth();
	const { authStatus, logout } = useContext(FacebookContext);
	const loggedRef = useRef(false);

	useEffect(() => {
		const logoutHappened = loggedRef.current && !logged;

		if (logoutHappened) {
			logout();
		}

		loggedRef.current = logged;
	}, [logged, logout]);

	useEffect(() => {
		(async () => {
			try {
				if (!authStatus?.accessToken) return;

				const token = await fbLogin(
					authStatus.accessToken,
					authStatus.userID
				);

				if (token) {
					setToken(token);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setFbLoading(false);
			}
		})();
	}, [authStatus, setFbLoading, setToken]);

	return null;
};
