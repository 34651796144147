import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { googleLoginClientId } from '../config';
import { loadScript } from '../helpers/map';
import { useAuth } from '../context/Auth';
import { googleLogin } from '../api/auth';

export const GoogleLoginContext = createContext<{
	loaded: boolean;
	account?: google.accounts.id.CredentialResponse;
}>({ loaded: false });

export const GoogleLoginProvider = (props: { children: ReactNode }) => {
	const { loading, logged, setToken } = useAuth();
	const [account, setAccount] =
		useState<google.accounts.id.CredentialResponse>();
	const [loaded, setLoaded] = useState(false);

	const googleLoginCallback = useCallback(
		async (response: google.accounts.id.CredentialResponse) => {
			setAccount(response);

			const token = await googleLogin(response.credential);

			setToken(token);
		},
		[setToken, setAccount]
	);

	useEffect(() => {
		loadScript(
			'https://accounts.google.com/gsi/client',
			document.querySelector('head'),
			'google-login',
			() => setLoaded(true)
		);
	}, []);

	useEffect(() => {
		if (!loaded) return;

		google.accounts.id.initialize({
			client_id: googleLoginClientId,
			callback: googleLoginCallback,
		});
	}, [loaded, googleLoginCallback]);

	useEffect(() => {
		if (loading || !loaded) {
			return;
		}

		if (logged) {
			google.accounts.id.cancel();
		} else {
			google.accounts.id.prompt();
		}
	}, [loading, logged, loaded]);

	return (
		<GoogleLoginContext.Provider value={{ loaded, account }}>
			{props.children}
		</GoogleLoginContext.Provider>
	);
};

type Props = {
	onSuccess: (token: string) => void;
	onFail: (message: string) => void;
};

export const GoogleLoginButton = (props: Props) => {
	const BUTTON_ID = 'google-login-btn';
	const { loaded, account } = useContext(GoogleLoginContext);
	const { onSuccess, onFail } = props;
	const clicked = useRef(false);

	useEffect(() => {
		if (!loaded) return;

		setTimeout(() => {
			const parent = document.getElementById(BUTTON_ID) as HTMLDivElement;

			if (!parent) return;

			google.accounts.id.renderButton(parent, {
				theme: 'outline',
				type: 'standard',
				shape: 'square',
				size: 'large',
				text: 'signin_with',
				logo_alignment: 'center',
				width: `${parent.clientWidth}px`,
				click_listener: () => {
					clicked.current = true;
				},
			});
		}, 1000);
	}, [loaded]);

	const handleLogin = useCallback(
		async (googleJWT: string) => {
			if (!clicked.current) return;
			clicked.current = false;

			try {
				const token = await googleLogin(googleJWT);

				onSuccess(token);
			} catch (err) {
				console.error('Google login error', err);

				onFail('Google login failed');
			}
		},
		[onSuccess, onFail]
	);

	useEffect(() => {
		if (!account) return;

		handleLogin(account.credential);
	}, [account, handleLogin]);

	return (
		<div
			id={BUTTON_ID}
			style={{
				border: '.5px solid rgba(0, 0, 0, 0.23)',
				borderRadius: 4,
			}}
		>
			Google Login
		</div>
	);
};
