import axios from 'axios';
import {
	EstateContactStatus,
	EstateResponse,
	Lead,
	LeadResponse,
} from 'foreclosure-types';
import { baseUrl } from './config';

export const getEstateLeads = async (estateId: string) => {
	const response = await axios.get(`${baseUrl}/lead/estate/${estateId}`);

	return response.data as LeadResponse['getList'];
};

export const getAll = async () => {
	const response = await axios.get(`${baseUrl}/lead`);

	return response.data as LeadResponse['getList'];
};

export const getOne = async (id: string) => {
	const response = await axios.get(`${baseUrl}/lead/${id}`);

	return response.data as LeadResponse['getOne'];
};

export const getWatiUrl = async (id: string) => {
	const response = await axios.get(`${baseUrl}/lead/${id}/whatsapp-url`);

	return response.data as LeadResponse['watiUrl'];
};

export const getWhatsappConversation = async (id: string, limit = 100) => {
	const response = await axios.get(
		`${baseUrl}/lead/${id}/whatsapp-conversation/${limit}`
	);

	return response.data as LeadResponse['whatsAppMessages'];
};

export const getWhatsappTemplates = async () => {
	const response = await axios.get(`${baseUrl}/lead/whatsapp-templates`);

	return response.data as LeadResponse['watiTemplates'];
};

export const patchOne = async (
	id: string,
	body: Partial<Pick<Lead, 'subscribed' | 'quality'>>
) => {
	const response = await axios.patch(`${baseUrl}/lead/${id}`, body);

	return response.data as LeadResponse['getOne'];
};

export const patchEstate = async (
	leadId: string,
	estateId: string,
	body: Partial<{ status: EstateContactStatus }>
) => {
	const response = await axios.patch(
		`${baseUrl}/lead/${leadId}/estate/${estateId}`,
		body
	);

	return response.data as LeadResponse['getOne'];
};

export const getEstates = async (id: string) => {
	const response = await axios.get(`${baseUrl}/lead/${id}/estates`);

	return response.data as EstateResponse['adminGetAll'];
};

export const updateNotes = async (id: string, notes: Lead['notes']) => {
	await axios.put(`${baseUrl}/lead/${id}/notes`, {
		notes,
	});
};

export const sendWhatsappTemplate = async (
	estateId: string,
	template: string,
	contactsIds: string[]
) => {
	await axios.post(`${baseUrl}/lead/wa-template/${estateId}`, {
		contacts: contactsIds,
		template,
	});
};

export const sendWhatsappMessage = async (leadId: string, message: string) => {
	const response = await axios.post(
		`${baseUrl}/lead/${leadId}/whatsapp-message`,
		{
			message,
		}
	);

	return response.data as LeadResponse['sendWhatsMessage'];
};
