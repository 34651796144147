import axios from 'axios';
import { EditHistoryResponse } from 'foreclosure-types';
import { baseUrl } from './config';

const apiUrl = baseUrl + '/edit-history';

export const getEditHistory = async (objectId: string) => {
	const response = await axios.get(`${apiUrl}/${objectId}`);

	return response.data as EditHistoryResponse['get'];
};
