import { ReactElement, ReactNode } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MainTemplate } from './Main';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
	},
	flexCenter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

type Props = {
	title: string;
	form?: ReactNode;
};

export const AuthTemplate = (props: Props): ReactElement => {
	const classes = useStyles({});
	const { form, title } = props;

	return (
		<MainTemplate displayHeaderShadow={false}>
			<Container maxWidth="xs">
				<div className={[classes.paper, classes.flexCenter].join(' ')}>
					<Link to="/" className={classes.flexCenter}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							{title}
						</Typography>
					</Link>
					{form}
				</div>
			</Container>
		</MainTemplate>
	);
};
