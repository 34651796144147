import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { SubscriptionPrice, SubscriptionTypes, User } from 'foreclosure-types';

import { useSubscription } from '../../helpers/payment';
import { BillingAddress } from './BillingAddress';
import { useBilling, useUserCount } from '../../hooks/auth';
import { useAuth } from '../../context/Auth';
import { usePaymentModal } from '../../context/Payment';
import { Modal } from '../Modal';
import { useMobileSize } from '../../hooks/responsive';

const useStyles = makeStyles((theme) => ({
	container: {
		width: 1000,
		maxWidth: '100%',
		outline: 0,
		padding: theme.spacing(1),
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			margin: 0,
			'&&& > *': {
				borderBottom: '1px solid #e0e0e0',
				paddingBottom: theme.spacing(4),
				marginBottom: theme.spacing(3),
			},
		},
	},
	scrollContainer: {
		[theme.breakpoints.down('md')]: {
			overflowY: 'scroll',
			maxHeight: '90vh',
		},
	},
}));

const SubscriptionItem = (props: {
	title: string;
	price: string;
	count: number;
	features: string[];
	buttonLabel: string;
	onClick?: () => void;
	disabled?: boolean;
}) => {
	const { t } = useTranslation();
	const { title, price, count, features, buttonLabel, onClick } = props;

	return (
		<Grid
			xs={12}
			lg={4}
			item
			container
			spacing={1}
			alignContent="flex-start"
		>
			<Grid item xs={12}>
				<Typography variant="body1" color="primary">
					<strong>{title}</strong>
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h5">{price}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2">
					<strong>
						{count} {t('subscription.subscribers')}
					</strong>
				</Typography>
				<br />
			</Grid>
			{features.map((text, index) => (
				<Grid key={text + index} item xs={12}>
					<Typography variant="body2">{text}</Typography>
				</Grid>
			))}
			<Grid item xs={12}>
				<br />
				<br />
				<Button
					onClick={onClick}
					color="primary"
					endIcon={<ArrowForwardIos />}
					disabled={props.disabled}
				>
					{props.disabled ? 'Ai acest abonament' : buttonLabel}
				</Button>
			</Grid>
		</Grid>
	);
};

const SubscriptionNotes = () => {
	const { t } = useTranslation();
	const { logged } = useAuth();

	return (
		<Grid item xs={12} style={{ marginTop: 30 }}>
			{!logged && (
				<>
					<Typography variant="body2">
						<br />
						{t('subscription.automatic_account')}
					</Typography>
					<br />
				</>
			)}
			<Typography variant="body2">
				<strong>{t('subscription.notes')}</strong>
			</Typography>
		</Grid>
	);
};

const BasicSubscription = (props: {
	count: number;
	onClick: () => unknown;
	buttonLabel: string;
	disabled?: boolean;
}) => {
	const { t } = useTranslation();
	const { isMobile } = useMobileSize();

	const features = [t('subscription.no_ads'), t('subscription.geoportal')];

	if (!isMobile) {
		features.push('-', '-', '-', '-', '-');
	}

	return (
		<SubscriptionItem
			onClick={props.onClick}
			title="Basic"
			buttonLabel={props.buttonLabel}
			price={t('subscription.montly_price', {
				price: SubscriptionPrice.BASIC,
			})}
			count={props.count}
			features={features}
			disabled={props.disabled}
		/>
	);
};

const ProSubscription = (props: {
	count: number;
	onClick: () => unknown;
	buttonLabel: string;
	disabled?: boolean;
}) => {
	const { t } = useTranslation();
	const { isMobile } = useMobileSize();

	const features = [
		t('subscription.no_ads'),
		t('subscription.geoportal'),
		t('subscription.extra_support'),
		t('subscription.extra_intel'),
		t('subscription.legal_history'),
		t('subscription.notifications'),
	];

	if (!isMobile) {
		features.push('-');
	}

	return (
		<SubscriptionItem
			onClick={props.onClick}
			title="Pro"
			buttonLabel={props.buttonLabel}
			price={t('subscription.montly_price', {
				price: SubscriptionPrice.PRO,
			})}
			count={props.count}
			features={features}
			disabled={props.disabled}
		/>
	);
};

const AgencySubscription = (props: {
	count: number;
	onClick: () => unknown;
	buttonLabel: string;
	disabled?: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<SubscriptionItem
			onClick={props.onClick}
			title={t('subscription.agency')}
			buttonLabel={props.buttonLabel}
			price={t('subscription.montly_price', {
				price: SubscriptionPrice.AGENCY,
			})}
			count={props.count}
			features={[
				t('subscription.no_ads'),
				t('subscription.geoportal'),
				t('subscription.extra_support'),
				t('subscription.extra_intel'),
				t('subscription.legal_history'),
				t('subscription.notifications'),
				t('subscription.share_geoportal'),
			]}
			disabled={props.disabled}
		/>
	);
};

export const SubscriptionPicker = (props: { scroll?: boolean }) => {
	const classes = useStyles();
	const { scroll } = props;
	const { t } = useTranslation();
	const { create } = useSubscription();
	const { billingValid } = useBilling();
	const [subscription, setSubscription] = useState<SubscriptionTypes | null>(
		null
	);
	const { isMobile } = useMobileSize();
	const { user } = useAuth();
	const count = useUserCount();

	const shouldDisplay = (type: SubscriptionTypes) => {
		return !subscription || subscription === type;
	};
	const subscriptionButton =
		subscription === null
			? t('subscription.select')
			: t('subscription.change');

	const handleClick = (type: SubscriptionTypes) => () => {
		if (billingValid) {
			create(type);

			return;
		}

		setSubscription((prev) => (prev ? null : type));
	};
	const handleSubmit = (billingAddress: Required<User>['billing']) => {
		if (!subscription) return;

		create(subscription, billingAddress);
	};

	return (
		<div className={scroll === false ? '' : classes.scrollContainer}>
			<Grid
				container
				spacing={2}
				justifyContent="center"
				className={classes.container}
			>
				{isMobile && <SubscriptionNotes />}
				{shouldDisplay(SubscriptionTypes.BASIC) && (
					<BasicSubscription
						count={count.basic}
						buttonLabel={subscriptionButton}
						// onClick={() => create(SubscriptionTypes.BASIC)}
						onClick={handleClick(SubscriptionTypes.BASIC)}
						disabled={
							user?.subscription === SubscriptionTypes.BASIC
						}
					/>
				)}

				{shouldDisplay(SubscriptionTypes.PRO) && (
					<ProSubscription
						count={count.pro}
						buttonLabel={subscriptionButton}
						// onClick={() => create(SubscriptionTypes.PRO)}
						onClick={handleClick(SubscriptionTypes.PRO)}
						disabled={user?.subscription === SubscriptionTypes.PRO}
					/>
				)}

				{shouldDisplay(SubscriptionTypes.AGENCY) && (
					<AgencySubscription
						count={count.agency}
						buttonLabel={subscriptionButton}
						// onClick={() => create(SubscriptionTypes.AGENCY)}
						onClick={handleClick(SubscriptionTypes.AGENCY)}
						disabled={
							user?.subscription === SubscriptionTypes.AGENCY
						}
					/>
				)}

				<Grid
					xs={12}
					lg={8}
					item
					style={{ display: subscription ? 'block' : 'none' }}
				>
					<BillingAddress onSubmit={handleSubmit} />
				</Grid>

				{!isMobile && <SubscriptionNotes />}
			</Grid>
		</div>
	);
};

export const SubscriptionPickerModal = () => {
	const { paymentModalOpen, closePaymentModal } = usePaymentModal();

	return (
		<Modal open={paymentModalOpen} onClose={closePaymentModal}>
			<SubscriptionPicker />
		</Modal>
	);
};
