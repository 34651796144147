import { IntervalFilter } from './IntervalFilter';
import { InnerFiltersProps } from '../types';

export const ApartmentFilters = ({ handleChange }: InnerFiltersProps) => {
	return (
		<>
			<IntervalFilter
				label="Nr camere"
				fromKey="roomsFrom"
				toKey="roomsTo"
				sufix="camere"
				handleChange={handleChange}
				min={0}
				max={100}
			/>
			<IntervalFilter
				label="Dimensiune apartament (mp)"
				fromKey="propertySizeFrom"
				toKey="propertySizeTo"
				sufix="mp"
				handleChange={handleChange}
				min={0}
				max={1000}
			/>
		</>
	);
};
